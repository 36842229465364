import * as React from 'react';

import { AppContext } from 'services/AppContext';
import { EnvContext } from 'services/EnvContext';
import { IVistoListItemWithProgress, IVistoPlan } from 'sp';
import { ProgressBlockBase } from './ProgressBlockBase';

export function ProgressBlock(props: {
  plan: IVistoPlan;
  item: IVistoListItemWithProgress;
  hideAssignedTo?: boolean;
}) {

  const { isMobile } = React.useContext(EnvContext);
  const { propertyBag, isPlanLive } = React.useContext(AppContext);

  return <ProgressBlockBase
    plan={props.plan}
    item={props.item}
    showProgress={propertyBag?.showProgress}
    showAssignees={propertyBag?.showAssignees && !props.hideAssignedTo}
    isMobile={isMobile}
    showHistory={propertyBag?.showHistory && isPlanLive}
    historyDate={propertyBag?.historyDate}
  />;
}
