import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';
import { ApiDashboardService } from 'services/api/ApiDashboardService';
import { IntegrationService } from 'services/IntegrationService';

export const MigrateParentPlanIds = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Migrate child plans`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const childPnansKeys = {};

    const processItems = async (kind: VistoKind) => {
      const listUrl = UrlService.getListRelativeUrl(foundPlan, kind);
      const items = await sp.web.getList(listUrl).items.top(2000)();

      for (const item of items) {
        const sourceItem = item['SourceItem'];
        const found = IntegrationService.findHookForLink(sourceItem);
        if (found?.hook) {
          const childRef = await found.hook.getDashbordRef(sourceItem);
          if (childRef) {
            childPnansKeys[childRef] = true;
          }
        }
      }

      return items;
    }

    processItems(VistoKind.Action);
    processItems(VistoKind.DP);
    processItems(VistoKind.LOP);

    await ApiDashboardService.setPlanChildren(foundPlan.siteUrl, foundPlan.planId, Object.keys(childPnansKeys));

    StorageCacheService.resetCache();
    return p;
  }
});
