import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipHost } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import { IAssistantDocument } from 'services/IAssistantDocument';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

export const AssistantFileList = (props: {
  items: IAssistantDocument[];
  onSelectionChanged: (item: IAssistantDocument[]) => void;
  onItemInvoked: (item: IAssistantDocument) => void;
}) => {

  const [items, setItems] = React.useState(props.items);

  React.useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const key = currColumn.fieldName as keyof IAssistantDocument;
    const newItems = items.slice(0).sort((a, b) => ((currColumn.isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1)); 
    setColumns(newColumns);
    setItems(newItems);
  };

  const defaultColumns: IColumn[] = [
    {
      key: 'column1',
      name: TextService.format(strings.AssistanFile_ListColumnType),
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: TextService.format(strings.AssistanFile_ListColumnDescriptionType),
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'name',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: _onColumnClick,
      onRender: (item: IAssistantDocument) => (
        <TooltipHost content={`${item.fileType} file`}>
          <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
        </TooltipHost>
      ),
    },
    {
      key: 'column2',
      name: TextService.format(strings.AssistanFile_ListColumnName),
      fieldName: 'name',
      minWidth: 110,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: TextService.format(strings.AssistanFile_ListSortedAZ),
      sortDescendingAriaLabel: TextService.format(strings.AssistanFile_ListSortedZA),
      onColumnClick: _onColumnClick,
      data: 'string',
      onRender: (item: IAssistantDocument) => {
        return (
          // eslint-disable-next-line react/jsx-no-bind
          <Link onClick={() => props.onItemInvoked(item)} underline>
            {item.name}
          </Link>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: TextService.format(strings.AssistantFile_ColumnDate),
      fieldName: 'dateModifiedValue',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      onColumnClick: _onColumnClick,
      data: 'number',
      onRender: (item: IAssistantDocument) => {
        return <span>{item.dateModified}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: TextService.format(strings.AssistantFile_ColumnStatus),
      fieldName: 'status',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      onColumnClick: _onColumnClick,
      data: 'string',
      onRender: (item: IAssistantDocument) => {
        return <span>{item.status}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: TextService.format(strings.AssistantFile_ColumnSize),
      fieldName: 'fileSizeRaw',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      onColumnClick: _onColumnClick,
      onRender: (item: IAssistantDocument) => {
        return <span>{item.fileSize}</span>;
      },
    },
  ]

  const [columns, setColumns] = React.useState(defaultColumns)

  const selectionRef = React.useRef<Selection>(new Selection({
    onSelectionChanged: () => {
      const selection = selectionRef.current.getSelection();
      props.onSelectionChanged(selection as IAssistantDocument[]);
    },
    getKey: (item: any, index?: number): string => {
      return item.key;
    }
  }));

  return (
    <DetailsList
      items={items}
      compact={true}
      columns={columns}
      styles={{
        headerWrapper: {
          '.ms-DetailsHeader': {
            paddingTop: 0
          }
        }
      }}
      selectionMode={SelectionMode.multiple}
      setKey='multiple'
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      selection={selectionRef.current}
      selectionPreservedOnEmptyClick={true}
      onItemInvoked={props.onItemInvoked}
      enterModalSelectionOnTouch={true}
      ariaLabelForSelectionColumn={TextService.format(strings.AssistanFile_ListToggleSelection)}
      ariaLabelForSelectAllCheckbox={TextService.format(strings.AssistanFile_ListToggleSelectionAll)}
      checkButtonAriaLabel={TextService.format(strings.AssistanFile_ListSelectRow)}
    />
  );
}
