import * as strings from 'VistoWebPartStrings';
import { IVistoListItem, IVistoListItemWithProgress, IVistoPlan, VistoActionItem, VistoAssocItem, VistoFocusItem, VistoKeyResultItem, VistoKind, VistoSoItem } from 'sp';
import { IUndoUnit } from 'services/IUndoUnit';
import { ICommand } from 'services/ICommand';
import { IItemChanges } from 'services/Interfaces';
import { ChangesService, IChanges } from 'services/ChangesService';
import { CommandName } from 'shared/CommandName';
import { INotify } from 'services/Notify';
import { TextService } from 'services/TextService';
import { IOperationOptions } from 'services/IOperationOptions';
import { PlanDataService } from 'services/PlanDataService';
import { StorageService } from './StorageService';
import { IntegrationService } from './IntegrationService';
import { UrlService } from 'shared/urlService';
import { IAppContext } from './AppContext';

type ISortableListItem = VistoActionItem | VistoFocusItem | VistoSoItem;

export class Commands {

  public static getReorderedItems<T>(sourceItems: T[], removedIndex: number, addedIndex: number): T[] {
    const items = [...sourceItems];
    const [payload] = items.splice(removedIndex, 1);
    items.splice(addedIndex, 0, payload);
    return items;
  }

  public static makeChangeSortOrderCommand(items: ISortableListItem[], notify: INotify): ICommand {

    const updates: IItemChanges<ISortableListItem>[] = [];

    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const changes = ChangesService.getChanges({ sortOrder: item.sortOrder }, { sortOrder: i });
      if (changes.detected) {
        item.sortOrder = i;
        updates.push({ item, changes });
      }
    }

    if (updates.length > 0) {
      return {
        prepare: async () => {
          return this.makeUpdateUndoUnit(updates, notify, { enableSimpleUpdate: true });
        },
        message: TextService.format(strings.Command_ChangeOrder),
        name: CommandName.ReorderItems
      };
    }
  }

  public static makeUpdatePlanPropertyCommand(
    changes: IChanges<IVistoPlan>,
    message: string,
    notify: INotify): ICommand {

    return {
      prepare: async () => {

        return {
          do: async (plan) => {
            const updatedPlan = await StorageService.get(plan.siteUrl).updatePlanItem(plan, changes.newValues, notify);
            return updatedPlan;
          },
          undo: async (plan) => {
            const updatedPlan = await StorageService.get(plan.siteUrl).updatePlanItem(plan, changes.oldValues, notify);
            return updatedPlan;
          },
        };
      },
      message,
      name: CommandName.UpdatePlanProperty
    };
  }

  public static makeUpdateUndoUnit<T extends IVistoListItem>(updates: IItemChanges<T>[], notify: INotify, options?: IOperationOptions): IUndoUnit {
    return {
      do: async (plan) => {
        return await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, options);
      },
      undo: async (plan) => {
        return await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, { reverse: true, ...options });
      }
    };
  }

  public static makeBreakLinkAction(plan: IVistoPlan, item: IVistoListItemWithProgress, breakChildLinks: boolean, commandName: CommandName, notify: INotify): ICommand {
    return {
      prepare: async () => {
        const updates: IItemChanges<IVistoListItemWithProgress>[] = [];

        const { hookKey: itemHook } = IntegrationService.findHookForLink(item.sourceItemUrl);

        const addChanges = (target: IVistoListItemWithProgress) => {
          const { hookKey: targetHook } = IntegrationService.findHookForLink(target.sourceItemUrl);
          if (itemHook === targetHook || itemHook === 'lists' && !targetHook) {
            const changes = ChangesService.getChanges<Partial<IVistoListItemWithProgress>>({
              sourceItemUrl: target.sourceItemUrl,
            }, {
              sourceItemUrl: null,
            });
            updates.push({ item: target, changes });
          }
        };

        addChanges(item);

        if (breakChildLinks) {
          if (item.kind === VistoKind.DP) {
            PlanDataService.getDpActions(plan, item.guid).forEach(action => addChanges(action));
          }

          if (item.kind === VistoKind.LOP) {
            PlanDataService.getLopDps(plan, item.guid).forEach(dp => {
              addChanges(dp);
              PlanDataService.getDpActions(plan, dp.guid).forEach(action => addChanges(action));
            });
          }
        }

        return {
          do: async (plan) => {
            return await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, { excludeExternals: true, dashboard: true })
          },
          undo: async (plan) => {
            return await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, { reverse: true, excludeExternals: true, dashboard: true })
          },
        };
      },
      message: TextService.format(strings.Commands_BreakLinkMessage, { title: TextService.formatTitle(item, plan.items) }),
      name: commandName
    };
  }

  public static makeRenameItemCommand(plan: IVistoPlan, item: IVistoListItem, changes: IChanges<IVistoListItem>, notify: INotify): ICommand {
    return {
      prepare: async () => {
        return this.makeUpdateUndoUnit([{ item, changes }], notify, { enableSimpleUpdate: true });
      },
      message: TextService.format(strings.Commands_RenameItemMessage, { 
        title: TextService.formatTitle(item, plan.items), 
        newName: changes.newValues.name 
      }),
      name: CommandName.RenameItem
    };
  }

  public static setSelection = (context: IAppContext, guid: string) => {
    if (context) {
      const plan = context.planRef.current;
      if (plan) {
        const item = PlanDataService.getItemByGuid(plan.items, guid);
        if (item) {
          const sel = UrlService.getSelectionFromItem(item);
          if (sel) {
            context.setSelection(sel);
          }
        }
      }
    }
  };

  public static makeUpdateCommand<T extends IVistoListItem>(updates: IItemChanges<T>[], notify: INotify, options?: IOperationOptions) {
    return {
      prepare: async () => {
        return Commands.makeUpdateUndoUnit(updates, notify, options);
      },
      message: TextService.format(strings.Command_UpdateItems, { number: updates.length }),
      name: CommandName.UpdateItem
    };
  }

  public static makeCreateCommand(items: IVistoListItem[], notify: INotify, options?: IOperationOptions) {
    return {
      prepare: async () => {
        return {
          do: (p: IVistoPlan) => StorageService.get(p.siteUrl).createItems(p, items, notify, options),
          undo: (p: IVistoPlan) => StorageService.get(p.siteUrl).deleteItems(p, items, notify, options),
        };
      },
      message: TextService.format(strings.Command_CreateItems, { number: items.length }),
      name: CommandName.CreateItem
    };
  }

  public static makeDeleteCommand(items: IVistoListItem[], notify: INotify): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (p) => {
            p = await StorageService.get(p.siteUrl).deleteItems(p, items, notify, { validate: true })
            return p;
          },
          undo: async (p) => {
            p = await StorageService.get(p.siteUrl).createItems(p, items, notify, { validate: true });
            return p;
          }
        };
      },
      message: TextService.format(strings.Command_DeleteItems, { number: items.length }),
      name: CommandName.DeleteItem
    };
  }

  public static enableSimpleUpdateAction(oldItem: VistoActionItem, newItem: VistoActionItem) {
    return oldItem && newItem && oldItem.lopGuid === newItem.lopGuid;
  }

  public static makeSaveCommand(oldItem: IVistoListItem, newItem: IVistoListItem, notify: INotify) {

    const enableSimpleUpdate = newItem.kind !== VistoKind.Action
      || this.enableSimpleUpdateAction(oldItem as VistoActionItem, newItem as VistoActionItem);

    return newItem.itemId
      ? this.makeUpdateCommand([{ item: oldItem, changes: ChangesService.getChanges(oldItem, newItem) }], notify, { enableSimpleUpdate })
      : this.makeCreateCommand([newItem], notify, { enableSimpleUpdate });
  }
}
