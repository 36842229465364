import { ApiService } from './ApiService';
import { IOpenaiAssistant } from 'shared/api/openai';
import { ApiOpenaiVectorStoreService } from './ApiOpenaiVectorStoreService';

export class ApiOpenaiAssistantService {

  public static async ensureAssistant(tid: string) {
    const store = await ApiOpenaiVectorStoreService.ensureVectorStore(tid);
    const assistants = await this.getAssistants();
    let assistant = assistants.find(a => a?.name?.includes(tid));
    if (!assistant) {
      assistant = await this.createAssistant(tid, store.id);
    }
    return assistant;
  }

  public static async getAssistants(): Promise<IOpenaiAssistant[]> {
    const result = await ApiService.fetch('/openai/assistants', 'get');
    return result as IOpenaiAssistant[];
  }

  public static async createAssistant(name: string, storeId: string): Promise<IOpenaiAssistant> {
    const result = await ApiService.fetch('/openai/assistants', 'post', {
      name,
      storeId,
    });
    return result as IOpenaiAssistant;
  }

  public static async getAssistant(storeId: string): Promise<IOpenaiAssistant> {
    const result = await ApiService.fetch(`/openai/assistants/${storeId}`, 'get');
    return result as IOpenaiAssistant;
  }

  public static async deleteAssistant(storeId: string) {
    const result = await ApiService.fetch(`/openai/assistants/${storeId}`, 'delete');
    return result;
  }

}
