import { IOperationHook } from './IOperationHook';
import { IIconInfo } from './IIconInfo';
import { ICheckListItem } from './ICheckListItem';
import { TextService } from './TextService';
import strings from 'VistoWebPartStrings';
import { IVistoListItemWithProgress, IVistoPlan } from 'sp';
import { ApiDashboardService } from './api/ApiDashboardService';
import { IEnvContext } from './EnvContext';

export class IntegrationService {

  public static hooks: { [key: string]: IOperationHook } = {};

  public static findHookForLink(url: string) {
    for (const hookKey in IntegrationService.hooks) {
      const hook = IntegrationService.hooks[hookKey];
      if (hook.isRecognizedLink(url)) {
        return { hook, hookKey };
      }
    }
    return {};
  }

  public static getBrowserLink(url: string, envContext: IEnvContext): string {
    const { hook } = this.findHookForLink(url);
    return hook ? hook.getBrowserLink(url, envContext) : url;
  }

  public static async getIconInfo(url: string): Promise<IIconInfo> {
    const { hook } = this.findHookForLink(url);
    if (hook) {
      return await hook.getIconInfo(url);
    }
    return {
      iconUrl: require('static/assets/links/visplan.svg'),
      tooltipText: TextService.format(strings.LinkIconTitle_Generic)
    };
  }

  public static async transformHtml(plan: IVistoPlan, item: IVistoListItemWithProgress, html: string): Promise<string> {
    const found = item.sourceItemUrl && IntegrationService.findHookForLink(item.sourceItemUrl);
    if (found?.hook) {
      html = await found.hook.transformHtml(plan, html);
    }
    return html;
  }

  public static async getCheckList(url: string, plan: IVistoPlan): Promise<ICheckListItem[]> {
    const { hook } = this.findHookForLink(url);
    if (hook) {
      const checkList = await hook.getCheckList(url, plan);
      if (checkList) {
        return checkList;
      }
    }
  }

  public static async getPlanChildRefs(plan: IVistoPlan): Promise<string[]> {
    
    const childPnansKeys = {};

    for (const guid in plan.items) {
      const item: IVistoListItemWithProgress = plan.items[guid];

      const found = item.sourceItemUrl && IntegrationService.findHookForLink(item.sourceItemUrl);
      if (found?.hook) {
        const childRef = await found.hook.getDashbordRef(item.sourceItemUrl);
        if (childRef) {
          childPnansKeys[childRef] = true;
        }
      }
    }

    return Object.keys(childPnansKeys);
  }

  public static async updatePlanChildRefs(plan: IVistoPlan): Promise<boolean> {
    const childRefs = await IntegrationService.getPlanChildRefs(plan);
    return await ApiDashboardService.setPlanChildren(plan.siteUrl, plan.planId, childRefs);
  }
}
