import { ApiService } from './ApiService';
import { IOpenaiVectorStore } from 'shared/api/openai';

export class ApiOpenaiVectorStoreService {

  public static async ensureVectorStore(tid: string) {
    let store = await this.findVectorStore(tid);
    if (!store) {
      store = await this.createVectorStore(tid);
    }
    return store;
  }

  public static async findVectorStore(tid: string) {
    const stores = await this.getVectorStores();
    return stores.find(store => store?.name?.includes(tid));
  }

  public static async getVectorStores(): Promise<IOpenaiVectorStore[]> {
    const result = await ApiService.fetch('/openai/vector_stores', 'get');
    return result as IOpenaiVectorStore[];
  }

  public static async createVectorStore(name: string): Promise<IOpenaiVectorStore> {
    const result = await ApiService.fetch('/openai/vector_stores', 'post', {
      name,
    });
    return result as IOpenaiVectorStore;
  }

  public static async getVectorStore(storeId: string): Promise<IOpenaiVectorStore> {
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}`, 'get');
    return result as IOpenaiVectorStore;
  }

  public static async deleteVectorStore(storeId: string) {
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}`, 'delete');
    return result;
  }

}
