import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { IVistoPlan, IVistoListItemWithAssignee, IVistoListItemWithProgress, IAttachment } from 'sp';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { EnvContext } from 'services/EnvContext';
import { ProgressService } from 'services/ProgressService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { Sidebar } from './Sidebar';
import { IContextualMenuItem } from '@fluentui/react';
import { AttachDocument } from './AttachDocument';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { CommandName } from 'shared/CommandName';
import { ISidebarProps } from './ISidebarProps';
import { IntegrationService } from 'services/IntegrationService';
import { useEnvNavigate } from 'services/NavigationService';
import { IIconInfo } from 'services/IIconInfo';

export interface ISidebarItemProps<T extends IVistoListItemWithAssignee> extends ISidebarProps {
  menuItems: IContextualMenuItem[];
  overflowMenuItems: IContextualMenuItem[];
  farItems: IContextualMenuItem[];
  plan: IVistoPlan;
  item: T;
}

export function SidebarItem(props: ISidebarItemProps<IVistoListItemWithAssignee>) {

  const envContext = React.useContext(EnvContext);
  const { dispatchCommand, notify } = React.useContext(AppContext);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);

  const onSave = (name, description, assignedTo) => {

    const changes = ChangesService.getChanges({
      name: props.item.name,
      description: props.item.description,
      assignedTo: props.item.assignedTo
    }, {
      name: name,
      description: description,
      assignedTo: assignedTo
    });

    return dispatchCommand({
      prepare: async () => {
        return Commands.makeUpdateUndoUnit([{ item: props.item, changes: changes }], notify, { enableSimpleUpdate: true });
      },
      message: TextService.format(strings.ItemEditDialog_CommandUpdate, { itemName: TextService.formatTitle({ ...props.item, name }, props.plan.items) }),
      name: CommandName.UpdateItemSidebar
    }, { wrap: true });
  };

  const onLoad = async () => {
    const loaded: IVistoListItemWithAssignee = await StorageService.get(props.plan.siteUrl).loadItem(props.plan, props.item.kind, props.item.guid, notify);
    return { name: loaded.name, description: loaded.description, assignedTo: loaded.assignedTo };
  };

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.item, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const attachmentsMenuItem: IContextualMenuItem = props.item.links && {
    key: 'MenuItem_Attachments',
    text: envContext?.isMobile ? TextService.format(strings.SidebarMenuItem_Attachments) : undefined,
    iconProps: {
      iconName: 'Attach'
    },
    subMenuProps: {
      items: MenuItems.getAttachmentsMenuItems(props.item, dispatchCommand, AttachmentService.makeItemAttachmentsCommand, notify)
    }
  };

  const itemWithProgress: IVistoListItemWithProgress = props.item;

  const href = IntegrationService.getBrowserLink(itemWithProgress.sourceItemUrl, envContext);

  const navigate = useEnvNavigate(envContext?.isPortal);

  const [iconInfo, setIconInfo] = React.useState<IIconInfo>(null);
  React.useEffect(() => {
    IntegrationService.getIconInfo(itemWithProgress.sourceItemUrl).then(info => setIconInfo(info));
  }, [itemWithProgress.sourceItemUrl])

  const progresMenuItem: IContextualMenuItem = itemWithProgress.sourceItemUrl && {
    key: 'MenuItem_Progress',
    text: envContext?.isMobile ? TextService.format(strings.SidebarMenuItem_Progress) : undefined,
    title: TextService.format(strings.MenuItem_OpenProgressLinkMenuItem),
    iconProps: { 
      imageProps: { 
        src: iconInfo?.iconUrl,
        width: 16,
        height: 16
      } 
    },
    onClick: (event) => {
      event.preventDefault();
      navigate(href, itemWithProgress.guid);
    },
    href,
    target: itemWithProgress.guid
  };

  const menuItems = [
    ...(props.menuItems ?? []),
    ...(attachmentsMenuItem ? [attachmentsMenuItem] : []),
    ...(progresMenuItem ? [progresMenuItem] : [])
  ];

  const overflowMenuItems = props.overflowMenuItems ?? [];

  return <Sidebar
    advisoryTopicKey={props.advisoryTopicKey}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    menuItems={menuItems}
    overflowMenuItems={overflowMenuItems}
    farItems={props.farItems}
    onLoad={onLoad}
    content={
      <React.Fragment>
        {props.content}
        {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
      </React.Fragment>}
    name={props.item.name}
    disableName={!ProgressService.allowEdit(props.item, 'name')}
    description={props.item.description}
    disableDescription={!ProgressService.allowEdit(props.item, 'description')}
    assignedTo={props.item.assignedTo}
    disableAssignedTo={!ProgressService.allowEdit(props.item, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
    onSave={onSave}
  />;
}
