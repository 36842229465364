import React, { useState } from 'react';
import { ActionButton, IconButton, IconNames, IPanelStyles, Panel, PanelType, PrimaryButton, Stack, Text, TextField, Tooltip, TooltipHost, useTheme } from '@fluentui/react';
import { AssistantCard } from './AssistantCard';
import { UserCard } from './UserCard';
import { EnvContext } from 'services/EnvContext';
import { IAssistatCard } from 'frames/IAssistatCard';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { AssistantInputField } from 'components/assistant/AssistantInputField';
import { AppContext } from 'services/AppContext';
import { AssistantSidebarHeader } from './AssistantSidebarHeader';

export const AssistantSidebar = (props: {
  isOpen: boolean;
  cards: IAssistatCard[];
  isGenerating: boolean;
  onClearMessages: () => void;
  onDismiss: () => void;
  onSend: (text: string) => Promise<void>;
}) => {

  const [newMessage, setNewMessage] = useState<string>('');

  const { propertyBag } = React.useContext(AppContext);

  const handleSend = async () => {
    setNewMessage('');
    props.onSend(newMessage);
  }

  const { isPortal } = React.useContext(EnvContext);

  const sidebarStyles = {
    main: {
      animation: 'none',
      marginTop: isPortal ? 248 : 200,
      borderTopRightRadius: 4,
    }
  };

  const assistantConsented = propertyBag.assistantConsented;

  return <Panel
    type={PanelType.customNear}
    customWidth='400px'
    isBlocking={false}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    onRenderHeader={() => (<AssistantSidebarHeader cards={props.cards} onClearMessages={props.onClearMessages} />)}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ styles: { root: { zIndex: 999 } } }}
  >
    <Stack grow tokens={{ childrenGap: 's1', padding: 'm 0 0 0' }}>
      <Stack grow style={{ overflow: 'auto' }} data-is-scrollable='true' tokens={{ childrenGap: 's1' }}>
        {props.cards.filter(c => c.role !== 'system').map((card, idx) => {
          return card.role === 'assistant'
            ? <AssistantCard key={idx} card={card} />
            : <UserCard key={idx} card={card} />
        })}
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 's1' }}>
        <AssistantInputField
          disabled={props.isGenerating}
          text={newMessage}
          setText={setNewMessage}
          onSubmit={handleSend}
          placeholder={TextService.format(strings.Assistant_PlaceholderText)}
          styles={{ root: { flexGrow: 1 } }}
        />
        {assistantConsented && 
          <IconButton iconProps={{ iconName: 'Send' }} disabled={props.isGenerating} onClick={handleSend} title={TextService.format(strings.Assistant_ButtonSend)} />}
      </Stack>
    </Stack>

  </Panel>;

}
