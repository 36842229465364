import { IOpenaiVectorStoreFileId } from 'shared/api/openai';
import { ApiService } from './ApiService';

export class ApiOpenaiVectorStoreFileService {

  public static async getVectorStoreFileIds(storeId: string): Promise<IOpenaiVectorStoreFileId[]> {
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}/files`, 'get');
    return result as IOpenaiVectorStoreFileId[];
  }

  public static async getVectorStoreFileId(storeId: string, fileId: string): Promise<IOpenaiVectorStoreFileId> {
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}/files/${fileId}`, 'get');
    return result as IOpenaiVectorStoreFileId;
  }

  public static async createVectorStoreFileId(storeId: string, fileId: string): Promise<IOpenaiVectorStoreFileId> {
    const request = {
      fileId,
    }
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}/files`, 'post', request);
    return result as IOpenaiVectorStoreFileId;
  }

  public static async deleteVectorStoreFileId(storeId: string, fileId: string) {
    const result = await ApiService.fetch(`/openai/vector_stores/${storeId}/files/${fileId}`, 'delete');
    return result;
  }

}
