import React from 'react';
import { AppContext } from 'services/AppContext';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { setIsLoading } from 'services/LoadingIndicator';
import { PlanDataService } from 'services/PlanDataService';
import { EditItemDialog } from 'components/EditItemDialog';
import { TextService } from 'services/TextService';
import { IFieldValueUser, IVistoListItem, VistoActionItem, VistoKind } from 'sp';
import { ActionListService } from './ActionListService';
import { Link, mergeStyles, Text, useTheme } from '@fluentui/react';
import { parseJSON } from 'shared/parse';
import { ProgressService } from 'services/ProgressService';
import { ConfirmDeleteDialog } from 'dialogs/common/ConfirmDeleteDialog';
import { CellKind } from 'shared/CellKind';
import { ExportExcelDialog } from 'dialogs/export/ExportExcelDialog';
import { StorageService } from 'services/StorageService';
import { EnvContext } from 'services/EnvContext';
import strings from 'VistoWebPartStrings';
import { AppTheme } from 'components/AppTheme';
import { EditMultipleActionsDialog } from 'dialogs/EditMultipleActionsDialog';

const VISPLAN_ICON = require('static/assets/links/visplan.svg');

const Spinner = () => {
  React.useEffect(() => {
    setIsLoading(TextService.format(strings.ActionListFrame_Loading));
    return () => setIsLoading('');
  });
  return (<div />);
};

const ActionListComponent = React.lazy(() => import(
  /* webpackChunkName: 'client-datagrid' */
  './ActionListComponent'));

export const ActionListFrame = (props: {
  readOnly: boolean;
  isPlanEditEnabled: boolean;
}) => {

  const { isMobile, isPortal } = React.useContext(EnvContext);
  const { planRef, dispatchCommand, notify } = React.useContext(AppContext);

  const plan = planRef.current;
  const allUsers = PlanDataService.getPlanUsers(plan);

  const storageKeyName = `VisPlan_ActionListView_${plan.planId}`;

  const deserializeItem = (data: any) => {
    const item = { ...data };
    if (Object.keys(data).indexOf('assignedTo') >= 0) {
      item.assignedTo = item.assignedTo && item.assignedTo.map((userName: string) => allUsers.find(u => u.userName === userName));
    }
    return item;
  };

  const seializeItem = (item: any) => {
    const data: any = { ...item };
    if (Object.keys(data).indexOf('assignedTo') >= 0) {
      data.assignedTo = item.assignedTo && item.assignedTo.map((u: IFieldValueUser) => u.userName);
    }
    return data;
  };

  const updateItemInplace = (newData: any, oldData: any) => {
    const oldItem = deserializeItem(oldData);
    const newItem = deserializeItem(newData);
    const changes = ChangesService.getChanges(oldItem, newItem);
    return dispatchCommand(Commands.makeUpdateCommand([{ item: oldData, changes }], notify), {
      wrap: false
    });
  };

  const items = PlanDataService
    .getItems<VistoActionItem>(plan.items, VistoKind.Action)
    .map(x => seializeItem(x));

  const linkClassName = mergeStyles({
    cursor: 'pointer'
  });

  const renderLinkField = (e) => {
    const item = (e.column.dataField === 'name')
      ? e.data
      : PlanDataService.getItemByGuid<VistoActionItem>(plan.items, e.value);

    if (item) {
      const imageClass = mergeStyles({ height: 14, width: 14, margin: 2, marginRight: 4, verticalAlign: 'text-bottom' });
      const target = (item.kind === VistoKind.Action)
        ? { cellKind: CellKind.DP, cellId: item.dpGuid, kind: item.kind, guid: item.guid }
        : { cellKind: ActionListService.getCellKind(item.kind), cellId: item.guid, kind: item.kind, guid: item.guid };
      return (<span>
        <a title={TextService.format(strings.ActionListFrame_LinkIconTitle)} className={linkClassName} onClick={() => notify.navigateTo(target)}>
          <img src={VISPLAN_ICON} className={imageClass} />
        </a>
        {item.name}
      </span>);
    } else {
      return e.value;
    }
  };

  const [exportBuffer, setExportBuffer] = React.useState(null);
  const onExporting = (buffer) => {
    setExportBuffer(buffer);
  };

  const viewSettings = parseJSON(localStorage.getItem(storageKeyName));
  const columns = ActionListService.getColumns(plan, viewSettings, isMobile);

  const [editItem, setEditItem] = React.useState<IVistoListItem>(null);
  const [editItems, setEditItems] = React.useState<VistoActionItem[]>(null);
  const [deleteItems, setDeleteItems] = React.useState<IVistoListItem[]>([]);

  const updateItemForm = (keys: string[]) => {
    if (keys.length === 1) {
      const item = PlanDataService.getItemByGuid(plan.items, keys[0]);
      if (item) {
        setEditItem(item);
      }
    } else {
      const items = keys.map(key => PlanDataService.getItemByGuid<VistoActionItem>(plan.items, key)).filter(x => x);
      if (items.length > 0)
        setEditItems(items);
    }
  };

  const isEditorDiabled = (dataField: string, item: VistoActionItem) => {
    if (!ProgressService.allowEdit(item, dataField as any)) {
      return true;
    }
    if (dataField === 'dpGuid' && !item.lopGuid) {
      return true;
    }
    if (dataField === 'assignedTo' && !StorageService.get(plan.siteUrl).assigneeSupported) {
      return true;
    }
    return false;
  };

  const onDismiss = (changed: boolean) => {
    setEditItem(null);
    setEditItems(null);
  };

  const theme = useTheme();
  const [repaintTrigger, setRepaintTrigger] = React.useState(0);

  const actionListRef = React.useRef(null);

  const resetView = () => {
    localStorage.removeItem(storageKeyName);
    actionListRef.current.resetView();
    setRepaintTrigger(repaintTrigger + 1);
  };

  const deleteConfirmed = (itemsToDelete: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(itemsToDelete, notify), { wrap: false });
  };

  const removeItemInplace = (key: string) => {
    const item = PlanDataService.getItemByGuid(plan.items, key);
    return dispatchCommand(Commands.makeDeleteCommand([item, ...PlanDataService.getDependencis(plan.items, item)], notify), { wrap: false });
  };

  const removeItemForm = (keys: string[]) => {
    const itemsToDelete = keys
      .map(key => PlanDataService.getItemByGuid(plan.items, key))
      .reduce((r, v) => [...r, v, ...PlanDataService.getDependencis(plan.items, v)], []);
    setDeleteItems(itemsToDelete);
  };

  const listStrings = {
    EditItem: TextService.format(strings.ActionListFrame_EditItem),
    DeleteItem: TextService.format(strings.ActionListFrame_DeleteItem),
    ItemCountTemplate: TextService.format(strings.ActionListFrame_ItemCount)
  };

  const actionList = React.useMemo(() => (
    <ActionListComponent
      ref={actionListRef}
      isPortal={isPortal}
      isMobile={isMobile}
      storageKeyName={storageKeyName}
      strings={listStrings}
      onUpdateItemInplace={updateItemInplace}
      onRemoveItemInplace={removeItemInplace}
      onUpdateItemForm={updateItemForm}
      onRemoveItemForm={removeItemForm}
      isEditorDisabled={isEditorDiabled}
      onRenderLinkField={renderLinkField}
      onExporting={onExporting}
      shortUiLanguage={TextService.shortUiLanguage}
      isPlanEditEnabled={props.isPlanEditEnabled}
      readOnly={props.readOnly}
      columns={columns}
      items={items}
      themeName={AppTheme.currentThemeName}
    />
  ), [plan, repaintTrigger, props.readOnly]);

  return (
    <React.Suspense fallback={<Spinner />}>
      {actionList}
      {editItem && <EditItemDialog onDismiss={onDismiss} plan={plan} item={editItem} />}
      {editItems && <EditMultipleActionsDialog onDismiss={onDismiss} plan={plan} items={editItems} />}
      {deleteItems.length > 0 && <ConfirmDeleteDialog planItems={plan.items} items={deleteItems} onDelete={deleteConfirmed} onDismiss={() => setDeleteItems([])} />}
      {exportBuffer && <ExportExcelDialog buffer={exportBuffer} planName={planRef.current.name} onDismiss={() => setExportBuffer(null)} />}
      <Text style={{ color: theme.palette.neutralTertiary, padding: 8 }}>You can <Link onClick={resetView}>reset</Link> the view to default</Text>
    </React.Suspense>
  );
};
