import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton, Stack } from '@fluentui/react';
import { defaultModalProps } from 'dialogs/common';
import React from 'react';
import { AppContext } from 'services/AppContext';
import { AuthService } from 'services/AuthService';
import { ChangesService } from 'services/ChangesService';
import { EnvContext } from 'services/EnvContext';
import { IExecutableAction } from 'services/IExecutableAction';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { TextService } from 'services/TextService';
import { TokenKind } from 'shared/TokenKind';
import { stringifyError } from 'shared/parse';
import { UrlService } from 'shared/urlService';
import { IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { ProjectDataService } from '../services';
import { ProjectWizardProgressPage } from './ProjectWizardProgressPage';
import { ProjectWizardStartPage } from './ProjectWizardStartPage';

export const ProjectWizard = (props: {
  plan: IVistoPlan;
  onDismiss: (changed: boolean, plan?: IVistoPlan) => void;
}) => {

  const [visPlan, setVisPlan] = React.useState<IVistoPlan>(props.plan);

  const { siteUrl } = React.useContext(EnvContext);
  const { dispatchCommand, notify } = React.useContext(AppContext);

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);

  const projectSettings = planSettings.integrations?.project;

  const [enabled, setEnabled] = React.useState(typeof (projectSettings?.enabled) === 'boolean'
    ? projectSettings.enabled
    : true);

  const [pwaUrl, setPwaUrl] = React.useState(typeof (projectSettings?.pwaUrl) === 'string'
    ? projectSettings.pwaUrl
    : `${UrlService.getOrigin(siteUrl)}/sites/pwa`);

  enum ProjectWizardPage {
    start,
    progress,
  }

  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();
  const [actions, setActions] = React.useState<IExecutableAction[]>([]);

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === actions.length || actions.length === 0;

  const [statusText, setStatusText] = React.useState('');

  const pageInfo = {

    [ProjectWizardPage.start]: {
      subText: TextService.format(strings.ProjectWizard_StartPageSubTitle),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !(!enabled || enabled && pwaUrl),
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [ProjectWizardPage.progress]: {
      subText: TextService.format(strings.ProjectWizard_ProgressPageSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => false,
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<ProjectWizardPage>(ProjectWizardPage.start);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.ProjectWizard_Title),
    subText: pageInfo[currentPageId].subText
  };

  const onLoad = () => {
    ProjectDataService.configure();
    return ProjectDataService.get(`${pwaUrl}/_api/ProjectServer/Projects`);
  };

  const onWizardBack = () => {
    switch (currentPageId) {

      case ProjectWizardPage.progress:
        setCurrentPageId(ProjectWizardPage.start);
        break;
    }
  };

  const prepareActions = () => {
    const oldProjectSettings = { ...projectSettings };
    const newProjectSettings = { ...projectSettings, enabled: enabled, pwaUrl: pwaUrl };
    const changes = ChangesService.getChanges(oldProjectSettings, newProjectSettings);
    if (changes.detected) {
      setActions([
        {
          title: TextService.format(strings.MessageProject_UpdateSettings),
          execute: p => dispatchCommand(PlanSettingsService.makeSetSettingCommand(p, { integrations: { ...planSettings.integrations, project: newProjectSettings } }, notify), { wrap: false })
        }
      ]);
    } else {
      setActions([]);
    }
  };

  const executeActions = async () => {
    setCurrentProgressActionIndex(undefined);

    const executeAction = async (p: IVistoPlan, i: number) => {
      const action = actions[i];
      setStatusText(action.title);
      setCurrentProgressActionIndex(i);
      try {
        return await action.execute(p);
      } catch (err) {
        action.hasError = true;
        action.errorMessage = stringifyError(err);
        return p;
      }
    };

    let updatedVisPlan = props.plan;
    for (let i = 0; i < actions.length; ++i) {
      updatedVisPlan = await executeAction(updatedVisPlan, i);
    }

    setVisPlan(updatedVisPlan);
    setCurrentProgressActionIndex(actions.length);
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case ProjectWizardPage.start:
        setCurrentPageId(ProjectWizardPage.progress);
        prepareActions();
        break;

      case ProjectWizardPage.progress:
        if (executed) {
          props.onDismiss(true, visPlan);
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  return (
    <Dialog
      minWidth={500}
      isBlocking={true}
      dialogContentProps={contentProps} modalProps={defaultModalProps} isOpen={true} onDismiss={() => props.onDismiss(false)}>
      <Stack style={{ minHeight: 200 }}>
        {(currentPageId == ProjectWizardPage.start)
          ? <ProjectWizardStartPage enabled={enabled} setEnabled={setEnabled} pwaUrl={pwaUrl} setPwaUrl={setPwaUrl} />
          : (currentPageId == ProjectWizardPage.progress)
            ? <AuthService.AuthScope name='ProjectWizard' kind={TokenKind.project} onLoad={onLoad}>
              <ProjectWizardProgressPage statusText={statusText} actions={actions} currentProgressActionIndex={currentProgressActionIndex} />
            </AuthService.AuthScope>
            : null
        }
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={() => props.onDismiss(false)} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
};
