export enum VistoKind {
  Plan = 0,
  // Entity = 1, # Removed
  SO = 2,
  // Phase = 3, # Removed
  LOP = 4,
  DP = 5,
  Action = 6,
  Assoc = 7,
  Focus = 8,
  KeyResult = 9,
  KRV = 10,
  Effect = 11,
}


export const VistoParentKinds = [
  VistoKind.SO, 
  VistoKind.KeyResult, 
  VistoKind.KRV
]
