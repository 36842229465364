import * as React from 'react';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { MainFrame } from 'frames/MainFrame';

import { IEnvContext } from 'services/EnvContext';
import { TokenKind } from 'shared/TokenKind';
import { PlannerConfigurationService } from 'integrations/planner';
import { ProjectDataService } from 'integrations/project';
import { DevOpsDataService } from 'integrations/devops';
import { env } from 'shared/clientEnv';
import { ApiService } from 'services/api/ApiService';

/**
 * Implementation of the VistoTeams Tab content page
 */

export const VistoTeamsTab = () => {

  const onLoad = async (envContext: IEnvContext) => {

    // TODO: Update when renaming plan
    // const oldSettings = await microsoftTeams.pages.getConfig();
    // const host = 'https://' + window.location.host;
    // const newSettings = TeamsAuthService.makeTabSettings(host, envContext);
    // if (oldSettings.websiteUrl !== newSettings.websiteUrl) {
    //   try {
    //     await microsoftTeams.pages.config.setConfig({ ...oldSettings, websiteUrl: newSettings.websiteUrl })
    //     trackClient.log(`Updated websiteUrl for plan ${envContext.planId}`);
    //   } catch (err) {
    //     trackClient.warn(`Failed to udpate websiteUrl for plan ${envContext.planId}`, err);
    //   }
    // }

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => TeamsAuthService.getTeamsToken());
  };

  const siteUrl = env.getParamFromUrl('siteUrl');
  const planId = env.getParamFromUrl('planId');

  return (
    <TeamsAuthScope key={`${siteUrl}##${planId}`} name='Teams_Tab' kind={TokenKind.sharepoint} onLoad={onLoad} siteUrl={siteUrl} planId={planId}>
      <MainFrame />
    </TeamsAuthScope>
  );
};
