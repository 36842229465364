import * as React from 'react';
import { Stack, Text, TextField, ITextFieldStyles, ITextStyles, mergeStyles, useTheme } from '@fluentui/react';
import { RichText } from '@pnp/spfx-controls-react/lib/controls/richText';
import styles from './TitleBlock.module.scss';
import * as strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { AssigneePicker } from 'dialogs/common/AssigneePicker';
import { AssigneeBlock } from 'frames/TopFrame/sidebars/common/AssigneeBlock';
import { IFieldValueUser } from 'sp';

export function TitleBlock(props: {
  edit: boolean;
  fontSize: string;

  name: string;
  nameDisabled?: boolean;
  onNameChanged?: (val: string) => void;

  description: string;
  descriptionDisabled?: boolean;
  onDescriptionChanged?: (val: string) => void;

  hideAssignedTo?: boolean;
  assignedTo?: IFieldValueUser[];
  assignedToDisabled?: boolean;
  onAssignedToChanged?: (val: IFieldValueUser[]) => void;

  onClick?: () => void;
}) {

  const theme = useTheme();

  const textStyles: Partial<ITextStyles> = {
    root: {
      fontSize: props.fontSize,
      margin: 0,
      padding: 's2',
    },
  };

  const textFieldStyles = {
    root: {
      minWidth: 250
    },

    field: {
      fontSize: props.fontSize,
      lineHeight: 'normal',
      padding: 's2',
      '&::placeholder': {
        fontSize: props.fontSize,
        color: theme.palette.neutralTertiary
      }
    },

    fieldGroup: {
      height: 'auto',
      minHeight: props.fontSize,
      fontSize: props.fontSize
    }
  };

  const onNameChanged = (_, val) => {
    props.onNameChanged(val);
  };

  const onAssignedToChanged = (val) => {
    props.onAssignedToChanged(val);
  }

  const onDescriptionChanged = (val: string) => {
    if (val) {
      const cleared = val.replace(/\s/g, '');
      if (cleared === '<p><br></p>' || cleared === '<p></p>') {
        val = '';
      }
    }
    props.onDescriptionChanged(val);
    return val;
  };

  const richtextStyle = mergeStyles({
    position: 'relative'
  });

  /* workaround https://github.com/pnp/sp-dev-fx-controls-react/issues/265 */
  return (
    <Stack className={styles.title} tokens={{ childrenGap: 's2' }}>
      {props.edit
        ? <TextField autoFocus placeholder={TextService.format(strings.TitleBlock_NameLabel)} disabled={props.nameDisabled} autoComplete='off' styles={textFieldStyles} multiline rows={1} autoAdjustHeight value={props.name} onChange={onNameChanged} />
        : <Text block className={styles.name} styles={textStyles}>{props.name}</Text>
      }
      {props.edit
        ? <RichText className={richtextStyle + ' ql-active'} placeholder={TextService.format(strings.TitleBlock_DescriptionLabel)} isEditMode={props.edit && !props.descriptionDisabled} value={props.description} onChange={onDescriptionChanged} />
        : <div className='ql-editor' dangerouslySetInnerHTML={{ __html: props.description }} ></div>
      }
      {!props.hideAssignedTo && (props.edit
        ? <AssigneePicker label={TextService.format(strings.EditActionDialog_AssigneeLabel)} disabled={props.assignedToDisabled} setValue={onAssignedToChanged} value={props.assignedTo} />
        : <AssigneeBlock assignedTo={props.assignedTo} />
      )}
    </Stack>
  );

}
