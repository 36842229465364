import React from 'react';
import strings from 'VistoWebPartStrings';
import { DefaultButton, FontSizes, Stack } from '@fluentui/react';
import { ICheckListItem } from 'services/ICheckListItem';
import { TextService } from 'services/TextService';
import { SidebarCheckListItem } from './SidebarCheckListItem';

export const SidebarCheckList = (props: {
  checkList: ICheckListItem[];
}) => {

  const [isExpanded, setExpanded] = React.useState(false);

  return props.checkList ? (
    <Stack horizontalAlign='start' tokens={{ childrenGap: 'm' }}>
      <DefaultButton
        iconProps={{ iconName: 'CheckList' }}
        styles={{ root: { fontSize: FontSizes.small, padding: '0 4px', height: 24 } }}
        disabled={props.checkList.length === 0}
        text={TextService.format(strings.SidebarCheckList_DoneTotal, { done: props.checkList?.filter(x => x.checked).length ?? 0, total: props.checkList?.length ?? 0 })}
        checked={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      />
      {isExpanded &&
        <Stack tokens={{ childrenGap: 's2' }}>
          {props.checkList.map(item => (<SidebarCheckListItem key={item.id} item={item} />))}
        </Stack>}
    </Stack>
  ) : null;
};
