import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack } from '@fluentui/react';
import { PlanWizardOperation, PlanWizardTrigger } from './PlanWizardTypes';
import { TextService } from 'services/TextService';
import { IWizardChoiceGroupOption, WizardChoiceGroup } from 'components/WizardChoiceGroup';
import { SitePicker } from 'dialogs/common/SitePicker';
import { EnvContext } from 'services/EnvContext';
import { LicenseService } from 'services/LicenseService';

export function PlanWizardStartPage(props: {
  trigger: PlanWizardTrigger;
  disabled: boolean;
  siteUrl: string;
  setSiteUrl: (val: string) => void;
  operation: PlanWizardOperation;
  setOperation: (val: PlanWizardOperation) => void;
}) {

  const { userObjectId } = React.useContext(EnvContext)

  const operations: IWizardChoiceGroupOption[] = [];

  if (props.trigger === 'TeamsConfigTab' || props.trigger === 'SpfxSiteHeader' || props.trigger === 'SpfxConfigurationButton') {
    operations.push({
      key: PlanWizardOperation.CreateNew.toString(),
      text: TextService.format(strings.PlanWizardStartPage_CreateNewLabel),
      description: TextService.format(strings.PlanWizardStartPage_CreateNewDescription),
    }, {
      key: PlanWizardOperation.OpenExisting.toString(),
      text: TextService.format(strings.PlanWizardStartPage_OpenExistingLabel),
      description: TextService.format(strings.PlanWizardStartPage_OpenExistingDescription)
    });
  }

  const label = (props.operation === PlanWizardOperation.CreateNew)
    ? TextService.format(strings.PlanWizardStartPage_CreateLocation)
    : TextService.format(strings.PlanWizardStartPage_OpenLocation);

  const isUpgrade = props.trigger === 'UpgradePlaceholder';

  if (isUpgrade) {
    operations.push({
      key: PlanWizardOperation.Upgrade.toString(),
      text: TextService.format(strings.PlanSettings_BlockUpgrade),
      description: TextService.format(strings.PlanWizardStartPage_Upgrade)
    });
  }

  const disabled = props.disabled || !LicenseService.license?.orchestrationEnabled || !userObjectId
  const title = !userObjectId ? TextService.format(strings.PlanWizardStartPage_NotLoggedIn) 
    : LicenseService.license?.orchestrationEnabled 
        ? undefined 
        : TextService.format(strings.Message_FeatureDisabled, { feature: 'Orchestration' });

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <WizardChoiceGroup
        disabled={props.disabled}
        options={operations}
        selectedKey={props.operation.toString()}
        onKeyChanged={val => props.setOperation(+val)}
      />
      {!isUpgrade && <SitePicker
        label={label}
        disabled={disabled}
        title={title}
        siteUrl={props.siteUrl}
        setSiteUrl={props.setSiteUrl}
      />}
    </Stack>
  );
}
