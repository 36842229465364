import { getObjectValues } from 'shared/parse';
import {
  VistoDpItem,
  VistoSoItem,
  IVistoListItemWithProgress,
  IVistoPlan,
  VistoLopItem,
  VistoKind,
  VistoActionItem,
  VistoAssocItem,
  IFieldValueUser,
  IVistoListItem
} from 'sp';
import { ChangesService } from './ChangesService';
import { IItemChanges } from './Interfaces';
import { PlanDataService } from './PlanDataService';
import { IntegrationService } from './IntegrationService';
import { TextService } from 'services/TextService';
import { IProgressData } from './IProgressData';
import { clearInfoBar, INotify, NotificationType, notifyInfoBar } from './Notify';
import { StorageService } from './StorageService';
import { Operation } from 'shared/Operation';
import { IOperationOptions } from './IOperationOptions';
import strings from 'VistoWebPartStrings';
import { StorageCacheService } from './StorageCacheService';
import { PlanValidationService } from './PlanValidationService';
import { ExcelService } from './ExcelService';

export interface IVersionData {
  created: Date;
  versionId: string;
  versionLabel: string;
  editor: IFieldValueUser;

  [key: string]: any;
}

export class ProgressService {

  public static getPlannedPercentComplete(startDate: Date, endDate: Date, statusDate: Date) {

    statusDate = TextService.getDate(statusDate);
    startDate = TextService.getDate(startDate);
    endDate = TextService.getDate(endDate);

    if (startDate && endDate) {

      let plannedPercentComplete = Math.round(100 * (statusDate.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime() + 24 * 60 * 60 * 1000));

      if (plannedPercentComplete < 0)
        plannedPercentComplete = 0;
      if (plannedPercentComplete > 100)
        plannedPercentComplete = 100;

      return plannedPercentComplete;
    } else if (startDate && statusDate > startDate || endDate && statusDate > endDate) {
      return 100;
    }
  }

  public static isCompleted(a: IVistoListItemWithProgress) {
    return TextService.isValidNumber(a.percentComplete) && a.percentComplete >= 99.99;
  }

  public static getPercentComplete(data: IProgressData, statusDate: Date) {

    statusDate = TextService.getDate(statusDate);
    const startDate = TextService.getDate(data.startDate);
    const endDate = TextService.getDate(data.endDate);

    if (TextService.isValidNumber(data.percentComplete)) {
      return data.percentComplete;
    } else if (startDate && statusDate > startDate || endDate && statusDate > endDate) {
      return 0;
    }
  }

  private static getWeightedSoItems(plan: IVistoPlan, so: VistoSoItem) {
    return PlanDataService.getItems<VistoAssocItem>(plan.items, VistoKind.Assoc)
      .filter(x => x.soGuid === so.guid)
      .map(assoc => ({
        item: PlanDataService.getItemByGuid<VistoActionItem>(plan.items, assoc.actionGuid),
        weight: assoc.confidence
      }))
      .filter(x => x.item /*&& !x.item.hidden*/);
  }

  public static allowRecalculation(item: IVistoListItemWithProgress, name: keyof IProgressData) {
    const hook = getObjectValues(IntegrationService.hooks).find(h => h.isRecognizedLink(item.sourceItemUrl));
    return !hook || hook && hook.allowRecalculation(name);
  }

  public static allowEdit(item: IVistoListItemWithProgress, name: keyof IProgressData) {
    const hook = getObjectValues(IntegrationService.hooks).find(h => h.isRecognizedLink(item.sourceItemUrl));
    return !hook || hook && hook.allowEdit(name);
  }

  private static getActionProgress(item: IVistoListItemWithProgress, statusDate: Date) {
    return {
      percentComplete: this.allowRecalculation(item, 'percentComplete')
        ? this.getPercentComplete(item, statusDate)
        : item.percentComplete,
      plannedPercentComplete: this.allowRecalculation(item, 'plannedPercentComplete')
        ? this.getPlannedPercentComplete(item.startDate, item.endDate, statusDate)
        : item.plannedPercentComplete
    };
  }

  public static getAllPercentCompleteUpdates(plan: IVistoPlan) {

    const updates: IItemChanges<IVistoListItemWithProgress>[] = [];

    const actions = PlanDataService.getItems<VistoActionItem>(plan?.items, VistoKind.Action);
    for (let action of actions) {

      const progress = this.getActionProgress(action, plan.statusDate);
      const changes = ChangesService.getChanges(action, progress);

      if (changes.detected)
        updates.push({ item: action, changes });
    }

    this.getPercentCompleteUpdates(
      plan, VistoKind.DP,
      (dp: VistoDpItem) => PlanDataService.getDpActions(plan, dp.guid).map(item => ({ item: item, weight: 1 })),
      updates
    );

    this.getPercentCompleteUpdates(
      plan, VistoKind.LOP,
      (lop: VistoLopItem) => PlanDataService.getLopDps(plan, lop.guid).map(item => ({ item: item, weight: 1 })),
      updates
    );

    this.getPercentCompleteUpdates(
      plan, VistoKind.SO,
      (so: VistoSoItem) => this.getWeightedSoItems(plan, so),
      updates
    );

    return updates;
  }

  public static itemCalculationDisabled: { [key: string]: (item: IVistoListItemWithProgress) => boolean } = {};

  private static isItemIncludedInCalculation(item: IVistoListItemWithProgress) {
    const rules = Object.keys(ProgressService.itemCalculationDisabled).map(key => ProgressService.itemCalculationDisabled[key]);
    for (const itemCalculationDisabled of rules) {
      if (itemCalculationDisabled(item))
        return false;
    }
    return true;
  }

  private static getPercentCompleteUpdates<T extends IVistoListItemWithProgress>(
    plan: IVistoPlan,
    kind: VistoKind,
    getWeightedItems: (item: T) => { item: IVistoListItemWithProgress, weight: number }[],
    updates: IItemChanges<IVistoListItemWithProgress>[]
  ) {

    const listItems = PlanDataService.getItems<T>(plan.items, kind).filter(item => ProgressService.isItemIncludedInCalculation(item));

    const getProperty = (item: IVistoListItemWithProgress, name: keyof IVistoListItemWithProgress): any => {
      const updated = updates.find(x => x.item.guid === item.guid);
      if (updated && Object.keys(updated.changes.newValues).indexOf(name) >= 0)
        return updated.changes.newValues[name];
      else
        return item[name];
    };

    for (let i = 0; i < listItems.length; ++i) {
      const item = listItems[i];
      const childItems = getWeightedItems(item);

      let result: IProgressData = {
        percentComplete: 0,
        plannedPercentComplete: 0
      };

      let percentCompleteWeightSumm = 0;
      let plannedPercentCompleteWeightSumm = 0;

      let haveIncompleteItems = false;

      for (var childItem of childItems) {

        const progress: IProgressData = {
          startDate: getProperty(childItem.item, 'startDate'),
          endDate: getProperty(childItem.item, 'endDate'),
          percentComplete: getProperty(childItem.item, 'percentComplete'),
          plannedPercentComplete: getProperty(childItem.item, 'plannedPercentComplete'),
        };

        if (!result.startDate || progress.startDate && progress.startDate < result.startDate)
          result.startDate = progress.startDate;

        if (!result.endDate || progress.endDate && result.endDate < progress.endDate)
          result.endDate = progress.endDate;

        const weight = childItem.weight || 1; // can be 1, 3, 9

        const havePercentComplete = TextService.isValidNumber(progress.percentComplete);
        const havePlannedPercentComplete = TextService.isValidNumber(progress.plannedPercentComplete);

        if (!havePercentComplete && !havePlannedPercentComplete) {
          continue;
        }

        if (havePercentComplete) {
          result.percentComplete += progress.percentComplete * weight;
          percentCompleteWeightSumm += weight;
        } else {
          haveIncompleteItems = true;
        }

        if (havePlannedPercentComplete) {
          result.plannedPercentComplete += progress.plannedPercentComplete * weight;
          plannedPercentCompleteWeightSumm += weight;
        } else {
          haveIncompleteItems = true;
        }
      }

      const changes = ChangesService.getChanges<IVistoListItemWithProgress>(item, {
        startDate: this.allowRecalculation(item, 'startDate') ? result.startDate : item.startDate,
        endDate: this.allowRecalculation(item, 'endDate') ? result.endDate : item.endDate,
        percentComplete: this.allowRecalculation(item, 'percentComplete')
          ? (percentCompleteWeightSumm ? Math.round(result.percentComplete / percentCompleteWeightSumm) : null)
          : item.percentComplete,
        plannedPercentComplete: this.allowRecalculation(item, 'plannedPercentComplete')
          ? ((plannedPercentCompleteWeightSumm && !haveIncompleteItems) ? Math.round(result.plannedPercentComplete / plannedPercentCompleteWeightSumm) : null)
          : item.plannedPercentComplete
      });

      if (changes.detected) {
        updates.push({ item, changes });
      }
    }

    return updates;
  }

  public static getProgressAt(history: IVersionData[], statusDate: Date): IProgressData {
    let result = {};
    for (let i = history.length - 1; i >= 0; --i) {
      const h = history[i];
      if (TextService.compareDateTime(TextService.getDate(statusDate), TextService.getDate(h.created)) <= 0) {
        result = h;
      }
    }
    return result;
  }

  public static async ensurePercentComplete(
    plan: IVistoPlan,
    notify: INotify
  ): Promise<IVistoPlan> {

    try {
      clearInfoBar(notify, 'PercentComplete');
      const updates = ProgressService.getAllPercentCompleteUpdates(plan);
      if (updates.length) {
        plan = await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, { excludeExternals: true, excludePercentComplete: true });
      }
    } catch (error) {
      notifyInfoBar(notify, {
        type: NotificationType.error,
        group: 'PercentComplete',
        message: TextService.format(strings.ErrorMessage_EnsurePercentComplete),
        error: error
      });
    }

    return plan;
  }

  public static async ensureSync(
    plan: IVistoPlan,
    items: IVistoListItem[],
    notify: INotify,
    operation: Operation,
    options: IOperationOptions
  ): Promise<IVistoPlan> {

    if (operation !== Operation.load) {
      StorageCacheService.resetCache();
    }

    if (!options?.excludeExternals) {
      for (const hookKey in IntegrationService.hooks) {
        const hook = IntegrationService.hooks[hookKey];
        plan = await hook.synchronize(plan, items, notify, operation, options);
      }

      plan = await ExcelService.synchronize(plan, items, notify, operation, options);
    }

    if (!options?.excludePercentComplete) {
      plan = await this.ensurePercentComplete(plan, notify);
    }

    if (options?.validate) {
      PlanValidationService.validate(plan, notify);
    }

    const updateDashboard = options?.dashboard ||
      operation === Operation.delete && items.some((x: IVistoListItemWithProgress) => !!x.sourceItemUrl);

    if (updateDashboard) {
      await IntegrationService.updatePlanChildRefs(plan);
    }

    return plan;
  }

}
