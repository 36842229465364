import { initializeIcons, Theme } from '@fluentui/react';
import { loadTheme as loadPalette } from '@microsoft/load-themed-styles';
import { createTheme, loadTheme } from '@fluentui/react';
import { env } from 'shared/clientEnv';

initializeIcons(document?.location?.protocol === 'file:' ? 'assets/' : undefined);

export class AppTheme {

  static teamsTheme = {

    'blue': {
      palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#242424',
        white: '#F5F5F5',
      }
    },

    'default': {
      palette: {
        themePrimary: '#6264a7',
        themeLighterAlt: '#f7f7fb',
        themeLighter: '#e1e1f1',
        themeLight: '#c8c9e4',
        themeTertiary: '#989ac9',
        themeSecondary: '#7173b0',
        themeDarkAlt: '#585a95',
        themeDark: '#4a4c7e',
        themeDarker: '#37385d',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#242424',
        white: '#F5F5F5',
      }
    },

    'dark': {
      palette: {
        themePrimary: '#7275c4',
        themeLighterAlt: '#050508',
        themeLighter: '#12131f',
        themeLight: '#22233b',
        themeTertiary: '#444676',
        themeSecondary: '#6467ad',
        themeDarkAlt: '#7e80ca',
        themeDark: '#8f91d2',
        themeDarker: '#aaacde',
        neutralLighterAlt: '#292929',
        neutralLighter: '#313131',
        neutralLight: '#3f3f3f',
        neutralQuaternaryAlt: '#484848',
        neutralQuaternary: '#4f4f4f',
        neutralTertiaryAlt: '#6d6d6d',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#1f1f1f',
      },

      semanticColors: {
        primaryButtonText: '#ffffff',
        primaryButtonTextHovered: '#ffffff',
        primaryButtonTextPressed: '#ffffff',
      }
    },

    'contrast': {
      palette: {
        themePrimary: '#000',
        themeLighterAlt: '#ffff01',
        themeLighter: '#FF0000',
        themeLight: '#00FF00',
        themeTertiary: '#FF0000',
        themeSecondary: '#6467ad',
        themeDarkAlt: '#FFFF01',
        themeDark: '#8f91d2',
        themeDarker: '#aaacde',
        neutralLighterAlt: '#292929',
        neutralLighter: '#FFFF01',
        neutralLight: '#3f3f3f',
        neutralQuaternaryAlt: '#ffff01',
        neutralQuaternary: '#4f4f4f',
        neutralTertiaryAlt: '#6d6d6d',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#fff',
        neutralPrimary: '#ffffff',
        neutralDark: '#fff',
        black: '#fff',
        white: '#000',
      },

      semanticColors: {
        primaryButtonText: '#FFFFFF',
        primaryButtonTextHovered: '#000000',
        primaryButtonTextPressed: '#000000',
      }
    },
  };

  static currentThemeName = '';
  static currentTheme: Theme;

  public static loadAppTheme(name: string) {

    if (AppTheme.currentThemeName === name) {
      return AppTheme.currentTheme;
    }
    const theme = createTheme(AppTheme.teamsTheme[name]);
    loadTheme(theme);
    loadPalette({ ...theme.palette, ...theme.semanticColors });
    AppTheme.currentThemeName = name;
    AppTheme.currentTheme = theme;
    return theme;
  }
}
