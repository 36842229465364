import { UrlService } from 'shared/urlService';
import { SharepointService } from 'services/SharepointService';
import { AuthService } from 'services/AuthService';
import { getListDefinition, IVistoListItem, IVistoPlan, ListItemUpdater, VistoKind } from 'sp';

export class ItemInfoService {

  static getSpList(plan: IVistoPlan, kind: VistoKind) {
    const spListUrl = UrlService.getListRelativeUrl(plan, kind);
    const sp = AuthService.getSpClient(plan.siteUrl, 'short');
    const spList = sp.web.getList(spListUrl);
    return spList;
  }

  public static async getSpItemInfoById(plan: IVistoPlan, kind: VistoKind, itemId: number) {
    const spList = this.getSpList(plan, kind);
    const spItem = await spList.items.getById(itemId)();
    const updater = new ListItemUpdater(0);
    const item = SharepointService.getItemFromSpItem(updater, spItem, kind);
    const result = {
      key: item.itemId,
      guid: item.guid,
      name: item.name,
    };
    return result;
  }

  public static async getSpItemInfoByGuid(plan: IVistoPlan, kind: VistoKind, guid: string) {
    const spList = this.getSpList(plan, kind);

    const definition = getListDefinition<IVistoListItem>(kind);
    const guidFieldName = definition.fields.guid.name;

    const [spItem] = await spList.items.filter(`${guidFieldName} eq '${guid}'`)();
    const updater = new ListItemUpdater(0);
    const item = SharepointService.getItemFromSpItem(updater, spItem, kind);
    return {
      key: item.itemId,
      guid: item.guid,
      name: item.name,
    }
  }

  public static async getSpItemInfos(plan: IVistoPlan, kind: VistoKind, filter: string) {
    const spList = this.getSpList(plan, kind);
    const spItems = await spList.items.filter(`substringof('${filter}',Title)`).top(10)();
    const updater = new ListItemUpdater(0);
    const result = spItems.map(spItem => {
      const item = SharepointService.getItemFromSpItem(updater, spItem, kind);
      return {
        key: item.itemId,
        guid: item.guid,
        name: item.name,
      };
    });
    return result;
  }

}
