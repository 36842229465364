import * as React from 'react';
import { TextService } from 'services/TextService';
import { Stack } from '@fluentui/react';
import { IVistoPlan, VistoKind } from 'sp';
import { InfoBar, useErrorInfo } from 'components/InfoBar';
import * as strings from 'VistoWebPartStrings';
import { SharepointExternalDataService, ItemInfoService } from '../services';
import { ApiDashboardService } from 'services/api/ApiDashboardService';
import { NotificationType } from 'services/Notify';
import { UrlService } from 'shared/urlService';
import { SharePointSiteUrlPicker } from './SharePointSiteUrlPicker';
import { SharePointSitePlanPicker } from './SharePointSitePlanPicker';
import { SharePointItemKindPicker } from './SharePointItemKindPicker';
import { SharePointItemPicker } from './SharePointItemPicker';
import { EnvContext } from 'services/EnvContext';

export function SharePointExternalDataLinkEditor(props: {
  plan: IVistoPlan;
  sourceItemUrl: string;
  setSourceItemUrl: (sourceItemUrl: string) => void;
}) {

  const envContext = React.useContext(EnvContext);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const loadPlanInfos = async () => {
    const result = await ApiDashboardService.getRelatedPlans(envContext.tid, envContext.userObjectId);
    return result;
  }

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setErrorInfo(null);
    setIsLoading(true);
    loadPlanInfos().then((newPlanInfos) => {
      setPlanInfos(newPlanInfos);
      setIsLoading(false);
    }, error => {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.VisPlanPicker_ErrorLoading), error });
      setIsLoading(false);
    })
  }, []);

  const defaultSiteUrl = SharepointExternalDataService.getSiteUrl(props.sourceItemUrl);
  const [selectedSiteUrl, _setSelectedSiteUrl] = React.useState(defaultSiteUrl);

  const defaultPlanId = SharepointExternalDataService.getPlanId(props.sourceItemUrl);
  const [selectedPlanId, _setSelectedPlanId] = React.useState(defaultPlanId);

  const defaultListKind = SharepointExternalDataService.getListKind(props.sourceItemUrl) ?? VistoKind.LOP;
  const [selectedItemKind, _setSelectedItemKind] = React.useState(defaultListKind);

  const defaultSelectedItemId = SharepointExternalDataService.getItemId(props.sourceItemUrl);
  const [selectedItemId, _setSelectedItemId] = React.useState(defaultSelectedItemId);

  const [planInfos, setPlanInfos] = React.useState<{ SiteUrl: string, PlanRef: string, ChannelRef?: string }[]>([{
    SiteUrl: defaultSiteUrl,
    PlanRef: defaultPlanId,
  }]);

  const updateSourceItemUrl = (opts: { siteUrl: string, planId: string, kind: VistoKind, itemId: number }) => {
    if (opts.siteUrl && opts.planId && opts.itemId) {
      ItemInfoService.getSpItemInfoById(opts, opts.kind, opts.itemId).then(info => {
        const channelId = planInfos.find(x => x.SiteUrl === opts.siteUrl && x.PlanRef === opts.planId)?.ChannelRef;
        const url = UrlService.getSourceLinkFromItem(opts, opts, channelId, envContext);
        props.setSourceItemUrl(url);
      });
    } else {
      props.setSourceItemUrl(undefined);
    }
  }

  const setSelectedSiteUrl = (val: string) => {
    _setSelectedSiteUrl(val);
    _setSelectedPlanId(undefined);
    updateSourceItemUrl({ siteUrl: val, planId: selectedPlanId, kind: selectedItemKind, itemId: selectedItemId })
  }

  const setSelectedPlanId = (val: string) => {
    _setSelectedPlanId(val);
    _setSelectedItemId(undefined);
    updateSourceItemUrl({ siteUrl: selectedSiteUrl, planId: val, kind: selectedItemKind, itemId: selectedItemId });
  }

  const setSelectedItemKind = (val: VistoKind) => {
    _setSelectedItemKind(val);
    _setSelectedItemId(undefined);
    updateSourceItemUrl({ siteUrl: selectedSiteUrl, planId: selectedPlanId, kind: val, itemId: selectedItemId })
  }

  const setSelectedItemId = (val: number) => {
    _setSelectedItemId(val);
    updateSourceItemUrl({ siteUrl: selectedSiteUrl, planId: selectedPlanId, kind: selectedItemKind, itemId: val })
  }

  return (
    <Stack tokens={{ padding: 's1', childrenGap: 's1' }}>
      <InfoBar {...errorInfo} />
      <Stack tokens={{ childrenGap: 's1' }}>
        <SharePointSiteUrlPicker disabled={isLoading} label={TextService.format(strings.VisPlanPicker_Site)} planInfos={planInfos} siteUrl={selectedSiteUrl} setSiteUrl={setSelectedSiteUrl} />
        <SharePointSitePlanPicker disabled={isLoading} label={TextService.format(strings.VisPlanPicker_Plan)} planInfos={planInfos} siteUrl={selectedSiteUrl} planId={selectedPlanId} setPlanId={setSelectedPlanId} />
        <Stack horizontal tokens={{ childrenGap: 'm' }}>
          <SharePointItemKindPicker disabled={isLoading} label={TextService.format(strings.VisPlanPicker_ItemType)} itemKind={selectedItemKind} setItemKind={setSelectedItemKind} />
          <SharePointItemPicker disabled={isLoading} label={TextService.format(strings.VisPlanPicker_ItemId)} siteUrl={selectedSiteUrl} planId={selectedPlanId} itemKind={selectedItemKind} itemId={selectedItemId} setItem={setSelectedItemId} />
        </Stack>
      </Stack>
    </Stack>
  );

}
