import * as React from 'react';
import { IContextualMenuItem, ContextualMenuItemType, ICommandBarItemProps, IContextualMenuItemProps, useTheme } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';
import { IAttachment, VistoFocusItem, IItemWithAttachments, IVistoListItemWithProgress, VistoEffectItem, IVistoPlan, VistoKind } from 'sp';
import { ICommand } from 'services/ICommand';
import { MakeAttachmentCommand, AttachmentService } from 'services/AttachmentService';
import { IPropertyBag } from 'services/IPropertyBag';
import { INotify } from 'services/Notify';
import { IntegrationService } from 'services/IntegrationService';
import { TextService } from 'services/TextService';
import { FileTypeIcon, IconType } from '@pnp/spfx-controls-react/lib/controls/fileTypeIcon';
import { ICommandOptions } from 'services/ICommandOptions';
import { LicenseService } from 'services/LicenseService';
import { IIsDisabled } from './sidebars/common/IIsDisabled';
import { IEnvContext } from 'services/EnvContext';

export class MenuItems {

  public static getCopyToClipboardMenuItem = (onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_CopyLink',
    text: TextService.format(strings.MenuItem_CopyLink),
    iconProps: { iconName: 'Copy' },
    onClick
  })

  public static getBreakProgressLinkMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteProgressLinkMenuItem',
    text: TextService.format(strings.MenuItem_BreakProgressLink),
    iconProps: { iconName: 'RemoveLink' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getEditProgressLinkMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_EditProgressLinkMenuItem',
    text: TextService.format(strings.MenuItem_EditProgressLinkMenuItem),
    iconProps: { iconName: 'AddLink' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getOpenProgressLinkMenuItem = (item: IVistoListItemWithProgress, envContext: IEnvContext): IContextualMenuItem => {
    const isDisabled = !item.sourceItemUrl ? { disabled: true, why: TextService.format(strings.DisabledReason_NoProgressSourceLink) } : undefined;
    return {
      key: 'MenuItem_OpenProgressLinkMenuItem',
      text: TextService.format(strings.MenuItem_OpenProgressLinkMenuItem),
      iconProps: { iconName: 'OpenInNewWindow' },
      href: IntegrationService.getBrowserLink(item.sourceItemUrl, envContext),
      target: item.guid,
      disabled: isDisabled?.disabled,
      title: isDisabled?.why
    }
  }

  private static getDefaultMenuItem = (isDisabled: IIsDisabled, editable: boolean, editText: string, viewText: string, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_Default',
    text: editable ? editText : viewText,
    iconProps: { iconName: editable ? 'Edit' : 'View' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDividerMenuItem = (position: number): IContextualMenuItem => ({
    key: `MenuItem_Divider_${position}`,
    itemType: ContextualMenuItemType.Divider
  })

  public static getDefaultMenuItemValidation = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) =>
    MenuItems.getDefaultMenuItem(isDisabled, editable, TextService.format(strings.MenuItem_EditValidation), TextService.format(strings.MenuItem_ViewValidation), onClick)

  public static getDefaultMenuItemAction = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) =>
    MenuItems.getDefaultMenuItem(isDisabled, editable, TextService.format(strings.MenuItem_EditAction), TextService.format(strings.MenuItem_ViewAction), onClick)

  public static getDefaultMenuItemDp = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) =>
    MenuItems.getDefaultMenuItem(isDisabled, editable, TextService.format(strings.MenuItem_EditDp), TextService.format(strings.MenuItem_ViewDp), onClick)

  public static getDefaultSoMenuItem = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) =>
    MenuItems.getDefaultMenuItem(isDisabled, editable, TextService.format(strings.MenuItem_EditSo), TextService.format(strings.MenuItem_ViewSo), onClick)

  public static getEditResultValueMenuItem = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) => ({
    key: 'MenuItem_EditResultValue',
    text: editable ? TextService.format(strings.MenuItem_EditKRValue) : TextService.format(strings.MenuItem_ViewKRValue),
    iconProps: { iconName: 'ReportAdd' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getShowKeyResultMenuItem = (checked: boolean, onClick: () => void, isDisabled: IIsDisabled) => ({
    key: 'MenuItem_ShowKeyResult',
    text: TextService.format(strings.VISTO_KR_ShowOnDiagram),
    iconProps: { iconName: checked ? 'CheckboxComposite' : 'Checkbox' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDefaultFocusMenuItem = (isDisabled: IIsDisabled, editable: boolean, onClick: () => void) =>
    MenuItems.getDefaultMenuItem(isDisabled, editable, TextService.format(strings.MenuItem_EditFocus), TextService.format(strings.MenuItem_ViewFocus), onClick)

  public static getAddExistingActionMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_AddExistingAction',
    text: TextService.format(strings.MenuItem_AddExistingAction),
    iconProps: { iconName: 'Add' },
    disabled,
    onClick
  })

  public static getMoveActionMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_getMoveActionMenuItem',
    text: TextService.format(strings.MenuItem_getMoveActionMenuItem),
    iconProps: { iconName: 'Forward' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getMoveKeyResultMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_getMoveKeyResultMenuItem',
    text: TextService.format(strings.MenuItem_getMoveKeyResultMenuItem),
    iconProps: { iconName: 'Forward' },
    disabled,
    onClick
  })

  public static getAddProjectActionMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_AddProjectAction',
    text: TextService.format(strings.MenuItem_AddProjectAction),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getAddDevOpsActionMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_AddDevOpsAction',
    text: TextService.format(strings.MenuItem_AddDevOpsAction),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getNewActionMenuItem = (onClick: () => void, subMenuItems: IContextualMenuItem[], isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_NewAction',
    text: TextService.format(strings.MenuItem_NewAction),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    split: subMenuItems.length > 0,
    onClick,
    subMenuProps: subMenuItems.length > 0 && {
      items: subMenuItems
    }
  })

  public static getNewFocusMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_NewFocus',
    text: TextService.format(strings.MenuItem_NewFocus),
    iconProps: { iconName: 'Add' },
    disabled,
    onClick
  })

  public static getNewKeyResultMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_NewKeyResult',
    text: TextService.format(strings.MenuItem_NewKR),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getNewEffectMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_NewEffect',
    text: TextService.format(strings.MenuItem_NewEffect),
    iconProps: { iconName: 'Add' },
    disabled,
    onClick
  })

  public static getNewSoMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_NewSo',
    text: TextService.format(strings.MenuItem_NewSo),
    iconProps: { iconName: 'Add' },
    disabled,
    onClick
  })

  public static getNewAssocMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_NewAssoc',
    text: TextService.format(strings.MenuItem_NewAssoc),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDeleteActionMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteAction',
    text: TextService.format(strings.MenuItem_DeleteAction),
    iconProps: { iconName: 'Delete' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getHideActionMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_ArchiveAction',
    text: TextService.format(strings.MenuItem_HideAction),
    disabled,
    onClick
  })

  public static getSetFocusMenuItem = (focuses: VistoFocusItem[], focusGuid: string, onSetFocus: (focusGuid: string) => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_FocusList',
    text: TextService.format(strings.MenuItem_AssignFocus),
    iconProps: { iconName: 'Forward' },
    subMenuProps: {
      items: focuses.map(f => ({
        key: `MenuItem_FocusList_${f.guid}`,
        text: f.name,
        checked: f.guid === focusGuid,
        canCheck: true,
        disabled: isDisabled?.disabled,
        title: isDisabled?.why,
        onClick: () => onSetFocus(f.guid)
      }))
    }
  })

  public static getSetEffectMenuItem = (disabled: boolean, effects: VistoEffectItem[], effectGuid: string, onSetEffect: (effectGuid: string) => void): IContextualMenuItem => ({
    key: 'MenuItem_EffectList',
    text: TextService.format(strings.MenuItem_AssignEffect),
    subMenuProps: {
      items: effects.map(e => ({
        key: `MenuItem_EffectList_${e.guid}`,
        text: e.name,
        checked: e.guid === effectGuid,
        canCheck: true,
        disabled,
        onClick: () => onSetEffect(e.guid)
      }))
    }
  })

  public static getDeleteSoMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteSo',
    text: TextService.format(strings.MenuItem_DeleteSo),
    iconProps: { iconName: 'Delete' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDeleteFocusMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteFocus',
    text: TextService.format(strings.MenuItem_DeleteFocus),
    iconProps: { iconName: 'Delete' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getSelectFocusActionsMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_SelectFocusActions',
    text: TextService.format(strings.MenuItem_AssignActions),
    iconProps: { iconName: 'Add' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDeleteAssocMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteAssoc',
    text: TextService.format(strings.MenuItem_DeleteAssoc),
    iconProps: { iconName: 'Delete' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getShowAssocChartMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_ShowChart',
    text: TextService.format(strings.MenuItem_ShowChart),
    iconProps: { iconName: 'Chart' },
    disabled,
    onClick
  })

  public static getDeleteKeyResultMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteKeyResult',
    text: TextService.format(strings.MenuItem_DeleteKR),
    iconProps: { iconName: 'Delete' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getDeleteEffectMenuItem = (disabled: boolean, onClick: () => void): IContextualMenuItem => ({
    key: 'MenuItem_DeleteEffect',
    text: TextService.format(strings.MenuItem_DeleteEffect),
    iconProps: { iconName: 'Delete' },
    disabled,
    onClick
  })

  public static getRelationshipMenuItem = (
    text: string,
    menuItems: IContextualMenuItem[]
  ): IContextualMenuItem => ({
    key: 'MenuItem_ProgressMenu',
    text,
    iconProps: { iconName: 'Relationship' },
    subMenuProps: { items: menuItems }
  })

  public static getAttachmentsMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_AttachmentsMenu',
    text: TextService.format(strings.Menu_AttachDocument),
    iconProps: { iconName: 'OpenFile' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getProgressLinkMenuItem = (menuItems: IContextualMenuItem[]) =>
    MenuItems.getRelationshipMenuItem(TextService.format(strings.Menu_ProgressLink), menuItems)

  public static getBreakNavigationLinkMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_DeleteNavigationLink',
    text: TextService.format(strings.MenuItem_DeleteNavigationLink),
    iconProps: { iconName: 'RemoveLink' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getEditNavigationLinkMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_NavigationLink',
    text: TextService.format(strings.MenuItem_EditNavigationLink),
    iconProps: { iconName: 'AddLink' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static getShowHistoryDialogMenu = (disabled: boolean, onClick: () => void): ICommandBarItemProps => ({
    key: 'ShowHistoryDialog',
    text: TextService.format(strings.MenuItem_ShowHistoryDialog),
    disabled,
    iconProps: { iconName: 'History' },
    onClick
  })

  public static getSetActiveFocusMenuItem = (onClick: () => void, isDisabled: IIsDisabled): IContextualMenuItem => ({
    key: 'MenuItem_SetActiveFocus',
    text: TextService.format(strings.MenuItem_SetActiveFocus),
    iconProps: { iconName: 'FavoriteStar' },
    disabled: isDisabled?.disabled,
    title: isDisabled?.why,
    onClick
  })

  public static renderFileIcon(a: IAttachment) {
    return <FileTypeIcon type={IconType.font} path={a.fileName} />;
  }

  public static getAttachmentsMenuItems(
    item: IItemWithAttachments,
    dispatchCommand: (command: ICommand, options: ICommandOptions) => void,
    makeAttachmentCommand: MakeAttachmentCommand,
    notify: INotify
  ): IContextualMenuItem[] {
    return AttachmentService.getItemAttachments(item)
      .map((attachment, pos) => {
        const menuItem: IContextualMenuItem = {
          key: `MenuItem_Attachment_${pos}`,
          text: attachment.fileName,
          iconProps: {
            iconName: 'File'
          },
          onRenderIcon: () => MenuItems.renderFileIcon(attachment),
          subMenuProps: {
            items: [
              {
                key: `MenuItem_Attachment_${pos}_Open`,
                text: TextService.format(strings.Menu_AttachOpen),
                href: attachment.defaultUrl,
                iconProps: {
                  iconName: 'OpenInNewWindow'
                },
                target: '_blank',
              },
              {
                key: `MenuItem_Attachment_${pos}_Download`,
                text: TextService.format(strings.Menu_AttachDownload),
                href: attachment.fileAbsoluteUrl,
                iconProps: {
                  iconName: 'Download'
                },
                target: '_blank',
              },
              {
                key: `MenuItem_Attachment_${pos}_Unlink`,
                text: TextService.format(strings.Menu_AttachUnlink),
                iconProps: {
                  iconName: 'RemoveLink'
                },
                onClick: () => dispatchCommand(AttachmentService.makeDeleteAttachmentCommand(item, pos, makeAttachmentCommand, notify), { wrap: true })
              },
            ]
          }
        };
        return menuItem;
      });
  }

  public static getShowMenu(isMobile: boolean, items: ICommandBarItemProps[]): ICommandBarItemProps {
    return {
      key: 'ShowMenu',
      text: TextService.format(strings.MenuItem_Top_Show),
      iconOnly: isMobile,
      iconProps: { iconName: 'View' },
      subMenuProps: {
        items
      },
    };
  }

  public static getShowValidationsMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {

    const theme = useTheme();

    return {
      key: 'ShowValidations',
      text: TextService.format(strings.MenuItems_ShowValidations),
      onRenderIcon: (props: IContextualMenuItemProps) => {
        return (
          <span style={{ position: 'relative', margin: '0 4px', height: 32, width: 14 }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 10 10' version='1.1'>
              <g style={{ display: 'inline' }}>
                <circle cx='5' cy='5' r='4' style={{ strokeWidth: '0.6', stroke: theme.palette.themePrimary, fill: theme.palette.neutralLighter }} />
                <path d='m 5, 1 4, 0 0, 4' style={{ strokeWidth: '0.6', stroke: theme.palette.themePrimary, fill: theme.palette.neutralLighter }} />
              </g>
            </svg>
          </span>
        );
      },
      checked: propertyBag?.showValidations,
      canCheck: true,
      onClick: () => setPropertyBag({ showValidations: !propertyBag?.showValidations })
    };
  }

  private static getGroupByIconName = (groupBy: string) => {
    switch (groupBy) {
      case 'focus':
        return 'Sprint';
      case 'kpi':
        return 'Chart';
      case 'assignee':
        return 'People';
      default:
        return 'GroupList';
    }
  }

  private static getGroupByMenuName = (groupBy: string) => {
    switch (groupBy) {
      case 'focus':
        return strings.SidebarMenu_GroupByFocus;
      case 'kpi':
        return strings.SidebarMenu_GroupByKpi;
      case 'assignee':
        return strings.SidebarMenu_GroupByAssignee;
      default:
        return strings.SidebarMenu_GroupByNone;
    }
  }

  public static getGroupByMenu(isMobile: boolean, propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'GroupByMenu',
      text: TextService.format(strings.SidebarMenu_GroupByButton),
      iconOnly: isMobile,
      iconProps: { iconName: this.getGroupByIconName(propertyBag.sidebarGroupBy) },
      subMenuProps: {
        items: [
          MenuItems.getGroupByMenuItem('focus', propertyBag, setPropertyBag),
          ...(LicenseService.license?.okrEnabled ? [MenuItems.getGroupByMenuItem('kpi', propertyBag, setPropertyBag)] : []),
          MenuItems.getGroupByMenuItem('assignee', propertyBag, setPropertyBag),
          MenuItems.getGroupByMenuItem('none', propertyBag, setPropertyBag),
        ]
      },
    };
  }

  public static getGroupByMenuItem(
    key: 'focus' | 'kpi' | 'none' | 'assignee',
    propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: `MenuItem_GroupBy_${key}`,
      text: TextService.format(this.getGroupByMenuName(key)),
      iconProps: { iconName: this.getGroupByIconName(key) },
      checked: propertyBag.sidebarGroupBy === key,
      canCheck:
        true, onClick: () => setPropertyBag({ sidebarGroupBy: key })
    }
  }

  public static getShowIndicatorsMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowIndicators',
      text: TextService.format(strings.MenuItems_ShowIndicators),
      iconProps: { iconName: 'ExploreData' },
      checked: propertyBag?.showIndicators,
      canCheck: true,
      onClick: () => setPropertyBag({ showIndicators: !propertyBag?.showIndicators })
    };
  }

  public static getShowProgressMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowProgress',
      text: TextService.format(strings.MenuItems_ShowProgress),
      iconProps: { iconName: 'CalculatorPercentage' },
      checked: propertyBag?.showProgress,
      canCheck: true,
      onClick: () => setPropertyBag({ showProgress: !propertyBag?.showProgress })
    };
  }

  public static getShowEffectsMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowEffects',
      text: TextService.format(strings.MenuItems_ShowEffects),
      iconProps: { iconName: 'GroupList' },
      checked: propertyBag?.showEffects,
      canCheck: true,
      onClick: () => setPropertyBag({ showEffects: !propertyBag?.showEffects })
    };
  }

  public static getShowAssigneesMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowAssignees',
      text: TextService.format(strings.MenuItems_ShowAssignees),
      iconProps: { iconName: 'ReminderPerson' },
      checked: propertyBag?.showAssignees,
      canCheck: true,
      onClick: () => setPropertyBag({ showAssignees: !propertyBag?.showAssignees })
    };
  }

  public static getShowHistoryMenu(disabled: boolean, propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowHistory',
      text: TextService.format(strings.MenuItems_ShowHistory),
      iconProps: { iconName: 'History' },
      checked: propertyBag?.showHistory,
      disabled,
      canCheck: true,
      onClick: () => setPropertyBag({ showHistory: !propertyBag?.showHistory })
    };
  }

  public static getShowAdvisoryMenu(propertyBag: IPropertyBag, setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void): ICommandBarItemProps {
    return {
      key: 'ShowAdvisory',
      text: TextService.format(strings.MenuItem_ShowAdvisory),
      iconOnly: true,
      iconProps: { iconName: 'Education' },
      checked: propertyBag?.showAdvisory,
      onClick: () => setPropertyBag({ showAdvisory: !propertyBag?.showAdvisory })
    };
  }

  public static GetTeamsLinkMenuItem(onClick: () => void): ICommandBarItemProps {
    return {
      key: 'MenuItem_TeamsLink',
      text: TextService.format(strings.MenuFrame_Menu_GetTeamsLink),
      iconProps: { iconName: 'Share' },
      onClick
    };
  }
}
