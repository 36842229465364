import React from 'react'
import strings from 'VistoWebPartStrings';
import { BasicDialog } from '../common';
import { TextService } from 'services/TextService';
import { CascadingTreePage } from './CascadingTreePage';
import { CascadingTreeService } from './CascadingTreeService';

import { IExecutableAction } from 'services/IExecutableAction';
import { SharePointConfigurationService } from 'services/SharePointConfigurationService';
import { IVistoPlan } from 'sp';
import { stringifyError } from 'shared/parse';
import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton, Stack } from '@fluentui/react';
import { defaultModalProps } from 'dialogs/common';
import { InfoBar, useErrorInfo } from 'components';
import { CascadingProgressPage } from './CascadingProgressPage';
import { NotificationType } from 'services/Notify';
import { ICascadingCard } from './ICascadingCard';
import { ITreeItem } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { DashboardGraphService } from 'frames/DashboardFrame/DashboardGraphService';
import { DashboardDataService } from 'services/DashboardDataService';
import { UrlService } from 'shared/urlService';
import { CascadingCardSet } from './CascadingCardSet';
import { StorageCacheService } from 'services/StorageCacheService';
import { EnvContext } from 'services/EnvContext';

export const CascadingWizard = (props: {
  onDismiss: () => void;
}) => {

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const envContext = React.useContext(EnvContext);

  enum CascadingWizardPage {
    config,
    progress,
  }

  interface IPlanExecutableAction extends IExecutableAction {
    plan: IVistoPlan;
  }

  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();
  const [actions, setPlanActions] = React.useState<IPlanExecutableAction[]>([]);

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === actions.length;

  const [statusText, setStatusText] = React.useState('');

  const pageInfo = {

    [CascadingWizardPage.config]: {
      subText: TextService.format(strings.CascadingWizard_PageStartSubTitle),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => newSelectedKeys.join() === oldSelectedKeys.join(),
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [CascadingWizardPage.progress]: {
      subText: TextService.format(strings.CascadingWizard_PageProgressSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => actions.length === 0 || (0 <= currentProgressActionIndex && currentProgressActionIndex < actions.length),
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<CascadingWizardPage>(CascadingWizardPage.config);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.CascadingWizard_Title),
    subText: pageInfo[currentPageId].subText
  };

  const onWizardBack = () => {
    switch (currentPageId) {
      case CascadingWizardPage.progress:
        setCurrentPageId(CascadingWizardPage.config);
        break;
    }
  };

  const prepareActions = async () => {

    const result: IPlanExecutableAction[] = [];
    try {
      setStatusText(TextService.format(strings.CascadingWizard_GeneratingActions));

      const oldKeySet = new Set(oldSelectedKeys);
      const newKeySet = new Set(newSelectedKeys);
  
      const removedKeys = oldSelectedKeys.filter(k => !newKeySet.has(k));
      for (const key of removedKeys) {
        let plan = await CascadingTreeService.getPlanFromKey(key);
        if (plan) {
          const actions = await SharePointConfigurationService.setTopPlanId(plan, null, null, msg => {
            setStatusText(msg);
          });
          for (const action of actions) {
            result.push({ ...action, plan });
          }
        }
      }
  
      const addedKeys = newSelectedKeys.filter(k => !oldKeySet.has(k));
      for (const key of addedKeys) {
  
        let plan = await CascadingTreeService.getPlanFromKey(key);
        if (plan) {
          const cards: ICascadingCard[] = await CascadingTreeService.getCards(envContext);
          const siteGroups = DashboardDataService.groupCardsBySite(cards, 'visplan:');
          const siteCardSet = await CascadingTreeService.getSiteCardSet(siteGroups, plan.siteUrl);
          const topPlanId = CascadingTreeService.getTopPlanId(siteCardSet, key);

          const actions = await SharePointConfigurationService.setTopPlanId(plan, topPlanId, null, msg => {
            setStatusText(msg);
          })
          for (const action of actions) {
            result.push({ ...action, plan });
          }
        }
      }
  
      setPlanActions(result);
    } finally {
      setStatusText(`${result.length} action(s) penging`);
    }
  };

  const executeActions = async () => {
    setCurrentProgressActionIndex(undefined);
    try {
      setStatusText(TextService.format(strings.CascadingWizard_ExecutingActions));

      let plan: IVistoPlan = undefined;
      for (let i = 0; i < actions.length; ++i) {
        const action = actions[i];
        if (plan?.planId != action.plan?.planId) {
          plan = action.plan;
        }
        setStatusText(action.title);
        setCurrentProgressActionIndex(i);
        try {
          plan = await action.execute(plan);
        } catch (error) {
          setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.CascadingWizard_ErrorExecutingActions), error });
          action.hasError = true;
          action.errorMessage = stringifyError(error);
        }
      }

      setCurrentProgressActionIndex(actions.length);
    } finally {
      StorageCacheService.resetCache();
      setStatusText(`Completed`);
    }
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case CascadingWizardPage.config:
        setCurrentPageId(CascadingWizardPage.progress);
        prepareActions();
        break;

      case CascadingWizardPage.progress:
        if (executed) {
          props.onDismiss();
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  const [tree, setTree] = React.useState<ITreeItem[]>();

  const sort = (a: ICascadingCard, b: ICascadingCard) => {
    return TextService.compareNames(a.name, b.name);
  }

  const makePlanTreeItem = (siteCards: CascadingCardSet, item: ICascadingCard): ITreeItem => {

    const topPlanId = CascadingTreeService.getTopPlanId(siteCards, item.key);
    const result: ITreeItem = {
      key: item.key,
      label: item.name,
      selectable: true,
      data: 'plan',
      disabled: !topPlanId,
    }

    const childItems = DashboardGraphService.getChildItems(siteCards, item.key, sort).filter(c => !c.external);
    if (childItems.length > 0) {
      result.children = childItems.map(c => makePlanTreeItem(siteCards, c));
    }
    return result;
  }

  const makeSiteTreeItem = (siteCards: CascadingCardSet, roots: ICascadingCard[], siteUrl: string): ITreeItem => {
    return {
      key: siteUrl,
      label: UrlService.getPathName(siteUrl),
      selectable: false,
      data: 'site',
      children: roots.map(r => makePlanTreeItem(siteCards, r))
    }
  }

  const load = async () => {

    const cards: ICascadingCard[] = await CascadingTreeService.getCards(envContext);
    const siteGroups = DashboardDataService.groupCardsBySite(cards, 'visplan:');

    const treeNodes: ITreeItem[] = [];

    const selectedKeySet = {};
    for (const siteUrl in siteGroups) {
      const siteCardSet = await CascadingTreeService.getSiteCardSet(siteGroups, siteUrl);
      const roots = DashboardGraphService.getRootItems(siteCardSet, sort);
      for (const root of roots) {
        selectedKeySet[root.key] = root;
      }
      const siteNode = makeSiteTreeItem(siteCardSet, roots, siteUrl);
      treeNodes.push(siteNode);
    }

    setTree(treeNodes);
    for (const card of cards) {
      if (card.topPlanId) {
        selectedKeySet[card.key] = card;
      }
    }
    const selectedKeys = Object.keys(selectedKeySet);

    setOldSelectedKeys(selectedKeys);
    setNewSelectedKeys(selectedKeys);
  }

  React.useEffect(() => {
    load();
  }, []);

  const [oldSelectedKeys, setOldSelectedKeys] = React.useState<string[]>([]);
  const [newSelectedKeys, setNewSelectedKeys] = React.useState<string[]>([]);

  return (
    <Dialog
      minWidth={500}
      dialogContentProps={contentProps} modalProps={{ ...defaultModalProps, isBlocking: true }} hidden={false} onDismiss={props.onDismiss}>
      <Stack style={{ minHeight: 300 }}>
        <Stack grow tokens={{ childrenGap: 'm' }}>
          <InfoBar {...errorInfo} />
          {(currentPageId == CascadingWizardPage.config)
            ? <CascadingTreePage
              tree={tree}
              oldSelectedKeys={oldSelectedKeys}
              setOldSelectedKeys={setOldSelectedKeys}
              newSelectedKeys={newSelectedKeys}
              setNewSelectedKeys={setNewSelectedKeys}
            />
            : (currentPageId == CascadingWizardPage.progress)
              ? <CascadingProgressPage statusText={statusText} actions={actions} currentProgressActionIndex={currentProgressActionIndex} />
              : null
          }
        </Stack>
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={props.onDismiss} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
}
