import * as React from 'react';
import { setIsLoading } from 'services/LoadingIndicator';
import { WebDashboard } from './WebDashboard';
import { LocalPlan } from './LocalPlan';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { AppFrame } from './AppFrame';
import { TeamsPlan } from './TeamsPlan';

export const Home = () => {

  setIsLoading('');

  return (
    <HashRouter future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    }}>
      <Routes>
        <Route path='/' element={<AppFrame />} >
          <Route index element={<WebDashboard />} />
          <Route path='local' element={<LocalPlan />} />
          <Route path='teams' element={<TeamsPlan />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};
