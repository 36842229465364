
export enum TokenKind {
  sharepoint,
  planner,
  project,
  devops,
  dashboard,
  excel,
  sites,
  api
}
