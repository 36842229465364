import { IBasePickerStyles } from "@fluentui/react/lib/Pickers";

import { IButtonStyles } from "@fluentui/react/lib/Button";
import { IStackStyles } from "@fluentui/react/lib/Stack";
import {
  mergeStyles,
  mergeStyleSets,
} from "@fluentui/react/lib/Styling";
import { useTheme } from '@fluentui/react';

export const useTeamChannelPickerStyles = () => { // eslint-disable-line @typescript-eslint/explicit-function-return-type

  const { palette } = useTheme();

  const renderIconButtonRemoveStyles: Partial<IButtonStyles> = {
    root: {
      height: 26,
      lineHeight: 26,
    },
  };

  const renderItemStylesMulti: Partial<IStackStyles> = {
    root: {
      height: 26,
      lineHeight: 26,
      paddingLeft: 10,
      marginLeft: 5,
      marginBottom: 5,
      cursor: "default",
      backgroundColor:  palette.themeLighterAlt,
      ":hover": {
        backgroundColor:palette.themeLighter,
      },
    },
  };

  const renderItemStylesSingle: Partial<IStackStyles> = {
    root: {
      height: 26,
      lineHeight: 26,
      paddingLeft: 10,
      cursor: "default",
      margin: 2,
      backgroundColor: palette.themeLighterAlt,
      ":hover": {
        backgroundColor: palette.themeLighter,
      },
    },
  };

  const pickerStyles: Partial<IBasePickerStyles> = {
    root: {
      width: " 100%",
      borderRadius: 0,
    },
    input: {
      borderTopStyle: "solid",
      width: "100%",
      borderTopWidth: 0,
      backgroundColor: palette.white,
      borderRadius: 0,
    },
    itemsWrapper: {
      padding: 5,
    },
    text: {
      borderStyle: "solid",
      width: "100%",
      borderWidth: 1,
      backgroundColor: palette.white,
      borderRadius: 0,
      borderColor: palette.neutralQuaternaryAlt,
      ":focus": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor:   palette.themePrimary,
      },
      ":hover": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor:   palette.themePrimary,
      },
      ":after": {
        borderStyle: "solid",
        borderWidth: 1,
        // borderColor: theme.neutralQuaternaryAlt,
        borderColor:   palette.themePrimary,
      },
    },
  };

  const componentClasses = mergeStyleSets({

    separator: mergeStyles({
      marginTop: 25,
      marginLeft: 20,
      marginRight: 20,
      borderBottomWidth: 1,
      borderBottomColor:     palette?.neutralQuaternaryAlt,
      borderBottomStyle: "solid",
    }),

    iconChannelItemStyles: {
      fontSize: 14,
      color: palette.themePrimary
    },
    iconChannelInfoStyles: {
      fontSize: 12,
      color: palette.themePrimary
    },

  });

  return {
    renderIconButtonRemoveStyles,
    pickerStyles,
    renderItemStylesSingle,
    renderItemStylesMulti,
    componentClasses
  };
};
