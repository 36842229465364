import * as strings from 'VistoWebPartStrings';
import { INotify } from 'services/Notify';
import { ICommand } from 'services/ICommand';
import { IItemChanges } from 'services/Interfaces';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { IVistoPlan, VistoDpItem, VistoLopItem } from 'sp';
import { mxgraph } from 'ts-mxgraph-typings';
import { CellKind } from 'shared/CellKind';
import { mx } from 'frames/TopFrame/drawing/common';
import { getPosition } from '../DrawingUpdate';
import { CommandName } from 'shared/CommandName';
import { ICommandOptions } from 'services/ICommandOptions';
import { PlanValidationService } from 'services/PlanValidationService';

const MARGIN = 20;

export const handleCellsMoved = (
  currentUi: any,
  currentPlan: IVistoPlan,
  dispatchCommand: (command: ICommand, options: ICommandOptions) => Promise<IVistoPlan>,
  graph: mxgraph.mxGraph,
  event: mxgraph.mxEventObject,
  notify: INotify) => {

  const { cells, dx, dy } = event.properties;

  const updates: IItemChanges<VistoDpItem>[] = [];
  let message = '';

  const handleDpCellMoved = (movedDp: mxgraph.mxCell) => {
    const dp: VistoDpItem = PlanDataService.getItemByGuid(currentPlan.items, mx.getCellGuid(movedDp));
    if (dp) {
      const foundLopCell = mx.findOverlappingLopCell(graph, movedDp);
      if (foundLopCell) {
        mx.centerDpOnLop(movedDp, foundLopCell);
      }

      dp.position = getPosition(movedDp);

      const oldLopGuid = dp && dp.lopGuid || null;
      const newLopGuid = mx.getCellGuid(foundLopCell) || null;
      if (newLopGuid != oldLopGuid) {
        message = TextService.format(strings.Command_AssociateLOP);
        const lopChanges = ChangesService.getChanges({ lopGuid: oldLopGuid }, { lopGuid: newLopGuid });
        updates.push({ item: dp, changes: lopChanges });

        const actions = PlanDataService.getDpActions(currentPlan, dp.guid);
        for (const action of actions) {
          const actionChanges = ChangesService.getChanges({ lopGuid: oldLopGuid }, { lopGuid: newLopGuid });
          updates.push({ item: action, changes: actionChanges });
        }
      }
      return true;
    }
  };

  const handleLopCellMoved = (movedLop: mxgraph.mxCell) => {
    const lop: VistoLopItem = PlanDataService.getItemByGuid(currentPlan.items, mx.getCellGuid(movedLop));
    if (lop) {
      lop.position = getPosition(movedLop);
    }
  };

  // const handlePositionMoved = (cell: mxgraph.mxCell) => {
  //   const geo = graph.getCellGeometry(cell);
  //   if (geo.x + geo.width > graph.pageFormat.width) {
  //     const dw = (geo.x + geo.width) - graph.pageFormat.width + MARGIN;
  //     resizeDiagram(currentUi, graph, dw);

  //     const title = mx.findSingleCell(graph, CellKind.TITLE);
  //     resizeCell(graph, title, dw);
  //   }
  // };

  const handleFocusMoved = (cell: mxgraph.mxCell, cells: mxgraph.mxCell[]) => {
    // const focus = mx.findSingleCell(graph, CellKind.FOCUS);
    // mx.moveCells(graph, [focus], dx, dy);
    const lops = mx.findCellsByKind(graph, CellKind.LOP);
    for (const lop of lops) {
      if (!cells.includes(lop)) {
        mx.moveLopBend(graph, lop, dx);
      }
    }
  };

  let validate = false;
  const haveLop = cells.find(cell => mx.getCellKind(cell) === CellKind.LOP);

  for (const cell of cells) {
    const kind = mx.getCellKind(cell);
    switch (kind) {
      // case CellKind.POSITION:
      //   handlePositionMoved(cell);
      //   break;

      case CellKind.FOCUS:
        handleFocusMoved(cell, cells);
        break;

      case CellKind.DP:
        if (!haveLop) {
          validate = handleDpCellMoved(cell);
        }
        break;
      case CellKind.LOP:
        handleLopCellMoved(cell);
        break;
    }
  }

  if (updates.length) {
    dispatchCommand({
      prepare: async () => {
        return Commands.makeUpdateUndoUnit(updates, notify, { validate: true });
      },
      message: message,
      name: CommandName.AssociateLOP
    }, {
      wrap: true
    });
  } else if (validate) {
    PlanValidationService.validateRequiredLinks(currentPlan, notify);
  }
};
