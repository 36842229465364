import React from 'react';
import { IContextualMenuItem, Panel, PanelType, Stack } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { useCommentSidebarService } from './CommentSidebarService';
import { ConfirmDelete } from './components/ConfirmDelete';
import { CommentItem } from './components/CommentItem';
import { IAddCommentPayload, ICommentCard } from './IComment';
import { InfoBar, useErrorInfo } from 'components';
import { AddComment } from './components/AddComment';
import { AppContext } from 'services/AppContext';
import { UrlService } from 'shared/urlService';
import { CellKind } from 'shared/CellKind';
import { ApiEmailService } from 'services/api/ApiEmailService';
import { IEmailRequest } from 'shared/api/email';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import regexifyString from 'regexify-string';

export const CommentSidebar = (props: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {

  const getCommentMenuItems = (card: ICommentCard): IContextualMenuItem[] => {
    return [
      {
        key: 'delete',
        text: TextService.format(strings.ButtonDelete),
        iconProps: { iconName: 'Delete' },
        onClick: () => setCommentToDelete(card)
      },
    ];
  }

  const envContext = React.useContext(EnvContext);

  const { planRef, selectionRef } = React.useContext(AppContext);

  const sidebarStyles = {
    main: {
      animation: 'none',
      marginTop: envContext.isPortal ? 248 : 200,
      borderTopRightRadius: 4,
    },
    content: {
      paddingTop: 8,
    },
  };

  const [cards, setCards] = React.useState<ICommentCard[]>([]);

  const filteredCards = cards.filter(c => {
    const filter = UrlService.parseSubEntityId(c.comment.subEntityId);
    return !selectionRef.current 
      || selectionRef.current.guid === filter?.guid 
      || (selectionRef.current.cellKind === CellKind.TITLE && (!filter || filter.cellKind === CellKind.TITLE))
      || (selectionRef.current.cellKind === CellKind.POSITION && (!filter || filter.cellKind === CellKind.POSITION));
  });

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const services = useCommentSidebarService();

  const loadComments = async () => {
    try {
      const comments = await services.getComments();
      const cards = comments.map(c => ({ comment: c, disabled: false }));
      setCards(cards);
    } catch (error) {
      setErrorInfo(error);
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      loadComments();
    }
  }, [props.isOpen, planRef.current]);

  const addComment = async (commentText: IAddCommentPayload) => {
    try {
      setErrorInfo(null);
      const created = services.makeComment(commentText);

      const preview = [{ comment: created, disabled: true }, ...cards];
      setCards(preview);

      const formattedText = regexifyString({ 
        pattern: /@mention\{\d+\}/g, 
        decorator: (match, index) => `<strong>@${created.mentions[index].name}</strong>`, 
        input: created.text 
      });
      
      const link = UrlService.makeConfigurableTabDeepLink(envContext.siteUrl, envContext.planId, envContext.channelId, created.subEntityId, envContext);

      const params = {
        PlanName: planRef.current.name,
        AuthorName: created.author.name,
        CommentText: formattedText.join(''),
        LinkToPlan: link
      }

      if (created.mentions.length > 0) {
        const email: IEmailRequest = {
          subject: TextService.format(strings.Comment_EmailSubject, params),
          body: TextService.format(strings.Comment_EmailBody, params),
          to: created.mentions.map(m => ({ address: m.email })),
        };
        await ApiEmailService.sendEmail(email);
      }

      await services.setComments(planRef.current, loaded => [created, ...loaded], TextService.format(strings.Notification_AddComment));

    } catch (error) {
      setErrorInfo(error);
    }
  }

  const [commentToDelete, setCommentToDelete] = React.useState<ICommentCard>(null);

  const onDeleteConfirmed = async (confirmed: boolean) => {
    if (confirmed) {
      const commentId = commentToDelete.comment.guid;
      try {
        const preview = cards.map(c => c.comment.guid === commentId ? { ...c, disabled: true } : c);
        setCards(preview);

        await services.setComments(planRef.current, loaded => loaded.filter(c => c.guid !== commentId), TextService.format(strings.Notification_DeleteComment));

      } catch (error) {
        setErrorInfo(error);
      }
    }
    setCommentToDelete(null);
  }

  return <Panel
    type={PanelType.customNear}
    customWidth='400px'
    isBlocking={false}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    headerText={TextService.format(strings.Comment_PanelTitle)}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ styles: { root: { zIndex: 999 } } }}
  >
    <AddComment onAddComment={addComment} services={services} />
    <InfoBar {...errorInfo} />
    <Stack style={{ marginTop: 16 }}>
      {filteredCards.map((card) =>
        <CommentItem key={card.comment.guid} card={card} menuItems={getCommentMenuItems(card)} />
      )}
    </Stack>

    {commentToDelete && <ConfirmDelete selectedComment={commentToDelete} onDismiss={onDeleteConfirmed} />}
  </Panel>;

}
