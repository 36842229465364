import { ICommand } from 'services/ICommand';
import { Operation } from 'shared/Operation';
import { IVistoListItemWithProgress, IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { setIsLoading } from './LoadingIndicator';
import { INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { StorageService } from './StorageService';
import { TextService } from 'services/TextService';
import { PlanValidationService } from './PlanValidationService';
import { IntegrationService } from './IntegrationService';
import { ProgressService } from './ProgressService';
import { StorageCacheService } from './StorageCacheService';
import { mx } from 'frames/TopFrame/drawing/common';
import { CommandName } from 'shared/CommandName';

interface ISyncDateInfo {
  syncDate?: Date;
  hooks: { [key: string]: Date };
}

export class RefreshCommand {

  public static getSyncDateInfo(plan: IVistoPlan): ISyncDateInfo {
    const result: ISyncDateInfo = {
      hooks: {}
    };
    const planSettings = PlanSettingsService.getPlanSettings(plan);
    for (const guid in plan.items) {
      const item: IVistoListItemWithProgress = plan.items[guid];
      const url = item.sourceItemUrl;
      if (url) {
        const { hook, hookKey } = IntegrationService.findHookForLink(url);
        if (hook) {
          const d = hook.getSyncDate(planSettings);
          if (!result.hooks[hookKey] || TextService.compareDateTime(result.hooks[hookKey], d) < 0) {
            result.hooks[hookKey] = d;
          }
          if (!result.syncDate || TextService.compareDateTime(result.syncDate, d) < 0) {
            result.syncDate = d;
          }
        }
      }
    }
    return result;
  }

  public static formatSyncDateDetails(syncDateInfo: ISyncDateInfo) {
    return Object.keys(syncDateInfo.hooks).map(k => `<div>${k}: ${TextService.formatDateTime(syncDateInfo.hooks[k])}</div>`).join('');
  }

  public static notifySyncDate(newPlan: IVistoPlan, notify: INotify) {
    const syncDateInfo = this.getSyncDateInfo(newPlan);
    if (syncDateInfo.syncDate) {
      notifyInfoBar(notify, {
        type: NotificationType.log,
        message: TextService.format(strings.Message_LastRefreshed, {
          syncDate: TextService.formatDateTime(syncDateInfo.syncDate, TextService.format(strings.Message_Never))
        }),
        error: this.formatSyncDateDetails(syncDateInfo),
        actions: [
          {
            title: TextService.format(strings.Command_RefreshActionName),
            command: this.makeRefreshCommand(notify)
          },
        ]
      });
    }
  }

  public static makeRefreshCommand(notify: INotify): ICommand {
    return {
      prepare: async () => {
        return {
          do: async (p, editor) => {
            StorageCacheService.resetCache();

            let newPlan = await StorageService.get(p.siteUrl).loadPlanItem(p);

            try {
              setIsLoading(TextService.format(strings.MainTab_LoadingPlanData));
              newPlan = await StorageService.get(newPlan.siteUrl).loadPlanData(newPlan, notify);
            } catch (error) {
              notifyInfoBar(notify, { type: NotificationType.error, message: TextService.format(strings.MainFrame_ErrorLoadingItems), error });
            }

            try {
              setIsLoading(TextService.format(strings.MainTab_UpdatingProgress));
              newPlan = await ProgressService.ensureSync(newPlan, [], notify, Operation.load, null);
              await StorageService.get(newPlan.siteUrl).updatePlanItem(newPlan, { settingsJson: newPlan.settingsJson }, notify);
            } catch (error) {
              notifyInfoBar(notify, { type: NotificationType.warn, message: TextService.format(strings.MainFrame_ErrorUpdatingProgress), error });
            }

            if (editor && newPlan.drawingXml !== p.drawingXml) {
              mx.setGraphXml(editor, newPlan.drawingXml, notify);
            }

            if (newPlan !== p) {
              notify.plan(newPlan);
            }

            PlanValidationService.validate(newPlan, notify);

            setIsLoading('');
            return newPlan;
          },
          undo: async (plan) => {
            return plan;
          }
        };
      },
      details: (plan: IVistoPlan) => {
        const syncDateInfo = this.getSyncDateInfo(plan);
        return this.formatSyncDateDetails(syncDateInfo);
      },
      message: TextService.format(strings.RefreshCommand_Message, { syncDate: TextService.formatDateTime(new Date()) }),
      name: CommandName.RefreshPlan
    };
  }

}
