import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { env } from '../../shared/clientEnv';
import { CommandName } from '../../shared/CommandName';

export class trackClient {

  private static appInsights: ApplicationInsights;
  public static trackDetails: boolean;
  public static origin: string;
  public static planId: string;

  public static initialize(name: string) {
    const appInsightsDetails = env.getTemplateParamValue('APPINSIGHTS_DETAILS');
    const trackDetails = appInsightsDetails === 'full';
    const disableTelemetry = appInsightsDetails === 'none';
    trackClient.trackDetails = trackDetails;
    trackClient.appInsights = new ApplicationInsights({
      config: {
        connectionString: env.getTemplateParamValue('APPLICATIONINSIGHTS_CONNECTION_STRING'),
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['js.hs-scripts.com', 'api.hubspot.com'],
        enableRequestHeaderTracking: trackDetails,
        enableResponseHeaderTracking: trackDetails,
        loggingLevelConsole: 2,
        disableTelemetry: disableTelemetry,
      }
    });

    trackClient.appInsights.loadAppInsights();

    const telemetryInitializer = (envelope) => {

      if (envelope.baseType === 'RemoteDependencyData') {
        const url = envelope.baseData.target as string;
        const success = envelope.baseData.success as boolean;
        const responseCode = envelope.baseData.responseCode as number;

        // prevent logging of user photos
        if (/https:\/\/graph.microsoft.com\/v1.0\/users\/.*\/photo\/\$value/.test(url)) {
          return false;
        }

        // prevent logging of normal planner requests
        if (/https:\/\/graph.microsoft.com\/v1.0\/planner/.test(url) && (success ||  responseCode === 404)) {
          return false;
        }
        
        // prevent logging of normal planner requests
        if (/_api\/web\/getFileByUrl(@p1)\/\$value/.test(url) && (success ||  responseCode === 404)) {
          return false;
        }

        if (/_api\/(web|site)\?\%24select\=ID$/.test(url) && (success ||  responseCode === 404 || responseCode === 401 || responseCode === 403)) {
          return false;
        }
    }

      envelope.tags['ai.cloud.role'] = 'BROWSER';
      return true;
    };

    trackClient.appInsights.addTelemetryInitializer(telemetryInitializer);
    trackClient.page(name);
  }

  public static getProperties(data?) {
    
    const result = {...data};

    if (trackClient.origin) {
      result.customer = trackClient.origin;
    }
    if (trackClient.planId) {
      result.planid = trackClient.planId;
    }
    return result;
  }

  public static page(name: string, data?) {
    trackClient.appInsights.trackPageView({ name, properties: trackClient.getProperties(data) });
  }

  public static event(name: CommandName, data?) {
    trackClient.appInsights.trackEvent({ name: CommandName[name], properties: trackClient.getProperties(data) });
  }

  public static throw(message, data?) {
    this.error(message, data);
    throw new Error(message);
  }

  public static error(message, data?) {
    console.error(message, data || '');

    if (trackClient.appInsights) {
      // if pnpjs error
      if (data?.hasOwnProperty('isHttpRequestError')) {
        data.response.clone().text().then(text => {
          trackClient.appInsights.trackException({ exception: new Error(message), severityLevel: SeverityLevel.Error, properties: this.getProperties({ ...data, text }) });
        }, () => {
          trackClient.appInsights.trackException({ exception: new Error(message), severityLevel: SeverityLevel.Error, properties: this.getProperties(data) });
        });
      } else {
        trackClient.appInsights.trackException({ exception: new Error(message), severityLevel: SeverityLevel.Error, properties: this.getProperties(data) });
      }
    }
  }

  public static warn(message, data?) {
    console.warn(message, data);
    if (trackClient.appInsights) {
      trackClient.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning, properties: this.getProperties(data) });
    }
  }

  public static log(message, data?) {
    console.log(message, data);
    if (trackClient.appInsights && trackClient.trackDetails) {
      trackClient.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information, properties: this.getProperties(data) });
    }
  }

  public static debug(message, data?) {
    console.debug(message, data);
    if (trackClient.appInsights && trackClient.trackDetails) {
      trackClient.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose, properties: this.getProperties(data) });
    }
  }
}

