import { trackClient } from 'services/trackClient';
import { IFieldValueUser, IVistoListItemWithAssignee, IVistoPlan } from 'sp';

import '@pnp/sp/batching';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { AuthService } from './AuthService';

const makeInvalidUserNamesError = (invalidUserNames: string[]) => {
  return {
    invalid_user_names: invalidUserNames,
    error_description: TextService.format(strings.ErrorMessage_SharepointUnableToResolveUsers, { userNames: TextService.formatList(invalidUserNames) })
  };
};

export class SharepointUserResolver {

  private static cachedUsers: IFieldValueUser[] = [];

  public static getUserName(loginName: string): string {
    return loginName.toLowerCase().split('|').slice(-1)[0];
  }

  public static resolveUserNames(siteUrl: string, userIdsToResolve: { [key: number]: IFieldValueUser }) {

    const sp = AuthService.getSpClient(siteUrl, 'long');

    return new Promise<void>((resolve, reject) => {

      if (!Object.keys(userIdsToResolve).length) {
        resolve();
        return;
      }

      const userIdsToResolveServerSide = {};
      for (const userId in userIdsToResolve) {
        const cachedUser = SharepointUserResolver.cachedUsers.find(u => u.id === +userId);
        if (cachedUser) {
          userIdsToResolve[+userId] = cachedUser;
        } else {
          userIdsToResolveServerSide[+userId] = null;
        }
      }

      if (!Object.keys(userIdsToResolveServerSide).length) {
        resolve();
        return;
      }

      trackClient.debug(`resolving user names (${Object.keys(userIdsToResolveServerSide).length})`, userIdsToResolveServerSide);

      const [batch, execute] = sp.batched();

      const invalidUserIds: number[] = [];
      for (const userId in userIdsToResolveServerSide) {
        batch.web.siteUsers.getById(+userId).select('LoginName', 'Title', 'Id')().then(userInfo => {
          const loaded: IFieldValueUser = {
            id: +userId,
            guid: null,
            title: TextService.getUserDisplayName(userInfo.Title),
            userName: SharepointUserResolver.getUserName(userInfo.LoginName)
          };
          SharepointUserResolver.cachedUsers.push(loaded);
          userIdsToResolve[+userId] = loaded;
        }, (error) => {
          invalidUserIds.push(+userId);
          trackClient.error(`Unable to resolve user id ${+userId}`, error);
        });
      }

      execute().then(() => {
        resolve();
      }, error => {
        trackClient.error(`Unable to resolve some user ids: ${TextService.formatList(invalidUserIds)}`, error);
        reject(error);
      });

    });
  }

  public static async resolveUserIds(plan: IVistoPlan, items: IVistoListItemWithAssignee[]) {

    const usersToResolveServerSide: { [userName: string]: IFieldValueUser[] } = {};

    for (const item of items) {
      const vals = item.assignedTo;
      if (Array.isArray(vals)) {
        for (const val of vals) {
          const cachedUser = SharepointUserResolver.cachedUsers.find(u => u.userName === val.userName);
          if (cachedUser?.id) {
            val.id = cachedUser.id;
          } else if (val.userName) {
            usersToResolveServerSide[val.userName] = [...(usersToResolveServerSide[val.userName] ?? []), val];
          }
        }
      }
    }

    const invalidUserNames: string[] = [];

    if (!Object.keys(usersToResolveServerSide).length) {
      return;
    }

    try {
      const sp = AuthService.getSpClient(plan.siteUrl);
      const [batch, execute] = sp.batched();
      for (const userName in usersToResolveServerSide) {
        batch.web.ensureUser(userName).then(ensured => {
          const loaded: IFieldValueUser = {
            id: ensured.data.Id,
            guid: null,
            title: TextService.getUserDisplayName(ensured.data.Title),
            userName: SharepointUserResolver.getUserName(ensured.data.LoginName)
          };
          SharepointUserResolver.cachedUsers.push(loaded);
          const vals = usersToResolveServerSide[userName];
          if (Array.isArray(vals)) {
            for (const val of vals) {
              val.id = loaded.id;
              val.userName = loaded.userName;
            }
          }
        }, (err) => {
          invalidUserNames.push(userName);
          trackClient.error(`Unable to resolve user ${userName}`, err);
        });
      }

      await execute();
      
    } catch (error) {
      trackClient.error(`Unable to resolve all users`, error);
      throw makeInvalidUserNamesError(Object.keys(usersToResolveServerSide));
    }

    if (invalidUserNames.length > 0) {
      throw makeInvalidUserNamesError(invalidUserNames);
    }
  }

}
