import { ITheme } from '@fluentui/react';
import { ItemInfoService } from 'integrations/sharepoint';
import { ApiPlanService } from 'services/api/ApiPlanService';
import { ChangesService } from 'services/ChangesService';
import { DashboardDataService } from 'services/DashboardDataService';
import { IEnvContext } from 'services/EnvContext';
import { IExecutableAction } from 'services/IExecutableAction';
import { INotify } from 'services/Notify';
import { PlanDataService } from 'services/PlanDataService';
import { PlanGenerationService } from 'services/PlanGenerationService';
import { ProgressService } from 'services/ProgressService';
import { SharePointConfigurationService } from 'services/SharePointConfigurationService';
import { StorageCacheService } from 'services/StorageCacheService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { makeGuidString } from 'shared/guid';
import { TextPlanJson } from 'shared/PlanGenerationModel';
import { UrlService } from 'shared/urlService';
import { IVistoPlan, VistoKind } from 'sp';
import strings from 'VistoWebPartStrings';

export class ChildConfigurationService {

  public static async getChildChanges(
    plan: IVistoPlan,
    oldSelectedKeys: Set<string>,
    newSelectedKeys: Set<string>,
    topPlanId: string,
    notify: INotify,
    envContext: IEnvContext,
    theme: ITheme,
    feedback: (message: string) => void
  ): Promise<IExecutableAction[]> {

    const allActions: IExecutableAction[] = [];

    const activeFocus = PlanDataService.getActiveFocus(plan);
    const lops = PlanDataService.getLops(plan).filter((lop) => newSelectedKeys.has(lop.guid) && !oldSelectedKeys.has(lop.guid));
    for (const lop of lops) {
      const dps = PlanDataService.getLopDps(plan, lop.guid).filter((dp) => newSelectedKeys.has(dp.guid) && !oldSelectedKeys.has(dp.guid));
      const jsonText: TextPlanJson = {
        planName: lop.name,
        planDescription: lop.description,
        positionName: plan.esName,
        positionDescription: plan.esDescription,
        focusName: activeFocus?.name,
        focusDescription: activeFocus?.description,
        capabilities: dps.map((dp) => {
          const actions = PlanDataService.getDpActions(plan, dp.guid);
          return {
            name: dp.name,
            description: dp.description,
            ambitions: actions.map((action) => {
              return {
                name: action.name,
                description: action.description,
              }
            })
          }
        })
      }
      const template: IVistoPlan = {
        planId: makeGuidString(),
        siteUrl: plan.siteUrl,
        name: lop.name,
        description: lop.description,
        esName: plan.esName,
        esDescription: plan.esDescription,
        planVersion: plan.planVersion,
        topPlanId: topPlanId,
        items: {}
      };
      const { newPlan, changes } = PlanGenerationService.generateVistoPlan(jsonText, template);
      const newActions = await SharePointConfigurationService.generateActions(newPlan, false, notify, feedback);
      allActions.push(...newActions);

      const addedCapabilities = changes.addedCapabilities;
      for (const addedCapability of addedCapabilities) {
        const dp = dps.find((dp) => dp.name === addedCapability.name);
        if (dp) {
          const action: IExecutableAction = {
            title: TextService.format(strings.ActionMessage_SetSourceLinkForItem, { item: TextService.formatTitle(dp, plan.items) }),
            execute: async () => {
              const itemInfo = await ItemInfoService.getSpItemInfoByGuid(newPlan, VistoKind.LOP, addedCapability.guid);
              const sourceItemUrl = UrlService.getSourceLinkFromItem(newPlan, { kind: VistoKind.LOP, itemId: itemInfo.key, guid: itemInfo.guid }, undefined, envContext)
              const changes = ChangesService.getChanges(dp, { sourceItemUrl })
              plan = await StorageService.get(plan.planId).updateItems(plan, [{ item: dp, changes }], notify, { enableSimpleUpdate: true, excludeExternals: true });
              return plan;
            }
          }
          allActions.push(action);
        }

        const addedAmbitions = changes.addedAmbitions.filter((ambition) => ambition.lopGuid === addedCapability.guid);
        for (const addedAmbition of addedAmbitions) {
          const a = PlanDataService.getDpActions(plan, dp.guid).find((a) => a.name === addedAmbition.name);
          if (a) {
            const action: IExecutableAction = {
              title: TextService.format(strings.ActionMessage_SetSourceLinkForItem, { item: TextService.formatTitle(a, plan.items) }),
              execute: async () => {
                const itemInfo = await ItemInfoService.getSpItemInfoByGuid(newPlan, VistoKind.DP, addedAmbition.guid);
                const sourceItemUrl = UrlService.getSourceLinkFromItem(newPlan, { kind: VistoKind.DP, itemId: itemInfo.key, guid: itemInfo.guid }, undefined, envContext)
                const changes = ChangesService.getChanges(a, { sourceItemUrl })
                plan = await StorageService.get(plan.planId).updateItems(plan, [{ item: a, changes }], notify, { enableSimpleUpdate: true, excludeExternals: true });
                return plan;
              }
            }
            allActions.push(action);
          }
        }
      }

      allActions.push({
        title: TextService.format(strings.ActionMessage_RegisteringNewPlan, { plan: newPlan.name }),
        execute: async () => {
          const sourceItemUrl = UrlService.getSourceLinkFromItem(newPlan, { kind: VistoKind.Plan, itemId: plan.itemId, guid: null }, undefined, envContext)
          const changes = ChangesService.getChanges(lop, { sourceItemUrl })
          plan = await StorageService.get(plan.planId).updateItems(plan, [{ item: lop, changes }], notify, { enableSimpleUpdate: true, excludeExternals: true });
          await ApiPlanService.openPlan(newPlan, envContext.groupId, null);

          const b = await DashboardDataService.getPlanCardPreview(newPlan.drawingXml, newPlan.styleJson, theme);
          await DashboardDataService.updatePlanPreview(newPlan, b);
  
          return plan;
        }
      });
    }

    allActions.push({
      title: TextService.format(strings.ActionMessage_ResetCache),
      execute: async () => {
        StorageCacheService.resetCache();
        plan = await ProgressService.ensurePercentComplete(plan, notify);
        notify.plan(plan);
        notify.update();
        return plan;
      }
    });

    return allActions;
  }

  public static getDefaultSelectedKeys(plan: IVistoPlan): string[] {

    const result: string[] = [];
    const lops = PlanDataService.getLops(plan);
    for (const lop of lops) {
      if (lop.sourceItemUrl) {
        result.push(lop.guid);
      }
      const dps = PlanDataService.getLopDps(plan, lop.guid);
      for (const dp of dps) {
        if (dp.sourceItemUrl) {
          result.push(dp.guid);
        }
      }
    }
    return result;
  }

}
