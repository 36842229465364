import * as React from 'react';
import { DashboardFrame } from 'frames/DashboardFrame/DashboardFrame';
import { PlanWizard } from 'wizard';
import { useNavigate } from 'react-router-dom';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { IEnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';
import { IVistoPlan } from 'sp';
import { useIsAuthenticated } from '@azure/msal-react';

export const WebDashboard = () => {

  const [isWizardOpen, setIsWizardOpen] = React.useState(false);

  const navigate = useNavigate();

  const onAddNew = () => {
    setIsWizardOpen(true);
  };

  const onWizardFinished = (changed: boolean, plan: IVistoPlan, ctx: IEnvContext) => {
    setIsWizardOpen(false);
    if (changed) {
      ctx.siteUrl = plan.siteUrl;
      ctx.planId = plan.planId;
      const url = UrlService.makePlanUrl(ctx.siteUrl, ctx.planId, ctx.channelId, ctx);
      navigate(url);
    }
  };

  const onLoad = async (ctx: IEnvContext) => {
  };

  const isAuthenticated = useIsAuthenticated();

  return (
    <WebAuthScope name='Web_Dashboard' key={`${isAuthenticated}`} kind={TokenKind.api} onLoad={onLoad} siteUrl={UrlService.LOCAL_URL}>
      <DashboardFrame onAddNew={onAddNew} />
      {isWizardOpen && <PlanWizard
        isPopup
        onDismiss={onWizardFinished}
        trigger='TeamsConfigTab'
      />}
    </WebAuthScope>
  );
};
