import { parseJSON, sleep } from 'shared/parse';
import { ApiService } from './ApiService';
import { IOpenaiMessage, IOpenaiThread, IOpenaiThreadRun } from 'shared/api/openai';
import { TextPlanJson } from 'shared/PlanGenerationModel';

export class ApiOpenaiThreadService {

  public static async getThreads(): Promise<IOpenaiThread[]> {
    const result = await ApiService.fetch('/openai/threads', 'get');
    return result as IOpenaiThread[];
  }

  public static async createThread(): Promise<IOpenaiThread> {
    const result = await ApiService.fetch('/openai/threads', 'post', {});
    return result as IOpenaiThread;
  }

  public static async getThread(storeId: string): Promise<IOpenaiThread> {
    const result = await ApiService.fetch(`/openai/threads/${storeId}`, 'get');
    return result as IOpenaiThread;
  }

  public static async deleteThread(storeId: string) {
    const result = await ApiService.fetch(`/openai/threads/${storeId}`, 'delete');
    return result;
  }

  public static async createThreadRun(threadId: string, assistantId: string): Promise<IOpenaiThreadRun> {
    const result = await ApiService.fetch(`/openai/threads/${threadId}/runs`, 'post', {
      assistantId
    });
    return result as IOpenaiThreadRun;
  }

  public static async postThreadMessage(threadId: string, message: IOpenaiMessage) {
    const result = await ApiService.fetch(`/openai/threads/${threadId}/messages`, 'post', message);
    return result as IOpenaiMessage;
  }

  public static async getThreadResult(threadId: string): Promise<TextPlanJson> {
    const result: any = await ApiService.fetch(`/openai/threads/${threadId}/messages`, 'get');
    const text = result?.data?.[0]?.content?.[0]?.text?.value as string;
    if (!text) {
      throw { message: 'No response from the assistant.' };
    }
    let json = parseJSON(text, null);
    if (!json) {
      const startIndex = text.indexOf('```json')
      const endIndex = text.indexOf('```', startIndex + 1);
      const formatted = text.substring(startIndex + 7, endIndex).trim();
      json = parseJSON(formatted, null);
      if (!json) {
        throw { message: text };
      }
    }
    return json as TextPlanJson;
  }

  public static async getThreadRun(threadId: string, runId: string): Promise<IOpenaiThreadRun> {
    const result = await ApiService.fetch(`/openai/threads/${threadId}/runs/${runId}`, 'get');
    return result as IOpenaiThreadRun;
  }

  public static async waitForProcessing(threadId: string, runId: string, maxWait: number = 10 * 60 * 1000) {
    const TERMINAL_STATES = new Set(['completed', 'failed', 'deleted']);

    const start = Date.now();
    let run = await this.getThreadRun(threadId, runId);

    while (!run.status || !TERMINAL_STATES.has(run.status)) {
      await sleep(1000);

      run = await this.getThreadRun(threadId, runId);
      if (Date.now() - start > maxWait) {
        throw { message: `Giving up on waiting for file ${threadId} to finish processing after ${maxWait} milliseconds.` };
      }
    }

    if (run.status === 'failed') {
      throw  { message: `Unable to generate: ${run.last_error.message}` };
    }

    return run;
  }
}
