import { IVistoListItem, IVistoPlan, VistoKind, VistoActionItem, VistoAssocItem, VistoDpItem, VistoLopItem, IPlanItems, VistoFocusItem, VistoKeyResultItem, VistoKeyResultValueItem, IFieldValueUser, IVistoListItemWithAssignee, VistoSoItem } from 'sp';
import { TextService, VistoKindInfo } from './TextService';
import { trackClient } from 'services/trackClient';
import { makeGuidString } from 'shared/guid';
import { ChangesService } from './ChangesService';
import { getObjectValues, parseJSON, unique } from 'shared/parse';
import { VistoEffectItem } from 'sp/Effect';

export const PlanVersion = {
  current: '4.3.53',
};

// graph api allows max 100 actually, 50 to provide better visual feedback
const MAX_BATCH_CHUNK_SIZE = 50;

export interface IPlanExportFilter {
  removeLinks?: boolean;
  removeAssignees?: boolean;
  removeComments?: boolean;
  removeDates?: boolean;
  filterItems?: boolean;
  removeRuntimeInfo?: boolean;
  selectedKeys?: string[];
}

export class PlanDataService {

  public static getPlanUsers(plan: IVistoPlan) {
    const users: IFieldValueUser[] = PlanDataService
      .getItemsHaving<VistoActionItem>(plan.items, (i: VistoActionItem) => i.kind === VistoKind.Action && i.assignedTo?.length > 0)
      .reduce((r, a) => [...r, ...a.assignedTo], []);

    return unique(users, 'userName').sort((a, b) => TextService.compareNames(a.title, b.title));
  }

  public static isPlanVersionLess(planVersion: string, currentVersion: string) {
    return !planVersion || currentVersion.localeCompare(planVersion, undefined, { numeric: true }) > 0;
  }

  public static isPlanOutdated(planVersion: string): boolean {
    return PlanDataService.isPlanVersionLess(planVersion, PlanVersion.current);
  }

  public static getAllVistoKinds(opts?: { includePlan?: boolean }) {
    const result = Object.keys(VistoKindInfo).map(x => Number(x)).filter(x => x !== VistoKind.Plan || opts?.includePlan) as VistoKind[];
    return result.sort((a,b) => a - b);
  }

  public static compareItemKind = (a: VistoKind, b: VistoKind): number => {
    return VistoKindInfo[a].sortOrder - VistoKindInfo[b].sortOrder;
  }

  public static compareItems = (a, b) => {
    if (a.kind !== b.kind)
      return PlanDataService.compareItemKind(a.kind, b.kind);
    if (TextService.isValidNumber(a.sortOrder) && TextService.isValidNumber(b.sortOrder))
      return a.sortOrder - b.sortOrder;
    return TextService.naturalComparer.compare(a.name, b.name);
  }

  public static getItemByGuid<T extends IVistoListItem>(planItems: IPlanItems, guid: string): T {
    if (planItems && guid) {
      const result = planItems[guid] as T;
      if (!result)
        trackClient.warn(`item with guid ${guid} not found`);
      return result;
    }
  }

  public static getItemByKindAndId<T extends IVistoListItem>(planItems: IPlanItems, kind: VistoKind, itemId: number): T {
    if (planItems && kind && itemId) {
      for (const guid in planItems) {
        const item = planItems[guid];
        if (item.kind === kind && item.itemId === itemId)
          return item as T;
      }
      trackClient.warn(`item with kind ${kind} and id ${itemId} not found`);
    }
  }

  public static getItemsHaving<T extends IVistoListItem>(planItems: IPlanItems, condition: (item: T) => boolean): T[] {
    if (planItems) {

      const result = [];
      for (const guid in planItems) {
        const item = planItems[guid] as T;
        if (condition(item)) {
          result.push(item);
        }
      }

      return result.sort(PlanDataService.compareItems);
    } else {
      return [];
    }
  }

  public static getItems<T extends IVistoListItem>(planItems: IPlanItems, kind: VistoKind): T[] {
    return this.getItemsHaving(planItems, x => x.kind === kind);
  }

  public static compareFocusActions = (a: VistoActionItem, b: VistoActionItem) => {
    if (a.startDate && !b.startDate)
      return -1;
    if (!a.startDate && b.startDate)
      return +1;
    if (a.startDate && b.startDate) {
      if (typeof (a.startDate.getTime) === 'function' && typeof (b.startDate.getTime) === 'function')
        return a.startDate.getTime() - b.startDate.getTime();
    }
    return this.compareItems(a, b);
  }

  public static getEfffectActions(actions: VistoActionItem[], effectGuid: string) {
    const result: VistoActionItem[] = [];

    for (let i = 0; i < actions.length; ++i) {
      const action: VistoActionItem = actions[i];
      if (action.effectGuid == effectGuid && !result.some(a => a.guid === action.guid)) {
        result.push(action);
      }
    }

    return result.sort(PlanDataService.compareFocusActions);
  }

  public static getFocusActions(plan: IVistoPlan, focusGuid: string) {
    const result: VistoActionItem[] = [];

    const actionList = this.getItems<VistoActionItem>(plan?.items, VistoKind.Action);
    for (let i = 0; i < actionList.length; ++i) {
      const action: VistoActionItem = actionList[i];
      if (action.focusGuid == focusGuid && !result.some(a => a.guid === action.guid)) {
        result.push(action);
      }
    }

    return result.sort(PlanDataService.compareFocusActions);
  }

  public static getFocusKPIs(plan: IVistoPlan, focusGuid: string) {
    const result: VistoKeyResultItem[] = [];

    // for (const action of this.getFocusActions(plan, focusGuid)) {
    //   if (action.kpiGuid && !result.some(x => x.guid === action.kpiGuid)) {
    //     result.push(this.getItemByGuid(plan.items, action.kpiGuid));
    //   }
    // }

    return result.sort(PlanDataService.compareItems);
  }

  public static getDpActions(plan: IVistoPlan, dpGuid: string) {
    const result: VistoActionItem[] = [];
    const actionList = this.getItems<VistoActionItem>(plan?.items, VistoKind.Action);
    for (let i = 0; i < actionList.length; ++i) {
      const action: VistoActionItem = actionList[i];
      if (action.dpGuid == dpGuid && !result.some(a => a.guid === action.guid) /*&& !action.hidden */) {
        result.push(action);
      }
    }

    return result.sort(PlanDataService.compareItems);
  }

  public static getActionAssocs(plan: IVistoPlan, actionGuid: string) {
    const result = PlanDataService
      .getItems<VistoAssocItem>(plan?.items, VistoKind.Assoc)
      .filter(v => v.actionGuid === actionGuid);
    return result;
  }

  public static getSoKeyResults(plan: IVistoPlan, soGuid: string) {
    const result = PlanDataService
      .getItems<VistoKeyResultItem>(plan?.items, VistoKind.KeyResult)
      .filter(v => v.soGuid === soGuid);
    return result;
  }

  public static getDpKeyResultSet(plan: IVistoPlan, dpGuid: string): { [key: string]: { kr: VistoKeyResultItem, showOnDiagram: boolean } } {
    return PlanDataService
      .getItems<VistoAssocItem>(plan?.items, VistoKind.Assoc)
      .filter(x => x.krGuid && PlanDataService.getItemByGuid<VistoActionItem>(plan.items, x.actionGuid)?.dpGuid === dpGuid)
      .map(x => ({ kr: PlanDataService.getItemByGuid<VistoKeyResultItem>(plan.items, x.krGuid), showOnDiagram: x.showOnDiagram }))
      .filter(x => x.kr)
      .reduce((r, x) => ({...r, [x.kr.guid] : { kr: x.kr, showOnDiagram: x.showOnDiagram || r[x.kr.guid]?.showOnDiagram } }), {});
  }

  public static getDpEffects(plan: IVistoPlan, dpGuid: string) {
    const result = PlanDataService
      .getItems<VistoEffectItem>(plan?.items, VistoKind.Effect)
      .filter(v => v.dpGuid === dpGuid);
    return result;
  }

  public static getEffects(plan: IVistoPlan) {
    return this.getItems<VistoEffectItem>(plan.items, VistoKind.Effect);
  }

  public static getLops(plan: IVistoPlan) {
    const result = PlanDataService.getItems<VistoLopItem>(plan.items, VistoKind.LOP);

    // sort by coordinates
    return result.sort((a, b) => {
      if (a.position && b.position) {
        if (a.position.y + 1 < b.position.y) return -1;
        if (a.position.y > b.position.y + 1) return +1;
      }

      return TextService.naturalComparer.compare(a.name, b.name);
    });
  }

  private static sortDps(result: VistoDpItem[]) {
    // sort by coordinates
    return result.sort((a, b) => {
      if (a.position && b.position) {
        if (a.position.y + a.position.height < b.position.y) return -1;
        if (a.position.y > b.position.y + b.position.height) return +1;

        if (a.position.x + a.position.width < b.position.x) return -1;
        if (a.position.x > b.position.x + b.position.width) return +1;
      }

      return TextService.naturalComparer.compare(a.name, b.name);
    });
  }

  public static getLopDps(plan: IVistoPlan, lopGuid: string) {
    const result = this.getItems<VistoDpItem>(plan.items, VistoKind.DP)
      .filter(dp => dp.lopGuid === lopGuid);
    return PlanDataService.sortDps(result);
  }

  public static getDps(plan: IVistoPlan) {
    const result = this.getItems<VistoDpItem>(plan.items, VistoKind.DP);
    return PlanDataService.sortDps(result);
  }

  public static getFocuses(plan: IVistoPlan) {
    return this.getItems<VistoFocusItem>(plan.items, VistoKind.Focus);
  }

  public static getPositionSos(plan: IVistoPlan) {
    const result = this.getItems<VistoLopItem>(plan.items, VistoKind.SO);

    return result.sort((a, b) => {
      if (a.position && b.position) {
        if (a.position.y + a.position.height < b.position.y) return -1;
        if (a.position.y > b.position.y + b.position.height) return +1;
      }

      return TextService.naturalComparer.compare(a.name, b.name);
    });
  }

  public static getDependencis(planItems: IPlanItems, item: IVistoListItem): IVistoListItem[] {
    const direct = this.getDirectDependencis(planItems, item);
    if (direct.length > 0) {
      const indirect = direct.reduce((r, x) => [...r, ...this.getDependencis(planItems, x)], []);
      return [...direct, ...indirect];
    }
    else
      return [];
  }

  public static getDirectDependencis(planItems: IPlanItems, item: IVistoListItem): IVistoListItem[] {
    switch (item.kind) {
      case VistoKind.Action:
        return [
          ...PlanDataService.getItems(planItems, VistoKind.Assoc).filter((x: VistoAssocItem) => x.actionGuid === item.guid)
        ];

      case VistoKind.DP:
        return [
          ...PlanDataService.getItems(planItems, VistoKind.Action).filter((x: VistoActionItem) => x.dpGuid === item.guid),
          ...PlanDataService.getItems(planItems, VistoKind.Effect).filter((x: VistoEffectItem) => x.dpGuid === item.guid),
          ...PlanDataService.getItems(planItems, VistoKind.KeyResult).filter((x: VistoKeyResultItem) => x.parentKrGuid === item.guid),
        ];

      case VistoKind.LOP:
        return [
          ...PlanDataService.getItems(planItems, VistoKind.DP).filter((x: VistoDpItem) => x.lopGuid === item.guid)
        ];

      case VistoKind.KeyResult:
        return [
          ...PlanDataService.getItems(planItems, VistoKind.KRV).filter((x: VistoKeyResultValueItem) => x.krGuid === item.guid),
        ];

      case VistoKind.Focus:
        return [
        ];

      case VistoKind.SO:
        return [
          ...PlanDataService.getItems(planItems, VistoKind.Assoc).filter((x: VistoAssocItem) => x.soGuid === item.guid),
          ...PlanDataService.getItems(planItems, VistoKind.KeyResult).filter((x: VistoKeyResultItem) => x.soGuid === item.guid),
        ];

      default:
        return [];
    }
  }

  public static getActiveFocus(plan: IVistoPlan) {
    const focuses = PlanDataService.getItems<VistoFocusItem>(plan.items, VistoKind.Focus);
    return focuses.find(f => f.active);
  }

  public static flattenItems<T>(structured: T[][]): T[] {
    return structured.reduce((r, v) => [...r, ...v], []);
  }

  private static addChunks<T>(result: T[][], all: T[]) {
    for (var i = 0; i < all.length; i += MAX_BATCH_CHUNK_SIZE) {
      result.push(all.slice(i, i + MAX_BATCH_CHUNK_SIZE));
    }
  }

  public static chunkItems<T>(list: T[], opts: { getItem: (x: T) => IVistoListItem, groupByKind: boolean }): T[][] {
    const structured = {};

    // only unique items
    for (const x of list) {
      const item = opts.getItem(x);
      const kind = opts.groupByKind ? item.kind : 0;
      if (!structured[kind])
        structured[kind] = {};
      structured[kind][item.guid] = x;
    }

    const result: T[][] = [];

    const sortedKinds = Object.keys(structured).map(x => +x).sort(PlanDataService.compareItemKind);
    for (const kind of sortedKinds) {
      const sameKindList = getObjectValues<T>(structured[kind]);
      if (kind === VistoKind.KeyResult) {
        const parentKeyResults = sameKindList.filter(x => !(opts.getItem(x) as VistoKeyResultItem).parentKrGuid);
        this.addChunks(result, parentKeyResults);
        const childKeyResults = sameKindList.filter(x => (opts.getItem(x) as VistoKeyResultItem).parentKrGuid);
        this.addChunks(result, childKeyResults);
      } else {
        this.addChunks(result, sameKindList);
      }
    }

    return result;
  }

  public static isDuplicateAssoc(items: IPlanItems, newAssoc: VistoAssocItem) {
    return !!PlanDataService.getItems<VistoAssocItem>(items, VistoKind.Assoc)
      .find(a => a.guid !== newAssoc.guid && a.actionGuid === newAssoc.actionGuid && a.soGuid === newAssoc.soGuid && a.krGuid === newAssoc.krGuid);
  }

  public static mergeItem(plan: IVistoPlan, newItem: IVistoListItem): IVistoPlan {

    const oldItem = PlanDataService.getItemByGuid(plan.items, newItem.guid);

    if (oldItem) {
      const changes = ChangesService.getChanges(oldItem, newItem);
      if (!changes.detected)
        return plan;
    }

    const items = { ...plan.items };
    items[newItem.guid] = newItem;
    return { ...plan, items };
  }

  public static dropItem(plan: IVistoPlan, kind: VistoKind, guid: string): IVistoPlan {

    if (!plan?.items[guid]) {
      return plan;
    }

    const items = { ...plan.items };
    delete items[guid];
    return { ...plan, items };
  }

  public static removeGuids(text: string, guids: object) {
    const matches = text.match(/\b[0-9a-fA-F]{32}\b/g);
    if (matches) {
      for (const match of matches) {
        if (!guids[match]) {
          guids[match] = `{{{guid.new${1+Object.keys(guids).length}}}}`;
        }
      }
      for (const guid in guids) {
        text = text.replace(new RegExp(guid, 'g'), guids[guid]);
      }
    }
    return text;
  }

  public static removeAssigneeIds(planItems: IPlanItems) {
    for (const guid in planItems) {
      const item = planItems[guid] as IVistoListItemWithAssignee;
      if (item.assignedTo) {
        for (const assignee of item.assignedTo) {
          delete assignee.id;
        }
      }
    }
  }

  public static removeRuntimeInfo(plan: IVistoPlan) {
    delete plan.revision;
    delete plan.drawingRevision;
    delete plan.modifiedDate;
    delete plan.createdDate;
    delete plan.previewPng;
    delete (plan as any).editable;
    delete (plan as any).timeZoneBias;
    delete (plan as any).itemId;
    delete (plan as any).siteUrl;
    delete (plan as any).planId;
    for (const guid in plan.items) {
      const item = plan.items[guid] as any;
      delete item.position;
      delete item.itemId;
    }
  }

  public static filterPlan(sourcePlan: IVistoPlan, filter?: IPlanExportFilter): IVistoPlan {

    const plan: IVistoPlan = parseJSON(JSON.stringify(sourcePlan));

    if (filter?.removeLinks) {
      for (const guid in plan.items) {
        const item = plan.items[guid] as any;
        delete item.sourceItemUrl;
      }
    }

    if (filter?.removeAssignees) {
      for (const guid in plan.items) {
        const item = plan.items[guid] as any;
        delete item.assignedTo;
      }
    }

    if (filter?.removeComments) {
      delete plan.commentsJson;
    }

    if (filter?.removeRuntimeInfo) {
      this.removeRuntimeInfo(plan);
    }

    this.removeAssigneeIds(plan.items);

    if (filter?.removeDates) {
      for (const guid in plan.items) {
        const item = plan.items[guid] as any;
        delete item.createdDate;
        delete item.modifiedDate;
      }
    }

    if (filter?.filterItems) {

      const doc = new DOMParser().parseFromString(plan.drawingXml, 'text/xml');

      const removeItem = (guid: string) => {
        delete plan.items[guid];
        const node = doc.getElementById(guid);
        if (node) {
          node.parentNode.removeChild(node);
        }
      };

      const selectedKeys = new Set(filter.selectedKeys);
      for (const lop of PlanDataService.getLops(sourcePlan)) {
        if (!selectedKeys.has(lop.guid)) {
          removeItem(lop.guid);
        }
        for (const dp of PlanDataService.getLopDps(sourcePlan, lop.guid)) {
          if (!selectedKeys.has(dp.guid)) {
            removeItem(dp.guid);
          }
          for (const action of PlanDataService.getDpActions(sourcePlan, dp.guid)) {
            if (!selectedKeys.has(dp.guid)) {
              removeItem(action.guid);
              for (const actionAssoc of PlanDataService.getActionAssocs(plan, action.guid)) {
                removeItem(actionAssoc.guid);
              }
            }
          }
        }
      }

      plan.drawingXml = doc.documentElement.outerHTML;
    }

    return plan;
  }

  public static getPlanLocalStorageKey(siteUrl: string, planId: string) {
    return `${siteUrl}/${planId}`;
  }

  public static removeInvalidItems(planItems: IPlanItems) {
    const validKinds = PlanDataService.getAllVistoKinds();
    const invalidItems = PlanDataService.getItemsHaving(planItems, x => !validKinds.includes(x.kind));
    for (const invalidItem of invalidItems) {
      delete planItems[invalidItem.guid];
    }
  }

  public static upgradeFilePlan(plan: IVistoPlan): IVistoPlan {

    const planItems = plan.items;

    PlanDataService.removeInvalidItems(planItems);

    if (PlanDataService.isPlanVersionLess(plan.planVersion, '4.3.15')) {
      const krsToMigrate = PlanDataService.getItemsHaving<VistoKeyResultItem>(planItems, x => x.kind === VistoKind.KeyResult && !!x['parentKrGuid']);
      for (const krToMigrate of krsToMigrate) {
        const guid = makeGuidString();
        const kpiGuid = krToMigrate['parentKrGuid'];
        const so = PlanDataService.getItemByGuid(planItems, kpiGuid);
        const assoc: VistoAssocItem = {
          kind: VistoKind.Assoc,
          guid: guid,
          itemId: 0,
          confidence: 9,
          name: 'migrated',
          actionGuid: null,
          // kpiGuid: kpiGuid,
          krGuid: krToMigrate.guid,
          soGuid: so?.guid ?? null,
          createdDate: krToMigrate.createdDate,
          modifiedDate: krToMigrate.modifiedDate,
        };
        // krToMigrate['krGuid'] = null;
        planItems[assoc.guid] = assoc;
      }
    }

    if (PlanDataService.isPlanVersionLess(plan.planVersion, '4.3.25')) {

      let count = 1;

      const assocs = PlanDataService.getItems<any>(planItems, VistoKind.Assoc);
      for (const assoc of assocs) {
        const kpiGuid = assoc['kpiGuid'];
        const kpi = PlanDataService.getItemByGuid<any>(planItems, kpiGuid);
        if (kpi) {
          const krGuid = assoc['krGuid'];
          const soGuid = assoc['soGuid'] || PlanDataService.getItemByGuid<VistoKeyResultItem>(planItems, krGuid)?.soGuid;
          kpi.parentKrGuid = krGuid;
          kpi.soGuid = soGuid;

          let actionGuid = assoc['actionGuid'];
          if (!actionGuid) {
            const dpGuid = kpi['dpGuid'];
            const dp = PlanDataService.getItemByGuid<any>(planItems, dpGuid);
            const lopGuid = dp['lopGuid'];

            const added: VistoActionItem = {
              kind: VistoKind.Action,
              guid: makeGuidString(),
              name: `migrated ${count++}`,
              dpGuid: dpGuid,
              lopGuid: lopGuid,
              focusGuid: null,
              useFocusDates: false,
              createdDate: assoc.createdDate,
              modifiedDate: assoc.modifiedDate,
            };

            actionGuid = added.guid;
            planItems[actionGuid] = added;
          }

          assoc['actionGuid'] = actionGuid;
          assoc['showOnDiagram'] = kpi['showOnDiagram'];
          kpi['showOnDiagram'] = false;
        }
      }

      for (const item of PlanDataService.getItems<any>(planItems, VistoKind.Assoc)) {
        delete item['kpiGuid'];
      }
      for (const item of PlanDataService.getItems<any>(planItems, VistoKind.Action)) {
        delete item['kpiGuid'];
      }
      for (const item of PlanDataService.getItems<any>(planItems, VistoKind.KeyResult)) {
        delete item['dpGuid'];
      }
    }

    const krs = PlanDataService.getItems<any>(planItems, VistoKind.KeyResult);
    krs.sort((a, b) => TextService.compareNames(a.name, b.name));
    let firstSoGuid = null;
    for (const kr of krs) {
      if (kr['soGuid'])
        firstSoGuid = kr['soGuid'];
    }
    for (const kr of krs) {
      const soGuid = kr['soGuid'];
      if (!soGuid) {
        kr['soGuid'] = firstSoGuid;
        kr['name'] = `${kr['name']} (migrated)`
      }
    }

    return { ...plan, items: planItems, planVersion: PlanVersion.current };
  };

  public static getAssigneeSet(plan: IVistoPlan) {
    const resultSet = {};
    for (const guid in plan.items) {
      const item = plan.items[guid] as IVistoListItemWithAssignee;
      if ([VistoKind.Action, VistoKind.DP, VistoKind.LOP].includes(item.kind) && item.assignedTo) {
        for (const assignee of item.assignedTo) {
          resultSet[assignee.userName || assignee.guid] = assignee;
        }
      }
    }
    return resultSet;
  }
}
