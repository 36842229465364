import React from 'react';
import strings from 'VistoWebPartStrings';
import { Stack, TextField, Toggle } from '@fluentui/react';
import { InfoBar } from 'components';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { PropertyBagService } from 'services/PropertyBagService';
import { TextService } from 'services/TextService';

export const AssistantInputField = (props: {
  text: string;
  setText: (text: string) => void;
  onSubmit?: () => void;
  disabled?: boolean;
  placeholder: string;
  autoFocus?: boolean;
  styles?: any;
}) => {

  const appContext = React.useContext(AppContext);

  const [, updateState] = React.useState(null);

  const assistantConsented = appContext
    ? appContext.propertyBag.assistantConsented
    : PropertyBagService.loadSettings().assistantConsented;

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (props.onSubmit) {
      if (e.key === 'Enter' && e.ctrlKey) {
        e.preventDefault();
        props.onSubmit();
      }
    }
  }

  return <React.Fragment>
    {assistantConsented
      ? <Stack grow>
        <TextField
          disabled={props.disabled}
          value={props.text}
          onChange={(_, val) => props.setText(val || '')}
          placeholder={props.placeholder}
          autoAdjustHeight
          multiline
          rows={5}
          onKeyDown={onKeyDown}
          autoFocus={props.autoFocus}
          styles={props.styles}
        />
      </Stack>
      : <InfoBar
        type={NotificationType.warn}
        message={TextService.format(strings.Assistant_ConsentTitle)}
        error={{
          message: TextService.format(strings.Assistant_ConsentText)
        }}
        actions={[{
          title: strings.Assistant_ConsentButton,
          action: async () => {
            if (appContext) {
              appContext.setPropertyBag({ assistantConsented: true });
            } else {
              PropertyBagService.saveSettings({ ...PropertyBagService.loadSettings(), assistantConsented: true });
              updateState({});
            }
          }
        }
        ]}
      />
    }
  </React.Fragment>
}
