export class SingleRequest {

  private static ongoingRequests: Map<string, Promise<any>> = new Map();

  public static async query(key: string, getter: () => Promise<any>) {

    if (this.ongoingRequests.has(key)) {
      return this.ongoingRequests.get(key);
    }

    const requestPromise = getter();
    this.ongoingRequests.set(key, requestPromise);

    try {
      const result = await requestPromise;
      return result;
    } finally {
      this.ongoingRequests.delete(key);
    }
  }

}
