import { mxgraph } from 'ts-mxgraph-typings';
import { Editor, mxglobals } from './mxglobals';

import { CellKind } from 'shared/CellKind';
import { ImageCacheService } from 'services/ImageCacheService';
import { trackClient } from 'services/trackClient';
import { StorageService } from 'services/StorageService';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { ApiService } from 'services/api/ApiService';
import { clearInfoBar, IBasicNotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { IVistoPlan } from 'sp';
import { PlanDataService } from 'services/PlanDataService';

export enum FixedViewType {
  None = 0,
  FitPage = 1,
  FillPage = 2,
  Width = 3,
}

export enum ViewAlignment {
  Begin = 0,
  Middle = 1,
  End = 2,
}

export class mx {

  private static intervalsOverlapping(a1: number, a2: number, b1: number, b2: number) {
    return (a1 <= b2) && (a2 >= b1);
  }

  public static areCellsOverlapping(lop: mxgraph.mxCell, dp: mxgraph.mxCell) {
    const lopGeo = lop.geometry;
    const start: mxgraph.mxPoint = lopGeo.getTerminalPoint(true);
    const end: mxgraph.mxPoint = lopGeo.points[0] || lopGeo.getTerminalPoint(false);

    const dpGeo = dp.geometry;

    const xOverlap = this.intervalsOverlapping(start.x, end.x, dpGeo.x, dpGeo.x + dpGeo.width);
    const yOverlap = this.intervalsOverlapping(start.y, end.y, dpGeo.y, dpGeo.y + dpGeo.height);
    return xOverlap && yOverlap;
  }

  public static getCellGuid(cell: mxgraph.mxCell) {
    return cell && cell.id;
  }

  public static getCellKind(cell: mxgraph.mxCell): CellKind {
    const styles = cell && cell.style && cell.style.split(';') || [];
    for (let i: CellKind = 0; i < CellKind.COUNT; ++i) {
      if (styles.indexOf(CellKind[i]) >= 0)
        return i;
    }
    return CellKind.NONE;
  }

  public static removeCells(graph: mxgraph.mxGraph, guids: string[]) {
    const allCells = mx.getAllCells(graph);
    const cells = [];
    for (let cell of allCells) {
      const guid = this.getCellGuid(cell);
      if (guids.indexOf(guid) >= 0) {
        cells.push(cell);
      }
    }
    if (cells.length) {
      graph.removeCells(cells);
    }
  }

  public static getAllCells(graph: mxgraph.mxGraph) {
    return graph.model.getChildCells(graph.getDefaultParent(), true, true);
  }

  public static findCells(graph: mxgraph.mxGraph, filter: (cell: mxgraph.mxCell) => boolean) {
    return this.getAllCells(graph).filter(filter);
  }

  public static selectCell(graph: mxgraph.mxGraph, condition: (cell: mxgraph.mxCell) => boolean) {
    if (!graph)
      return;

    const allCells = mx.getAllCells(graph);
    for (let cell of allCells) {
      if (condition(cell)) {
        graph.selectionModel.setCell(cell);
      }
    }
  }

  public static findOverlappingLopCell(graph: mxgraph.mxGraph, movedCell: mxgraph.mxCell) {
    const lopCells = this.findOverlappingLopCells(graph, movedCell);
    if (lopCells.length > 0) {

      const movedCellY = movedCell.geometry.getCenterY();

      let result = lopCells[0];
      let resultDistance = Math.abs(result.geometry.sourcePoint.y - movedCellY);

      for (let i = 1; i < lopCells.length; i++) {
        const lopCell = lopCells[i];

        const lopY = lopCell.geometry.sourcePoint.y;
        const distance = Math.abs(lopY - movedCellY);

        if (distance < resultDistance) {
          result = lopCell;
          resultDistance = distance;
        }
      }
      return result;
    }
  }

  public static findOverlappingLopCells(graph: mxgraph.mxGraph, movedCell: mxgraph.mxCell) {
    return this.findCells(graph, cell => this.getCellKind(cell) === CellKind.LOP && this.areCellsOverlapping(cell, movedCell));
  }

  private static getDistanceToLop(dp: mxgraph.mxCell, lop: mxgraph.mxCell) {
    const p0 = lop.geometry.sourcePoint;
    const pe = lop.geometry.points[0];
    const x = dp.geometry.getCenterX();
    const y = dp.geometry.getCenterY();
    return mxglobals.mxUtils.ptLineDist(p0.x, p0.y, pe.x, pe.y, x, y);
  }

  public static findOverlappingDpCells(graph: mxgraph.mxGraph, movedLop: mxgraph.mxCell) {

    const overlappingDps = this.findCells(graph, cell =>
      this.getCellKind(cell) === CellKind.DP &&
      this.areCellsOverlapping(movedLop, cell));

    // exclude from overlapping those which are closer to another LOP
    return overlappingDps.filter(overlappingDp => {

      const distanceToMovedLop = this.getDistanceToLop(overlappingDp, movedLop);

      const otherLops = this.findCells(graph, otherLop =>
        this.getCellKind(otherLop) === CellKind.LOP &&
        this.areCellsOverlapping(otherLop, overlappingDp) &&
        this.getCellGuid(movedLop) !== this.getCellGuid(otherLop));

      for (const otherLop of otherLops) {
        const distanceToOtherLop = this.getDistanceToLop(overlappingDp, otherLop);
        if (distanceToOtherLop < distanceToMovedLop) {
          return false;
        }
      }

      return true;
    });
  }

  public static centerDpOnLop(dpCell: mxgraph.mxCell, lopCell: mxgraph.mxCell) {
    const start = lopCell.geometry.getTerminalPoint(true);
    const geo = dpCell.geometry.clone();
    geo.y = start.y - geo.height / 2;
    dpCell.setGeometry(geo);
  }

  public static getGraphXml(editor: Editor) {

    const graph = editor.graph as any;

    const enc = new mxglobals.mxCodec(mxglobals.mxUtils.createXmlDocument());
    const node = enc.encode(graph.getModel());

    // node.setAttribute('grid', (graph.isGridEnabled()) ? '1' : '0');
    node.setAttribute('gridSize', graph.gridSize);
    node.setAttribute('guides', (graph.graphHandler.guidesEnabled) ? '1' : '0');
    node.setAttribute('tooltips', (graph.tooltipHandler.isEnabled()) ? '1' : '0');
    node.setAttribute('connect', (graph.connectionHandler.isEnabled()) ? '1' : '0');
    node.setAttribute('arrows', (graph.connectionArrowsEnabled) ? '1' : '0');
    node.setAttribute('fold', (graph.foldingEnabled) ? '1' : '0');
    // node.setAttribute('page', (graph.pageVisible) ? '1' : '0');
    node.setAttribute('pageScale', graph.pageScale);
    node.setAttribute('pageWidth', graph.pageFormat.width);
    node.setAttribute('pageHeight', graph.pageFormat.height);

    if (graph.background != null) {
      node.setAttribute('background', graph.background);
    }

    const backgroundImage = graph.backgroundImage;
    if (backgroundImage) {
      node.setAttribute('backgroundImage', backgroundImage.src);
      node.setAttribute('backgroundImageWidth', backgroundImage.width);
      node.setAttribute('backgroundImageHeight', backgroundImage.height);
      node.setAttribute('backgroundImageAspect', graph.backgroundImageAspect ? '1' : '0');
      node.setAttribute('backgroundImageSlice', graph.backgroundImageSlice ? '1' : '0');
      node.setAttribute('backgroundImageOpacity', graph.backgroundImageOpacity);
    }

    if (graph.extFonts != null && graph.extFonts.length > 0) {
      node.setAttribute('extFonts', graph.extFonts.map((ef) => ef.name + '^' + ef.url).join('|'));
    }

    return mxglobals.mxUtils.getXml(node);
  }

  public static setFixedView(
    graph: mxgraph.mxGraph,
    scale: number | null,
    fixedViewType: FixedViewType,
    viewAlignmentX: ViewAlignment,
    viewAlignmentY: ViewAlignment) {

    const { width: pw, height: ph } = graph.pageFormat;
    const { clientWidth, clientHeight } = graph.container;

    const margin = 15;
    const cw = clientWidth - margin * 2;
    const ch = clientHeight - margin * 2;

    if (!scale) {
      scale = 1;
      if (fixedViewType === FixedViewType.FitPage)
        scale = Math.min(cw / pw, ch / ph);
      if (fixedViewType === FixedViewType.FillPage)
        scale = Math.max(cw / pw, ch / ph);
      if (fixedViewType === FixedViewType.Width) {
        const scaleX = cw / pw;
        if (scaleX < 1)
          scale = scaleX;
      }
    }

    const tx = (viewAlignmentX === ViewAlignment.Begin) ? (margin * scale) : (viewAlignmentX === ViewAlignment.End) ? (cw - (pw - margin) * scale) : ((cw - (pw - margin) * scale) / 2);
    const ty = (viewAlignmentY === ViewAlignment.Begin) ? (margin * scale) : (viewAlignmentY === ViewAlignment.End) ? (ch - (ph - margin) * scale) : ((ch - (ph - margin) * scale) / 2);

    graph.view.scaleAndTranslate(scale, tx / scale, ty / scale);
  }

  public static focusFirstTime(graph: mxgraph.mxGraph) {
    const position = mx.getAllCells(graph).find(x => mx.getCellKind(x) === CellKind.TITLE);
    if (position)
      graph.setSelectionCell(position);
  }


  public static distributeCellsVertically(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[]) {

    if (cells) {
      cells = graph.getSelectionCells();
    }

    if (cells != null && cells.length > 1) {

      const edges = cells
        .filter(cell => graph.getModel().isEdge(cell))
        .map(cell => graph.view.getState(cell))
        .filter(v => !!v)
        .sort((a, b) => a.y - b.y);

      if (edges.length > 2) {

        const s = graph.view.scale;
        const t = graph.view.translate;

        const min = (edges[0].y) / s - t.y;

        const max = (edges[edges.length - 1].y + edges[edges.length - 1].height) / s - t.y;

        const gap = (max - min) / (edges.length - 1);

        graph.getModel().beginUpdate();
        try {
          let pos = min;

          for (const edge of edges) {
            const dps = mx.findOverlappingDpCells(graph, edge.cell);
            const geo = graph.getCellGeometry(edge.cell);

            if (geo != null) {

              const newGeo = geo.clone();
              newGeo.sourcePoint.y = Math.round(pos);
              newGeo.points[0].y = Math.round(pos);

              for (const dp of dps) {
                const pstate = graph.view.getState(graph.model.getParent(dp));
                const dpGeo = graph.getCellGeometry(dp);
                const newDpGeo = dpGeo.clone();
                newDpGeo.y = Math.round(pos) - (dpGeo.height / 2) - pstate.origin.y;
                graph.getModel().setGeometry(dp, newDpGeo);
              }

              pos = pos + gap;

              graph.getModel().setGeometry(edge.cell, newGeo);
            }
          }
        }
        finally {
          graph.getModel().endUpdate();
        }
      }
    }

    return cells;
  }

  public static distributeCellsHorizontally(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[]) {

    if (cells) {
      cells = graph.getSelectionCells();
    }

    if (cells != null && cells.length > 1) {

      const vertices = cells
        .filter(cell => graph.getModel().isVertex(cell))
        .map(cell => graph.view.getState(cell))
        .filter(v => !!v)
        .sort((a, b) => a.getCenterX() - b.getCenterX());

      if (vertices.length > 2) {

        const s = graph.view.scale;
        const t = graph.view.translate;

        const min = (vertices[0].x) / s - t.x;

        const max = (vertices[vertices.length - 1].x + vertices[vertices.length - 1].width) / s - t.x;

        const size = vertices.reduce((r, v) => r + v.width, 0) / s;
        const gap = (max - min - size) / (vertices.length - 1);

        graph.getModel().beginUpdate();
        try {
          let pos = min;

          for (const vertex of vertices) {
            const pstate = graph.view.getState(graph.model.getParent(vertex.cell));
            const geo = graph.getCellGeometry(vertex.cell);

            if (geo != null && pstate != null) {
              const newGeo = geo.clone();

              newGeo.x = Math.round(pos) - pstate.origin.x;
              pos = pos + newGeo.width + gap;

              graph.getModel().setGeometry(vertex.cell, newGeo);
            }
          }
        }
        finally {
          graph.getModel().endUpdate();
        }
      }
    }

    return cells;
  }

  public static clearStyles(graph: mxgraph.mxGraph) {
    graph.getModel().beginUpdate();
    try {
      const cells = graph.getSelectionCells();
      const model = graph.model;

      for (const cell of cells) {
        const cellKind = this.getCellKind(cell);
        switch (cellKind) {
          case CellKind.IMAGE:
            break;

          case CellKind.DP:
          case CellKind.LOP:
          case CellKind.POSITION:
          case CellKind.TITLE:
          case CellKind.FOCUS:
          case CellKind.PAGESIZER:
            model.setStyle(cell, CellKind[cellKind]);
            break;

          default:
            model.setStyle(cell, '');
            break;
        }
      }
    }
    finally {
      graph.getModel().endUpdate();
    }
  }


  public static setGraphXml(editor: Editor, xml: string, notify: IBasicNotify) {
    const eventsEnabled = editor.graph.isEventsEnabled();
    try {
      clearInfoBar(notify, 'SetGraphXml');
      editor.graph.setEventsEnabled(false);
      const node = mxglobals.mxUtils.parseXml(xml).documentElement;
      editor.setGraphXml(node);
    } catch (error) {
      StorageCacheService.resetCache();
      notifyInfoBar(notify, {
        message: TextService.format(strings.ErrorMessage_InvalidPlanXml),
        type: NotificationType.error,
        group: 'SetGraphXml',
        error
      });
    } finally {
      editor.graph.setEventsEnabled(eventsEnabled);
    }
  }

  public static getSelectionGuid(plan: IVistoPlan, cellKind: CellKind, cellId: string): string {
    switch (cellKind) {
      case CellKind.LOP:
      case CellKind.DP:
        return cellId;
      case CellKind.FOCUS:
        return PlanDataService.getActiveFocus(plan)?.guid;
    }
  }

  public static getSelectedCells(graph: mxgraph.mxGraph) {
    if (graph?.selectionModel?.cells?.length > 0) {
      return graph.selectionModel.cells.map(cell => cell.id);
    } else {
      return [];
    }
  }

  public static setSelectedCells(graph: mxgraph.mxGraph, cells: string[]) {
    if (cells?.length > 0) {
      mx.selectCell(graph, cell => cells.indexOf(cell.id) >= 0);
    }
  }

  public static findCellsByKind(graph: mxgraph.mxGraph, cellKind: CellKind) {
    return this.findCells(graph, cell => this.getCellKind(cell) === cellKind);
  }

  public static findCellByKind(graph: mxgraph.mxGraph, cellKind: CellKind) {
    return mx.findCellsByKind(graph, cellKind)[0];
  }

  public static moveLopBend(graph: mxgraph.mxGraph, lop: mxgraph.mxCell, dx: number) {
    const geo = graph.getCellGeometry(lop);
    const newGeo: mxgraph.mxGeometry = geo.clone();
    newGeo.points[0].x = geo.points[0].x + dx;
    graph.getModel().setGeometry(lop, newGeo);
  };

  public static moveCells(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[], dx: number, dy: number) {
    for (const cell of cells) {
      const geo = graph.getCellGeometry(cell);
      const newGeo: mxgraph.mxGeometry = geo.clone();
      newGeo.x = geo.x + dx;
      newGeo.y = geo.y + dy;
      graph.model.setGeometry(cell, newGeo);
    }
  };

  public static resizeCell(graph: mxgraph.mxGraph, cell: mxgraph.mxCell, dx: number, dy: number) {
    const geo = graph.getCellGeometry(cell);
    const newGeo: mxgraph.mxGeometry = geo.clone();
    newGeo.width = geo.width + dx;
    newGeo.height = geo.height + dy;
    graph.model.setGeometry(cell, newGeo);
  }

  public static resizeDiagram(currentUi: any, graph: mxgraph.mxGraph, dx: number, dy: number) {

    const pageFormat = new mxglobals.mxRectangle(0, 0, graph.pageFormat.width + dx, graph.pageFormat.height + dy);
    const backgroundImage = graph.backgroundImage;
    if (backgroundImage) {
      backgroundImage.width = graph.pageFormat.width + dx;
      backgroundImage.height = graph.pageFormat.height + dy;
    }
    const backgroundColor = graph['background'];
    const change = new mxglobals.ChangePageSetup(currentUi, backgroundColor, backgroundImage, pageFormat);

    graph.model.execute(change);
  }

  public static addSizingRect(graph: mxgraph.mxGraph, text: string) {
    const sizer = new mxglobals.mxCell(text, new mxglobals.mxGeometry(0, 0, graph.pageFormat.width, graph.pageFormat.height), 'PAGESIZER');
    sizer.vertex = true;
    graph.addCell(sizer);
    graph.setSelectionCell(sizer);
  }

  public static removeSizingRect(graph: mxgraph.mxGraph) {
    const sizer = mx.findCells(graph, cell => mx.getCellKind(cell) === CellKind.PAGESIZER);
    if (sizer) {
      graph.removeCells(sizer, false);
    }
  }

  public static alignLopsLeft(graph: mxgraph.mxGraph, edges: mxgraph.mxCell[]) {
    let left = edges.map(e => graph.getCellGeometry(e)).reduce((r, g) => Math.min(r, g.sourcePoint.x), Number.MAX_VALUE);
    for (const edge of edges) {
      const geo = graph.getCellGeometry(edge);
      const newGeo: mxgraph.mxGeometry = geo.clone();
      newGeo.sourcePoint.x = left;
      graph.getModel().setGeometry(edge, newGeo);
    }
  }

  public static alignLopsRight(graph: mxgraph.mxGraph, edges: mxgraph.mxCell[]) {
    let right = edges.map(e => graph.getCellGeometry(e)).reduce((r, g) => Math.max(r, g.points[0].x), Number.MIN_VALUE);
    for (const edge of edges) {
      const geo = graph.getCellGeometry(edge);
      const newGeo: mxgraph.mxGeometry = geo.clone();
      newGeo.points[0].x = right;
      graph.getModel().setGeometry(edge, newGeo);
    }
  }

  public static alignCells(graph: mxgraph.mxGraph, align: string, cells: mxgraph.mxCell[]) {
    const edges = cells.filter(cell => graph.getModel().isEdge(cell));
    if (edges.length > 0) {
      switch (align) {
        case 'left':
          this.alignLopsLeft(graph, edges);
          break;
        case 'right':
          this.alignLopsRight(graph, edges);
          break;
      }
    } else {
      graph.alignCells(align, cells);
    }
  }

}

// overwrite LOP specific behavior for the bend
const oldGetPointForEvent = mxglobals.mxEdgeHandler.prototype.getPointForEvent;
mxglobals.mxEdgeHandler.prototype.getPointForEvent = function (me) {
  const result = oldGetPointForEvent.call(this, me);

  const cellKind = mx.getCellKind(this.state.cell);
  if (cellKind === CellKind.LOP) {

    if (this.index == 0)
      result.y = this.abspoints[1].y;
    if (this.index == 1)
      result.y = this.abspoints[0].y;
  }

  return result;
};

// in case we can't get image by dirct URL, we can use token (Bearer token) request
// in this case, we cache the result in localStorage for 5 minutes
// and convert the image to a blobl using createObjectURL

const immediateImageResolver = (node: SVGImageElement, src: string) => {
  const url = ImageCacheService.getCachedImageUrl(src) ?? src;

  // avoid recursive onError
  if (node.getAttribute('xlink:href') !== url) {
    node.setAttributeNS(mxglobals.mxConstants.NS_XLINK, 'xlink:href', url);
  }
};

const deferredImageResolver = (node: SVGImageElement, src: string) => {
  if (src.startsWith('https:')) {
    const opacity = node.getAttribute('opacity');
    node.setAttribute('opacity', '0');
    ImageCacheService.getImageAsync(src, url => StorageService.get(url).getFile(url)).then(resolved => {
      if (!resolved) {
        trackClient.warn(`Unable to get image: ${src}`);
      }
      node.setAttribute('opacity', opacity);
      // avoid recursive onError
      if (resolved && node.getAttribute('xlink:href') !== resolved) {
        node.setAttributeNS(mxglobals.mxConstants.NS_XLINK, 'xlink:href', resolved);
      }
    });
  }
};

mxglobals.mxImage.prototype['immediateImageResolver'] = immediateImageResolver;
mxglobals.mxImage.prototype['deferredImageResolver'] = deferredImageResolver;

const readBlob = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (ev) => {
      const dataUrl = ev.target.result as string;
      resolve(dataUrl);
    });
    reader.addEventListener('error', (ev) => {
      reject(ev);
    })
    reader.readAsDataURL(blob);
  })
}

export const getImageDataUrl = async (src: string): Promise<string> => {
  try {
    if (src.startsWith('data:')) {
      return src;
    }

    const blob = (src.toLowerCase().indexOf('.sharepoint.com') >= 0)
      ? await StorageService.get(src).getFile(src)
      : await ApiService.fetchImage(src, await ApiService.getIdToken());

    const url = await readBlob(blob);
    return url;

  } catch (err) {
    trackClient.error(`Unable convert image to data url: ${src}`, err);
    return src;
  }
};

export const loadGraphBackground = (graph, node) => {
  var bg = node.getAttribute('background');

  if (bg != null && bg.length > 0) {
    graph.background = bg;
  }
  else {
    graph.background = null;
  }

  var bgImage = node.getAttribute('backgroundImage');
  if (bgImage) {
    var bgImageWidth = parseFloat(node.getAttribute('backgroundImageWidth'));
    var bgImageHeight = parseFloat(node.getAttribute('backgroundImageHeight'));
    graph.setBackgroundImage(new mxglobals.mxImage(bgImage, bgImageWidth, bgImageHeight));
    graph.backgroundImageAspect = node.getAttribute('backgroundImageAspect') != '0';
    graph.backgroundImageSlice = node.getAttribute('backgroundImageSlice') != '0';
    graph.backgroundImageOpacity = parseFloat(node.getAttribute('backgroundImageOpacity')) || 100;
  }

  var pw = parseFloat(node.getAttribute('pageWidth'));
  var ph = parseFloat(node.getAttribute('pageHeight'));

  if (!isNaN(pw) && !isNaN(ph)) {
    graph.pageFormat = new mxglobals.mxRectangle(0, 0, pw, ph);
  }

};

export const getGraphSvg = (graph, params: { usePage: boolean, embedImages: boolean, width?: number, height?: number, includeOverlays?: boolean }) => {
  const collected = [];

  // if (graph.backgroundImage) {
  //   collected.push(new Promise(resolve => {
  //     getImageDataUrl(graph.backgroundImage.src).then(dataUrl => {
  //       graph.backgroundImage.src = dataUrl;
  //       resolve(true);
  //     }, err => {
  //       trackClient.error('unable to convert background to data url', err);
  //       resolve(false);
  //     });
  //   }));
  // }

  const exportResolver = (node: SVGImageElement, src: string) => {
    collected.push(new Promise(resolve => {
      getImageDataUrl(src).then(dataUrl => {
        node.setAttributeNS(mxglobals.mxConstants.NS_XLINK, 'xlink:href', dataUrl);
        resolve(true);
      }, err => {
        trackClient.error('unable to convert picture to data url', err);
        resolve(false);
      });
    }));
  };

  if (params.embedImages) {
    mxglobals.mxImage.prototype['immediateImageResolver'] = exportResolver;
  }
  const imgExport = new mxglobals.mxImageExport();
  imgExport.includeOverlays = params.includeOverlays;
  const root = graph.getSvg(graph.background, 1, 0, params.usePage, true, true, true, imgExport);
  return new Promise<string>((resolve) => {
    Promise.all(collected).then(() => {
      if (params.embedImages) {
        mxglobals.mxImage.prototype['immediateImageResolver'] = immediateImageResolver;
      }
      if (params.width) {
        root.setAttribute('width', params.width);
      }
      if (params.height) {
        root.setAttribute('height', params.height);
      }
      const svg = mxglobals.mxUtils.getXml(root);
      resolve(svg);
    });
  });
};
