import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogFooter, DialogType, Image, ImageCoverStyle, ImageFit, PrimaryButton, Stack, Toggle } from '@fluentui/react';
import * as React from 'react';

import { IImageInfo } from './IImageInfo';
import { TextService } from 'services/TextService';
import { trackClient } from 'services/trackClient';
import { ImagePicker } from './ImagePicker';
import { ImageResizeTypeDropdown } from './ImageResizeTypeDropdown';
import { ImageOpacitySlider } from './ImageOpacitySlider';
import { ImageCacheService } from 'services/ImageCacheService';
import { FixedViewType } from '../common';

export function ImageDialog(props: {
  drop: IImageInfo;
  onDismiss: (changed: boolean, imageInfo: IImageInfo) => void;
}) {

  React.useEffect(() => trackClient.page('ImageDialog'), []);

  const [imageInfo, setImageInfo] = React.useState(props.drop);

  const [isPickerOpen, setIsPickerOpen] = React.useState(!props.drop.src);

  const imageFit =
    (imageInfo.resizeType === FixedViewType.None)
      ? undefined
      : (imageInfo.resizeType === FixedViewType.FitPage)
        ? ImageFit.centerContain
        : (imageInfo.resizeType === FixedViewType.FillPage)
          ? ImageFit.centerCover
          : ImageFit.none;

  const imageCoverStyle =
    (imageInfo.width > imageInfo.height)
      ? ImageCoverStyle.landscape : ImageCoverStyle.portrait;


  const getPreview = async () => {
    const url = ImageCacheService.getCachedImageUrl(imageInfo.src) ?? imageInfo.src;
    return (
      <Image
        src={url}
        height={previewHeight}
        width={previewWidth}
        imageFit={imageFit}
        coverStyle={imageCoverStyle}
        style={{
          opacity: (imageInfo.opacity ?? 100) / 100,
        }}
      />
    );
  };

  const previewWidth = 300;
  const previewHeight = previewWidth * (props.drop.height ?? 200) / (props.drop.width ?? 300);

  return (
    <Dialog hidden={false} minWidth={350} dialogContentProps={{ title: TextService.format(strings.ImageDialog_EditImage), type: DialogType.normal, }} onDismiss={() => props.onDismiss(false, props.drop)} >
      <Stack tokens={{ childrenGap: 's1 0' }}>
        <ImagePicker
          imageSrc={imageInfo}
          setImageSrc={val => setImageInfo({ ...imageInfo, ...val })}
          opacity={imageInfo.opacity}
          backgroundColor={imageInfo.backgroundColor}
          resizeType={imageInfo.resizeType}
          isPickerOpen={isPickerOpen}
          setIsPickerOpen={setIsPickerOpen}
          getPreview={getPreview}
          previewWidth={previewWidth}
          previewHeight={previewHeight}
        />
        <ImageOpacitySlider
          disabled={!imageInfo.src}
          opacity={imageInfo.opacity}
          setOpacity={opacity => setImageInfo({ ...imageInfo, opacity })}
        />
        <ImageResizeTypeDropdown
          disabled={!imageInfo.src}
          resizeType={imageInfo.resizeType}
          setResizeType={resizeType => setImageInfo({ ...imageInfo, resizeType })}
        />
        <Toggle
          label={TextService.format(strings.ImageDialog_FixedAspectRatio)}
          checked={imageInfo?.fixedAspect}
          onChange={(_, val) => setImageInfo({ ...imageInfo, fixedAspect: val })}
          onText={TextService.format(strings.Toggle_On)}
          offText={TextService.format(strings.Toggle_Off)}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton disabled={!imageInfo} onClick={() => props.onDismiss(true, imageInfo)} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={() => props.onDismiss(false, props.drop)} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
}
