import { trackClient } from 'services/trackClient';
import { getObjectValues } from 'shared/parse';

export interface ITreeVertex {
  key: string;
  children: { childRef: string; fixed?: boolean; } [];
}

export class TreeService {

  public static createItemSet = (cardInfos: ITreeVertex[]) => {
    return cardInfos.reduce((set, item) => ({ ...set, [item.key]: item }), {});
  }
  
  public static getChildSet(tree: { [key: string]: ITreeVertex }, key: string): { [key: string]: { key: string, fixed: boolean } } {
    const result: { [key: string]: { key: string, fixed: boolean } } = {};
    if (tree[key]) {
      for (const child of tree[key].children) {
        result[child.childRef] = { key: child.childRef, fixed: child.fixed };
      }
    }
    return result;
  }

  public static getParentSet(tree: { [key: string]: ITreeVertex }, key: string): { [key: string]: { key: string, fixed: boolean } } {
    const result: { [key: string]: { key: string, fixed: boolean } } = {};
    for (const parentKey in tree) {
      const parent = tree[parentKey];
      const found = parent.children.find(c => c.childRef === key);
      if (found) {
        result[parentKey] = { key: parentKey, fixed: found.fixed };
      }
    }
    return result;
  }

  static markConnected(tree: { [key: string]: ITreeVertex }, key: string, marks: Set<string>, direction: 'up' | 'down' | 'both') {

    if (marks.has(key))
      return;

    marks.add(key);

    if (direction === 'down' || direction === 'both') {
      const childSet = this.getChildSet(tree, key);
      for (const childKey in childSet) {
        this.markConnected(tree, childKey, marks, direction);
      }
    }

    if (direction === 'up' || direction === 'both') {
      const parentSet = this.getParentSet(tree, key);
      for (const parentKey in parentSet) {
        this.markConnected(tree, parentKey, marks, direction);
      }
    }
  }

  static getRoots<T extends ITreeVertex>(tree: { [key: string]: T; }) {
    const rootKeySet = new Set<string>();
    const marks = new Set<string>();

    for (const key in tree) {

      if (marks.has(key)) {
        continue;
      }

      const subtreeKeySet = new Set<string>();
      this.markConnected(tree, key, subtreeKeySet, 'both');

      const subtreeRootsKeySet = new Set<string>();
      for (const mark of subtreeKeySet) {
        marks.add(mark);
        const parentKeys = this.getParentSet(tree, mark);
        if (!Object.keys(parentKeys).length) {
          subtreeRootsKeySet.add(mark);
          rootKeySet.add(mark);
        }
      }
      if (subtreeRootsKeySet.size === 0) {
        rootKeySet.add(key);
      }
    }
    return [...rootKeySet.values()].map(r => tree[r]);
  }

  static filterCards<T extends ITreeVertex>(allCardInfos: T[], filter: (v: T) => boolean) {
    const keyCards = allCardInfos.filter(ci => filter(ci));
    const tree = TreeService.createItemSet(allCardInfos);
    const keySet = new Set<string>();
    for (const keyCard of keyCards) {
      const keySetUp = new Set<string>();
      this.markConnected(tree, keyCard.key, keySetUp, 'up');
      for (const key of keySetUp) {
        keySet.add(key);
      }

      const keySetDown = new Set<string>();
      this.markConnected(tree, keyCard.key, keySetDown, 'down');
      for (const key of keySetDown) {
        keySet.add(key);
      }
    }
    return allCardInfos.filter(ci => keySet.has(ci.key));
  }

  static splitBranchKeys (tree: { [key: string]: ITreeVertex }, key: string) {

    const childSet = TreeService.getChildSet(tree, key);
    const children = getObjectValues(childSet);

    const parentSet = TreeService.getParentSet(tree, key);
    const parents = getObjectValues(parentSet);

    let siblingSet: { [key: string]: { key: string, fixed: boolean } } = {};
    for (const parentKey in parentSet) {
      siblingSet = { ...siblingSet, ...TreeService.getChildSet(tree, parentKey) };
    }
    delete siblingSet[key];

    const siblings = getObjectValues(siblingSet);
    return { parents, siblings, children };
  }
}
