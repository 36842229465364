import { ComboBox, IComboBoxOption, Text, Stack, useTheme, Checkbox } from '@fluentui/react'
import React from 'react'
import { DevOpsService } from '../services/DevOpsService'
import { IDevOpsItemType } from '../services/IDevOpsItemType';
import { DevOpsIcon } from './DevOpsIcon';
import { TextService } from 'services/TextService';

const DevOpsItemTypeComboboxIcon = (props: {
  devopsUrl: string;
  itemType: IDevOpsItemType;
}) => {
  const [iconUrl, setIconUrl] = React.useState<string>(null);
  React.useEffect(() => {
    DevOpsService.getWorkItemIconUrl(props.devopsUrl, props.itemType.icon, props.itemType.color).then(url => setIconUrl(url));
  }, [props.devopsUrl, props.itemType.icon, props.itemType.color]);
  return iconUrl ? <DevOpsIcon size={16} src={iconUrl} /> : null;
}

export const DevOpsItemTypeCombobox = (props: {
  devopsUrl: string;
  label: string;
  itemTypes: IDevOpsItemType[];
  itemTypeNames: string[];
  setItemTypeNames: (itemTypeNames: string[]) => void;
}) => {

  const itemTypes = props.itemTypes || [];

  const onItemTypeChange = (option: IComboBoxOption) => {
    props.setItemTypeNames(option?.selected ? [...props.itemTypeNames, option!.key as string] : props.itemTypeNames.filter(k => k !== option!.key));
  };

  const uniqueItemTypes = Array.from(new Map(itemTypes.filter(x => x.projects?.length > 0).map(item => [item.name, item])).values());

  const getProjects = (name: string) => {
    const projects = itemTypes.filter(item => item.name === name).flatMap(item => item.projects);
    const uniqueProjects = Array.from(new Set(projects));
    return uniqueProjects.sort((a, b) => TextService.compareNames(a, b)).join(', ');
  }

  const theme = useTheme();

  return (
    <ComboBox
      multiSelect
      label={props.label}
      options={uniqueItemTypes.map(itemType => ({
        key: itemType.name,
        text: itemType.name,
        data: itemType
      }))}
      selectedKey={props.itemTypeNames}
      onChange={(_, val) => onItemTypeChange(val)}
      calloutProps={{ calloutMaxHeight: 200 }}
      onRenderItem={p => {
        return (
          <Stack horizontal verticalAlign='center' tokens={{ padding: 's2', childrenGap: 's2' }}>
            <DevOpsItemTypeComboboxIcon devopsUrl={props.devopsUrl} itemType={p.data} />
            <Checkbox checked={props.itemTypeNames.includes(p.key as string)} onChange={(_, checked) => onItemTypeChange({ key: p.key, text: p.text, selected: checked })} />
            <Text style={{ color: theme.palette.neutralPrimary }}>{p.text}</Text>
            <Text variant='small' style={{ color: theme.palette.neutralTertiary }}>{getProjects(p.key as string)}</Text>
          </Stack>
        );
      }
      }
    />
  )
}
