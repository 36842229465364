import React from 'react';
import { useErrorInfo } from 'components';
import { AuthService, IAuthScopeProps } from 'services/AuthService';
import { IEnvContext, EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { setIsLoading } from 'services/LoadingIndicator';
import strings from 'VistoWebPartStrings';
import { isConsentError, isInteractionRequiredError, stringifyError } from 'shared/parse';
import { trackClient } from 'services/trackClient';
import { ApiService } from 'services/api/ApiService';
import { WebAuthService } from 'teams/services/WebAuthService';

import { StorageService } from 'services/StorageService';
import { SharepointService } from 'services/SharepointService';
import { LocalStorageService } from 'services/LocalStorageService';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { UrlService } from 'shared/urlService';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { ProjectDataService } from 'integrations/project';
import { DevOpsDataService } from 'integrations/devops';
import { PlannerConfigurationService } from 'integrations/planner';
import { PlaceholderConsent } from 'components/PlaceholderConsent';
import { PlaceholderAuthError } from 'components/PlaceholderAuthError';
import { TokenKind } from 'shared/TokenKind';
import { Guid } from 'context';

export function WebAuthScope(props: IAuthScopeProps) {

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [consentError, setConsentError] = useErrorInfo();
  const [authError, setAuthError] = useErrorInfo();

  const [envContext, setEnvContext] = React.useState<IEnvContext>(null);

  const authenticate = async () => {

    setIsLoading(TextService.format(strings.LoadingIndicator_Authenticating));
    setIsProcessing(true);

    trackClient.origin = document.location.origin;
    trackClient.initialize(props.name);

    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();

    ApiService.configure(() => WebAuthService.getMsalToken());
    StorageService.configure({
      sharepointService: new SharepointService(),
      indexeddbService: new LocalStorageService()
    });

    AuthService.TokenProvider = (kind, host) => AuthService.getServerSideToken(() => WebAuthService.getMsalToken(), kind, host);
    AuthService.AuthScope = WebAuthScope;
    AuthService.getConsent = WebAuthService.getConsent;

    const webPartContext = SharepointService.makeWebPartContext(props.siteUrl, TextService.format(strings.Context_SharePointSite), 'en-us');

    const msalToken = await WebAuthService.getMsalToken();
    const payload = msalToken && jwtDecode<JwtPayload>(msalToken);

    const tid = payload?.['tid'];
    const userObjectId = payload?.['oid'];
    const userPrincipalName = payload ? payload['preferred_username'] : TextService.format(strings.Context_Local);
    const userDisplayName = payload ? payload['name'] : TextService.format(strings.Context_Local);

    const ctx: IEnvContext = {
      tid,
      userPrincipalName,
      userDisplayName,
      userObjectId,

      groupId: null,

      isTeams: false,
      isPortal: true,
      isMobile: (window.innerWidth < 450 || window.innerHeight < 450),

      siteUrl: props.siteUrl,
      planId: props.planId,
      subEntityId: null,

      teamId: '',
      teamName: TextService.format(strings.WebAuthScope_DefaultTeam),
      channelId: '',
      channelName: TextService.format(strings.WebAuthScope_DefaultChannel),

      defaultFolderName: null,
      defaultFolderRelativeUrl: null,
      webPartContext,
    };

    AuthService.configure({
      tid,
      userObjectId,
      defaultBaseUrl: props.siteUrl
    });

    if (props.siteUrl && !UrlService.isLocalUrl(props.siteUrl) && props.kind === TokenKind.sharepoint) {

      if (!userObjectId) {
        throw { error: 'interaction_required', message: `The user must be logged in to access ${props.siteUrl}` };
      }

      if (!tid) {
        throw { error: 'interaction_required', message: `The user must be logged in with organizational account to access ${props.siteUrl}` };
      }

      ctx.webPartContext.pageContext.web.id = new Guid(await SharepointService.getWebId(props.siteUrl));
      ctx.webPartContext.pageContext.site.id = new Guid(await SharepointService.getSiteId(props.siteUrl));

      const webProperties = await SharepointService.getWebProperties(props.siteUrl);
      ctx.groupId = webProperties?.GroupId;
      ctx.defaultFolderName = webProperties?.GroupDocumentsUrl;
      const serverRelativeUrl = UrlService.getPathName(props.siteUrl);
      ctx.defaultFolderRelativeUrl = `${serverRelativeUrl}/${webProperties?.GroupDocumentsUrl}`;
      PlannerConfigurationService.configure(tid, webProperties.GroupId);
    }

    await props.onLoad(ctx);
    setIsLoading('');
    setIsProcessing(false);

    setEnvContext(ctx);
  };

  const consent = async () => {
    try {
      await AuthService.getConsent(props.kind, props.kind === TokenKind.sharepoint ? UrlService.getDomain(props.siteUrl) : '', authenticate);
      setConsentError(null);
    } catch (error) {
      setIsLoading('');
      setIsProcessing(false);
      setConsentError({
        type: NotificationType.warn,
        message: TextService.format(strings.AuthService_ErrorGetConsent, { reason: stringifyError(error) })
      });
    }
  };

  const init = async () => {

    try {
      await authenticate();
    } catch (error) {
      if (isConsentError(error)) {
        AuthService.resetAuth(props.kind);
        setConsentError(error);
      } else {
        setAuthError({
          type: isInteractionRequiredError(error) ? NotificationType.warn : NotificationType.error,
          message: isInteractionRequiredError(error)
            ? TextService.format(strings.AuthError_InteractionRequired)
            : TextService.format(strings.AuthError_Connect),
          error
        });
      }
      setIsLoading('');
      setIsProcessing(false);
    }
  };

  const refresh = async () => {
    AuthService.resetAuth(props.kind);
    if (isInteractionRequiredError(authError.error)) {
      await WebAuthService.loginPopup();
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return consentError
    ? <PlaceholderConsent consentError={consentError} disabled={isProcessing} onConfigure={consent} />
    : authError
      ? <PlaceholderAuthError authError={authError} disabled={isProcessing} onConfigure={refresh} />
      : envContext
        ? <EnvContext.Provider value={envContext}>{props.children}</EnvContext.Provider>
        : null
}
