import React from 'react';
import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp';
import { EditKeyResultList } from './EditKeyResultList';
import { EditKeyResultExcel } from './EditKeyResultExcel';
import { Stack, Toggle } from '@fluentui/react';
import { parseJSON } from 'shared/parse';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { LicenseService } from 'services/LicenseService';

export const EditKeyResultBlock = (props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  setKr: (kr: VistoKeyResultItem) => void;
  values: VistoKeyResultValueItem[];
  valueKind: KeyResultValueKind;
  onSetValues: (values: VistoKeyResultValueItem[]) => void;
  rightToolbar?: JSX.Element;
  isRowBold: (item: any) => boolean;
}) => {

  const excelOptions: IkeyResultExcelOptions = parseJSON(props.valueKind === KeyResultValueKind.Actual ? props.kr.excelActuals : props.kr.excelTargets, {});
  const setExcelOptions = (val: IkeyResultExcelOptions) => {
    props.setKr({ ...props.kr, [props.valueKind === KeyResultValueKind.Actual ? 'excelActuals' : 'excelTargets']: JSON.stringify(val) });
  }

  const useExcel = LicenseService.license.excelEnabled && excelOptions.useExcel;

  return (
    <Stack style={{ minHeight: 200 }}>
      <Stack horizontal horizontalAlign='space-between'>
        <Toggle
          label={TextService.format(strings.EditKeyResultBlock_UseExcelLabel)}
          checked={useExcel}
          disabled={!LicenseService.license?.excelEnabled || !props.kr.name}
          title={LicenseService.license?.excelEnabled ? undefined : TextService.format(strings.Message_FeatureDisabled, { feature: 'Excel' })}
          inlineLabel
          onText={TextService.format(strings.EditKeyResultBlock_UseExcelYes)}
          offText={TextService.format(strings.EditKeyResultBlock_UseExcelNo)}
          onChange={(_, checked) => setExcelOptions({ ...excelOptions, useExcel: checked })}
        />
        {props.rightToolbar}
      </Stack>

      {useExcel
        ? <EditKeyResultExcel {...props} excelOptions={excelOptions} setExcelOptions={setExcelOptions} />
        : <EditKeyResultList {...props} />
      }
    </Stack>
  );
};
