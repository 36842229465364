import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { PrimaryButton, DefaultButton, Dialog, DialogFooter, IDialogContentProps, DialogType, Stack, useTheme } from '@fluentui/react';

import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { IVistoPlan } from 'sp';
import { trackClient } from 'services/trackClient';
import { defaultModalProps } from 'dialogs/common';
import { ChildPlanWizardProgressPage } from './ChildPlanWizardProgressPage';
import { stringifyError } from 'shared/parse';
import { InfoBar, useErrorInfo } from 'components';
import { ChildPlanWizardSelectPage } from './ChildPlanWizardSelectPage';
import { IntegrationService } from 'services/IntegrationService';
import { IExecutableAction } from 'services/IExecutableAction';
import { ChildConfigurationService } from './ChildPlanService';
import { EnvContext } from 'services/EnvContext';
import { CascadingTreeService } from 'dialogs/cascading/CascadingTreeService';
import { DashboardDataService } from 'services/DashboardDataService';

enum PlanWizardPage {
  selection,
  progress,
}

export function ChildPlanWizard(props: {
  plan: IVistoPlan;
  onDismiss: (changed: boolean, plan?: IVistoPlan) => void;
}) {

  const [visPlan, setVisPlan] = React.useState<IVistoPlan>(props.plan);
  const [topPlanEnabled, setTopPlanEnabled] = React.useState(true);

  const envContext = React.useContext(EnvContext);
  const { notify } = React.useContext(AppContext);

  const [actions, setActions] = React.useState<IExecutableAction[]>();
  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [statusText, setStatusText] = React.useState('');

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === actions?.length;

  const defaultSelectedKeys = ChildConfigurationService.getDefaultSelectedKeys(visPlan);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(defaultSelectedKeys);

  const pageInfo = {

    [PlanWizardPage.selection]: {
      subText: TextService.format(strings.ChildPlanWizard_SelectItemsSubTitle),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => selectedKeys.length === 0,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [PlanWizardPage.progress]: {
      subText: TextService.format(strings.ChildPlanWizard_ProgressSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => isProcessing,
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<PlanWizardPage>(PlanWizardPage.selection);

  const theme = useTheme();

  const analyzeChanges = async () => {
    try {
      setCurrentProgressActionIndex(undefined);
      setActions(null);
      setIsProcessing(true);
  
        setStatusText(TextService.format(strings.PlanWizard_AnalyzingHierarchy));

        const cards = await CascadingTreeService.getCards(envContext);
        const siteGroups = DashboardDataService.groupCardsBySite(cards, 'visplan:');
        const siteCardSet = await CascadingTreeService.getSiteCardSet(siteGroups, props.plan.siteUrl);
        const topPlanId = CascadingTreeService.getTopPlanId(siteCardSet, TextService.getPlanDashboardKey(props.plan.siteUrl, props.plan.planId));

        setStatusText(TextService.format(strings.PlanWizard_AnalyzingSite));

        const result = await ChildConfigurationService.getChildChanges(visPlan, new Set(defaultSelectedKeys), new Set(selectedKeys), topPlanId, notify, envContext, theme, msg => {
          setStatusText(msg);
        });

        setIsProcessing(false);
        setActions(result);
        if (result.length) {
          setStatusText(TextService.format(strings.PlanWizard_ClickToProceed));
        } else {
          setStatusText(TextService.format(strings.PlanWizard_UpToDate));
        }
      }
      catch (err) {
      trackClient.error(`error generating actions for plan`, err);
      setStatusText(`Something went wrong. ${stringifyError(err)}`);
      setIsProcessing(false);
    }
  };

  const executeActions = async () => {
    setIsProcessing(true);
    setCurrentProgressActionIndex(undefined);

    const executeAction = async (p: IVistoPlan, i: number) => {
      const action = actions[i];
      setStatusText(action.title);
      setCurrentProgressActionIndex(i);
      try {
        return await action.execute(p);
      } catch (err) {
        action.hasError = true;
        action.errorMessage = stringifyError(err);
        return p;
      }
    };

    let updatedVisPlan = visPlan;
    for (let i = 0; i < actions.length; ++i) {
      updatedVisPlan = await executeAction(updatedVisPlan, i);
    }

    await IntegrationService.updatePlanChildRefs(updatedVisPlan);

    setIsProcessing(false);
    setVisPlan(updatedVisPlan);
    setCurrentProgressActionIndex(actions.length);
  };

  const onWizardBack = () => {
    switch (currentPageId) {

      case PlanWizardPage.progress:
        setCurrentPageId(PlanWizardPage.selection);
        break;
    }
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case PlanWizardPage.selection:
        setCurrentPageId(PlanWizardPage.progress);
        analyzeChanges();
        break;

      case PlanWizardPage.progress:
        if (executed) {
          props.onDismiss(true, visPlan);
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.ChildPlanWizard_Title),
    subText: pageInfo[currentPageId].subText
  };

  return (
    <Dialog
      minWidth={500}
      isBlocking={true}
      dialogContentProps={contentProps} modalProps={defaultModalProps} isOpen={true} onDismiss={() => props.onDismiss(false)}>
      <Stack style={{ minHeight: 200 }}>
        <InfoBar {...errorInfo} />
        {(currentPageId == PlanWizardPage.selection)
          ? <ChildPlanWizardSelectPage 
              plan={props.plan} 
              selectedKeys={selectedKeys} 
              setSelectedKeys={setSelectedKeys}
              topPlanEnabled={topPlanEnabled}
              setTopPlanEnabled={setTopPlanEnabled}
            />
          : (currentPageId == PlanWizardPage.progress)
            ? <ChildPlanWizardProgressPage statusText={statusText} actions={actions ?? []} currentProgressActionIndex={currentProgressActionIndex} />
            : null
        }
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={() => props.onDismiss(false)} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
}
