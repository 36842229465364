import React from 'react'
import { TextService } from 'services/TextService'
import strings from 'VistoWebPartStrings'
import { BasicDialog } from './BasicDialog'

export const ConfirmDiscardDialog = (props: {
  onCommit: () => Promise<void>;
  onDismiss: () => void;
  pendingChangesCount: number;
}) => {
  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={props.onCommit}
      buttonOkText={TextService.format(strings.ButtonSave)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonDiscard)}
      title={TextService.format(strings.DialogDiscard_Title)}
      zIndex={10030}
    >
      <div>{TextService.format(strings.DialogDiscard_Message, { pendingChangesCount: props.pendingChangesCount })}</div>
    </BasicDialog>
  );
}
