import { TokenKind } from 'shared/TokenKind';
import { Operation } from 'shared/Operation';
import { IVistoListItem, IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind } from 'sp';
import { IOperationOptions } from './IOperationOptions';
import { clearInfoBar, IBasicNotify, INotify, NotificationType, notifyInfoBar } from './Notify';

import { ExcelWorkbookSession } from './ExcelWorkbookSession';
import { TextService } from './TextService';
import { makeGuid } from 'shared/guid';
import { PlanDataService } from './PlanDataService';
import { StorageService } from './StorageService';
import { CommandsKeyResult } from './CommandsKeyResult';
import { isConsentError, parseJSON, stringifyError } from 'shared/parse';
import { UrlService } from 'shared/urlService';
import { SharepointService } from './SharepointService';
import { trackClient } from 'services/trackClient';
import { ExcelWorkbookService } from './ExcelWorkbookService';
import { AuthService } from './AuthService';
import strings from 'VistoWebPartStrings';
import { StorageCacheService } from './StorageCacheService';

export class ExcelService {

  private static async updateKeyResultValues(
    plan: IVistoPlan,
    excelOptions: IkeyResultExcelOptions,
    kr: VistoKeyResultItem,
    valueKind: KeyResultValueKind,
    notify: INotify,
    options: IOperationOptions) {

    if (excelOptions.useExcel) {
      const oldValues = PlanDataService.getItemsHaving(plan.items, (x: VistoKeyResultValueItem) => x.kind === VistoKind.KRV && x.krGuid === kr.guid && x.valueKind === valueKind);
      const newValues = await this.getKeyResultData(plan, kr, excelOptions, valueKind, notify);

      const changes = CommandsKeyResult.getKeyResultListChanges(oldValues, newValues);
      if (changes.updated.length > 0) {
        plan = await StorageService.get(plan.siteUrl).updateItems(plan, changes.updated, notify, options);
      }
      if (changes.added.length > 0) {
        plan = await StorageService.get(plan.siteUrl).createItems(plan, changes.added, notify, options);
      }
      if (changes.deleted.length > 0) {
        plan = await StorageService.get(plan.siteUrl).deleteItems(plan, changes.deleted, notify, options);
      }
    }
    return plan;
  }

  public static async synchronize(plan: IVistoPlan, items: IVistoListItem[], notify: INotify, operation: Operation, options: IOperationOptions) {

    if (operation === Operation.load) {

      const excelOptions = { 
        enableSimpleUpdate: true, 
        excludeExternals: true, 
        excludePercentComplete: true,
        ...options
      };

      const krs = PlanDataService.getItems<VistoKeyResultItem>(plan.items, VistoKind.KeyResult);
      for (const kr of krs) {
        const actualOptions: IkeyResultExcelOptions = parseJSON(kr.excelActuals, {});
        if (actualOptions.useExcel) {
          plan = await this.updateKeyResultValues(plan, actualOptions, kr, KeyResultValueKind.Actual, notify, excelOptions);
        }
        const targetOptions: IkeyResultExcelOptions = parseJSON(kr.excelTargets, {});
        if (targetOptions.useExcel) {
          plan = await this.updateKeyResultValues(plan, targetOptions, kr, KeyResultValueKind.Target, notify, excelOptions);
        }
      }
    }

    return plan;
  }

  public static async getDefaultExcelOptions(plan: IVistoPlan, kr: VistoKeyResultItem, defaultFolderRelativeUrl: string, valueKind: KeyResultValueKind): Promise<IkeyResultExcelOptions> {

    let excelWorkbook = UrlService.combine(UrlService.getOrigin(plan.siteUrl), defaultFolderRelativeUrl);

    const rootUrl = await ExcelWorkbookService.getRootUrl(plan.siteUrl);

    if (rootUrl && excelWorkbook.toLowerCase().startsWith(rootUrl.toLowerCase())) {
      excelWorkbook = excelWorkbook.substring(rootUrl.length + 1);
    } else {
      excelWorkbook = '';
    }

    const kpiFolderName = `${TextService.makeFileName(plan.name)} OKRs`;
    excelWorkbook = UrlService.combine(excelWorkbook, kpiFolderName);

    const workbookFileName = `${TextService.makeFileName(kr.name)}.xlsx`;
    excelWorkbook = UrlService.combine(excelWorkbook, workbookFileName);

    const valueKindName = KeyResultValueKind[valueKind];
    const excelTable = kr.name ? `${kr.name.replace(/[^A-Za-z0-9]/g, '_').replace(/(^\d)/, '_$1')}_${valueKindName}` : valueKindName;

    return {
      useExcel: true,
      excelWorkbook,
      excelTable,
      excelDateColumn: 'Date',
      excelValueColumn: 'Value',
      excelCommentColumn: 'Comment'
    };
  }

  public static async getWorkbookInfo(siteUrl: string, excelOptions: IkeyResultExcelOptions) {

    try {
      if (excelOptions.excelWorkbook && excelOptions.excelTable) {
        StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/drives');
        const drive = await ExcelWorkbookService.getDrive(siteUrl);
        const file = await ExcelWorkbookService.getFile(drive, excelOptions);
        const tables = await ExcelWorkbookService.getWorkbookTables(drive, file.id);
        return {
          tables,
          file
        }
      }
    } catch (error) {
      if (error.status !== 404) {
        throw error;
      }
    }
  }

  public static changesDetected = (oldValues: VistoKeyResultValueItem[], newValues: VistoKeyResultValueItem[]) => {
    const changes = CommandsKeyResult.getKeyResultListChanges(oldValues, newValues);
    return changes.updated.length > 0 || changes.added.length > 0 || changes.deleted.length > 0;
  }

  public static async createKeyResultData(
    p: IVistoPlan,
    kr: VistoKeyResultItem,
    excelOptions: IkeyResultExcelOptions,
    krvs: VistoKeyResultValueItem[]
  ) {

    const wb = new ExcelWorkbookSession();

    try {
      try {
        await wb.open(p.siteUrl, excelOptions);
      } catch (error) {
        if (error.status !== 404) {
          trackClient.throw(
            TextService.format(strings.ExcelError_OpenWorkbook, {
              excelWorkbook: excelOptions.excelWorkbook,
              krTitle: TextService.formatTitle(kr, p.items),
              message: error.message
            }),
            error
          );
        } else {
          excelOptions.excelWorkbook = await wb.create(p.siteUrl, excelOptions.excelWorkbook);
        }
      }

      const tables = await wb.get(`/tables`);
      if (!tables.some((x: any) => x.name === excelOptions.excelTable)) {
        try {
          await wb.post(`/worksheets/add`, {
            name: excelOptions.excelTable
          });
        } catch (error) {
          trackClient.throw(
            TextService.format(strings.ExcelError_CreateWorksheet, {
              excelTable: excelOptions.excelTable,
              krTitle: TextService.formatTitle(kr, p.items),
              message: error.message
            }),
            error
          );
        }

        await wb.patch(`/worksheets/${excelOptions.excelTable}/range(address='A1:C1')`, {
          values: [[excelOptions.excelDateColumn, excelOptions.excelValueColumn, excelOptions.excelCommentColumn]],
        });

        const addedTable = await wb.post(`/worksheets/${excelOptions.excelTable}/tables/add`, {
          address: 'A1:C1',
          hasHeaders: true
        });

        await wb.patch(`/tables/${addedTable.name}`, {
          name: excelOptions.excelTable
        });

        // to set upt the cache
        await wb.get('/tables');
      }

      if (krvs.length > 0) {
        const rows = await wb.post(`/tables/${excelOptions.excelTable}/rows`, {
          values: krvs.map(krv => {
            const value = krv.value;
            const date = krv.valueDate && krv.valueDate.toISOString().split('T')[0];
            const comment = krv.description;
            return [
              date ?? null,
              value ?? null,
              comment ?? null
            ]
          })
        });
      }

      return excelOptions;
    } catch (error) {
      trackClient.throw(
        TextService.format(strings.ExcelError_CreateTable, {
          excelTable: excelOptions.excelTable,
          krTitle: TextService.formatTitle(kr, p.items),
          message: error.message
        }),
        error);
    } finally {
      await wb.close();
    }
  }

  private static parseValue = (value: any) => {
    const result = parseFloat(value);
    return isNaN(result) ? null : result;
  }

  private static parseValueDate = (date: any) => {
    if (typeof date === 'number') {
      return new Date(Date.UTC(0, 0, date - 1));
    } else {
      const result = date && new Date(date);
      return result && !isNaN(result.valueOf()) ? result : null;
    }
  }

  private static parseComment = (comment: any) => {
    return comment ?? null;
  }

  public static async getKeyResultData(
    p: IVistoPlan,
    kr: VistoKeyResultItem,
    excelOptions: IkeyResultExcelOptions,
    valueKind: KeyResultValueKind,
    notify: IBasicNotify
  ): Promise<VistoKeyResultValueItem[]> {

    const wb = new ExcelWorkbookSession();

    const krvs: VistoKeyResultValueItem[] = [];
    const action = async () => {
      krvs.splice(0, krvs.length);
      await wb.open(p.siteUrl, excelOptions);

      // get Excel file using graph API
      const cols = await wb.get(`/tables/${excelOptions.excelTable}/columns`);

      const colValue = cols.find((x: any) => x.name === excelOptions.excelValueColumn);
      const colDate = cols.find((x: any) => x.name === excelOptions.excelDateColumn);
      const colComment = cols.find((x: any) => x.name === excelOptions.excelCommentColumn);

      if (!colValue || !colDate) {
        return;
      }

      const length = Math.min(colValue.values.length, colDate.values.length);
      for (let i = 1; i < length; i++) {
        const [excelValue] = colValue.values[i];
        const [excelDate] = colDate.values[i];
        const [excelComment] = colComment ? colComment.values[i] : [null];

        const value = this.parseValue(excelValue);
        const valueDate = this.parseValueDate(excelDate);
        const comment = this.parseComment(excelComment);

        if (value && valueDate) {
          const v: VistoKeyResultValueItem = {
            kind: VistoKind.KRV,
            guid: makeGuid(),
            krGuid: kr.guid,
            valueKind,
            value,
            valueDate,
          };
          if (colComment) {
            v.description = `${comment}`;
          }
          krvs.push(v);
        }
      }
      krvs.sort((a, b) => TextService.compareDateTime(b.valueDate, a.valueDate));
    }

    try {
      await action();
      return krvs;
    } catch (error) {
      if (isConsentError(error)) {
        this.makeConsentNotification(action, notify);
      }

      trackClient.throw(
        TextService.format(strings.ExcelError_ReadData, {
          excelTable: excelOptions.excelTable,
          krTitle: TextService.formatTitle(kr, p.items),
          message: stringifyError(error)
        }),
        error
      );
    } finally {
      await wb.close();
    }
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify) {

    AuthService.resetAuth(TokenKind.excel);

    notifyInfoBar(notify, {
      message: TextService.format(strings.ExcelConsent_Message),
      group: 'Excel_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.ExcelConsent_Details),
      actions: [
        {
          title: TextService.format(strings.ExcelConsent_Button),
          action: async () => {
            try {
              await AuthService.getConsent(TokenKind.excel, '', callback);
              clearInfoBar(notify, 'Excel_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.ExcelConsent_Succeeded), group: 'Excel_Consent' });
            } catch (error) {
              const message = TextService.format(strings.ExcelConsent_Failed);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Excel_Consent' });
            }
          }
        }
      ]
    });
  }
}
