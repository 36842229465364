import React from 'react';
import { EditActionDialog, EditAssocDialog, EditDpDialog, EditFocusDialog, EditLopDialog, EditSoDialog } from 'dialogs';
import { VistoKind, VistoAssocItem, VistoActionItem, VistoDpItem, VistoLopItem, VistoSoItem, IVistoPlan, IVistoListItem, VistoFocusItem, VistoKeyResultItem } from 'sp';
import { EditKeyResultValuesDialog } from 'dialogs/EditKeyResultValuesDialog';

export const EditItemDialog = (props: {
  plan: IVistoPlan;
  item: IVistoListItem;
  onDismiss: (changed: boolean) => void;
}) => {

  switch (props.item.kind) {
    case VistoKind.Assoc:
      return <EditAssocDialog onDismiss={props.onDismiss} plan={props.plan} assoc={props.item as VistoAssocItem} />;
    case VistoKind.Action:
      return <EditActionDialog onDismiss={props.onDismiss} plan={props.plan} action={props.item as VistoActionItem} />;
    case VistoKind.DP:
      return <EditDpDialog onDismiss={props.onDismiss} plan={props.plan} dp={props.item as VistoDpItem} />;
    case VistoKind.LOP:
      return <EditLopDialog onDismiss={props.onDismiss} plan={props.plan} lop={props.item as VistoLopItem} />;
    case VistoKind.SO:
      return <EditSoDialog onDismiss={props.onDismiss} plan={props.plan} so={props.item as VistoSoItem} />;
    case VistoKind.Focus:
      return <EditFocusDialog onDismiss={props.onDismiss} plan={props.plan} focus={props.item as VistoFocusItem} />;
    case VistoKind.KeyResult:
      return <EditKeyResultValuesDialog onDismiss={props.onDismiss} plan={props.plan} kr={props.item as VistoKeyResultItem} />;
    default:
      return null;
  }

};
