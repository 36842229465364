import React from 'react';
import { Toggle, TooltipHost } from '@fluentui/react';

export const ToolbarToggle = (props: { label: string, tooltip: string, value: boolean, onChange: (value: boolean) => void }) => {

  const styles = {
    root: {
      marginBottom: 0,
      marginLeft: 8,
    },
    label: {
      marginRight: 8,
      wordBreak: 'normal',
      whiteSpace: 'nowrap'
    }
  }

  return (
    <TooltipHost content={props.tooltip}>
      <Toggle inlineLabel styles={styles} label={props.label} checked={props.value} onChange={(_, val) => props.onChange(!props.value)} />
    </TooltipHost>
  );
}
