import * as React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, IPalette, PrimaryButton, Stack, getColorFromString, ColorPicker, IDragOptions, ContextualMenu, IModalProps } from '@fluentui/react';

import * as strings from 'VistoWebPartStrings';
import { PaletteColorPicker, SwatchPalette } from 'components';
import { defaultModalProps } from './DefaultModalProps';
import { trackClient } from 'services/trackClient';
import { TextService } from 'services/TextService';

export function ColorPickerDialog(props: {
  color: string;
  setColor: (color: string) => void;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('ColorPickerDialog'), []);

  const [selectedColor, setSelectedColor] = React.useState(props.color);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.ColorPickerDialog_SelectColorLabel), type: DialogType.normal, }}
      maxWidth={null}
      modalProps={defaultModalProps}
      onDismiss={props.onDismiss} >
      <Stack horizontal tokens={{ childrenGap: 'm' }}>
        <Stack>
          <PaletteColorPicker swatchPalette={SwatchPalette.basic} color={selectedColor} setColor={setSelectedColor} />
          <PaletteColorPicker swatchPalette={SwatchPalette.neutral} color={selectedColor} setColor={setSelectedColor} />
          <PaletteColorPicker swatchPalette={SwatchPalette.theme} color={selectedColor} setColor={setSelectedColor} />
        </Stack>
        <ColorPicker alphaType='none' color={getColorFromString(selectedColor)} onChange={(_, val) => setSelectedColor(`#${val.hex}`)} styles={{ panel: { padding: 0 } }} />
      </Stack>
      <DialogFooter>
        <PrimaryButton disabled={!selectedColor} onClick={() => props.setColor(selectedColor)} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={props.onDismiss} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
}

export const registerColorPickerDialog = (ui, openColorDialog: (color: string, onSave: (color: string) => void) => void) => {

  ui.pickColor = (color, apply) => {
    var graph = ui.editor.graph;
    var selState = graph.cellEditor.saveSelection();

    openColorDialog(color, (newColor) => {
      graph.cellEditor.restoreSelection(selState);
      apply(newColor);
    });
  };
};
