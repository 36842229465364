import React from 'react';
import strings from 'VistoWebPartStrings';
import { Stack, CommandBar, useTheme, ICommandBarItemProps, Spinner, SpinnerSize } from '@fluentui/react';
import { BasicDialog } from 'dialogs/common';
import { TextService } from 'services/TextService';
import { AssistantFileList } from './AssistantFileList';
import { ApiOpenaiFileService } from 'services/api/ApiOpenaiFileService';
import { AssistantService } from 'services/AssistantService';
import { IAssistantDocument } from 'services/IAssistantDocument';
import { FileService } from 'services/FileService';
import { ApiOpenaiVectorStoreService } from 'services/api/ApiOpenaiVectorStoreService';
import { EnvContext } from 'services/EnvContext';
import { ApiOpenaiVectorStoreFileService } from 'services/api/ApiOpenaiVectorStoreFileService';
import { InfoBar, useErrorInfo } from 'components/InfoBar';
import { NotificationType } from 'services/Notify';

export const AssistantFileDialog = (props: {
  onDismiss: () => void;
  onItemsChanged?: () => void;
}) => {

  const { tid } = React.useContext(EnvContext);

  const onCommit = async () => {
  }

  const [items, setItems] = React.useState<IAssistantDocument[]>([]);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const loadItems = async () => {
    const store = await ApiOpenaiVectorStoreService.ensureVectorStore(tid);
    const attachments = await ApiOpenaiVectorStoreFileService.getVectorStoreFileIds(store.id);
    const docs: IAssistantDocument[] = [];
    for (const attachment of attachments) {
      const file = await ApiOpenaiFileService.getFile(attachment.id);
      const doc = AssistantService.mapFileToDocument(file);
      docs.push(doc);
    }
    setItems(docs);
    props.onItemsChanged();
  }

  React.useEffect(() => {
    onRefresh();
  }, []);

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const onAddNew = async () => {
    const file = await FileService.prompt();
    if (file) {
      try {
        setIsAddingNew(true);
        if (file.size > 5 * 1024 * 1024) {
          throw new Error(TextService.format(strings.AssistantFile_SizeLimited));
        }
        const created = await ApiOpenaiFileService.createFile(file);
        const processed = await ApiOpenaiFileService.waitForProcessing(created.id);
        const store = await ApiOpenaiVectorStoreService.ensureVectorStore(tid);
        await ApiOpenaiVectorStoreFileService.createVectorStoreFileId(store.id, processed.id);
        await loadItems();
      } catch (error) {
        setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.AssistanFile_UnableToAddFile), error });
      } finally {
        setIsAddingNew(false);
      }
    }
  }

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const onRefresh = async () => {
    try {
      setIsRefreshing(true);
      await loadItems();
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.AssistanFile_UnableToRefresh), error });
    } finally {
      setIsRefreshing(false);
    }
  }

  const [selectedItems, setSelectedItems] = React.useState<IAssistantDocument[]>([]);

  const [isDeleting, setIsDeleting] = React.useState(false);
  const onDelete = async () => {
    try {
      setIsDeleting(true);
      if (selectedItems.length > 0) {
        const store = await ApiOpenaiVectorStoreService.ensureVectorStore(tid);
        for (const item of selectedItems) {
          await ApiOpenaiVectorStoreFileService.deleteVectorStoreFileId(store.id, item.key);
          await ApiOpenaiFileService.deleteFile(item.key);
        }
        await loadItems();
      }
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.AssistanFile_UnableToDeleteFile), error });
    } finally {
      setIsDeleting(false);
    }
  }

  const menuItems: ICommandBarItemProps[] = [
    {
      key: 'addnew',
      text: TextService.format(strings.AssistanFile_AddNew),
      iconProps: { iconName: 'Add' },
      disabled: isRefreshing || isAddingNew,
      onClick: () => {
        onAddNew();
      }
    },
    {
      key: 'refresh',
      text: TextService.format(strings.AssistanFile_Refresh),
      iconProps: { iconName: 'Refresh' },
      disabled: isRefreshing,
      onClick: () => {
        onRefresh();
      }
    },
    {
      key: 'delete',
      text: TextService.format(strings.AssistanFile_Delete),
      iconProps: { iconName: 'Delete' },
      disabled: isRefreshing || isDeleting || !(selectedItems.length > 0),
      onClick: () => {
        onDelete();
      }
    }
  ];

  const onSelectionChanged = (selected: IAssistantDocument[]) => {
    setSelectedItems(selected || []);
  }

  const onItemInvoked = async (item: IAssistantDocument) => {
  }

  const theme = useTheme();

  const listStyle = {
    minHeight: 200,
    border: `1px solid ${theme.palette.neutralTertiary}`,
    opacity: isAddingNew || isRefreshing || isDeleting ? 0.5 : 1
  };

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={onCommit}
      buttonOkText={TextService.format(strings.ButtonSave)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.AssistanFile_DialogTitle)}
      minWidth={400}
      maxWidth={800}
      zIndex={1000010}>
      <Stack grow>
        <Stack horizontal grow>
          <Stack.Item grow>
            <CommandBar styles={{ root: { padding: 0 } }} items={menuItems} />
          </Stack.Item>
          {(isRefreshing || isAddingNew || isDeleting) && <Spinner size={SpinnerSize.small} />}
        </Stack>
        <InfoBar {...errorInfo} onDismiss={() => setErrorInfo(null)} />
        <Stack.Item grow style={listStyle}>
          <AssistantFileList
            items={items}
            onSelectionChanged={onSelectionChanged}
            onItemInvoked={onItemInvoked}
          />
        </Stack.Item>
      </Stack>
    </BasicDialog>
  );
}
