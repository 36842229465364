import { TokenKind } from './TokenKind';

export function getAuthScopes(kind: TokenKind, domain: string, appId: string, appDomain: string): string[] {
  switch (kind) {
    case TokenKind.sharepoint:
      return [`https://${domain}/AllSites.Manage`];

    case TokenKind.project:
      return [`https://${domain}/Project.Read`];

    case TokenKind.devops:
      return [`499b84ac-1321-427f-aa17-267ca6975798/user_impersonation`];

    case TokenKind.planner:
      return [`Tasks.ReadWrite`, `User.ReadBasic.All`];

    case TokenKind.dashboard:
      return [`User.Read`, `User.ReadBasic.All`, `Team.ReadBasic.All`, `Channel.ReadBasic.All`];

    case TokenKind.sites:
      return [`Sites.Read.All`];

    case TokenKind.excel:
      return [`Files.ReadWrite.All`];

    case TokenKind.api:
      return [`api://${appDomain}/${appId}/access_as_user`];

    default:
      return [`openid`, `profile`, `email`, `offline_access`];
  }
}
