import React from 'react';
import { IFieldValueUser } from 'sp';
import { UserInfoPhotoService } from './UserInfoPhotoService';

export const useAssignees = (assignedTo: IFieldValueUser[], deps: React.DependencyList) => {

  const itemAssignees = assignedTo?.map(x => ({
    ...x,
    imageUrl: UserInfoPhotoService.getCachedUserPhotoUrl(x.guid || x.userName)
  })) ?? [];

  const [assignees, setAssignees] = React.useState(itemAssignees);

  React.useEffect(() => {
    const missing = itemAssignees.filter(a => !a.imageUrl);
    Promise.all(missing.map(a => UserInfoPhotoService.getUserPhotoUrl(a.guid || a.userName))).then(results => {
      for (let i = 0; i < results.length; ++i) {
        missing[i].imageUrl = results[i];
      }
      setAssignees([...itemAssignees]);
    });
  }, deps);

  return assignees;
}
