import { sleep } from 'shared/parse';
import { ApiService } from './ApiService';
import { IOpenaiFile } from 'shared/api/openai';

export class ApiOpenaiFileService {

  public static async getFiles(): Promise<IOpenaiFile[]> {
    const result = await ApiService.fetch('/openai/files', 'get');
    return result as IOpenaiFile[];
  }

  public static async waitForProcessing(id: string, maxWait: number = 10 * 60 * 1000) {
    const TERMINAL_STATES = new Set(['processed', 'error', 'deleted']);

    const start = Date.now();
    let file = await ApiOpenaiFileService.getFile(id);

    while (!file.status || !TERMINAL_STATES.has(file.status)) {
      await sleep(1000);

      file = await ApiOpenaiFileService.getFile(id);
      if (Date.now() - start > maxWait) {
        throw { message: `Giving up on waiting for file ${id} to finish processing after ${maxWait} milliseconds.` };
      }
    }
    return file;
  }

  public static async createFile(file: File): Promise<IOpenaiFile> {

    const fd = new FormData();
    fd.append('file', file);

    const result = await ApiService.fetch('/openai/files', 'post', fd);
    return result as IOpenaiFile;
  }

  public static async getFile(fileId: string): Promise<IOpenaiFile> {
    const result = await ApiService.fetch(`/openai/files/${fileId}`, 'get');
    return result as IOpenaiFile;
  }

  public static async deleteFile(fileId: string) {
    const result = await ApiService.fetch(`/openai/files/${fileId}`, 'delete');
    return result;
  }

}
