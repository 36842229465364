import * as React from 'react';
import { MainFrame } from 'frames/MainFrame';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { IEnvContext } from 'services/EnvContext';
import { ApiService } from 'services/api/ApiService';
import { WebAuthService } from 'teams/services/WebAuthService';
import { useSearchParams } from 'react-router-dom';
import { UrlService } from 'shared/urlService';
import { useIsAuthenticated } from '@azure/msal-react';

export const LocalPlan = () => {

  const onLoad = async (ctx: IEnvContext) => {
    ApiService.configure(() => WebAuthService.getMsalToken());
  };

  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');

  const isAuthenticated = useIsAuthenticated();

  return (
    <WebAuthScope key={`${planId}##${isAuthenticated}`} name='Web_LocalPlan' kind={TokenKind.api} onLoad={onLoad} siteUrl={UrlService.LOCAL_URL} planId={planId}>
      <MainFrame />
    </WebAuthScope>
  );
};
