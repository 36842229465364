import * as React from 'react';
import { IContextualMenuItem, Stack, FontSizes } from '@fluentui/react';
import { IFieldValueUser, IVistoPlan } from 'sp';
import * as strings from 'VistoWebPartStrings';
import { TitleBlock } from 'components';
import { AppContext } from 'services/AppContext';
import { TextService } from 'services/TextService';
import { trackClient } from 'services/trackClient';
import { SidebarBase } from './SidebarBase';
import { ISidebarProps } from './ISidebarProps';

interface ISidebarValueProps extends ISidebarProps {
  name: string;
  disableName: boolean;

  description: string;
  disableDescription: boolean;

  hideAssignedTo?: boolean;
  assignedTo?: IFieldValueUser[];
  disableAssignedTo?: boolean;

  onLoad: () => Promise<{ name: string, description: string, assignedTo?: IFieldValueUser[] }>;
  onSave: (name: string, description: string, assignedTo?: IFieldValueUser[]) => Promise<IVistoPlan>;
}

export function Sidebar(props: ISidebarValueProps) {

  const defaultState = {
    isEditing: false,
    isLoading: false,
    isSaving: false,
    name: props.name,
    description: props.description,
    assignedTo: props.assignedTo,
  };

  const [state, setState] = React.useState(defaultState);

  React.useEffect(() => {
    setState(defaultState);
  }, [props.name, props.description]);

  const onNameChanged = (val) => {
    setState({ ...state, name: val });
  };

  const onAssignedToChanged = (val) => {
    setState({ ...state, assignedTo: val });
  };

  // Not using setDescription on purpose (RichTextEdit bug)
  const onDescriptionChanged = (val) => {
    state.description = val;
    return val;
  };

  const { isPlanEditEnabled, isPopupOpen, setIsPopupOpen } = React.useContext(AppContext);
  React.useEffect(() => {
    setIsPopupOpen(state.isEditing || state.isSaving || state.isLoading);
  }, [state.isEditing, state.isSaving, state.isLoading]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen || state.isSaving || state.isLoading;

  const onLoad = () => {
    setState({ ...state, isLoading: true });
    props.onLoad().then(loaded => {
      setState({ ...state, name: loaded.name, description: loaded.description, isLoading: false, isEditing: true });
    }, err => {
      setState({ ...state, isEditing: true });
      trackClient.error(`Error loading item name/description`, err);
    });

  };

  const onSave = () => {
    setState({ ...state, isSaving: true });
    props.onSave(state.name, state.description, state.assignedTo).then(() => {
      setState({ ...state, isSaving: false, isEditing: false, isLoading: false });
    }, err => {
      setState({ ...state, isSaving: false, isEditing: true, isLoading: false });
      trackClient.error(`Error saving item name/description`, err);
    });
  };

  const onCancel = () => {
    setState(defaultState);
  };

  const inplaceMenu: IContextualMenuItem[] =
    !state.isEditing ?
      [{
        key: 'InplaceEdit',
        text: TextService.format(strings.Sidebar_InplaceEdit),
        iconProps: { iconName: 'Edit' },
        disabled: isEditDisabled,
        onClick: onLoad
      }] : [{
        key: 'InplaceEditSave',
        text: TextService.format(strings.Sidebar_InplaceSave),
        disabled: state.isSaving || !state.name,
        iconProps: { iconName: 'Save' },
        onClick: onSave
      }, {
        key: 'InplaceEditCancel',
        text: TextService.format(strings.Sidebar_InplaceCancel),
        iconProps: { iconName: 'Cancel' },
        onClick: onCancel
      }];

  const menuItems = [
    ...inplaceMenu,
    ...props.menuItems
  ];

  const onDismiss = () => {
    onCancel();
    props.onDismiss();
  };

  return <SidebarBase
    isOpen={props.isOpen}
    isBlocking={state.isEditing}
    onDismiss={onDismiss}
    advisoryTopicKey={props.advisoryTopicKey}
    menuItems={menuItems}
    overflowMenuItems={props.overflowMenuItems}
    farItems={props.farItems}
    content={
      <Stack tokens={{ childrenGap: 'm' }}>
        <TitleBlock
          fontSize={FontSizes.xLarge}
          nameDisabled={state.isSaving || props.disableName}
          descriptionDisabled={state.isSaving || props.disableDescription}
          edit={state.isEditing}
          name={state.name}
          description={state.description}
          onNameChanged={onNameChanged}
          onDescriptionChanged={onDescriptionChanged}
          hideAssignedTo={props.hideAssignedTo}
          assignedTo={state.assignedTo}
          onAssignedToChanged={onAssignedToChanged}
          assignedToDisabled={state.isSaving || props.disableAssignedTo}
        />
        {props.content}
      </Stack>}
  />;
}
