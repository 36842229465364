import React from 'react';
import { IBasicNotify, INotification, NotificationType } from 'services/Notify';

export interface INotifications extends IBasicNotify {
  status: INotification;
  items: INotification[];
  setStatus: (status: INotification) => void;
  updateStatus: () => void;
}

export const useNotifications = (): INotifications => {

  const [status, setStatus] = React.useState<INotification>(null);
  const notificationsRef = React.useRef<INotification[]>([]);

  const updateStatus = () => {
    let result = null;
    for (const n of notificationsRef.current) {
      if (n.actions && n.actions.some(a => a.done)) {
        continue;
      }
      if (!result || n.type >= result.type || n.bubble) {
        result = n;
      }
    }
    setStatus(result);
  };

  const isDuplicateNotification = (info: INotification) => {
    return notificationsRef.current.find(x => x.message === info.message && `${x.error}` === `${info.error}`);
  };

  const addNotification = (info: INotification) => {
    if (info.type === NotificationType.success || info.type === NotificationType.log || !isDuplicateNotification(info)) {

      // automatically hide success notifications
      if (info.type === NotificationType.success || info.timeout) {
        setTimeout(() => {
          const index = notificationsRef.current.indexOf(info);
          if (index >= 0) {
            notificationsRef.current.splice(index, 1);
            updateStatus();
          }
        }, info.timeout || 5000);
      }

      notificationsRef.current.push(info);
      updateStatus();
    }
  };

  const clearNotifications = (group?: string) => {
    notificationsRef.current = notificationsRef.current.filter(n => group && n.group !== group);
    updateStatus();
  };

  const items = notificationsRef.current;

  return {
    status,
    items,
    setStatus,
    updateStatus,
    addNotification,
    clearNotifications,
  }
}
