import {
  IFieldDefinition,
  VistoKind,
  IVistoPlan,
  IVistoList,
  getListDefinition,
  IVistoListItem,
  VistoParentKinds,
  ILookupFieldDefinition,
  FieldType,
  VistoSoItem,
  VistoKeyResultItem,
  VistoKeyResultValueItem,
} from 'sp';
import { IFieldInfo } from '@pnp/sp/fields';
import { ChangesService } from './ChangesService';
import { IExecutableAction } from './IExecutableAction';
import * as strings from 'VistoWebPartStrings';
import { IListInfo } from '@pnp/sp/lists';
import '@pnp/sp/views';
import '@pnp/sp/fields';
import '@pnp/sp/navigation';
import '@pnp/sp/regional-settings/web';
import { ClientsideWebpart } from '@pnp/sp/clientside-pages';

import { TextService } from 'services/TextService';
import { IViewInfo } from '@pnp/sp/views';
import { PlanDataService, PlanVersion } from './PlanDataService';
import { trackClient } from 'services/trackClient';
import { areGuidsEqual } from 'shared/guid';
import { INotify } from './Notify';
import { makeMigrateFocuses } from './migrations/MigrateFocuses';
import { makeMigrateKeyResults } from './migrations/MigrateKeyResults';
import { makeMigrateNoteFields } from './migrations/MigrateNoteFields';
import { makeMigratePlannerDuplicates } from './migrations/MigratePlanerDuplicates';
import { makeMigrateRichTextFields } from './migrations/MigrateRichTextFields';
import { MigrateSourceUrlFields } from './migrations/MigrateSourceUrlFields';
import { MigrateSourceOriginalPlanId } from './migrations/MigrateSourceOriginalPlanId';
import { makeMigrateNavigationLinkFields } from './migrations/MigrateNavigationLinkFields';
import { AuthService } from './AuthService';
import { SPFI } from '@pnp/sp';
import '@pnp/sp/batching';
import { StorageService } from './StorageService';
import { StorageCacheService } from './StorageCacheService';
import { makeMigrateFixEnumTranslation } from './migrations/MigrateEnums';
import { makeMigrateFixHangingValidations } from './migrations/MigrateFixHangingValidations';
import { UrlService } from 'shared/urlService';
import { makeMigrateRemoveFKR } from './migrations/MigrateRemoveFKR';
import { makeMigrateKeyResultsToAssoc } from './migrations/MigrateKeyResultsToAssoc';
import { migrateFixAssocMatrix } from './migrations/MigrateFixAssocMatrix';
import { MigrateKeyResultsToAssocActionFill } from './migrations/MigrateKeyResultsToAssocActionFill';
import { MigrateParentPlanIds } from './migrations/MigrateParentPlanIds';
import { makeMigrateCleanupKeyResults } from './migrations/MigrateCleanupKeyResults';
import { MigrateKrHierarchy } from './migrations/MigrateKrHierarchy';
import { MigrateFocusOrderFix } from './migrations/MigrateFocusOrderFix';
import { MigrationHelperService } from './MigrationHelperService';
import { ProgressService } from './ProgressService';
import { PlanSettingsService } from './PlanSettingsService';
import { MigrateDeleteEntityPhase } from './migrations/MigrateDeleteEntityPhase';
import { SharepointService } from './SharepointService';
import { IItemChanges } from './Interfaces';

interface IListSubAction {
  title: string;
  action: (listUrl: string, batch: SPFI) => void;
}

export function getListFields(listFields: any): IFieldDefinition[] {
  return Object.keys(listFields).map(k => listFields[k]);
}

function getFieldTitle(field: IFieldDefinition): string {
  return strings[`${field.key}`] ? TextService.format(strings[`${field.key}`]) : '';
}

function getFieldDescription(field: IFieldDefinition): string {
  return strings[`${field.key}_Help`] ? TextService.format(strings[`${field.key}_Help`]) : '';
}

function getFieldChanges(field: IFieldDefinition, spFields: IFieldInfo[]) {
  const spField = spFields && spFields.filter(f => f.InternalName === field.name)[0];

  const oldItem = {
    Title: spField && spField.Title,
    Description: spField && spField.Description
  };

  const newItem = {
    Title: getFieldTitle(field),
    Description: getFieldDescription(field)
  };

  return ChangesService.getChanges(oldItem, newItem);
}

async function executeBatch(sp: SPFI, listUrl: string, actions: IListSubAction[]) {
  const [batch, execute] = sp.batched();
  for (let a = 0; a < actions.length; ++a) {
    actions[a].action(listUrl, batch);
  }
  await execute();
}

export class SharePointConfigurationService {

  public static async generateActions(
    planProps: IVistoPlan,
    createPages: boolean,
    notify: INotify,
    feedback: (message: string) => void
  ) {

    const sp = AuthService.getSpClient(planProps.siteUrl);

    const listId = {};

    const getListId = (kind: VistoKind) => listId[kind];

    function scheduleListUpdate<T>(definition: IVistoList<T>, existingList: IListInfo) {

      const updates: IListSubAction[] = [];

      const oldValue = {
        Title: existingList?.Title,
        EnableVersioning: existingList?.EnableVersioning,
        ...(existingList?.EnableVersioning ? { MajorVersionLimit: existingList?.MajorVersionLimit } : {})
      };

      const newValues = {
        Title: TextService.getListTitle(definition.kind, planProps.name),
        EnableVersioning: definition.enableVersioning,
        ...(definition.enableVersioning ? { MajorVersionLimit: definition.majorVersionLimit } : {})
      };

      const changes = ChangesService.getChanges(oldValue, newValues);

      if (changes.detected) {
        updates.push({
          title: TextService.format(strings.SharePointConfigurationService_UpdatingListProperties, { changedProps: TextService.formatList(changes.properties) }),
          action: (listUrl: string, batch: SPFI) => {
            batch.web.getList(listUrl).update(changes.newValues);
          }
        });
      }

      return updates;
    }

    function scheduleViewUpdate<T>(definition: IVistoList<T>, existingViews: IViewInfo[]) {

      const updates: IListSubAction[] = [];

      if (!existingViews) {

        updates.push({
          title: TextService.format(strings.SharePointConfigurationService_ConfigureDefaultView),
          action: (listUrl: string, batch: SPFI) => {
            batch.web.getList(listUrl).defaultView.setViewXml(definition.defaultViewXml);
          }
        });
      }

      if (definition.additionalViews && !existingViews?.length) {

        for (const viewDefinition of definition.additionalViews) {
          const name = TextService.format(strings[viewDefinition.viewNameStringId]);
          updates.push({
            title: TextService.format(strings.SharePointConfigurationService_AddView, { name }),
            action: (listUrl: string, batch: SPFI) => {
              batch.web.getList(listUrl).views.add(name).then(created => {
                created.view.setViewXml(definition.defaultViewXml);
              });
            }
          });
        }

      }

      return updates;
    }

    const schedulePlanCreate = () => {
      const updates: IExecutableAction[] = [];

      updates.push({
        title: TextService.format(strings.SharePointConfigurationService_CreatePlan, { name: planProps.name }),
        execute: async () => {
          const timeZone = await sp.web.regionalSettings.timeZone();

          const planSettings = PlanSettingsService.getPlanSettings(planProps);
          const statusDate = PlanSettingsService.getStatusDate(planSettings);

          const plan: IVistoPlan = {
            planId: planProps.planId,
            siteUrl: planProps.siteUrl,
            planVersion: PlanVersion.current,
            editable: true,
            statusDate: TextService.getDate(statusDate),
            timeZoneBias: timeZone.Information.Bias + timeZone.Information.DaylightBias,
            items: {},
            revision: 0,
            drawingRevision: 0,
            name: planProps.name,
            description: planProps.description,
            esName: planProps.esName,
            esDescription: planProps.esDescription,
            styleJson: planProps.styleJson,
            drawingXml: planProps.drawingXml,
            topPlanId: planProps.topPlanId,
            commentsJson: planProps.commentsJson,
            settingsJson: planProps.settingsJson
          };

          return await StorageService.get(plan.siteUrl).createPlanItem(plan);
        }
      });

      const importedItems = Object.keys(planProps.items).map(k => planProps.items[k]);
      const chunks = PlanDataService.chunkItems(importedItems, { getItem: x => x, groupByKind: true });

      let itemsCreated = 0;
      for (let i = 0; i < chunks.length; ++i) {
        const chunk = chunks[i];
        updates.push({
          title: TextService.format(strings.MessageProgress_CreatingItems, {
            itemKindName: TextService.getVistoKindName(chunk[0].kind),
            start: itemsCreated + 1,
            end: importedItems.length
          }),
          subActionsTitles: chunk.map(x =>
            TextService.format(strings.SharePointConfigurationService_CreateNewItem, { item: TextService.formatTitle(x, planProps.items) })
          ),
          execute: async (plan) => {
            plan = await StorageService.get(plan.siteUrl).createItems(plan, chunk, notify, { excludeExternals: true, excludePercentComplete: true });
            return plan;
          }
        });
        itemsCreated += chunk.length;
      }

      updates.push({
        title: TextService.format(strings.MessageProgress_Updating),
        execute: async (plan) => {
          plan = await ProgressService.ensurePercentComplete(plan, notify);
          return plan;
        }
      });

      return updates;
    };

    const schedulePlanUpdate = (existingPlan: IVistoPlan) => {
      const updates: IExecutableAction[] = [];

      // replace field when migrating to 3.0.8
      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '3.0.8')) {
        updates.push(makeMigrateNoteFields(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '3.0.9')) {
        updates.push(makeMigrateRichTextFields(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '3.0.10')) {
        updates.push(makeMigratePlannerDuplicates(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.0.2')) {
        updates.push(makeMigrateKeyResults(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.2.8')) {
        updates.push(makeMigrateFocuses(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.1')) {
        updates.push(MigrateSourceUrlFields(foundPlan));
      }

      if (foundPlan.planVersion === '4.3.2') {
        updates.push(makeMigrateNavigationLinkFields(foundPlan, getListId));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.10')) {
        updates.push(makeMigrateFixEnumTranslation(foundPlan))
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.12')) {
        updates.push(makeMigrateFixHangingValidations(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.14')) {
        updates.push(makeMigrateRemoveFKR(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.15')) {
        updates.push(makeMigrateKeyResultsToAssoc(foundPlan, getListId));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.16')) {
        updates.push(migrateFixAssocMatrix(foundPlan, getListId));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.18')) {
        updates.push(MigrateKeyResultsToAssocActionFill(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.20')) {
        updates.push(MigrateParentPlanIds(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.23')) {
        updates.push(MigrateKrHierarchy(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.24')) {
        updates.push(makeMigrateCleanupKeyResults(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.32')) {
        updates.push(MigrateFocusOrderFix(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.40')) {
        updates.push(MigrateDeleteEntityPhase(foundPlan));
      }

      if (PlanDataService.isPlanVersionLess(foundPlan.planVersion, '4.3.51')) {
        updates.push(MigrateSourceOriginalPlanId(foundPlan));
      }

      if (PlanDataService.isPlanOutdated(existingPlan.planVersion)) {
        const planVersion = PlanVersion.current;
        updates.push({
          title: TextService.format(strings.SharePointConfigurationService_SetPlanVersion, { planVersion }),
          execute: async (p) => {
            if (updates.every(u => !u.hasError)) {
              await StorageService.get(planProps.siteUrl).updatePlanItem(planProps, { planVersion }, notify);
              StorageCacheService.resetCache();
              return { ...p, planVersion };
            }
          }
        });
      }

      return updates;
    };

    const scheduleFieldCreation = (fields: IFieldDefinition[], existingFields: IFieldInfo[]): IListSubAction[] => {
      return fields
        .filter(field => {
          const found = existingFields && existingFields.filter(f => f.InternalName === field.name)[0];
          return !field.builtIn && !found;
        })
        .map(field => {
          const fieldName = field.name;
          return {
            title: TextService.format(strings.SharePointConfigurationService_CreateField, { fieldName }),
            action: (listUrl: string, batch: SPFI) => {
              const xml = MigrationHelperService.getFieldXml(field, getListId);
              batch.web.getList(listUrl).fields.createFieldAsXml(xml);
            }
          };
        });
    };

    const scheduleFieldUpdate = (fields: IFieldDefinition[], listFields: IFieldInfo[]): IListSubAction[] => {
      return fields
        .filter(field => {
          const changes = getFieldChanges(field, listFields);
          return field.key && changes.detected;
        })
        .map(field => {
          const fieldName = field.name;
          const changes = getFieldChanges(field, listFields);
          return {
            title: TextService.format(strings.SharePointConfigurationService_UpdateField, { fieldName, changedProps: TextService.formatList(changes.properties) }),
            action: (listUrl: string, batch: SPFI) => {
              batch.web.getList(listUrl).fields.getByInternalNameOrTitle(fieldName).update(changes.newValues, 'SP.Field');
            }
          };
        });
    };

    let foundPlan: IVistoPlan = null;
    const foundLists = await sp.web.lists.select('Id', 'DefaultViewUrl', 'Title', 'EnableVersioning', 'MajorVersionLimit')();

    const getFoundList = (kind: VistoKind) => {
      const listRelativeUrl = SharepointService.getListRelativeUrl(planProps, kind);
      return foundLists.find(l => l.DefaultViewUrl.indexOf(listRelativeUrl) >= 0);
    };

    const kinds = PlanDataService.getAllVistoKinds({ includePlan: true }).sort(PlanDataService.compareItemKind);

    for (const kind of kinds) {
      const foundList = getFoundList(kind);
      if (foundList)
        listId[kind] = foundList.Id;
    }

    const result: IExecutableAction[] = [];

    for (const kind of kinds) {

      if (planProps.topPlanId && VistoParentKinds.includes(kind)) {
        continue;
      }

      feedback(TextService.format(strings.SharePointConfiguration_AnalyzingList, { name: planProps.name, vistoKind: TextService.getVistoKindName(kind) }));

      const listName = UrlService.getListName(kind, planProps.planId);
      const definition = getListDefinition<IVistoListItem>(kind);
      const listUrl = SharepointService.getListRelativeUrl(planProps, kind);
      const list = sp.web.getList(listUrl);
      const foundList = getFoundList(kind);

      const foundFields = foundList && await list.fields();
      const foundViews = foundList && await list.views();

      const listUpdate = scheduleListUpdate(definition, foundList);
      const viewUpdate = scheduleViewUpdate(definition, foundViews);

      const fields = getListFields(definition.fields);
      const createFieldActions = scheduleFieldCreation(fields, foundFields);
      const updateFieldActions = scheduleFieldUpdate(fields, foundFields);

      const subActions = [...listUpdate, ...createFieldActions, ...updateFieldActions, ...viewUpdate];
      const subActionsTitles = subActions.map(x => x.title);

      const executeUpdate = async (p) => {
        await executeBatch(sp, listUrl, listUpdate);
        await executeBatch(sp, listUrl, createFieldActions);
        await executeBatch(sp, listUrl, updateFieldActions);
        await executeBatch(sp, listUrl, viewUpdate);
        return p;
      };

      if (foundList) {

        if (definition.kind === VistoKind.Plan) {
          try {
            foundPlan = await StorageService.get(planProps.siteUrl).loadPlanItem(planProps, ['name', 'settingsJson']);
          } catch (err) {
            if (foundPlan)
              trackClient.warn(`Plan ${planProps.planId} has no version`);
            else
              trackClient.warn(`Plan ${planProps.planId} does not exist in the plan list`);
          }
        }

        const title = TextService.format(strings.SharePointConfigurationService_UpdateList, { listTitle: foundList.Title });

        const execute = async (p) => {
          return await executeUpdate(p);
        };

        if (subActions.length > 0)
          result.push({ title, subActionsTitles, execute });

      } else {

        const listTitle = TextService.getListTitle(kind, planProps.name);
        const title = TextService.format(strings.SharePointConfigurationService_CreateList, { listTitle });

        const execute = async (p) => {

          const created = await sp.web.lists.add(listName, undefined, undefined, false, {
            EnableVersioning: definition.enableVersioning,
            ...(definition.enableVersioning ? { MajorVersionLimit: definition.majorVersionLimit } : {}),
          });

          listId[definition.kind] = created.data.Id;

          return await executeUpdate(p);
        };

        result.push({ title, subActionsTitles, execute });
      }
    }

    const planActions = foundPlan
      ? schedulePlanUpdate(foundPlan)
      : schedulePlanCreate();

    result.push(...planActions);


    if (createPages) {

      const scheduleCreatePage = (fileName: string, title: string, guid: string) => {
        result.push({
          execute: async (p) => {
            const page = await sp.web.addClientsidePage(fileName, title);
            let section = page.addSection();

            const partDefs = await sp.web.getClientsideWebParts();
            const partDef = partDefs.filter(c => areGuidsEqual(c.Id, guid))[0];
            if (partDef) {
              const part = ClientsideWebpart.fromComponentDef(partDef);
              part.setProperties({
                planId: planProps.planId
              });
              section.addControl(part);
              await page.save();

              const pageItem = await page.getItem();
              await pageItem.update({ PageLayoutType: 'SingleWebPartAppPage' });
            } else {
              const error_description = TextService.format(strings.SharePointConfigurationService_WebPartMissing);
              throw { error_description };
            }
            return p;
          },
          title: TextService.format(strings.SharePointConfigurationService_CreatePage, { title }),
        });
      };

      const planTitle = TextService.format(strings.SharePointConfigurationService_PlanPageTitle, { planName: planProps.name });
      const planFileName = TextService.makePlanFileName(planProps.planId);

      const matrixTitle = TextService.format(strings.SharePointConfigurationService_MatrixPageTitle, { planName: planProps.name });
      const matrixFileName = TextService.makeMatrixFileName(planProps.planId);

      scheduleCreatePage(planFileName, planTitle, '9a9646e2-306f-4a6a-8122-cf038fc32ce5');
      scheduleCreatePage(matrixFileName, matrixTitle, 'd9da5336-f9f8-4ad4-a626-b70411d2c796');

      result.push({
        title: TextService.format(strings.SharePointConfigurationService_CreateNavLinks),
        execute: async (p) => {
          const nav = sp.web.navigation.quicklaunch;
          const existingLinks = await nav();

          const planPageUrl = UrlService.makePageUrl(planProps.siteUrl, planFileName);
          const planLinkTitle = TextService.format(strings.SharePointConfigurationService_PlanLinkTitle, { planName: planProps.name });
          const link = await nav.add(planLinkTitle, planPageUrl);

          const actionsListUrl = SharepointService.getListRelativeUrl(planProps, VistoKind.Action);
          await nav.getById(link.data.Id).children.add(TextService.format(strings.SharePointConfigurationService_ActionsLinkTitle), actionsListUrl);

          const matrixPageUrl = UrlService.makePageUrl(planProps.siteUrl, matrixFileName);
          await nav.getById(link.data.Id).children.add(TextService.format(strings.SharePointConfigurationService_MatrixLinkTitle), matrixPageUrl);

          const homeLink = existingLinks.find(x => x.Url === planProps.siteUrl || x.Url?.indexOf('Home.aspx') >= 0);
          if (homeLink) {
            await nav.moveAfter(link.data.Id, homeLink.Id);
          }
          return p;
        }
      });
    }

    return result;
  }

  private static async deleteLocalLists(plan: IVistoPlan) {
    const sp = AuthService.getSpClient(plan.siteUrl);
    const foundLists = await sp.web.lists.select('Id', 'DefaultViewUrl', 'Title', 'EnableVersioning', 'MajorVersionLimit')();
    for (const kind of VistoParentKinds) {
      const listUrl = UrlService.getListRelativeUrl(plan, kind);
      if (foundLists.find(l => l.DefaultViewUrl.indexOf(listUrl) >= 0)) {
        const list = sp.web.getList(listUrl);
        await list.recycle();
      }
    }
  }

  private static async deleteLocalFields(plan: IVistoPlan) {
    
    const sp = AuthService.getSpClient(plan.siteUrl);
    const updates: IItemChanges<IVistoListItem>[] = [];
    const deleteActions: IExecutableAction[] = [];
    const localVistoKinds = PlanDataService.getAllVistoKinds({ includePlan: false }).filter(k => !VistoParentKinds.includes(k));
    for (const kind of localVistoKinds) {
      const fields = getListDefinition(kind).fields;
      for (const fieldName in fields) {
        const field = fields[fieldName] as IFieldDefinition;
        if (field.type === FieldType.Lookup) {
          const fieldLookup = field as ILookupFieldDefinition;
          if (VistoParentKinds.includes(fieldLookup.lookupKind)) {
            const listUrl = UrlService.getListRelativeUrl(plan, kind);
            const list = sp.web.getList(listUrl);

            const fields = list && await list.fields();
            const field = fields && fields.find(f => f.InternalName === fieldLookup.name)
        
            if (field) {
              const items = PlanDataService.getItems(plan.items, kind);
              for (const item of items) {
                updates.push({
                  item, 
                  changes: ChangesService.getChanges(item, { [fieldName]: item[fieldName] })
                })
              }
              deleteActions.push({
                title: TextService.format(strings.SharePointConfiguration_DeleteLocalFields, { fieldName: fieldLookup.name, listName: TextService.getListTitle(kind, plan.name) }),
                subActionsTitles: items.map(x => TextService.formatTitle(x, plan.items)),
                execute: async (p) => {
                  await list.fields.getByInternalNameOrTitle(fieldLookup.name).delete();
                  return p;
                }
              });
            }
          }
        }
      }
    }
    return { updates, deleteActions };
  }

  public static async setTopPlanId(plan: IVistoPlan, topPlanId: string, notify: INotify, feedback: (message: string) => void) {

    const operationOptions = { excludeExternals: true, excludePercentComplete: true };

    feedback(TextService.format(strings.SharePointConfiguration_AnalyzingSetTopPlanId, { name: plan.name }));

    plan = await StorageService.get(plan.siteUrl).loadPlanData(plan, notify);

    const sos = PlanDataService.getItemsHaving<VistoSoItem>(plan.items, x => x.kind === VistoKind.SO && x.originalPlanId === plan.planId);
    const krs = PlanDataService.getItemsHaving<VistoKeyResultItem>(plan.items, x => x.kind === VistoKind.KeyResult && x.originalPlanId === plan.planId);
    const krSet = new Set<string>(krs.map(kr => kr.guid));
    const krvs = PlanDataService.getItemsHaving<VistoKeyResultValueItem>(plan.items, x => x.kind === VistoKind.KRV && krSet.has(x.krGuid));

    const items = [...sos, ...krs, ...krvs];

    const result: IExecutableAction[] = [];

    result.push({
      title: TextService.format(strings.SharePointConfiguration_DeleteRelatedItems),
      subActionsTitles: items.map(x => TextService.formatTitle(x, plan.items)),
      execute: async (p) => {
        return await StorageService.get(p.siteUrl).deleteItems(p, items, notify, operationOptions);
      }
    });
    
    const { updates, deleteActions } = await this.deleteLocalFields(plan);

    result.push(...deleteActions);

    result.push({
      title: TextService.format(strings.SharePointConfiguration_DeleteRelatedLists),
      subActionsTitles: VistoParentKinds.map(k => TextService.getVistoKindName(k)),
      execute: async (p) => {
        await this.deleteLocalLists(p);
        return p;
      }
    });

    const actions = await SharePointConfigurationService.generateActions({ ...plan, topPlanId }, false, notify, feedback);
    result.push(...actions);

    if (items.length > 0) {
      result.push({
        title: TextService.format(strings.SharePointConfiguration_RecreateRelatedItems),
        subActionsTitles: items.map(x => TextService.formatTitle(x, plan.items)),
        execute: async (p) => {
          return await StorageService.get(p.siteUrl).createItems({...p, topPlanId }, items, notify, operationOptions);
        }
      });
    }

    if (updates.length > 0) {
      result.push({
        title: TextService.format(strings.SharePointConfiguration_UpdateRelatedLookups),
        subActionsTitles: updates.map(x => `${TextService.formatChanges(x.item.kind, x.changes)}`),
        execute: async (p) => {
          return await StorageService.get(p.siteUrl).updateItems({...p, topPlanId }, updates, notify, operationOptions);
        }
      })
    }

    const topPlan = topPlanId ? await StorageService.get(plan.siteUrl).loadPlanItem({ siteUrl: plan.siteUrl, planId: topPlanId }, ['name']) : null;
    result.push({
      title: TextService.format(strings.SharePointConfiguration_SetTopPlanField, { topPlanName: topPlan ? topPlan.name : strings.SharePointConfiguration_TopPlanNull }),
      execute: async (p) => {
        return await StorageService.get(p.siteUrl).updatePlanItem(p, { topPlanId }, notify);
      }
    });

    return result;
  }
}
