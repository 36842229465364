import React from 'react';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { IUserInfo } from 'shared/IUserInfo';
import { makeGuid } from 'shared/guid';
import { AppContext } from 'services/AppContext';
import { getObjectValues, parseJSON, sleep } from 'shared/parse';
import { ChangesService } from 'services/ChangesService';
import { EnvContext } from 'services/EnvContext';
import { IAddCommentPayload, IComment } from './IComment';
import { UrlService } from 'shared/urlService';
import SPPeopleSearchService from '@pnp/spfx-controls-react/lib/services/PeopleSearchService';
import { PlanDataService } from 'services/PlanDataService';
import { IFieldValueUser } from 'sp/common';
import { PrincipalType } from '@pnp/spfx-controls-react/lib/controls/peoplepicker';
import { IVistoPlan } from 'sp';
import { StorageService } from 'services/StorageService';

export const useCommentSidebarService = () => {

  const { tid, userObjectId, userPrincipalName, userDisplayName, webPartContext } = React.useContext(EnvContext);
  const { planRef, selectionRef, notify } = React.useContext(AppContext);

  const peopleSearchService = new SPPeopleSearchService(webPartContext);

  const parseCommentsJson = (commentsJson: string): IComment[] => {
    const result: IComment[] = parseJSON(commentsJson, []);
    return result.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());
  }

  const servies = React.useMemo(() => {

    const makeComment = (comment: IAddCommentPayload): IComment => {
      const created: IComment = {
        author: {
          oid: userObjectId,
          name: userDisplayName,
          tid,
          preferred_username: userPrincipalName
        },
        subEntityId: UrlService.makeSubEntityId(selectionRef.current),
        createdDate: new Date(),
        guid: makeGuid(),
        text: comment.text,
        mentions: comment.mentions,
      };
      return created;
    }

    const setComments = async (p: IVistoPlan, update: (comments: IComment[]) => IComment[], message: string) => {

      const retry = async () => {

        const loaded = await StorageService.get(p.siteUrl).loadPlanItem(p, ['commentsJson']);
        const loadedComments = parseCommentsJson(loaded.commentsJson);
        const updatedComments = update(loadedComments);

        const changes = ChangesService.getChanges(loaded, { commentsJson: JSON.stringify(updatedComments) }, ['commentsJson']);
        if (changes.detected) {
          const updated = await StorageService.get(p.siteUrl).updatePlanItem(loaded, changes.newValues, notify, loaded.etag);
          notify.plan({
            ...p,
            revision: updated.revision,
            commentsJson: updated.commentsJson
          });
          setTimeout(() => notify.update(), 0);
        }
      }

      try {
        return await retry();
      } catch (e) {
        if (e.status === 409) {
          await sleep(500);
          return await retry();
        } else {
          throw e;
        }
      }
    }

    const getComments = async (): Promise<IComment[]> => {
      const comments = parseCommentsJson(planRef.current?.commentsJson);
      return comments;
    }

    const getSuggestions = async (): Promise<IUserInfo[]> => {
      const assigneeSet = PlanDataService.getAssigneeSet(planRef.current);
      const allAssignees = getObjectValues<IFieldValueUser>(assigneeSet);
      return allAssignees.map(a => ({
        oid: a.guid,
        tid: null,
        name: a.title,
        preferred_username: a.userName
      }));
    }

    const getUsers = async (searchText: string): Promise<IUserInfo[]> => {
      if (searchText.length < 3) {
        const results = await getSuggestions();
        return results.filter(r => r.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || r.preferred_username.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
      } else {
        const results = await peopleSearchService.searchPeople(searchText, 10, [PrincipalType.User], null, null, false);
        return results.map(r => ({
          oid: r.id,
          tid: null,
          name: r.text,
          preferred_username: r.secondaryText
        }));
      }
    }

    const getPhotoUrl = async (id: string): Promise<string> => {
      const result = await UserInfoPhotoService.getUserPhotoUrl(id);
      return result;
    }

    return {
      makeComment,
      setComments,
      getComments,
      getSuggestions,
      getUsers,
      getPhotoUrl
    }

  }, [planRef.current]);

  return servies;
}
