import { Stack } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { InfoBar } from './InfoBar';
import { isInteractionRequiredError } from 'shared/parse';

export const PlaceholderAuthError = (props: {
  disabled: boolean;
  onConfigure: () => Promise<void>;
  authError?: any;
}) => {

  const messages = isInteractionRequiredError(!props.authError || props.authError.error) ? {
    iconText: TextService.format(strings.AuthContext_InteractionAuthError),
    description: TextService.format(strings.AuthContext_InteractionAuthErrorExplanation),
    buttonLabel: TextService.format(strings.AuthContext_InteractionAuthErrorAction)
  } : {
    iconText: TextService.format(strings.AuthContext_AuthError),
    description: TextService.format(strings.AuthContext_AuthErrorExplanation),
    buttonLabel: TextService.format(strings.AuthContext_AuthErrorAction)
  };

  return (
    <Stack grow>
      {props.authError && <InfoBar {...props.authError} />}
      <Placeholder
        iconName='LaptopSecure'
        iconText={messages.iconText}
        description={messages.description}
        buttonLabel={messages.buttonLabel}
        disableButton={props.disabled}
        onConfigure={props.onConfigure}
      />
    </Stack>
  );
}
