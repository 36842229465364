import * as React from 'react';
import strings from 'VistoWebPartStrings';
import { BasicDialog } from './BasicDialog';
import { IVistoPlan, VistoActionItem, VistoFocusItem, VistoKind } from 'sp';
import { AppContext } from 'services/AppContext';
import { ChangesService } from 'services/ChangesService';
import { Commands } from 'services/Commands';
import { IItemChanges } from 'services/Interfaces';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { trackClient } from 'services/trackClient';
import { ActionLassoDialogList } from './ActionLassoDialogList';
import { DefaultButton, Stack } from '@fluentui/react';
import { ConfirmDiscardDialog } from './ConfirmDiscardDialog';
import { PendingChanges } from './PendingChanges';

export function ActionLassoDialog(props: {
  plan: IVistoPlan,
  focus: VistoFocusItem;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('ActionLassoDialog'), []);

  const { notify, dispatchCommand } = React.useContext(AppContext);

  const allActions = PlanDataService.getItemsHaving<VistoActionItem>(
    props.plan.items, (action: VistoActionItem) => action.kind === VistoKind.Action && (!action.focusGuid || action.focusGuid === props.focus.guid));

  const defaultAllAssignedItems = allActions.filter(x => x.focusGuid === props.focus.guid);
  const [allAssignedItems, setAllAssignedItems] = React.useState(defaultAllAssignedItems);

  const defaultAllUnassignedItems = allActions.filter(x => !x.focusGuid);
  const [allUnassignedItems, setAllUnassignedItems] = React.useState(defaultAllUnassignedItems);

  const pendingChanges = [
    ...allAssignedItems.filter(item => item.focusGuid !== props.focus.guid).map(item => TextService.format(strings.PendingChange_Assigned, { title: TextService.formatTitle(item, props.plan.items) })),
    ...allUnassignedItems.filter(item => item.focusGuid).map(item => TextService.format(strings.PendingChange_Unassigned, { title: TextService.formatTitle(item, props.plan.items) }))
  ];

  const onCommit = async () => {
    const updates: IItemChanges<VistoActionItem>[] = [];
    for (const item of allAssignedItems) {
      if (item.focusGuid !== props.focus.guid) {
        let update: any = { focusGuid: props.focus.guid };
        if (item.useFocusDates) {
          const focus = PlanDataService.getItemByGuid<VistoFocusItem>(props.plan.items, props.focus.guid);
          if (focus?.startDate) {
            update.startDate = focus.startDate;
          }
          if (focus?.endDate) {
            update.endDate = focus.endDate;
          }
        }
        updates.push({ item, changes: ChangesService.getChanges(item, update) });
      }
    }
    for (const item of allUnassignedItems) {
      if (item.focusGuid) {
        updates.push({ item, changes: ChangesService.getChanges(item, { focusGuid: null }) });
      }
    }
    await dispatchCommand(Commands.makeUpdateCommand(updates, notify), { wrap: true });
  };

  const moveItems = (keys: string[],
    first: VistoActionItem[], setFirst: (items: VistoActionItem[]) => void,
    second: VistoActionItem[], setSecond: (items: VistoActionItem[]) => void,
  ) => {
    const newFirst = first.slice(0);
    const newSecond = second.slice(0);
    for (const key of keys) {
      const found = newFirst.findIndex(x => keys.indexOf(x.guid) >= 0);
      if (found >= 0) {
        newSecond.unshift(...newFirst.splice(found, 1));
      }
    }
    setFirst(newFirst);
    setSecond(newSecond);
  };

  const onUnassignActions = (keys: string[]) => {
    moveItems(keys, allAssignedItems, setAllAssignedItems, allUnassignedItems, setAllUnassignedItems);
  };

  const onAssignActions = (keys: string[]) => {
    moveItems(keys, allUnassignedItems, setAllUnassignedItems, allAssignedItems, setAllAssignedItems);
  };

  const [isConfirmCloseDialogVisible, setIsConfirmCloseDialogVisible] = React.useState(false);

  const onDismiss = (save: boolean) => {
    if (!save && pendingChanges.length > 0) {
      setIsConfirmCloseDialogVisible(true);
    } else {
      props.onDismiss();
    }
  };

  return (
    <BasicDialog onDismiss={onDismiss}
      buttonOkAction={onCommit}
      isButtonOkDisabled={!pendingChanges.length}
      buttonOkText={TextService.format(strings.ButtonSave)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.ActionLassoDialog_Title)}
      statusBarItem={<PendingChanges items={pendingChanges} />}
    >
      <Stack>
        <Stack tokens={{ childrenGap: 'm' }}>
          <ActionLassoDialogList
            today={props.plan.statusDate}
            isRowBold={(item: VistoActionItem) => item.focusGuid !== props.focus.guid}
            label={TextService.format(strings.ActionLassoDialog_AssignedListTitle, { focusName: props.focus.name, count: allAssignedItems.length })}
            allActions={allAssignedItems}
            buttonLabel={TextService.format(strings.ActionLassoDialog_ButtonUnassignTitle)}
            onButtonClick={onUnassignActions}
          />
          <ActionLassoDialogList
            today={props.plan.statusDate}
            isRowBold={(item: VistoActionItem) => !!item.focusGuid}
            label={TextService.format(strings.ActionLassoDialog_UnassignedListTitle, { focusName: props.focus.name })}
            allActions={allUnassignedItems}
            buttonLabel={TextService.format(strings.ActionLassoDialog_ButtonAssignTitle)}
            onButtonClick={onAssignActions}
            startDate={props.focus.startDate}
            endDate={props.focus.endDate}
          />
        </Stack>
        {isConfirmCloseDialogVisible && <ConfirmDiscardDialog
          onDismiss={props.onDismiss}
          onCommit={onCommit}
          pendingChangesCount={pendingChanges.length}
        />}
      </Stack>
    </BasicDialog>
  );
}
