import React from 'react';
import { Stack, Text, useTheme } from '@fluentui/react';
import { TreeView, TreeViewSelectionMode, SelectChildrenMode, TreeItemActionsDisplayMode, ITreeItem } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const CascadingTreePage = (
  props: {
    tree: ITreeItem[];

    oldSelectedKeys: string[];
    setOldSelectedKeys: (keys: string[]) => void;

    newSelectedKeys: string[];
    setNewSelectedKeys: (keys: string[]) => void;
  }
) => {

  const { palette } = useTheme();

  const onTreeItemSelect = (items: ITreeItem[]) => {
    props.setNewSelectedKeys(items.map(i => i.key));
  }

  return (
    <Stack grow style={{ maxHeight: '50vh', minHeight: 200, overflowY: 'auto' }}>
      {props.tree ? <TreeView
        items={props.tree}
        defaultExpanded={true}
        selectionMode={TreeViewSelectionMode.Multiple}
        selectChildrenMode={SelectChildrenMode.Select | SelectChildrenMode.Unselect}
        showCheckboxes={true}
        treeItemActionsDisplayMode={TreeItemActionsDisplayMode.ContextualMenu}
        defaultSelectedKeys={props.newSelectedKeys}
        onSelect={onTreeItemSelect}
      />
        : <Stack grow horizontalAlign='center' verticalAlign='center' >
          <Text style={{ color: palette.neutralTertiary }}>{TextService.format(strings.CascadingTreePage_Loading)}</Text>
        </Stack>}
    </Stack>
  )
}  
