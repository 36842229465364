import * as React from 'react';
import strings from 'VistoWebPartStrings';
import { Dropdown, Pivot, PivotItem, ResponsiveMode, Stack, Text, TextField } from '@fluentui/react';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { InfoBar, useErrorInfo } from 'components';
import { IVistoPlan } from 'sp';
import { parseJSON } from 'shared/parse';
import { PlanWizardOpenExistingOperation } from './PlanWizardTypes';
import { IWebFilePickerResult, WebFilePicker } from './WebFilePicker';
import { PlanPicker } from 'components/PlanPicker';

export function PlanWizardOpenExistingPage(props: {
  siteUrl: string;
  existingPlans: IVistoPlan[];

  openExistingOperation: PlanWizardOpenExistingOperation;
  setOpenExistingOperation(val: PlanWizardOpenExistingOperation);

  planName: string;
  setPlanName: (planName: string) => void;

  planId: string;
  setPlanId: (val: string) => void;
}) {

  const [isOpen, setIsOpen] = React.useState(false);

  const [planName, _setPlanName] = React.useState(props.planName);
  const setPlanName = (val: string) => {
    _setPlanName(val);
    props.setPlanName(val.trim());
  };

  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [isImporting, setIsImporting] = React.useState(false);

  const reloadImportedFile = async (url: string) => {
    try {
      setIsImporting(true);
      setErrorInfo(null);

      const blob = await StorageService.get(url).getFile(url);
      const content = await blob.text();
      const loadedPlan: IVistoPlan = parseJSON(content);
      setPlanName(loadedPlan.name);

      setIsImporting(false);
    } catch (error) {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.PlanWizardImport_ErrorImportingFile), error });
      setIsImporting(false);
    }
  };

  React.useEffect(() => {
    if (props.planId) {
      if (props.planId.startsWith('https:')) {
        reloadImportedFile(props.planId);
      } else {
        const found = props.existingPlans.find(x => x.planId === props.planId);
        if (found) {
          setPlanName(found.name);
        }
      }
    }
  }, [props.planId]);

  const onSave = async (val: IWebFilePickerResult) => {
    for (const result of val.results) {

      try {

        if (!result.fileAbsoluteUrl) {

          const fileConent = await result.downloadFileContent();
          const addedFile = await StorageService.get(props.siteUrl).putFile(props.siteUrl, val.selectedFolder, result.fileName, fileConent);
          result.fileAbsoluteUrl = addedFile.fileAbsoluteUrl;
          result.fileName = addedFile.fileName;
        }

        const permalink = await StorageService.get(result.fileAbsoluteUrl).makeAttachment(result.fileName, result.fileAbsoluteUrl, props.siteUrl);

        props.setPlanId(permalink.fileAbsoluteUrl);

      } catch (error) {
        setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.PlanWizardImport_ErrorImportingFile), error });
      }
    }
  };

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <InfoBar {...errorInfo} />

      <TextField
        autoComplete='off'
        required
        disabled={isImporting}
        label={TextService.format(strings.PlanWizardOpen_PlanNameLabel)}
        value={planName}
        onChange={(_, val) => setPlanName(val)}
      />

      <Pivot selectedKey={props.openExistingOperation} onLinkClick={(item) => props.setOpenExistingOperation(item.props.itemKey as PlanWizardOpenExistingOperation)}>

        <PivotItem itemKey={'SelectPlan'} headerText={TextService.format(strings.PlanWizardOpen_TabLive)}>
          <PlanPicker
            enableNull={false}
            existingPlans={props.existingPlans}
            label={TextService.format(strings.PlanWizardSelectPage_SelectExistingDescription)}
            placeholder={TextService.format(strings.PlanWizardSelectPage_SelectExistingPlaceholder)}
            planId={props.planId}
            setPlanId={props.setPlanId}
          />
        </PivotItem>

        <PivotItem itemKey={'SelectFile'} headerText={TextService.format(strings.PlanWizardOpen_TabFile)}>
          <Stack grow tokens={{ padding: 's1', childrenGap: 's1' }}>
            <Text>{TextService.format(strings.PageSelectFileView_UrlDescription)}</Text>
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 's1' }}>
              <Stack.Item grow><TextField value={props.planId} onChange={(_, val) => props.setPlanId(val as string)} /></Stack.Item>
              <WebFilePicker isOpen={isOpen} onCancel={() => setIsOpen(false)} onSave={onSave} />
            </Stack>
          </Stack>
        </PivotItem>

      </Pivot>
    </Stack>
  );
}
