import { TimelinePipe } from '@pnp/core';
import { InjectHeaders, Queryable } from '@pnp/queryable';

export function SpDefaultHeaders(metadata: boolean): TimelinePipe<Queryable> {

  return (instance: Queryable) => {

      instance
          .using(InjectHeaders({
              'Accept': metadata ? 'application/json' : 'application/json;odata=nometadata',
              'Content-Type': 'application/json;charset=utf-8',
          }));

      return instance;
  };
}
