import React from 'react';
import strings from 'VistoWebPartStrings';
import { TextService } from 'services/TextService';
import { UpgradeTreePage } from './UpgradeTreePage';
import { useUpgradeTreeService } from './useUpgradeTreeService';
import { IExecutableAction } from 'services/IExecutableAction';
import { SharePointConfigurationService } from 'services/SharePointConfigurationService';
import { IVistoPlan } from 'sp';
import { stringifyError } from 'shared/parse';
import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton, Stack } from '@fluentui/react';
import { defaultModalProps } from 'dialogs/common';
import { InfoBar, useErrorInfo } from 'components';
import { UpgradeProgressPage } from './UpgradeProgressPage';
import { NotificationType } from 'services/Notify';
import { IUpgradeCard } from './IUpgradeCard';
import { ITreeItem } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { DashboardGraphService } from 'frames/DashboardFrame/DashboardGraphService';
import { DashboardDataService } from 'services/DashboardDataService';
import { PlanDataService } from 'services/PlanDataService';
import { UrlService } from 'shared/urlService';
import { UpgradeCardSet } from './UpgradeCardSet';
import { StorageService } from 'services/StorageService';
import { StorageCacheService } from 'services/StorageCacheService';

export const BatchUpgradeWizard = (props: {
  onDismiss: () => void;
}) => {

  const [errorInfo, setErrorInfo] = useErrorInfo();

  enum BatchUpgradeWizardPage {
    config,
    progress,
  }

  interface IPlanExecutableAction extends IExecutableAction {
    plan: IVistoPlan;
  }

  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();
  const [actions, setPlanActions] = React.useState<IPlanExecutableAction[]>([]);

  const executed =
    TextService.isValidNumber(currentProgressActionIndex) && currentProgressActionIndex === actions.length;

  const [statusText, setStatusText] = React.useState('');

  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const pageInfo = {

    [BatchUpgradeWizardPage.config]: {
      subText: TextService.format(strings.BatchUpgradeWizard_StartPageSubTitle),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => selectedKeys.length === 0,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [BatchUpgradeWizardPage.progress]: {
      subText: TextService.format(strings.BatchUpgradeWizard_ProgressPageSubTitle),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => actions.length === 0 || (0 <= currentProgressActionIndex && currentProgressActionIndex < actions.length),
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<BatchUpgradeWizardPage>(BatchUpgradeWizardPage.config);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: TextService.format(strings.BatchUpgradeWizard_Title),
    subText: pageInfo[currentPageId].subText
  };

  const onWizardBack = () => {
    switch (currentPageId) {
      case BatchUpgradeWizardPage.progress:
        setCurrentPageId(BatchUpgradeWizardPage.config);
        break;
    }
  };

  const prepareActions = async () => {

    const result: IPlanExecutableAction[] = [];
    try {
      setStatusText(TextService.format(strings.BatchUpgradeWizard_GeneratingActions));

      for (const key of selectedKeys) {
        let plan = await treeService.getPlanFromKey(key);
        if (plan) {
          plan = await StorageService.get(plan.siteUrl).loadPlanItem(plan);
          const actions = await SharePointConfigurationService.generateActions(plan, false, null, msg => {
            setStatusText(msg);
          });
          for (const action of actions) {
            result.push({ ...action, plan });
          }
        }
      }

      setPlanActions(result);
    } finally {
      setStatusText(TextService.format(strings.BatchUpgradeWizard_ActionsReady, { count: result.length }));
    }
  };

  const executeActions = async () => {

    setCurrentProgressActionIndex(undefined);
    
    try {
      setStatusText(TextService.format(strings.BatchUpgradeWizard_ExecutingActions));

      let plan: IVistoPlan = undefined;
      for (let i = 0; i < actions.length; ++i) {
        const action = actions[i];
        if (plan?.planId != action.plan?.planId) {
          plan = action.plan;
        }
        setStatusText(action.title);
        setCurrentProgressActionIndex(i);
        try {
          plan = await action.execute(plan);
        } catch (error) {
          setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.BatchUpgradeWizard_ErrorExecutingActions), error });
          action.hasError = true;
          action.errorMessage = stringifyError(error);
        }
      }

      setCurrentProgressActionIndex(actions.length);
    } finally {
      StorageCacheService.resetCache();
      setStatusText(strings.BatchUpgradeWizard_Done);
    }
  };

  const onWizardNext = () => {
    switch (currentPageId) {

      case BatchUpgradeWizardPage.config:
        setCurrentPageId(BatchUpgradeWizardPage.progress);
        prepareActions();
        break;

      case BatchUpgradeWizardPage.progress:
        if (executed) {
          props.onDismiss();
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
            }
          });
        }
        break;
    }
  };

  const treeService = useUpgradeTreeService();

  const [tree, setTree] = React.useState<ITreeItem[]>();

  const sort = (a: IUpgradeCard, b: IUpgradeCard) => {
    return TextService.compareNames(a.name, b.name);
  }

  const makePlanTreeItem = (siteCards: UpgradeCardSet, item: IUpgradeCard): ITreeItem => {

    const result: ITreeItem = {
      key: item.key,
      label: `${item.name} (${PlanDataService.isPlanOutdated(item.planVersion) ? item.planVersion : 'up to date'})`,
      selectable: true,
      data: 'plan',
    }

    const childItems = DashboardGraphService.getChildItems(siteCards, item.key, sort).filter(c => !c.external);
    if (childItems.length > 0) {
      result.children = childItems.map(c => makePlanTreeItem(siteCards, c));
    }
    return result;
  }

  const makeSiteTreeItem = (siteCards: UpgradeCardSet, siteUrl: string): ITreeItem => {
    const roots = DashboardGraphService.getRootItems(siteCards, sort);
    const pathName = UrlService.getPathName(siteUrl);
    return {
      key: siteUrl,
      label: pathName.replace(/\/sites\//g, ''),
      selectable: true,
      data: 'site',
      children: roots.map(r => makePlanTreeItem(siteCards, r))
    }
  }

  const load = async () => {

    const cards: IUpgradeCard[] = await treeService.getCards();
    const siteGroups = DashboardDataService.groupCardsBySite(cards, 'visplan:');

    const treeNodes: ITreeItem[] = [];

    for (const siteUrl in siteGroups) {
      const siteCardSet = await treeService.getSiteCardSet(siteGroups, siteUrl);
      const siteNode = makeSiteTreeItem(siteCardSet, siteUrl);
      treeNodes.push(siteNode);
    }

    setTree(treeNodes);
    const selectedKeys = cards.filter(item => !!PlanDataService.isPlanOutdated(item.planVersion)).map(i => i.key);

    setSelectedKeys(selectedKeys);
  }

  React.useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      minWidth={500}
      dialogContentProps={contentProps} modalProps={{ ...defaultModalProps, isBlocking: true }} hidden={false} onDismiss={props.onDismiss}>
      <Stack style={{ minHeight: 300 }}>
        <Stack grow tokens={{ childrenGap: 'm' }}>
          <InfoBar {...errorInfo} />
          {(currentPageId == BatchUpgradeWizardPage.config)
            ? <UpgradeTreePage
              tree={tree}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
            : (currentPageId == BatchUpgradeWizardPage.progress)
              ? <UpgradeProgressPage statusText={statusText} actions={actions} currentProgressActionIndex={currentProgressActionIndex} />
              : null
          }
        </Stack>
      </Stack>
      <DialogFooter>
        <DefaultButton text={TextService.format(strings.ButtonCancel)} onClick={props.onDismiss} />
        <DefaultButton text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
        <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
      </DialogFooter>
    </Dialog>
  );
}
