import { PlannerLinkService } from 'integrations/planner/services/PlannerLinkService';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoListItemWithProgress, IVistoPlan, VistoActionItem, VistoDpItem, VistoFocusItem, VistoKind } from 'sp';
import { IIsDisabled } from './IIsDisabled';
import strings from 'VistoWebPartStrings';

export class SidebarMenuItemDisabled {

  public static isPopupOpenDisabled(isPopupOpen: boolean): IIsDisabled {
    if (isPopupOpen) {
      return {
        disabled: true,
        why: isPopupOpen
          ? TextService.format(strings.DisabledReason_PopupOpen)
          : undefined
      }
    }
  }

  public static isEditDisabled(isPlanEditEnabled?: boolean): IIsDisabled {
    if (!isPlanEditEnabled) {
      return {
        disabled: true,
        why: TextService.format(strings.DisabledReason_PlanNotEditable)
      }
    }
  }

  public static isActiveFocus(focus: VistoFocusItem) {
    if (focus.active) {
      return {
        disabled: true,
        why: TextService.format(strings.DisabledReason_IsActiveFocus)
      }
    }
  }

  public static isBreakLinkDisabled(item: IVistoListItemWithProgress, plan: IVistoPlan): IIsDisabled {
    if (!item.sourceItemUrl) {
      return {
        disabled: true,
        why: TextService.format(strings.DisabledReason_NotLinkedToSource)
      }
    }
    if (item.kind === VistoKind.Action) {
      const action = item as VistoActionItem;
      const dp = PlanDataService.getItemByGuid<VistoDpItem>(plan.items, action.dpGuid);
      if (dp && PlannerLinkService.isPlannerLink(dp.sourceItemUrl)) {
        return {
          disabled: true,
          why: TextService.format(strings.DisabledReason_PlannerAutoManagedCantBreak)
        }
      }
    }
  }

  public static isEditLinkDisabled(item: IVistoListItemWithProgress, plan: IVistoPlan): IIsDisabled {
    if (item.kind === VistoKind.Action) {
      const action = item as VistoActionItem;
      const dp = PlanDataService.getItemByGuid<VistoDpItem>(plan.items, action.dpGuid);
      if (dp && PlannerLinkService.isPlannerLink(dp.sourceItemUrl)) {
        return {
          disabled: true,
          why: TextService.format(strings.DisabledReason_PlannerAutoManagedCantEdit)
        }
      }
    }
  }

}
