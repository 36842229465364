import * as React from 'react';
import { MainFrame } from 'frames/MainFrame';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { IEnvContext } from 'services/EnvContext';
import { WebAuthService } from 'teams/services/WebAuthService';
import { PlannerConfigurationService } from 'integrations/planner/services';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { ApiService } from 'services/api/ApiService';
import { ProjectDataService } from 'integrations/project/services';
import { DevOpsDataService } from 'integrations/devops/services';
import { useSearchParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

export const TeamsPlan = () => {

  const onLoad = async (envContext: IEnvContext) => {

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => WebAuthService.getMsalToken());
  };

  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const siteUrl = searchParams.get('siteUrl');

  const isAuthenticated = useIsAuthenticated();

  return (
    <WebAuthScope key={`${siteUrl}##${planId}##${isAuthenticated}`} name='Web_TeamsPlan' kind={TokenKind.sharepoint} onLoad={onLoad} siteUrl={siteUrl} planId={planId}>
      <MainFrame />
    </WebAuthScope>
  );
};
