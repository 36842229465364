import { PlanDataService } from './PlanDataService';
import { IVistoPlan, KeyResultValueKind, VistoKeyResultValueItem, VistoKind, VistoFocusItem, VistoKeyResultItem, KeyResultType } from 'sp';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export class PlanKeyresultsService {

  public static formatKeyResultValue(val: number, kr: VistoKeyResultItem, units: boolean) {
    const text = TextService.isValidNumber(val) ? val.toLocaleString(TextService.uiLanguage, {
      maximumFractionDigits: kr.decimalPoints ?? 0,
      minimumFractionDigits: kr.decimalPoints ?? 0
    }) : '?';

    if (kr.keyResultType === KeyResultType.Binary) {
      return !!val
        ? TextService.format(strings.BinaryKeyResultValue_1)
        : TextService.format(strings.BinaryKeyResultValue_0);
    } else {
      return units ? `${text} ${kr.units}` : text;
    }
  }

  public static getDateText(x: Date) {
    return TextService.formatDate(TextService.getDate(x));
  }

  public static getKeyResultValueDateName(plan: IVistoPlan, val: Date) {
    const namedDates = PlanDataService.getFocuses(plan);
    const result = this.getDateText(val);
    return namedDates.filter(x => this.getDateText(x.endDate) === result).map(x => x.name).join(', ');
  }

  public static sortKeyResultValues(items: VistoKeyResultValueItem[]) {
    return items.sort((a, b) => TextService.compareDateTime(b.valueDate, a.valueDate));
  }

  public static sortFocuses(items: VistoFocusItem[]) {
    return items.sort((a, b) => TextService.compareDateTime(b.startDate, a.startDate));
  }

  public static getNamedDate(plan: IVistoPlan, name: string) {
    const namedDates = PlanDataService.getFocuses(plan);
    return namedDates.find(x => x.name === name);
  }

  public static getKeyResultValues(plan: IVistoPlan, krGuid: string) {
    const values = PlanDataService.getItemsHaving<VistoKeyResultValueItem>(plan.items,
      (item: VistoKeyResultValueItem) => item.kind === VistoKind.KRV && item.krGuid === krGuid);

    const targets = values.filter(item => item.valueKind === KeyResultValueKind.Target);
    const actuals = values.filter(item => item.valueKind === KeyResultValueKind.Actual);
    return {
      targets: this.sortKeyResultValues(targets),
      actuals: this.sortKeyResultValues(actuals)
    };
  }

  public static getValueAtDate(values: { valueDate: Date; value: number }[], d: Date) {
    if (values.length === 0) {
      return undefined;
    } else if (values.length === 1) {
      return values[0].value;
    } else {
      const first = values[0];
      const last = values[values.length - 1];
      if (TextService.compareDateTime(d, first.valueDate) >= 0) {
        return first.value;
      }
      if (TextService.compareDateTime(d, last.valueDate) <= 0) {
        return last.value;
      }

      const t = d.getTime();
      for (let i = 0; i < values.length - 1; ++i) {
        const t1 = values[i].valueDate.getTime();
        const v1 = values[i].value;
        const t2 = values[i + 1].valueDate.getTime();
        const v2 = values[i + 1].value;
        if (t1 <= t && t < t2) {
          return v1 + (v2 - v1) * (t - t1) / (t2 - t1);
        }
      }
    }
  }

  private static getTrend(values: { valueDate: Date; value: number }[]) {
    if (values.length > 0) {
      const point1 = this.getValueAtDate(values, values[values.length - 1].valueDate);
      const point2 = this.getValueAtDate(values, new Date(values[values.length - 1].valueDate.getTime() - 10000));
      return point1 - point2;
    } else {
      return 0;
    }
  }

  /// in case there are multiple values within the same date, average them
  public static getUniqueValues(items: { valueDate: Date; value: number }[]) {

    items = items.filter(item => item.valueDate && TextService.isValidNumber(item.value));

    if (items.length === 0) {
      return [];
    }

    const result: { valueDate: Date; value: number }[] = [];

    let valueAcc = items[0].value;
    let valueDate = items[0].valueDate.getTime();
    let duplicateCount = 1;

    for (let i = 1; i < items.length; ++i) {
      const item = items[i];
      if ((item.valueDate.getTime() - valueDate) < 1000 * 60 * 60 * 24) {
        valueAcc += item.value;
        duplicateCount += 1;
      } else {
        result.push({ value: valueAcc / duplicateCount, valueDate: new Date(valueDate) });
        valueAcc = item.value;
        valueDate = item.valueDate.getTime();
        duplicateCount = 1;
      }
    }

    result.push({ value: valueAcc / duplicateCount, valueDate: new Date(valueDate) });

    return result;
  }


  public static getKeyResultProgress(planTargets: VistoKeyResultValueItem[], planActuals: VistoKeyResultValueItem[], startDate: Date, endDate: Date, statusDate: Date) {

    const targets = this.getUniqueValues(planTargets);
    const actuals = this.getUniqueValues(planActuals);

    const startValue = this.getValueAtDate(targets, startDate);
    const endValue = this.getValueAtDate(targets, endDate);
    const referenceValue = this.getValueAtDate(targets, statusDate);

    const currentValue = this.getValueAtDate(actuals, statusDate);

    const trend = this.getTrend(actuals);

    return {
      startValue,
      endValue,
      currentValue,
      referenceValue,
      trend
    };
  }

  public static normalizedProgress(value: number, startValue: number, endValue: number, trim: boolean) {

    const haveProgress =
      TextService.isValidNumber(value) &&
      TextService.isValidNumber(endValue) &&
      TextService.isValidNumber(startValue);

    let result = haveProgress &&
      Math.round(100 * (value - startValue) / (endValue - startValue));

    if (trim && result < 0) {
      result = 0;
    }

    if (trim && result > 100) {
      result = 100;
    }

    return result;
  }
}
