import * as microsoftTeams from '@microsoft/teams-js';
import { TokenKind } from 'shared/TokenKind';
import { stringifyError } from 'shared/parse';
import { TextService } from 'services/TextService';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'services/trackClient';
import { IVistoPlan } from 'sp';

export class TeamsAuthService {

  public static async getTeamsToken(): Promise<string> {
    return await microsoftTeams.authentication.getAuthToken({
      silent: false
    });
  }

  public static makeTabSettings(host: string, plan: IVistoPlan): microsoftTeams.settings.Settings {
    const encodedPlanId = encodeURIComponent(plan.planId);
    const encodedSiteUrl = encodeURIComponent(plan.siteUrl);
    return {
      contentUrl: host + `/tab?planId=${encodedPlanId}&siteUrl=${encodedSiteUrl}&name={loginHint}&theme={theme}`,
      websiteUrl: host + `/#/teams?planId=${encodedPlanId}&siteUrl=${encodedSiteUrl}`,
      removeUrl: host + `/tab/remove?planId=${encodedPlanId}&siteUrl=${encodedSiteUrl}&name={loginHint}&theme={theme}`,
      entityId: plan.planId,
      suggestedDisplayName: plan.name,
    }
  }

  // Show the consent pop-up
  public static async getConsent(kind: TokenKind, domain: string, callback: () => Promise<any>): Promise<any> {

    // await microsoftTeams.authentication.authenticate({
    //   url: `${window.location.origin}/auth/auth-start#kind=${kind}`,
    //   width: 600,
    //   height: 535,
    // });
    // for (let attempt = 0; attempt < 15; ++attempt) {
    //   try {
    //     await callback();
    //     return;
    //   } catch (error) {
    //     trackClient.warn(`Consent check failed (attempt ${attempt} of 10)`, stringifyError(error));
    //     await new Promise(resolve => setTimeout(resolve, 2000));
    //   }
    // }
    // throw { error_description: TextService.format(strings.AuthError_Grant) };

    return new Promise((resolve, reject) => {

      microsoftTeams.authentication.authenticate({
        url: `${window.location.origin}/auth/auth-start#kind=${kind}`,
        width: 600,
        height: 535,
        successCallback: () => {
          let attempt = 0;
          const test = () => {
            callback().then((result) => {
              resolve(result);
            }).catch((error) => {
              trackClient.warn(`Consent check failed (attempt ${attempt} of 10)`, stringifyError(error));
              if (attempt < 15) {
                attempt = attempt + 1;
                setTimeout(test, 2000);
              } else {
                reject(TextService.format(strings.AuthError_Grant));
              }
            });
          };
          test();
        },
        failureCallback: (reason) => {
          reject(reason);
        }
      });
      
    });
  }
}
