import React from 'react';
import { FontSizes, Text } from '@fluentui/react';

export const ProgressStatusText = (props: {
  statusText: string;
}) => {
  const styles = {
    root: {
      fontSize: FontSizes.mediumPlus,
      minHeight: '3em'
    }
  }
  return (
    <Text variant='mediumPlus' styles={styles}>{props.statusText}</Text>
  )
}
