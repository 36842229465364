import React from 'react';
import { IconButton, Image } from '@fluentui/react'
import { IVistoListItem, IVistoPlan } from 'sp'
import { useCommentSidebarService } from './CommentSidebarService';
import { UrlService } from 'shared/urlService';
import { IComment } from './IComment';
import { AppContext } from 'services/AppContext';

const ICON_URL = require('static/assets/links/comment.svg');

export const CommentIcon = (props: {
  plan: IVistoPlan;
  item: IVistoListItem;
}) => {

  const { notify} = React.useContext(AppContext);
  const service = useCommentSidebarService();

  const [comment, setComment] = React.useState<IComment>();

  React.useEffect(() => {
    service.getComments().then((comments) => {
      const selection = UrlService.getSelectionFromItem(props.item);
      const subEntityId = UrlService.makeSubEntityId(selection);
      const found = comments.find(c => c.subEntityId == subEntityId);
      if (found) {
        setComment(found);
      }
    });
  }, [props.plan, props.item]);

  const onClick = () => {
    const subEntityId = UrlService.makeSubEntityId(UrlService.getSelectionFromItem(props.item));
    notify.comment(subEntityId);
  }

  return comment
    ? <IconButton iconProps={{ imageProps: { src: ICON_URL, width: 16, height: 16 } }} onClick={onClick} />
    : null;
}
