import { trackClient } from 'services/trackClient';
import { parseJSON } from 'shared/parse';

const DEFAULT_DASHBOARD_KEEP_MINUTES = 5;

export class StorageCacheService {

  public static getValue(key: string): any {
    try {
      const value = sessionStorage.getItem(key);
      if (value) {
        const data = parseJSON(value);
        return (data && new Date() < new Date(data.ts)) ? data.value : null;
      }
    } catch (error) {
      trackClient.error('StorageCacheService.getValue', error);
      return null;
    }
  }

  public static setValue(key: string, value: any) {
    try {
      const ts = new Date();
      ts.setMinutes(ts.getMinutes() + DEFAULT_DASHBOARD_KEEP_MINUTES);
      sessionStorage.setItem(key, JSON.stringify({ ts, value }));
    } catch (error) {
      trackClient.error('StorageCacheService.setValue', error);
    }
  }

  // can be optimized further by resetting the cache only for related lists
  // now it simply clears everything related to the current site
  public static resetCache(searchKey?: string) {
    if (!searchKey) {
      searchKey = 'https://';
    }
    const matchedKeys = new Set<string>();
    for (let i = 0; i < sessionStorage.length; ++i) {
      const key = sessionStorage.key(i);
      if (key.toLowerCase().startsWith(searchKey.toLowerCase())) {
        matchedKeys.add(key);
      }
    }
    matchedKeys.forEach(matchedKey => {
      sessionStorage.removeItem(matchedKey);
    });
  }

}
