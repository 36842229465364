import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, KeyResultType, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem, VistoKind, VistoSoItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { InfoBar, TitleBlock, useErrorInfo } from 'components';
import { FontSizes, TextField, Stack, Toggle, Text, Dropdown, IDropdownOption, DropdownMenuItemType, useTheme } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { PlanDataService } from 'services/PlanDataService';
import { ProgressService } from 'services/ProgressService';
import { TextService } from 'services/TextService';
import { trackClient } from 'services/trackClient';
import { EditKeyResultBlock } from './EditKeyResultBlock';
import { CommandsKeyResult, IKeyResultChanges } from 'services/CommandsKeyResult';
import { EditKeyResultChart } from './EditKeyResultChart';
import { ConfirmDiscardDialog } from './common/ConfirmDiscardDialog';
import { PendingChanges } from './common/PendingChanges';
import { StorageService } from 'services/StorageService';
import { KrPicker } from './common/KrPicker';
import { SoPicker } from './common/SoPicker';
import { AssigneePicker } from './common/AssigneePicker';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { NULL_KEY } from 'shared/parse';
import { LicenseService } from 'services/LicenseService';

export function EditKeyResultDialog(props: {
  plan: IVistoPlan;
  kr: VistoKeyResultItem;
  oldTargets: VistoKeyResultValueItem[];
  newTargets: VistoKeyResultValueItem[];
  onDismiss: (changed: boolean) => void;
  onCommit: (changes: IKeyResultChanges) => Promise<any>;
  isNew: boolean;
}) {

  React.useEffect(() => trackClient.page('EditKeyResultDialog'), []);

  const { isPlanEditEnabled } = React.useContext(AppContext);

  const oldKr = props.kr;
  const [newKr, setNewKr] = React.useState<VistoKeyResultItem>({ ...oldKr });

  const oldTargets = props.oldTargets;
  const [newTargets, setNewTargets] = React.useState(props.newTargets);

  const krOptions: IDropdownOption[] = [];
  for (const so of PlanDataService.getItems<VistoSoItem>(props.plan.items, VistoKind.SO)) {
    const krs = PlanDataService.getSoKeyResults(props.plan, so.guid);
    if (krs.length > 0) {
      krOptions.push({
        key: so.guid,
        text: so.name,
        itemType: DropdownMenuItemType.Header
      });
      for (const kr of krs) {
        krOptions.push({
          key: kr.guid,
          text: kr.name,
        });
      }
    }
  }

  krOptions.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.UnassignedItem)
  });

  const targetsChanges = CommandsKeyResult.getKeyResultListChanges(oldTargets, newTargets);

  const pendingChanges = [
    ...targetsChanges.added.map(a => TextService.format(strings.PendingChange_Added, { title: TextService.formatTitle(a, props.plan.items) })),
    ...targetsChanges.deleted.map(a => TextService.format(strings.PendingChange_Deleted, { title: TextService.formatTitle(a, props.plan.items) })),
    ...targetsChanges.updated.map(a => TextService.format(strings.PendingChange_Updated, { title: TextService.formatTitle(a.item, props.plan.items) })),
  ];

  const isRowBold = (item: VistoKeyResultValueItem) => {
    return targetsChanges.added.some(x => x.guid === item.guid)
      || targetsChanges.deleted.some(x => x.guid === item.guid)
      || targetsChanges.updated.some(x => x.item.guid === item.guid);
  };

  const onCommit = () => {
    return props.onCommit({ oldKr, newKr, oldTargets, newTargets })
  }

  const [isConfirmCloseDialogVisible, setIsConfirmCloseDialogVisible] = React.useState(false);

  const onDismiss = (save: boolean) => {
    if (!save && pendingChanges.length > 0 && !props.isNew) {
      setIsConfirmCloseDialogVisible(true);
    } else {
      props.onDismiss(false);
    }
  };

  const krTypeOptions = [
    { key: KeyResultType.Numeric, text: TextService.format(strings.EditKeyResultDialog_KeyResultTypeNumeric) },
    { key: KeyResultType.Binary, text: TextService.format(strings.EditKeyResultDialog_KeyResultTypeBinary) },
    { key: KeyResultType.Budget, text: TextService.format(strings.EditKeyResultDialog_KeyResultTypeBudget) }
  ];

  const onKeyResultTypeChanged = (keyResultType: KeyResultType) => {
    const newKrUpdated = { ...newKr, keyResultType };
    if (props.isNew) {
      if (newKr.keyResultType === KeyResultType.Numeric && keyResultType === KeyResultType.Budget) {
        if (newKr.units === '%' && newTargets.length === 2 && newTargets[0].value === 0 && newTargets[1].value === 100) {
          newKrUpdated.units = '$';
          setNewTargets([{ ...newTargets[0], value: 100 }, { ...newTargets[1], value: 100 }])
        }
      }
      if (newKr.keyResultType === KeyResultType.Budget && keyResultType === KeyResultType.Numeric) {
        if (newKr.units === '$' && newTargets.length === 2 && newTargets[0].value === 100 && newTargets[1].value === 100) {
          newKrUpdated.units = '%';
          setNewTargets([{ ...newTargets[0], value: 0 }, { ...newTargets[1], value: 100 }])
        }
      }
    }
    setNewKr(newKrUpdated);
  }

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);
  const indicatorsHidden = !planSettings?.showKpiIndicators;

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const localToggleDisabled = !isPlanEditEnabled || !LicenseService.license?.orchestrationEnabled;
  const localToggleDisabledTitle = LicenseService.license?.orchestrationEnabled ? undefined : TextService.format(strings.Message_FeatureDisabled, { feature: 'Orchestration' })

  return <ItemEditDialog
    onDismiss={onDismiss}
    plan={props.plan}
    oldItem={oldKr}
    newItem={newKr}
    onCommit={onCommit}
    validationIssues={!newKr.name}
    zIndex={10010}
    maxWidth='100vw'
    titles={{ update: strings.ItemEditDialog_TitleConfigure }}
    statusBarItem={<PendingChanges items={pendingChanges} />}
  >
    <InfoBar {...errorInfo} />
    <Stack horizontal tokens={{ childrenGap: 'l2' }} wrap verticalAlign='start' data-is-scrollable='true'>

      <Stack tokens={{ childrenGap: 'm' }}>
        <TitleBlock
          fontSize={FontSizes.xLarge}
          edit={isPlanEditEnabled}
          name={newKr.name}
          description={newKr.description}
          nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newKr, 'name')}
          descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newKr, 'description')}
          onNameChanged={val => setNewKr({ ...newKr, name: val })}
          onDescriptionChanged={val => newKr.description = val}
          hideAssignedTo
        />
        <Stack horizontal tokens={{ childrenGap: 's1' }}>
          <Stack.Item style={{ flex: 1 }}>
            <SoPicker
              plan={props.plan}
              disabled={!isPlanEditEnabled}
              label={TextService.format(strings.EditAssocDialog_StrategicObjectiveSelectorLabel)}
              soGuid={newKr.soGuid}
              setSoGuid={(soGuid) => setNewKr({ ...newKr, soGuid, parentKrGuid: null })}
            />
          </Stack.Item>
          <Stack.Item style={{ flex: 1 }}>
            <KrPicker
              plan={props.plan}
              disabled={!isPlanEditEnabled}
              label={TextService.format(strings.DialogLabel_ParentKeyResult)}
              soGuid={newKr.soGuid}
              disabledKrGuid={newKr.guid}
              krGuid={newKr.parentKrGuid}
              setKrGuid={(parentKrGuid) => setNewKr({ ...newKr, parentKrGuid })}
            />
          </Stack.Item>
        </Stack>

        <AssigneePicker
          label={TextService.format(strings.EditActionDialog_AssigneeLabel)}
          disabled={!isPlanEditEnabled || !ProgressService.allowEdit(newKr, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
          setValue={assignedTo => setNewKr({ ...newKr, assignedTo })}
          value={newKr.assignedTo}
        />

        <Toggle
          inlineLabel
          label={TextService.format(strings.EditKeyResultDialog_ShowOnDiagram_Label)}
          checked={newKr.showOnDiagram}
          onChange={(_, val) => setNewKr({ ...newKr, showOnDiagram: val })}
          onText={TextService.format(strings.Toggle_On)}
          offText={TextService.format(strings.Toggle_Off)}
        />
        
        <Toggle
        inlineLabel
        label={TextService.format(strings.EditKeyResultDialog_Local)}
        checked={newKr.local}
        disabled={localToggleDisabled}
        title={localToggleDisabledTitle}
        onChange={(_, val) => setNewKr({ ...newKr, local: val })}
        onText={TextService.format(strings.Toggle_On)}
        offText={TextService.format(strings.Toggle_Off)}
      />
        
        {indicatorsHidden && <Text variant='small' >{TextService.format(strings.Message_Warning_IndicatorsHidden)}</Text>}

        <Dropdown
          disabled={!isPlanEditEnabled}
          label={TextService.format(strings.EditKeyResultDialog_KeyResultTypeLabel)}
          selectedKey={newKr.keyResultType ?? KeyResultType.Numeric}
          onChange={(_, val) => onKeyResultTypeChanged(+val.key)}
          options={krTypeOptions}
        />
        <Stack horizontal tokens={{ childrenGap: 'l2' }}>
          <TextField
            min={0}
            max={5}
            type='number'
            disabled={!isPlanEditEnabled || newKr.keyResultType === KeyResultType.Binary}
            label={TextService.format(strings.EditKeyResultDialog_DecimalPoints_Label)}
            value={newKr.decimalPoints?.toString()}
            onChange={(_, val) => setNewKr({ ...newKr, decimalPoints: +val > 5 ? 5 : +val })}
          />

          <TextField
            disabled={!isPlanEditEnabled || newKr.keyResultType === KeyResultType.Binary}
            label={TextService.format(strings.EditKeyResultDialog_Units_Label)}
            value={newKr.units}
            onChange={(_, val) => setNewKr({ ...newKr, units: val })}
          />

          <Toggle
            label={TextService.format(strings.EditKeyResultDialog_Inverse_Label)}
            checked={newKr.inverse}
            disabled={!isPlanEditEnabled || newKr.keyResultType === KeyResultType.Binary}
            onChange={(_, val) => setNewKr({ ...newKr, inverse: val })}
            onText={TextService.format(strings.Toggle_On)}
            offText={TextService.format(strings.Toggle_Off)}
          />
        </Stack>
      </Stack>

      <Stack tokens={{ childrenGap: 'm' }}>

        <EditKeyResultChart plan={props.plan} kr={newKr} targets={newTargets} minHeight={150} />

        <EditKeyResultBlock
          plan={props.plan}
          kr={newKr}
          setKr={setNewKr}
          valueKind={KeyResultValueKind.Target}
          values={newTargets}
          onSetValues={setNewTargets}
          isRowBold={isRowBold}
        />
      </Stack>

    </Stack>
    {isConfirmCloseDialogVisible && <ConfirmDiscardDialog
      onDismiss={() => props.onDismiss(false)}
      onCommit={onCommit}
      pendingChangesCount={pendingChanges.length}
    />}
  </ItemEditDialog>;

}
