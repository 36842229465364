import { Choices, FieldType, IChoiceFieldDefinition, IFieldDefinition, IFieldDefinitions, ILookupFieldDefinition } from './IFieldDefinition';
import { VistoKind, IVistoListItem, IFieldValueUrl } from '.';
import { IFieldValueUser } from '.';
import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'services/trackClient';
import { SharepointUserResolver } from 'services/SharepointUserResolver';
import { parseJSON } from 'shared/parse';
import { TextService } from 'services/TextService';

export class ListItemUpdater {

  private siteTimeZoneBias: number;
  public constructor(timeZoneBias: number) {
    this.siteTimeZoneBias = timeZoneBias;
  }

  private knownItemsByKindAndId: { [kind in VistoKind]?: { [id: number]: IVistoListItem } } = {};
  private knownItemsByGuid: { [guid: string]: IVistoListItem } = {};
  private knownUsersById: { [id: number]: IFieldValueUser } = {};
  public resolvers: ((final: boolean) => Promise<any>)[] = [];

  public getKnownItemByKindAndId(kind: VistoKind, id: number) {
    return this.knownItemsByKindAndId?.[kind]?.[id];
  }

  public getKnownItemByGuid(guid: string) {
    return this.knownItemsByGuid?.[guid];
  }

  public getKnownUserById(id: string) {
    return this.knownUsersById?.[id];
  }

  public addKnownItem(item: IVistoListItem) {
    const knownItemsByKind = this.knownItemsByKindAndId[item.kind];
    if (knownItemsByKind) {
      knownItemsByKind[item.itemId] = item;
    } else {
      this.knownItemsByKindAndId[item.kind] = {
        [item.itemId]: item
      };
    }
    this.knownItemsByGuid[item.guid!] = item;
  }

  public addKnownUser(user: IFieldValueUser) {
    this.knownUsersById[user.id] = user;
  }

  /***************************************************/

  public parseChoice(spValue, choices: Choices): any {

    let found = choices.find(o => TextService.format(strings[o.stringId]) == spValue);

    // in case of language switch : 'High (1)' vs 'Hög (1)', try number patterns instead
    // the Visto enums are defined like 'High (1)', here we can figure out the value from number
    if (!found) {
      const pattern = /\d+/;
      found = choices.find(o => +pattern.exec(TextService.format(strings[o.stringId]))?.[0] === +pattern.exec(spValue)?.[0]);
    }

    return found && found.key || 0;
  }

  public async resolveAll(siteUrl: string) {

    await SharepointUserResolver.resolveUserNames(siteUrl, this.knownUsersById);

    for (let resolver of this.resolvers) {
      await resolver(true);
    }
  }

  private parseLookupId(lookupItemId, lookupKind: VistoKind, item, fieldName) {
    item[fieldName] = null;
    if (+lookupItemId) {
      const resolver = async (final: boolean) => {
        const found = this.getKnownItemByKindAndId(lookupKind, +lookupItemId);
        if (found) {
          item[fieldName] = found.guid;
        } else {
          if (final) {
            trackClient.warn(`unable to resolve lookup ${fieldName} in ${item?.name} (${lookupItemId})`);
          } else {
            this.resolvers.push(resolver);
          }
        }
      };
      resolver(false);
    }
  }

  private parseLookupValue(lookupItem, item, fieldName) {
    item[fieldName] = lookupItem?.LookupValue ?? null;
  }

  public parseDate(spValue: any): any {
    const value = spValue && new Date(spValue);
    const timeZoneAwareValue = value && new Date(value.getTime() - (this.siteTimeZoneBias - value.getTimezoneOffset()) * 60000);
    return timeZoneAwareValue;
  }

  private parseBoolean(spValue): any {
    return spValue;
  }

  private parseUrl(spValue: string): IFieldValueUrl {
    try {
      return parseJSON(spValue);
    } catch (err) {
      trackClient.warn(`unable to parse URL, ignored: ${spValue}`);
    }
  }

  public parseUserId(userIds: any[], item, fieldName): any {
    item[fieldName] = [];
    if (Array.isArray(userIds)) {
      for (const userId of userIds) {
        const resolver = async (final: boolean) => {
          const found = this.getKnownUserById(userId);
          if (found) {
            item[fieldName].push({
              id: found.id,
              title: found.title,
              userName: found.userName
            });
          } else {
            if (final) {
              trackClient.warn(`unable to resolve user ${fieldName} in ${item?.name} (${userId})`);
            } else {
              this.knownUsersById[userId] = null;
              this.resolvers.push(resolver);
            }
          }
        };
        resolver(false);
      }
    }
  }

  private parseUserValue(userValues, item, fieldName) {
    item[fieldName] = Array.isArray(userValues)
      ? userValues.map(v => ({
        id: v.LookupId,
        title: v.LookupValue,
        userName: v.Email
      }))
      : [];
  }

  public parsePercents(spValue: any) {
    return (TextService.isValidNumber(spValue) ? Math.round(spValue * 100) : null) as any;
  }

  public parseDefault(spValue: any) {
    return spValue;
  }

  public getItemSpFieldUsingFieldName<T>(field: IFieldDefinition, item: Partial<T>, spItem, fieldName: keyof T, history: boolean) {
    const spValue = spItem[field.name];
    const spValueId = spItem[field.name + 'Id'];
    if (field.type === FieldType.Lookup) {
      if (history)
        this.parseLookupValue(spValue, item, fieldName);
      else
        this.parseLookupId(spValueId, (field as ILookupFieldDefinition).lookupKind, item, fieldName);
    } else if (field.type === FieldType.User) {
      if (history)
        this.parseUserValue(spValue, item, fieldName);
      else
        this.parseUserId(spValueId, item, fieldName);
    } else if (field.type === FieldType.Choice) {
      item[fieldName] = this.parseChoice(spValue, (field as IChoiceFieldDefinition).choices);
    } else if (field.type === FieldType.DateTime) {
      item[fieldName] = this.parseDate(spValue);
    } else if (field.type === FieldType.Boolean) {
      item[fieldName] = this.parseBoolean(spValue);
    } else if (field.type === FieldType.URL) {
      item[fieldName] = this.parseUrl(spValue) as any;
    } else if (field.usePercents) {
      item[fieldName] = this.parsePercents(spValue);
    } else {
      item[fieldName] = this.parseDefault(spValue);
    }
  }

  public getItemSpField<T>(fields: IFieldDefinitions<T>, item: Partial<T>, spItem, fieldName: keyof T) {
    const field: IFieldDefinition = fields[fieldName];
    return this.getItemSpFieldUsingFieldName(field, item, spItem, fieldName, false);
  }

  /***************************************************/

  public unparseChoice(val, choices: Choices) {
    const found = choices.filter(o => o.key == +val)[0];
    return found && TextService.format(strings[found.stringId]) || null;
  }

  private unparseLookup(field: ILookupFieldDefinition, spItem, item, fieldName) {
    const guid = item[fieldName];
    const lookupItem: IVistoListItem = this.getKnownItemByGuid(guid);
    spItem[field.name + 'Id'] = lookupItem && lookupItem.itemId || null;
  }

  private unparseUser(field: IFieldDefinition, spItem, item, fieldName) {
    const itemValue: IFieldValueUser[] = item[fieldName] || [];
    spItem[field.name + 'Id'] = itemValue.map(x => x.id);
  }

  public unparseDate(val) {
    const timeZoneAwareValue = val && new Date(val.getTime() + (this.siteTimeZoneBias - val.getTimezoneOffset()) * 60000);
    return timeZoneAwareValue ?? null;
  }

  private unparseBoolean(val) {
    return val;
  }

  private unparseUrl(val: IFieldValueUrl) {
    return val ? JSON.stringify(val) : null;
  }

  private unparsePercents(val) {
    return TextService.isValidNumber(val) ? (val / 100) : null;
  }

  private unparseDefault(val) {
    return val;
  }

  public setItemSpField<T>(fields: IFieldDefinitions<T>, spItem, item: Partial<T>, fieldName: keyof T) {
    const field: IFieldDefinition = fields[fieldName];
    if (item.hasOwnProperty(fieldName)) {
      if (field.type === FieldType.Lookup)
        this.unparseLookup(field as ILookupFieldDefinition, spItem, item, fieldName);
      else if (field.type === FieldType.User)
        this.unparseUser(field, spItem, item, fieldName);
      else if (field.type === FieldType.Choice)
        spItem[field.name] = this.unparseChoice(item[fieldName], (field as IChoiceFieldDefinition).choices);
      else if (field.type === FieldType.DateTime)
        spItem[field.name] = this.unparseDate(item[fieldName]);
      else if (field.type === FieldType.Boolean)
        spItem[field.name] = this.unparseBoolean(item[fieldName]);
      else if (field.type === FieldType.URL)
        spItem[field.name] = this.unparseUrl(item[fieldName] as any);
      else if (field.usePercents)
        spItem[field.name] = this.unparsePercents(item[fieldName]);
      else
        spItem[field.name] = this.unparseDefault(item[fieldName]);
    }
  }

}
