import * as React from 'react';
import { BaseComponentContext } from 'context';

export interface IEnvContext {
  siteUrl: string;
  planId: string;
  subEntityId: string;

  teamId: string;
  teamName: string;
  channelId: string;
  channelName: string;

  defaultFolderName: string;
  defaultFolderRelativeUrl: string;
  webPartContext: BaseComponentContext;

  groupId: string;
  tid: string;
  userObjectId: string;
  userDisplayName: string;
  userPrincipalName: string;
  
  isTeams: boolean;
  isMobile: boolean;
  isPortal: boolean;
}

export const EnvContext = React.createContext<IEnvContext>(null);
