import React, { useState } from 'react';
import { DashboardDataService } from 'services/DashboardDataService';
import { EnvContext } from 'services/EnvContext';
import { IVistoPlan } from 'sp';
import { TextService } from 'services/TextService';
import { DefaultButton, Icon, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import { Callout, DirectionalHint, Link } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useEnvNavigate } from 'services/NavigationService';
import strings from 'VistoWebPartStrings';
import { AppContext } from 'services/AppContext';
import { TreeService } from 'services/TreeService';

interface IListItem {
  key: string;
  name: string;
  url?: string;
  locationName?: string;
  locationUrl?: string;
  imageUrl?: string;
  indent: number;
  bold?: boolean;
}

export const NavigationFrameButton = (props: {
  plan: IVistoPlan;
}) => {

  const [current, setCurrent] = React.useState<IListItem>({
    key: TextService.getPlanDashboardKey(props.plan.siteUrl, props.plan.planId),
    name: props.plan.name,
    locationName: TextService.format(strings.Navigation_ThisPlan),
    imageUrl: props.plan.previewPng,
    bold: true,
    indent: 1,
  });
  const [treeItems, setTreeItems] = React.useState<{ parents: IListItem[], siblings: IListItem[], children: IListItem[] }>(null);

  const envContext = React.useContext(EnvContext);
  const { notify } = React.useContext(AppContext);
  const navigate = useEnvNavigate(envContext?.isPortal);

  const onClick = (url: string, ev) => {
    ev.preventDefault();
    navigate(url);
  }

  const theme = useTheme();

  const loadPlans = async () => {

    const key = TextService.getPlanDashboardKey(props.plan.siteUrl, props.plan.planId);

    const loadedCardInfos = await DashboardDataService.getCardsFromPlanItems(envContext);

    const tree = TreeService.createItemSet(loadedCardInfos);

    const items = TreeService.splitBranchKeys(tree, key);
    const branchKeys = new Set([
      ...items.siblings.map(x => x.key),
      ...items.parents.map(x => x.key),
      ...items.children.map(x => x.key),
      key
    ]);

    const filtered = loadedCardInfos.filter(ci => branchKeys.has(ci.key));
    DashboardDataService.loadAllCardDetails(filtered, theme, notify).then(() => {
      const branchTree = TreeService.createItemSet(filtered);
      const branchItems = TreeService.splitBranchKeys(branchTree, key);

      setTreeItems({
        parents: branchItems.parents.map(x => ({ ...tree[x.key], indent: 0, bold: false })),
        siblings: branchItems.siblings.map(x => ({ ...tree[x.key], indent: 1, bold: false })),
        children: branchItems.children.map(x => ({ ...tree[x.key], indent: 2, bold: false })),
      });
    });
  }

  const buttonId = useId('callout-button');
  const [isCalloutVisible, _setIsCalloutVisible] = useState(false);
  const setIsCalloutVisible = (value: boolean) => {
    _setIsCalloutVisible(value);
    if (value) {
      updateCurrent();
    }
  }

React.useEffect(() => {
    if (isCalloutVisible) {
      loadPlans();
    }
  }, [props.plan, isCalloutVisible])

  const ListItem = (props: { item: IListItem }) => {

    const imageStyle = {
      width: 48,
      height: 36,
      border: `1px solid ${theme.palette.neutralTertiary}`
    };

    const linkStyle = {
      fontWeight: props.item.bold ? 'bold' : 'normal',
    };

    return (

      <Stack horizontal verticalAlign='center' style={{ paddingLeft: props.item.indent * 20 }} tokens={{ childrenGap: 'm' }}>
        {props.item.imageUrl && <img src={props.item.imageUrl} style={imageStyle} />}
        <Stack>
          <Link disabled={!props.item.url} style={linkStyle} onClick={(ev) => onClick(props.item.url, ev)}>{props.item.name}</Link>
          <Link disabled={!props.item.locationUrl} style={linkStyle} onClick={(ev) => onClick(props.item.locationUrl, ev)}>
            <Text variant='small'>{props.item.locationName}</Text>
          </Link>
        </Stack>
      </Stack>
    );
  }

  const updateCurrent = async () => {
    DashboardDataService.getPlanCardPreview(props.plan.drawingXml, props.plan.styleJson, theme).then(png => {
      DashboardDataService.pngToDataUrl(png).then(dataUrl => {
        setCurrent({
          key: TextService.getPlanDashboardKey(props.plan.siteUrl, props.plan.planId),
          name: props.plan.name,
          locationName: TextService.format(strings.Navigation_ThisPlan),
          locationUrl: null,
          bold: true,
          imageUrl: dataUrl,
          indent: 1,
          url: null,
        });
      })
    })
  }

  const calloutStyles = {
    root: {
      minWidth: 200,
      minHeight: 100,
    }
  };

  const iconStyle: any = {
    fontSize: 50,
    opacity: 0.25,
    position: 'absolute',
    left: 75,
    top: 25,
    animation: 'visplanLoadingIndicatorRotate 3s linear infinite'
  };

  const buttonStyle = {
    minWidth: 0,
    padding: '0 8px',
  }

  const makeListItems = (items: IListItem[]) => {
    return items
      .sort((a, b) => TextService.compareNames(a.name, b.name))
      .map(x => (<ListItem key={x.key} item={x} />))
  }

  return (
    <Stack horizontal verticalAlign='center' style={{ marginRight: 8 }}>
      <TooltipHost content={TextService.format(strings.NavigationFrame_ButtonTooltip)}>
        <DefaultButton id={buttonId} onClick={() => setIsCalloutVisible(!isCalloutVisible)} style={buttonStyle} iconProps={{ iconName: 'Org' }} />
      </TooltipHost>
      {isCalloutVisible && (
        <Callout target={`#${buttonId}`} isBeakVisible={false} onDismiss={() => setIsCalloutVisible(false)} directionalHint={DirectionalHint.bottomLeftEdge} styles={calloutStyles} >
          {treeItems
            ? <Stack tokens={{ padding: 'm', childrenGap: 's1' }}>
              <Text variant='large'>{TextService.format(strings.NavigationFrame_PanelTitle)}</Text>
              {makeListItems(treeItems.parents)}
              {makeListItems(treeItems.siblings)}
              <ListItem key={current.key} item={current} />
              {makeListItems(treeItems.children)}
            </Stack>
            : <Icon
              iconName='ProgressLoopOuter'
              style={iconStyle}
            />}
        </Callout>)}
    </Stack>
  );
};
