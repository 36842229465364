import { Stack, Icon, Image, Link, Text, useTheme } from '@fluentui/react';
import React from 'react';
import { ICheckListItem } from 'services/ICheckListItem';

export const SidebarCheckListItem = (props: {
  item: ICheckListItem;
}) => {

  const style = { textDecoration: props.item.checked ? 'line-through' : 'none' };
  const url = props.item.url;

  const iconUrl = props.item.iconUrl;
  const stateColor = props.item.stateColor;
  const stateText = props.item.stateText;

  const theme = useTheme();

  return (
    <Stack horizontal verticalAlign='baseline' tokens={{ childrenGap: 's2' }}>
      <Icon iconName={props.item.checked ? 'CheckMark' : 'RadioBullet'}></Icon>
      {iconUrl && <Image width={12} height={12} src={iconUrl} />}
      {stateColor && <Icon iconName='CircleFill' style={{ color: `#${stateColor}`, fontSize: 12 }}></Icon>}
      {url
        ? <Link href={url} style={style} target='_blank'>{props.item.title}</Link>
        : <Text style={style}>{props.item.title}</Text>}
      {stateText && <Text style={{ color: theme.palette.neutralTertiary }}>{stateText}</Text>}
    </Stack>
  );
};
