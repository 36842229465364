import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { getObjectValues } from 'shared/parse';
import { IVistoListItem, IVistoPlan, VistoActionItem, VistoAssocItem, VistoDpItem, VistoKeyResultItem, VistoKind } from 'sp';
import strings from 'VistoWebPartStrings';
import { SidebarActionListGroup } from './SidebarActionListGroup';
import { SidebarKeyResultList } from './SidebarKeyResultList';

const getKrs = (plan: IVistoPlan, item: IVistoListItem) => {
  switch (item.kind) {
    case VistoKind.DP:
      return getObjectValues(PlanDataService.getDpKeyResultSet(plan, item.guid))
        .map(x => x.kr)
        .sort((a, b) => TextService.compareNames(a.name, b.name));
    case VistoKind.SO:
      return PlanDataService.getSoKeyResults(plan, item.guid);
    default:
      return [];
  }
}

export const SidebarKeyResultActionList = (props: {
  plan: IVistoPlan;
  item: IVistoListItem;
  includeActions: boolean;
}) => {

  const krs = getKrs(props.plan, props.item);
  const dp = props.item.kind === VistoKind.DP ? props.item as VistoDpItem : undefined;

  const itemSet = new Set(krs.map(x => x.guid));
  const rootSet = new Set(itemSet);
  for (const kr of krs) {
    if (itemSet.has(kr.parentKrGuid)) {
      rootSet.delete(kr.guid);
    }
  }

  const processedActionSet = new Set(PlanDataService.getItemsHaving<VistoAssocItem>(props.plan.items,
    a => a.kind === VistoKind.Assoc && itemSet.has(a.krGuid)).map(a => a.actionGuid));

  const noKeyResultActions = PlanDataService.getItemsHaving<VistoActionItem>(props.plan.items,
    a => a.kind === VistoKind.Action && a.dpGuid === dp?.guid && !processedActionSet.has(a.guid));

  const roots = krs
    .filter(kr => rootSet.has(kr.guid))
    .filter(kr => !kr.local || kr.originalPlanId === props.plan.planId);

  return <>
    <SidebarKeyResultList includeActions={props.includeActions} plan={props.plan} items={krs} roots={roots} dp={dp} />
    <SidebarActionListGroup
      plan={props.plan}
      actions={noKeyResultActions}
      expandKey={'NO_KEY_RESULT'}
      header={TextService.format(strings.SidebarGroup_KpiNoneTitle, { count: noKeyResultActions.length })}
    />
  </>;
};
