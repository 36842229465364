import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, Image, ImageCoverStyle, ImageFit, useTheme } from '@fluentui/react';
import * as React from 'react';

import { IImageInfo } from './IImageInfo';
import { TextService } from 'services/TextService';
import { trackClient } from 'services/trackClient';
import { ImagePicker } from './ImagePicker';
import { ImageResizeTypeDropdown } from './ImageResizeTypeDropdown';
import { ImageOpacitySlider } from './ImageOpacitySlider';
import { ImageCacheService } from 'services/ImageCacheService';
import { AppContext } from 'services/AppContext';
import { mxgraph } from 'ts-mxgraph-typings';
import { ColorComboBox } from 'frames/TopFrame/ribbon/ColorComboBox';
import { FixedViewType } from '../common';

export function BackgroundDialog(props: {
  drop: IImageInfo;
  onDismiss: (changed: boolean, imageInfo: IImageInfo) => void;
}) {

  React.useEffect(() => trackClient.page('BackgroundDialog'), []);

  const [imageInfo, setImageInfo] = React.useState(props.drop);

  const { planRef, editorUiRef } = React.useContext(AppContext);
  const theme = useTheme();

  const [isPickerOpen, setIsPickerOpen] = React.useState(false);

  const graph: mxgraph.mxGraph = editorUiRef.current.editor.graph;

  const previewWidth = 300;
  const previewHeight = previewWidth * (graph.pageFormat.height ?? 225) / (graph.pageFormat.width ?? 300);

  const getPreview = async () => {
    const svg = await ImageCacheService.getPreviewSvg({
      embedImages: false,
      drawingXml: planRef.current.drawingXml,
      styleJson: planRef.current.styleJson,
      theme: theme,
      width: previewWidth,
      height: previewHeight,
      background: imageInfo
    });

    return (
      <div style={{ width: previewWidth, height: previewHeight }} dangerouslySetInnerHTML={{ __html: svg }} />
    );
  };

  return (
    <Dialog hidden={false} minWidth={350} dialogContentProps={{ title: TextService.format(strings.BackgroundDialog_Title), type: DialogType.normal, }} onDismiss={() => props.onDismiss(false, props.drop)} >
      <Stack tokens={{ childrenGap: 's1 0' }}>
        <ImagePicker
          imageSrc={imageInfo}
          setImageSrc={val => setImageInfo({ ...imageInfo, src: val.src })}
          opacity={100}
          backgroundColor={null}
          resizeType={FixedViewType.None}
          isPickerOpen={isPickerOpen}
          setIsPickerOpen={setIsPickerOpen}
          getPreview={getPreview}
          previewWidth={previewWidth}
          previewHeight={previewHeight}
        />
        <ImageOpacitySlider
          disabled={!imageInfo.src}
          opacity={imageInfo.opacity}
          setOpacity={opacity => setImageInfo({ ...imageInfo, opacity })}
        />
        <ImageResizeTypeDropdown
          disabled={!imageInfo.src}
          resizeType={imageInfo.resizeType}
          setResizeType={resizeType => setImageInfo({ ...imageInfo, resizeType })}
        />
        <ColorComboBox
          text={TextService.format(strings.BackgroundDialog_ColorButtonText)}
          buttonColor={imageInfo.backgroundColor}
          iconName='BackgroundColor'
          title={TextService.format(strings.BackgroundDialog_ColorButtonTitle)}
          color={imageInfo.backgroundColor}
          setColor={backgroundColor => setImageInfo({ ...imageInfo, backgroundColor })}
          extraItems={null}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton disabled={!imageInfo} onClick={() => props.onDismiss(true, imageInfo)} text={TextService.format(strings.ButtonOK)} />
        <DefaultButton onClick={() => props.onDismiss(false, props.drop)} text={TextService.format(strings.ButtonCancel)} />
      </DialogFooter>
    </Dialog>
  );
}
