import * as React from 'react';
import { Panel, PanelType, IPanelStyles, useTheme } from '@fluentui/react';
import { SidebarTopMenu } from './SidebarTopMenu';
import { AppContext } from 'services/AppContext';
import { Advisory } from 'components/Advisory';
import { ISidebarProps } from './ISidebarProps';
import { EnvContext } from 'services/EnvContext';

export function SidebarBase(props: ISidebarProps) {

  const theme = useTheme();

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const { isMobile, isPortal } = React.useContext(EnvContext);

  const sidebarStyles = {
    main: {
      animation: 'none',
      marginTop: isPortal ? 98 : 50,
      borderTopLeftRadius: 4,
      '@media (min-width: 1920px)': { // use default on HD screens
        width: isMobile ? '380px' : '640px'
      },
      '@media (min-width: 1366px)': { // use default on HD screens
        width: isMobile ? '380px' : '640px'
      },
      '@media (min-width: 1024px)': { // reduce sidebar size on default laptop
        width: isMobile ? '380px' : '550px'
      },
      '@media (min-width: 640px)': { // reduce sidebar size on default laptop
        width: '380px'
      },
      width: '95%',
    },
    closeButton: {
      maxHeight: 40,
      backgroundColor: theme.palette.white,
      '&:hover': {
        backgroundColor: theme.palette.neutralLight,
      },
      '&:active': {
        backgroundColor: theme.palette.neutralPrimary
      }
    }
  };

  const divStyle: React.CSSProperties = {
    backgroundColor: theme.palette.neutralLighter,
    boxShadow: theme.effects.elevation4,
    marginTop: 16,
  };

  return <Panel
    type={PanelType.medium}
    isBlocking={!!props.isBlocking}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    styles={sidebarStyles}
    focusTrapZoneProps={{ disabled: true }}
    layerProps={{ 
      eventBubblingEnabled: true,
      styles: { root: { zIndex: 999 } } 
    }}
    onRenderNavigationContent={(panelProps, defaultRender) =>
      <SidebarTopMenu
        menuItems={props.menuItems}
        overflowMenuItems={props.overflowMenuItems}
        farItems={props.farItems}
        closeButton={defaultRender(panelProps)} />
    }
  >
    {props.content}
    {propertyBag.showAdvisory && props.advisoryTopicKey && <Advisory isSidebar style={divStyle} topic={props.advisoryTopicKey} />}
  </Panel>;
}
