import * as React from 'react';
import { TokenKind } from 'shared/TokenKind';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { IEnvContext } from 'services/EnvContext';
import { DashboardFrame } from 'frames/DashboardFrame/DashboardFrame';
import { UrlService } from 'shared/urlService';
import { navigateTo } from 'services/NavigationService';
import { DevOpsDataService } from 'integrations/devops';
import { PlannerConfigurationService } from 'integrations/planner';
import { ProjectDataService } from 'integrations/project';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { ApiService } from 'services/api/ApiService';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { MainFrame } from 'frames/MainFrame';
import { env } from 'shared/clientEnv';
import { IVistoPlan } from 'sp';
import { PlanWizard } from 'wizard/PlanWizard';

export const VistoTeamsTabPersonal = () => {

  const contextRef = React.useRef<IEnvContext>();
  const [plan, setPlan] = React.useState<IVistoPlan>();
  const [isWizardOpen, setIsWizardOpen] = React.useState(false);

  const onLoad = async (envContext: IEnvContext) => {

    contextRef.current = envContext;

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => TeamsAuthService.getTeamsToken());

    const plan = UrlService.getPlanFromSubEntityId(envContext.subEntityId);
    if (plan && plan.planId && plan.planId !== env.getTemplateParamValue('PERSONAL_TAB_ID')) {
      setPlan(plan);
    }
  };

  const onAddNew = () => {
    if (contextRef.current?.isTeams) {
      navigateTo(UrlService.makeAppDeepLink(contextRef.current));
    } else {
      setIsWizardOpen(true);
    }
  };

  const onWizardFinished = (changed: boolean, plan: IVistoPlan, ctx: IEnvContext) => {
    if (changed) {
      contextRef.current = ctx;
      setPlan(plan);
    }
    setIsWizardOpen(false);
  };

  return (
    <TeamsAuthScope key={`${plan?.siteUrl}##${plan?.planId}`} name='Teams_Tab' kind={TokenKind.dashboard} onLoad={onLoad} siteUrl={plan?.siteUrl} planId={plan?.planId}>
      {plan
        ? <MainFrame />
        : <DashboardFrame onAddNew={onAddNew} />
      }
      {isWizardOpen && <PlanWizard
        isPopup
        onDismiss={onWizardFinished}
        trigger='TeamsConfigTab'
      />}
    </TeamsAuthScope>
  );
};
