import { DashboardDataService } from 'services/DashboardDataService';
import { CascadingCardSet } from './CascadingCardSet';
import { TreeService } from 'services/TreeService';
import { StorageService } from 'services/StorageService';
import { IVistoPlan } from 'sp';
import { ICascadingCard } from './ICascadingCard';
import { IEnvContext } from 'services/EnvContext';

export class CascadingTreeService {

  static async getSiteCardSet (siteGroups: any, siteUrl: string) {

    const siteCards = siteGroups[siteUrl];
    await DashboardDataService.loadSitePlanProps(siteUrl, siteCards, ['name', 'planId', 'planVersion', 'topPlanId'], null);

    const siteCardSet = {}
    for (const key in siteCards) {
      const siteCard = siteCards[key];
      siteCardSet[siteCard.key] = siteCard;
    }
    return siteCardSet;
  }

  static getTopPlanId (siteCards: CascadingCardSet, key: string) {

    const parentSet = TreeService.getParentSet(siteCards, key);
    for (const parentKey in parentSet) {
      const parent = siteCards[parentKey];
      if (parent.topPlanId) {
        return parent.topPlanId;
      } else {
        const grandParentId = CascadingTreeService.getTopPlanId(siteCards, parent.key);
        if (grandParentId)
          return grandParentId;
        else
          return parent.planId;
      }
    }
  }

  static async getPlanFromKey (key: string): Promise<IVistoPlan> {
    const re = new RegExp(`^visplan:(.+)##(.*)`);
    const match = re.exec(key);
    if (match) {
      const siteUrl = match[1];
      const planId = match[2];
      const plan = await StorageService.get(siteUrl).loadPlanItem({ siteUrl, planId }, ['name', 'planId', 'topPlanId']);
      return plan;
    }
  }

  static async getCards (envContext: IEnvContext): Promise<ICascadingCard[]> {
    return await DashboardDataService.getCardsFromPlanItems(envContext);
  }
}
