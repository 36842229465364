import * as React from 'react';
import strings from 'VistoWebPartStrings';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { PlanWizard } from 'wizard';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { Stack } from '@fluentui/react';
import { TokenKind } from 'shared/TokenKind';
import { TextService } from 'services/TextService';
import { IEnvContext } from 'services/EnvContext';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { AuthService } from 'services/AuthService';
import { ApiService } from 'services/api/ApiService';
import { LicenseService } from 'services/LicenseService';
import { stringifyError } from 'shared/parse';
import { IVistoPlan } from 'sp';
import { env } from 'shared/clientEnv';

/**
 * Implementation of VistoTeams Tab configuration page
 */
export const VistoTeamsTabConfig = () => {

  AuthService.resetAuth(TokenKind.sharepoint);

  const planRef = React.useRef<IVistoPlan>();
  const [isWizardOpen, setIsWizardOpen] = React.useState(false);

  const onLoad = async (ctx: IEnvContext) => {

    const onSaveHandler = async (saveEvent: microsoftTeams.settings.SaveEvent) => {
      const host = 'https://' + window.location.host;
      const settings = TeamsAuthService.makeTabSettings(host, planRef.current);
      try {
        await microsoftTeams.pages.config.setConfig(settings);
        saveEvent.notifySuccess();
      } catch (err) {
        saveEvent.notifyFailure(stringifyError(err));
      };
    };

    ApiService.configure(() => TeamsAuthService.getTeamsToken());
    const license = await ApiService.getLicense();
    LicenseService.setLicense(license);

    TextService.setUiLanguage(ctx.webPartContext.pageContext.cultureInfo.currentCultureName);

    // existing plan
    if (!ctx.planId) {
      microsoftTeams.pages.config.registerOnSaveHandler(onSaveHandler);
      setIsWizardOpen(true);
    }
  };

  const onWizardFinished = (changed: boolean, plan: IVistoPlan, ctx: IEnvContext) => {
    if (changed) {
      planRef.current = plan;
    }
    setIsWizardOpen(false);
    microsoftTeams.pages.config.setValidityState(!!plan);
  };

  const siteUrl = env.getParamFromUrl('siteUrl');
  const planId = env.getParamFromUrl('planId');

  return (
    <TeamsAuthScope key={`${siteUrl}##${planId}`} name='Teams_Config' kind={TokenKind.sharepoint} onLoad={onLoad} siteUrl={siteUrl} planId={planId}>
      {isWizardOpen ?
        <PlanWizard
          isPopup={false}
          onDismiss={onWizardFinished}
          trigger='TeamsConfigTab'
        />
        : <Stack grow>
          <Placeholder iconName='CheckMark' iconText={TextService.format(strings.TabConfig_ConfiguredIconText)} description={TextService.format(strings.TabConfig_Ready)} hideButton />
        </Stack>

      }
    </TeamsAuthScope>
  );
};
