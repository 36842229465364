import * as strings from 'VistoWebPartStrings';
import * as React from 'react';

import { IVistoPlan, VistoSoItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';
import { FontSizes, Stack, Toggle } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { ProgressService } from 'services/ProgressService';
import { trackClient } from 'services/trackClient';
import { TextService } from 'services/TextService';
import { StorageService } from 'services/StorageService';
import { LicenseService } from 'services/LicenseService';

export function EditSoDialog(props: {
  plan: IVistoPlan;
  so: VistoSoItem;
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('EditSoDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldSo = props.so;
  const [newSo, setNewSo] = React.useState<VistoSoItem>({ ...oldSo });

  const localToggleDisabled = !isPlanEditEnabled || !LicenseService.license?.orchestrationEnabled;
  const localToggleDisabledTitle = LicenseService.license?.orchestrationEnabled ? undefined : TextService.format(strings.Message_FeatureDisabled, { feature: 'Orchestration' })

  return <ItemEditDialog
    onDismiss={props.onDismiss}
    onLoaded={loaded => setNewSo({ ...newSo, ...loaded })}
    plan={props.plan}
    oldItem={oldSo}
    newItem={newSo}
    onCommit={() => dispatchCommand(Commands.makeSaveCommand(oldSo, newSo, notify), { wrap: false })}
    validationIssues={!newSo.name}
  >
    <Stack tokens={{ childrenGap: 's1' }}>
      <TitleBlock
        fontSize={FontSizes.xLarge}
        edit={isPlanEditEnabled}
        name={newSo.name}
        description={newSo.description}
        nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newSo, 'name')}
        descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newSo, 'description')}
        onNameChanged={val => setNewSo({ ...newSo, name: val })}
        onDescriptionChanged={val => newSo.description = val}
        assignedToDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newSo, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
        onAssignedToChanged={assignedTo => setNewSo({ ...newSo, assignedTo: assignedTo })}
        assignedTo={newSo.assignedTo}
      />
      <Toggle
        inlineLabel
        label={TextService.format(strings.EditSoDialog_Local)}
        checked={newSo.local}
        disabled={localToggleDisabled}
        title={localToggleDisabledTitle}
        onChange={(_, val) => setNewSo({ ...newSo, local: val })}
        onText={TextService.format(strings.Toggle_On)}
        offText={TextService.format(strings.Toggle_Off)}
      />
    </Stack>
  </ItemEditDialog>;

}
