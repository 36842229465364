import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { ActionButton, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';

import { getListDefinition, IVistoListItemWithProgress, IVistoPlan, VistoKind } from 'sp';
import { ProgressService } from 'services/ProgressService';
import { IProgressData } from 'services/IProgressData';
import { SharepointService } from 'services/SharepointService';
import { TextService } from 'services/TextService';
import { AssigneeBlockBase } from './AssigneeBlockBase';
import { HistoryDialog } from './HistoryDialog';
import { PropertyBagService } from 'services/PropertyBagService';
import { SimpleProgressBar } from './SimpleProgressBar';
import { RichTextTooltipHost } from 'components';
import { ProgressBarTooltip } from './ProgressBarTooltip';
import { ProgressBubble } from './ProgressBubble';
import { ProgressBlockDates } from './ProgressBlockDates';
import { AppContext } from 'services/AppContext';

export function ProgressBlockBase(props: {
  plan: IVistoPlan;
  item: IVistoListItemWithProgress;
  showProgress: boolean;
  showAssignees?: boolean;
  isMobile?: boolean;
  showHistory?: boolean;
  historyDate?: Date;
}) {

  const { palette } = useTheme();

  const progress: IVistoListItemWithProgress = props.item;

  const haveProgress = props.showProgress && (progress.kind !== VistoKind.Focus) && (
    !!progress?.startDate || 
    !!progress?.endDate || 
    TextService.isValidNumber(progress.percentComplete) || 
    TextService.isValidNumber(progress.plannedPercentComplete)
  );
  
  const haveAssignees = props.showAssignees && (progress?.assignedTo?.length > 0);

  const [historicalProgress, setHistoricalProgress] = React.useState<IProgressData>(null);

  const { isPlanLive, isPlanLocal } = React.useContext(AppContext);
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const historyDate = props.historyDate ?? PropertyBagService.defaultPropertyBag.historyDate;
  const haveHistory = !isHistoryDisabled && props.showHistory && !!progress.itemId && getListDefinition(progress.kind).hasProgress;

  React.useEffect(() => {
    if (haveHistory) {
      SharepointService.getItemVersions(props.plan, progress).then(versions => {
        const progressAtDate = ProgressService.getProgressAt(versions, historyDate);
        setHistoricalProgress(progressAtDate);
      });
    }
  }, [props.item, props.plan, haveHistory, historyDate]);

  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);

  const onClick = () => {
  };

  const rows = [
    { label: TextService.format(strings.SimpleProgressBar_Reference), value: `${progress.plannedPercentComplete ?? '?'}%` },
    { label: TextService.format(strings.SimpleProgressBar_Actual), value: `${progress.percentComplete ?? '?'}%` },
  ];

  const haveSomething = haveAssignees || haveProgress || haveHistory;

  return (haveSomething &&
    <Stack>
      {haveAssignees && <AssigneeBlockBase assignedTo={props.item.assignedTo} hideDetails={props.item.assignedTo.length > 1} />}
      {haveProgress && <Stack horizontal verticalAlign='end'>
          <ProgressBlockDates startDate={progress?.startDate} endDate={progress?.endDate} />
          <RichTextTooltipHost tooltipFooter={<ProgressBarTooltip rows={rows} />}>
            <Stack horizontal verticalAlign='end'>
              <SimpleProgressBar
                onClick={onClick}
                color={palette.neutralTertiaryAlt}
                value={progress.percentComplete}
                reference={progress.plannedPercentComplete}
                valueText={`${progress.percentComplete ?? '? '}%`}
                start={'0'} end={'100'} />
              <ProgressBubble size={20} plan={props.plan} progress={progress} />
            </Stack>
          </RichTextTooltipHost>
      </Stack>}
      {haveHistory &&
        <Stack horizontal verticalAlign='center' style={{ backgroundColor: palette.neutralLight, minHeight: 40, paddingRight: 8 }}>
          {historicalProgress ?
            <Stack tokens={{ padding: 's2' }} horizontal verticalAlign='center'>
              <ProgressBlockDates startDate={historicalProgress.startDate} endDate={historicalProgress.endDate} />
              <SimpleProgressBar
                color={palette.neutralTertiaryAlt}
                value={historicalProgress.percentComplete}
                reference={historicalProgress.plannedPercentComplete}
                valueText={`${historicalProgress.percentComplete ?? '? '}%`}
                start={'0'} end={'100'} />
              <ProgressBubble size={20} plan={props.plan} progress={historicalProgress} />
            </Stack>
            :
            <Stack tokens={{ padding: '0 s1 0 s2' }}>
              {TextService.format(strings.HistoryBlock_ReadingHistory)}
            </Stack>
          }
          <Stack.Item>
            {historicalProgress
              ? <ActionButton onClick={() => setHistoryDialogVisible(true)} iconProps={{ iconName: 'History' }} >{props.isMobile ? TextService.formatDate(historyDate) : ''}</ActionButton>
              : <Spinner size={SpinnerSize.medium} />
            }
          </Stack.Item>
        </Stack>
      }
      {historyDialogVisible && <HistoryDialog item={props.item} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
    </Stack>
  );
}
