import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { CommentItem } from './CommentItem';
import { IDialogContentStyles } from '@fluentui/react/lib/Dialog';
import strings from 'ControlStrings';
import { ICommentCard } from '../IComment';
import { TextService } from 'services/TextService';
import { ButtonCancel, ButtonOK } from 'VistoWebPartStrings';

export const ConfirmDelete = (props: {
  selectedComment: ICommentCard;
  onDismiss: (deleteComment: boolean) => void;
}) => {
  const { onDismiss, selectedComment: selectedCard } = props;
  const stylesSubText: Partial<IDialogContentStyles> = {
    subText: { fontWeight: 600 },
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };
  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: strings.CommentsDialogDeleteTitle,
    styles: stylesSubText,
    subText: strings.CommentDIalogDeleteSubText,
  };
  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={() => onDismiss(false)}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        <CommentItem card={selectedCard} />
        <DialogFooter>
          <PrimaryButton onClick={() => onDismiss(true)} text={TextService.format(ButtonOK)} />
          <DefaultButton onClick={() => onDismiss(false)} text={TextService.format(ButtonCancel)} />
        </DialogFooter>
      </Dialog>
    </>
  );
};
