import { Stack, Toggle } from '@fluentui/react';
import React from 'react';
import { IPlanExportFilter } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';
import { ExportPlanFilterItems } from './ExportPlanFilterItems';

export const ExportPlanFilterPage = (props: {
  plan: IVistoPlan,
  filter: IPlanExportFilter;
  setFilter: (filter: IPlanExportFilter) => void;
}) => {
  return (
    <Stack tokens={{ childrenGap: 's2' }}>
      <Toggle
        inlineLabel
        label={TextService.format(strings.ExportPlanFilterPage_RemoveExistingLinks)}
        checked={props.filter.removeLinks}
        onChange={() => props.setFilter({ ...props.filter, removeLinks: !props.filter.removeLinks })}
      />
      <Toggle
        inlineLabel
        label={TextService.format(strings.ExportPlanFilterPage_RemoveAssignees)}
        checked={props.filter.removeAssignees}
        onChange={() => props.setFilter({ ...props.filter, removeAssignees: !props.filter.removeAssignees })}
      />
      <Toggle
        inlineLabel
        label={TextService.format(strings.ExportPlanFilterPage_RemoveComments)}
        checked={props.filter.removeComments}
        onChange={() => props.setFilter({ ...props.filter, removeComments: !props.filter.removeComments })}
      />
      <Toggle
        inlineLabel
        label={TextService.format(strings.ExportPlanFilterPage_FilterItems)}
        checked={props.filter.filterItems}
        onChange={() => props.setFilter({ ...props.filter, filterItems: !props.filter.filterItems })}
      />
      <ExportPlanFilterItems
        plan={props.plan}
        disabled={!props.filter.filterItems}
        selectedKeys={props.filter.selectedKeys}
        setSelectedKeys={(vals) => props.setFilter({ ...props.filter, selectedKeys: vals })}
      />
    </Stack>
  );
};
