import * as React from 'react';
import { BasicDialog } from './BasicDialog';
import { IPlanItems, IVistoListItem, IVistoPlan, VistoKind } from 'sp';
import { ITreeItem, TreeItemActionsDisplayMode, TreeView } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { PlanDataService } from 'services/PlanDataService';
import { IntegrationService } from 'services/IntegrationService';
import { TextService } from 'services/TextService';
import { Stack, Text, Link, Icon, useTheme } from '@fluentui/react';

import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'services/trackClient';

export function ConfirmDeleteDialog(props: {
  planItems: IPlanItems,
  onDismiss: () => void;
  onDelete: (items: IVistoListItem[]) => Promise<IVistoPlan>;
  items: IVistoListItem[];
}) {

  React.useEffect(() => trackClient.page('ConfirmDeleteDialog'), []);

  const items = PlanDataService.flattenItems(PlanDataService.chunkItems(props.items, { getItem: x => x, groupByKind: true }));

  const treeitems: ITreeItem[] = [];

  const findParent = (children: ITreeItem[], keys: string[]): ITreeItem => {
    for (const child of children) {
      for (const key of keys) {
        if (key && child.key == key) {
          return child;
        }
      }
      if (child.children) {
        const childFound = findParent(child.children, keys);
        if (childFound) {
          return childFound;
        }
      }
    }
  };

  const copyKind = (kind: VistoKind, parentFieldNames?: string[]) => {
    for (const item of items) {
      if (item.kind === kind) {

        const treeitem: ITreeItem = {
          key: item.guid,
          label: TextService.formatTitle(item, props.planItems),
          data: item
        };

        const parent = findParent(treeitems, parentFieldNames?.map(k => item[k]) ?? []);
        if (parent) {
          if (parent.children) {
            parent.children.push(treeitem);
          } else {
            parent.children = [treeitem];
          }
          continue;
        }
        treeitems.push(treeitem);
      }
    }
  };

  copyKind(VistoKind.SO);
  copyKind(VistoKind.Focus);
  copyKind(VistoKind.LOP);
  copyKind(VistoKind.DP, ['lopGuid']);
  copyKind(VistoKind.Action, ['dpGuid', 'focusGuid', 'effectGuid']);
  copyKind(VistoKind.Assoc, ['actionGuid', 'soGuid', 'krGuid', 'krGuid']);
  copyKind(VistoKind.KeyResult, ['soGuid', 'parentKrGuid']);
  copyKind(VistoKind.Effect, ['dpGuid']);
  copyKind(VistoKind.KRV, ['krGuid']);

  const onRenderItem = (item: ITreeItem) => {
    return (
      <Text>{item.label}{item.data?.sourceItemUrl && <Link href={item.data.sourceItemUrl} target={item.data.guid}>&nbsp;<Icon iconName='Relationship' /></Link>}</Text>
    );
  };

  const theme = useTheme();

  const hooks = Object
    .keys(IntegrationService.hooks)
    .map(key => ({ key, message: IntegrationService.hooks[key].removalWarning(items) }))
    .filter(h => h.message);

  return (
    <BasicDialog onDismiss={props.onDismiss}
      buttonOkAction={() => props.onDelete(props.items)}
      buttonOkText={TextService.format(strings.ConfirmDeleteDialog_ButtonOkLabel)}
      buttonOkBusyText={TextService.format(strings.ConfirmDeleteDialog_ButtonOkBusyLabel)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.ConfirmDeleteDialog_Title)}
      subText={TextService.format(strings.ConfirmDeleteDialog_SubText)}
      >
        <Stack tokens={{ childrenGap: 'l2' }}>
          <TreeView
            items={treeitems}
            defaultExpanded={true}
            showCheckboxes={false}
            treeItemActionsDisplayMode={TreeItemActionsDisplayMode.ContextualMenu}
            expandToSelected={true}
            defaultExpandedChildren={true}
            onRenderItem={onRenderItem}
          />
          <Stack tokens={{ padding: '0 s1', childrenGap: 'm' }}>
            {hooks.map(h => <Text key={h.key} style={{ color: theme.palette.neutralTertiary }} >
              <span dangerouslySetInnerHTML={{ __html: h.message }} />
            </Text>)}
          </Stack>
        </Stack>
    </BasicDialog>
  );
}
