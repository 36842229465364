import { VistoKind, addListDefinition, IFieldDefinitions, ListItemUpdater, Choices, IVistoListItemWithAssignee } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export enum KeyResultType {
  Numeric = 0,
  Binary = 1,
  Budget = 2,
};

const keyResultTypes: Choices = [
  { key: KeyResultType.Numeric, stringId: 'KeyResultType_Numeric' },
  { key: KeyResultType.Binary, stringId: 'KeyResultType_Binary' },
  { key: KeyResultType.Budget, stringId: 'KeyResultType_Budget' },
];

export interface VistoKeyResultItem extends IVistoListItemWithAssignee {
  readonly soGuid?: string;
  readonly parentKrGuid?: string;
  readonly units?: string;
  readonly decimalPoints?: number;
  readonly showOnDiagram?: boolean;
  readonly inverse?: boolean;
  readonly keyResultType: number;

  readonly excelActuals?: string;
  readonly excelTargets?: string;

  readonly originalPlanId: string;
  readonly local?: boolean;
}

export const VistoKeyResultListFields: IFieldDefinitions<VistoKeyResultItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_KR_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_KR_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_KR_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_KR_Description'),
  links: FieldGenerator.makeLinksField('VISTO_KR_Links'),
  soGuid: FieldGenerator.makeLookupField('VISTO_KR_SO', 'SO', true, VistoKind.SO, false),
  parentKrGuid: FieldGenerator.makeLookupField('VISTO_KR_KR', 'KR', true, VistoKind.KeyResult, false),
  units: FieldGenerator.makeTextField('VISTO_KR_Units', 'Units', true),
  showOnDiagram: FieldGenerator.makeBooleanField('VISTO_KR_ShowOnDiagram', 'ShowOnDiagram', true),
  decimalPoints: FieldGenerator.makeNumberField('VISTO_KR_DecimalPoints', 'DecimalPoints', true),
  keyResultType: FieldGenerator.makeChoiceField('VISTO_KR_Type', 'Type', true, keyResultTypes),
  assignedTo: FieldGenerator.makeUserField('VISTO_KR_AssignedTo', 'AssignedTo', true),
  inverse: FieldGenerator.makeBooleanField('VISTO_KR_Inverse', 'Inverse', false),

  excelActuals: FieldGenerator.makeNoteField('VISTO_KR_ExcelActuals', 'ExcelActuals', false, false),
  excelTargets: FieldGenerator.makeNoteField('VISTO_KR_ExcelTargets', 'ExcelTargets', false, false),

  originalPlanId: FieldGenerator.makeTextField('VISTO_KR_OriginPlanId', 'Origin', true),
  local: FieldGenerator.makeBooleanField('VISTO_KR_Local', 'Local', true),

  createdDate: FieldGenerator.makeCreatedField('VISTO_KR_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_KR_Modified'),
};

addListDefinition({
  kind: VistoKind.KeyResult,
  fields: VistoKeyResultListFields,
  enableVersioning: true,
  majorVersionLimit: 500,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='${VistoKeyResultListFields.name.name}' />
      <FieldRef Name='${VistoKeyResultListFields.soGuid.name}' />
      <FieldRef Name='${VistoKeyResultListFields.parentKrGuid.name}' />
      <FieldRef Name='${VistoKeyResultListFields.description.name}' />
      <FieldRef Name='${VistoKeyResultListFields.units.name}' />
      <FieldRef Name='${VistoKeyResultListFields.showOnDiagram.name}' />
      <FieldRef Name='${VistoKeyResultListFields.decimalPoints.name}' />
      <FieldRef Name='${VistoKeyResultListFields.keyResultType.name}' />
      <FieldRef Name='${VistoKeyResultListFields.assignedTo.name}' />
      <FieldRef Name='${VistoKeyResultListFields.inverse.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoKeyResultItem>) => {
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'soGuid');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'parentKrGuid');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'units');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'showOnDiagram');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'inverse');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'decimalPoints');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'keyResultType');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'assignedTo');

    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'excelActuals');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'excelTargets');

    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'originalPlanId');
    updater.setItemSpField(VistoKeyResultListFields, spItem, update, 'local');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoKeyResultItem>, spItem) => {
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'soGuid');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'parentKrGuid');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'units');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'showOnDiagram');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'inverse');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'decimalPoints');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'keyResultType');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'assignedTo');

    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'excelActuals');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'excelTargets');

    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'originalPlanId');
    updater.getItemSpField(VistoKeyResultListFields, item, spItem, 'local');
  },
});
