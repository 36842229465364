import { Stack } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { InfoBar } from './InfoBar';

export const PlaceholderConsent = (props: {
  disabled: boolean;
  onConfigure: () => Promise<void>;
  maxHeight?: number;
  consentError?: any;
}) => {

  const style = props.maxHeight ? { maxHeight: props.maxHeight } : {};

  return (
    <Stack grow style={style}>
      {props.consentError && <InfoBar {...props.consentError} />}
      <Placeholder
        iconName='LaptopSecure'
        iconText={TextService.format(strings.AuthContext_ConsentRequired)}
        description={TextService.format(strings.AuthContext_ConsentRequiredExplanation)}
        buttonLabel={TextService.format(strings.AuthContext_ConsentRequiredAction)}
        disableButton={props.disabled}
        onConfigure={props.onConfigure}
      />
    </Stack>
  );
}
