import * as React from 'react';
import { IVistoPlan } from 'sp';
import { IPropertyBag } from './IPropertyBag';
import { INotify } from './Notify';
import { ICommand } from './ICommand';
import { IRealtimeService } from './IRealtimeService';
import { ICommandOptions } from './ICommandOptions';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';

export interface IAppContext {
  
  propertyBag: IPropertyBag;
  setPropertyBag: (propertyBag: Partial<IPropertyBag>) => void;

  dispatchCommand: (command: ICommand, options: ICommandOptions) => Promise<IVistoPlan>;

  planId: string;

  notify: INotify;
  wsContext: IRealtimeService;

  isPlanEditEnabled: boolean;
  isPlanLive: boolean;
  isPlanLocal: boolean;
  isPlanDesktop: boolean;

  isPopupOpen: boolean;
  setIsPopupOpen(isPopupOpen: boolean): void;

  selectionRef: React.MutableRefObject<ISelectedCellInfo>;
  setSelection: (selection: ISelectedCellInfo) => void;

  setDrawingData: (xml: string, force: boolean) => void;

  editorUiRef: React.MutableRefObject<any>;
  setEditorUi: (editorUi: any) => void;

  planRef: React.MutableRefObject<IVistoPlan>;
}

export const AppContext = React.createContext<IAppContext>(null);
