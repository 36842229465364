import { Dialog, DialogType, DialogFooter, PrimaryButton, ActivityItem, IActivityItemProps, Spinner, SpinnerSize, PersonaSize, mergeStyleSets } from '@fluentui/react';
import { InfoBar, useErrorInfo } from 'components';
import React from 'react';
import { ChangesService } from 'services/ChangesService';
import { NotificationType } from 'services/Notify';
import { SharepointService } from 'services/SharepointService';
import { TextService } from 'services/TextService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { getListDefinition, IVistoListItemWithProgress, IVistoPlan } from 'sp';
import strings from 'VistoWebPartStrings';

export const HistoryDialog = (props: {
  item: IVistoListItemWithProgress;
  plan: IVistoPlan;
  onDismiss: () => void;
}) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorInfo, setErrorInfo] = useErrorInfo();

  type IActivityItem = IActivityItemProps & { key: string; };
  const [activityItems, setActivityItems] = React.useState<IActivityItem[]>([]);

  const classNames = mergeStyleSets({
    historyItem: {
      overflow: 'auto',
      maxHeight: 100
    },
    historyItemText: {
      marginRight: 4,
      overflowWrap: 'anywhere'
    },
    historyItemTitle: {
      fontWeight: 500,
      marginRight: 4
    },
    activityItem: {
      marginBottom: 8
    },
  });

  const definition = getListDefinition(props.item.kind);

  const formatHistoryItems = (changes) => {
    return changes.properties.map(name => (
      <div key={name} className={classNames.historyItem}><span></span>
        <span className={classNames.historyItemTitle}>{TextService.format(strings[definition.fields[name].key])}</span>
        <span className={classNames.historyItemText}>{TextService.makeValue(name, changes.oldValues[name])}</span>
        <span className={classNames.historyItemTitle}>{TextService.format(strings.RightArrow)}</span>
        <span className={classNames.historyItemText}>{TextService.makeValue(name, changes.newValues[name])}</span>
      </div>)
    );
  };
  const loadHistory = async (): Promise<IActivityItem[]> => {
    const versions = await SharepointService.getItemVersions(props.plan, props.item);
    const items = versions
      .map((v, i) => {
        const isLast = i === versions.length - 1;
        const changes = ChangesService.getChanges<any>(isLast ? {} : versions[i + 1], versions[i],
          Object.keys(definition.fields).filter(x => x !== 'createdDate' && x !== 'modifiedDate'));
        return { v, changes, title: isLast ? TextService.format(strings.HistoryBlock_ItemCreatedBy) : TextService.format(strings.HistoryBlock_ItemUpdatedBy) };
      })
      // .filter(({ changes }) => changes.detected)
      .map(({ v, changes, title }) => {
        const activityItem: IActivityItem = {
          key: v.versionId,
          activityDescription: [
            <strong key={1}>{v.versionLabel}</strong>,
            <span key={2}>{` ${title} `}</span>,
            <strong key={3}>{v.editor.title}</strong>,
            ...formatHistoryItems(changes)
          ],
          activityPersonas: v.editor ? [{
            id: v.editor.userName,
            text: v.editor.title,
            secondaryText: v.editor.userName,
            imageInitials: TextService.getFullNameInitials(v.editor.title),
            size: PersonaSize.size48
          }] : undefined,
          // comments: 'Hello, this is the text of my basic comment!',
          timeStamp: TextService.formatDateTime(v.created),
        };
        return activityItem;
      });
    return items;
  };

  React.useEffect(() => {
    setIsLoading(true);
    setErrorInfo(null);
    loadHistory().then(items => {
      setIsLoading(false);
      setActivityItems(items);
      Promise.all(items.map(async (item) => {
        for (const p of item.activityPersonas) {
          p.imageUrl = await UserInfoPhotoService.getUserPhotoUrl(p.secondaryText);
        }
      })).then(() => {
        setActivityItems([...items]);
      });
    }, (error) => {
      setIsLoading(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.HistoryBlock_ErrorReadingHistory), error });
    });
  }, [props.item, props.plan]);

  return (
    <Dialog minWidth={500} hidden={false} dialogContentProps={{ title: TextService.format(strings.HistoryBlock_AuditLogDialogTitle), type: DialogType.normal, }} onDismiss={props.onDismiss}>
      <InfoBar {...errorInfo} />
      {isLoading && <Spinner size={SpinnerSize.large} />}
      <div style={{ overflowY: 'auto', minHeight: 300, maxHeight: '75vh' }}>
        {activityItems.map(item => <ActivityItem className={classNames.activityItem} {...item} key={item.key} />)}
      </div>
      <DialogFooter>
        <PrimaryButton onClick={props.onDismiss} text={TextService.format(strings.ButtonOK)} />
      </DialogFooter>
    </Dialog>
  );
};

