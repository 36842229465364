import { Operation } from 'shared/Operation';
import { trackClient } from 'services/trackClient';
import { IVistoListItem, IVistoPlan, VistoKind } from 'sp';
import { ICommand } from './ICommand';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';

export enum NotificationType {
  log,
  success,
  warn,
  error,
}

interface INotificationActionConfirmation {
  buttonOkText: string;
  buttonOkBusyText: string;
  buttonCancelText: string;
  title: string;
  content: string;
}

interface INotificationActionEdit {
  plan: IVistoPlan;
  item: IVistoListItem;
}

export interface INotificationAction {
  title: string; 
  action?: () => Promise<void>;
  command?: ICommand;
  done?: boolean;
  confirmation?: INotificationActionConfirmation;
  edit?: INotificationActionEdit;
}

export interface INotification {
  type: NotificationType;
  group?: string;
  message: string;
  error?: any;
  guid?: string;
  actions?: INotificationAction[];
  bubble?: string;
  timeout?: number;
}

export interface IBasicNotify {
  addNotification(info: INotification): void;
  clearNotifications(group?: string): void;
}

export interface INotify extends IBasicNotify {
  operation(op: Operation, kind: VistoKind, guid: string): void;
  plan(plan: IVistoPlan): void;
  navigateTo(selection: ISelectedCellInfo): void;
  update(): void;
  comment(subEntityId: string): void;
}

const logNotification = (info: INotification) => {
  switch (info.type) {
    case NotificationType.error:
      console.error(info.message, info.error);
      break;
    case NotificationType.warn:
      console.warn(info.message, info.error);
      break;
    case NotificationType.success:
      console.info(info.message);
      break;
    case NotificationType.log:
      console.debug(info.message);
      break;
  }
};

export const clearInfoBar = (notify: IBasicNotify, group?: string) => {
  if (notify) {
    notify.clearNotifications(group);
  }
};

export const notifyInfoBar = (notify: IBasicNotify, info: INotification) => {

  logNotification(info);

  if (notify) {
    notify.addNotification(info);
  } else {
    trackClient.error(`Ignored message: ${info.message}`, info.error);
  }
};
