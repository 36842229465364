import { IEmailRequest, IEmailResponse } from 'shared/api/email';
import { ApiService } from './ApiService';

export class ApiEmailService {

  public static async sendEmail(msg: IEmailRequest) {
    const result = await ApiService.fetch<IEmailResponse>(`/email/comment`, 'post', msg);
    return result;
  }

}
