import React from 'react';
import { Callout, DefaultButton, Persona, PersonaSize, Stack } from '@fluentui/react';
import { useAccount, useIsAuthenticated } from '@azure/msal-react';
import { WebAuthService } from 'teams/services/WebAuthService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { AuthService } from 'services/AuthService';
import { StorageCacheService } from 'services/StorageCacheService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';

export const LoginControl = (props: {

}) => {

  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();

  const login = () => {
    WebAuthService.msal.loginPopup({ scopes: ['User.Read'], prompt: 'select_account' }).then((response) => {
      WebAuthService.msal.setActiveAccount(response.account);
    });
  };

  const logout = () => {
    setIsCalloutVisible(false);
    WebAuthService.msal.logoutPopup().then(() => {
      AuthService.resetAuth();
      StorageCacheService.resetCache();
      WebAuthService.msal.setActiveAccount(null);
    });
  };

  const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);

  const onClick = () => {
    if (isAuthenticated) {
      setIsCalloutVisible(!isCalloutVisible);
    } else {
      login();
    }
  };

  const buttonId = 'c7456caa-b3f3-49e7-86ed-068b9031bd20';

  const [imageUrl, setImageUrl] = React.useState<string>();
  React.useEffect(() => {
    if (isAuthenticated && account?.username) {
      UserInfoPhotoService.getUserPhotoUrl(account.localAccountId).then(url => {
        if (url) {
          setImageUrl(url)
        }
      })
    } else {
      setImageUrl(undefined);
    }
  }, [isAuthenticated, account?.username]);

  return (
    <Stack>
      <DefaultButton id={buttonId} onClick={onClick}>
        <Persona
          imageShouldFadeIn={false}
          text={isAuthenticated ? (account?.name ?? TextService.format(strings.Button_Logout)) : TextService.format(strings.Button_Login)}
          showInitialsUntilImageLoads={false}
          size={PersonaSize.size24}
          imageUrl={imageUrl}
          hidePersonaDetails={false}
          showUnknownPersonaCoin={!isAuthenticated}
        />
      </DefaultButton>
      {isCalloutVisible && <Callout gapSpace={0} target={`#${buttonId}`} onDismiss={() => setIsCalloutVisible(false)}>
        <Stack horizontal tokens={{ padding: 'm' }}>
          <Persona
            imageShouldFadeIn={false}
            text={account?.name ?? TextService.format(strings.Button_LoggedIn)}
            showInitialsUntilImageLoads={false}
            secondaryText={account?.username}
            imageUrl={imageUrl}
            size={PersonaSize.size40}
            hidePersonaDetails={false}
          />
          <Stack.Item align='end'>
            <DefaultButton onClick={logout}>Logout</DefaultButton>
          </Stack.Item>
        </Stack>
      </Callout>}
    </Stack>
  );
};
