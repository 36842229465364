import * as React from 'react';
import { Stack, TextField } from '@fluentui/react';

import { TextService } from 'services/TextService';
import { BasicDialog } from './common';

import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'services/trackClient';

const requestFile = {
  'en-us': require('raw-loader!loc/license-request.en-us.txt'),
  'sv-se': require('raw-loader!loc/license-request.sv-se.txt'),
};

export function RequestLicenseKeyDialog(props: {
  siteUrl: string;
  planId: string;
  locale: string;
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('RequestLicenseKeyDialog'), []);

  const update = async () => {
  };

  const planId = props.planId;

  const encodedSiteUrl = encodeURIComponent(props.siteUrl);
  const encodedPlanId = encodeURIComponent(planId);

  const email = TextService.format(strings.RequestKey_EmailHref, { encodedSiteUrl, encodedPlanId });

  const requestTemplate = requestFile[props.locale] || requestFile['en-us'];
  const requestContent = TextService.format(requestTemplate, { url: props.siteUrl, planId });
  const requestHref = `data:text/plain,${requestContent}`;

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={update}
      minWidth={600}
      title={TextService.format(strings.RequestKey_DialogTitle)}
      buttonOkText={TextService.format(strings.RequestKey_ButtonClose)}
      buttonOkBusyText={TextService.format(strings.RequestKey_Requesting)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
    >
      <Stack tokens={{ childrenGap: 'm' }}>
        <TextField label={TextService.format(strings.RequestKey_SiteUrlLabel)} value={props.siteUrl} readOnly />
        <TextField label={TextService.format(strings.RequestKey_PlanIdLabel)} value={planId} readOnly />
        <a href={email}>{TextService.format(strings.RequestKey_EmailLink)}</a>
        <a href={requestHref} download={TextService.format(strings.RequestKey_FileName)}>{TextService.format(strings.RequestKey_DownloadFile)}</a>
      </Stack>
    </BasicDialog>);

}
