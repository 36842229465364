import * as React from 'react';
import { DocumentCard, DocumentCardActions, DocumentCardActivity, DocumentCardImage, DocumentCardLocation, DocumentCardTitle, DocumentCardType, IButtonProps, IDocumentCardActivityPerson, IDocumentCardStyles, ImageFit, TooltipHost, useTheme } from '@fluentui/react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { ICardInfo } from 'services/ICardInfo';
import { UrlService } from 'shared/urlService';
import { useEnvNavigate } from 'services/NavigationService';
import { stringifyError } from 'shared/parse';
import { EnvContext } from 'services/EnvContext';

export const DashboardPlanCard = (props: {
  cardInfo: ICardInfo;
  onTogglePinned: (val: ICardInfo) => void;
  onDelete: (val: ICardInfo) => void;
}) => {

  const theme = useTheme();

  const cardStyles: IDocumentCardStyles = {
    root: {
      display: 'inline-block',
      boxShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 18%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
      marginRight: 20,
      marginBottom: 20,
      width: 250,
      padding: 10
    }
  };

  const ci: ICardInfo = props.cardInfo;

  const { isPortal } = React.useContext(EnvContext);
  const navigate = useEnvNavigate(isPortal);

  const actions: IButtonProps[] = [
    {
      iconProps: { iconName: ci.pinned ? 'Unpin' : 'Pin' },
      onClick: (ev) => {
        props.onTogglePinned(ci);
        ev.stopPropagation();
        ev.preventDefault();
      }
    },
    {
      menuProps: {
        items: [
          {
            key: 'Open',
            text: TextService.format(strings.DashboardCardMenu_OpenPlan),
            iconProps: { iconName: 'OpenInNewWindow' },
            onClick: () => navigate(ci.url)
          },
          {
            key: 'Pin',
            text: ci.pinned ? TextService.format(strings.DashboardCardMenu_Unpin) : TextService.format(strings.DashboardCardMenu_Pin),
            iconProps: { iconName: ci.pinned ? 'Unpin' : 'Pin' },
            onClick: () => props.onTogglePinned(ci)
          },
          ...((UrlService.isLocalUrl(ci.siteUrl) || ci.missing) ?
            [{
              key: 'Delete',
              text: TextService.format(strings.DashboardCardMenu_Delete),
              iconProps: { iconName: 'Delete' },
              onClick: () => props.onDelete(ci)
            }] : [])
        ]
      },
      menuIconProps: { iconName: 'More' }
    },
  ];

  const people: IDocumentCardActivityPerson[] = ci.users.map(u => ({
    name: u.name,
    profileImageSrc: u.profileImageSrc,
    initials: u.initials
  }));

  const formatActivity = () => {
    return ci.lastModified
      ? TextService.format(strings.DashboardCard_ModifiedDate, { date: TextService.formatDate(ci.lastModified) })
      : TextService.format(strings.DashboardCard_AccessedDate, { date: TextService.formatDate(ci.lastAccessed) });
  };

  const onLocationClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(ci.locationUrl);
  }

  return (
    <DocumentCard key={ci.planId} styles={cardStyles} type={DocumentCardType.normal} onClick={() => navigate(ci.url)} >
      <DocumentCardImage
        imageSrc={ci.imageUrl}
        imageFit={ImageFit.contain}
        height={150}
        width={250}
        iconProps={ci.imageUrl ? null : {
          iconName: (ci.error || ci.missing) ? 'Unknown' : 'ProgressLoopOuter',
          styles: {
            root: {
              animation: (ci.error || ci.missing) ? null : 'visplanLoadingIndicatorRotate 3s linear infinite',
              color: theme.palette.neutralTertiary
            }
          }
        }}
      />
      <TooltipHost content={ci.error ? stringifyError(ci.error) : undefined}>
        <DocumentCardLocation location={ci.locationName} locationHref={ci.locationUrl} onClick={e => onLocationClick(e)} />
        <DocumentCardTitle
          title={ci.name}
          styles={{
            root: {
              color: ci.error
                ? theme.palette.redDark
                : (ci.missing ? theme.palette.yellowDark : null)
            }
          }}
        />
      </TooltipHost>
      <TooltipHost content={people.map(u => u.name).join(', ')}>
        <DocumentCardActivity activity={formatActivity()} people={people} />
      </TooltipHost>
      <DocumentCardActions actions={actions} />
    </DocumentCard>
  );
};
