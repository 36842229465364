import React from 'react';
import { Dropdown, ResponsiveMode } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { NULL_KEY } from 'shared/parse';
import strings from 'VistoWebPartStrings';

export const PlanPicker = (props: {
  label: string;
  enableNull: boolean;
  placeholder: string;
  existingPlans: { planId: string, name?: string }[];
  planId: string;
  disabled?: boolean;
  title?: string;
  setPlanId: (val: string) => void;
}) => {

  const options = props.existingPlans.map(item => ({ 
    key: item.planId, 
    text: item.name,
    disabled: false,
  })).sort((a, b) => TextService.compareNames(a.text, b.text));

  if (props.enableNull) {
    options.unshift({
      key: NULL_KEY,
      text: TextService.format(strings.TextValue_None),
      disabled: false,
    });
  }

  return (
    <Dropdown
      label={props.label}
      placeholder={props.placeholder}
      options={options}
      disabled={props.disabled}
      selectedKey={props.planId ?? NULL_KEY}
      title={props.title}
      onChange={(_, val) => props.setPlanId(val.key === NULL_KEY ? null : '' + val.key)}
      responsiveMode={ResponsiveMode.large}
    />
  );
}
