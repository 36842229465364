import { mxgraph } from 'ts-mxgraph-typings';
import { CellKind } from 'shared/CellKind';
import { mx } from 'frames/TopFrame/drawing/common';

export const handleCellsResized = (
  currentUi: any,
  graph: mxgraph.mxGraph,
  event: mxgraph.mxEventObject) => {

  const { cells, bounds, previous } = event.properties;
  const [cell] = cells;

  if (mx.getCellKind(cell) === CellKind.PAGESIZER) {
    const [cellBounds] = bounds;
    const [cellPrevious] = previous;
    const dx = cellBounds.width - cellPrevious.width;
    const dy = cellBounds.height - cellPrevious.height;

    const position = mx.findCellByKind(graph, CellKind.POSITION);
    const focus = mx.findCellByKind(graph, CellKind.FOCUS);
    mx.moveCells(graph, [position, focus], dx, 0);

    mx.resizeDiagram(currentUi, graph, dx, dy);

    const title = mx.findCellByKind(graph, CellKind.TITLE);
    mx.resizeCell(graph, title, dx, 0);

    const lops = mx.findCellsByKind(graph, CellKind.LOP);
    for (const lop of lops) {
      mx.moveLopBend(graph, lop, dx);
    }

    // right side moved
    if (cellBounds.x === cellPrevious.x) {


    } else { // left side moved

      const dps = mx.findCellsByKind(graph, CellKind.DP);
      mx.moveCells(graph, dps, dx, 0);

      const sizer = mx.findCellByKind(graph, CellKind.PAGESIZER);
      mx.moveCells(graph, [sizer], dx, 0);

      const translate = graph.view.translate;
      graph.view.setTranslate(translate.x - dx, translate.y);
    }
  }
};
