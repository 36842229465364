import { IPlanOpenedRequest, IPlanOpenedResponse } from 'shared/api/plan';

import { IEnvContext } from '../EnvContext';
import { ApiService } from './ApiService';
import { IVistoPlan } from 'sp';

export class ApiPlanService {

  public static async openPlan(plan: IVistoPlan, groupId: string, channelId: string) {

    const req: IPlanOpenedRequest = {
      siteUrl: plan.siteUrl,
      planId: plan.planId,
      groupId,
      channelId,
    };

    const result = await ApiService.fetch<IPlanOpenedResponse>(`/plan/open`, 'post', req);
    return result;
  }

}
