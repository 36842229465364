import { Dropdown } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { NULL_KEY } from 'shared/parse';
import { IVistoPlan, VistoKeyResultItem, VistoKind } from 'sp';

export const KrPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  soGuid?: string;
  disabledKrGuid?: string;
  krGuid: string;
  setKrGuid: (krGuid: string) => void;
}) => {

  const krOptions = PlanDataService.getItemsHaving<VistoKeyResultItem>(props.plan.items,
    x => x.kind === VistoKind.KeyResult && (!props.soGuid || x.soGuid === props.soGuid))
    .map(kpi => ({
      key: kpi.guid,
      text: (kpi.parentKrGuid ? PlanDataService.getItemByGuid(props.plan.items, kpi.parentKrGuid)?.name + ' > ' : '') + kpi.name,
      disabled: kpi.guid === props.disabledKrGuid,
    }))
    .sort((a, b) => TextService.compareNames(a.text, b.text))

  krOptions.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.UnassignedItem),
    disabled: false,
  });

  return (
    <Dropdown
      label={props.label}
      disabled={props.disabled}
      selectedKey={props.krGuid ?? NULL_KEY}
      onChange={(_, val) => props.setKrGuid(val.key === NULL_KEY ? null : '' + val.key)}
      options={krOptions}
    />
  );
}
