import * as React from 'react';

import { IVistoPlan, VistoDpItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';
import { FontSizes, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { ProgressService } from 'services/ProgressService';
import { trackClient } from 'services/trackClient';
import { AssigneePicker } from './common/AssigneePicker';
import { StorageService } from 'services/StorageService';

export function EditDpDialog(props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
  drop?: VistoDpItem;
  onDismiss: (changed: boolean, dp: VistoDpItem) => void;
}) {

  React.useEffect(() => trackClient.page('EditDpDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldDp = props.dp ?? props.drop;
  const [newDp, setNewDp] = React.useState<VistoDpItem>({ ...oldDp });

  return (
    <ItemEditDialog
      onDismiss={(changed) => props.onDismiss(changed, newDp)}
      onLoaded={loaded => setNewDp({ ...newDp, ...loaded })}
      validationIssues={!newDp.name}
      onCommit={() => props.drop ? Promise.resolve(props.plan) : dispatchCommand(Commands.makeSaveCommand(oldDp, newDp, notify), { wrap: false })}
      plan={props.plan}
      oldItem={oldDp}
      newItem={newDp}
    >
      <Stack tokens={{ childrenGap: 's1' }}>
        <TitleBlock
          fontSize={FontSizes.xLarge}
          edit={isPlanEditEnabled}
          name={newDp.name}
          description={newDp.description}
          nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newDp, 'name')}
          descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newDp, 'description')}
          onNameChanged={val => setNewDp({ ...newDp, name: val })}
          onDescriptionChanged={val => newDp.description = val}
          assignedTo={newDp.assignedTo}
          onAssignedToChanged={assignedTo => setNewDp({ ...newDp, assignedTo: assignedTo })}
          assignedToDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newDp, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
        />
      </Stack>
    </ItemEditDialog>);

}
