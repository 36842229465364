import { CopyShareLinkDialog } from 'dialogs/CopyShareLinkDialog';
import React from 'react';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';

export const useSidebarShareDialog = (): [JSX.Element, (visible: boolean, sel: ISelectedCellInfo) => void] => {

  const selectedCellInfoRef = React.useRef<ISelectedCellInfo>(null);
  const [isCopyPlanLinkDialogVisible, _setIsCopyPlanLinkDialogVisible] = React.useState(false);
  
  const setIsCopyPlanLinkDialogVisible = (visible: boolean, sel?: ISelectedCellInfo) => {
    selectedCellInfoRef.current = sel;
    _setIsCopyPlanLinkDialogVisible(visible);
  };

  return [
    isCopyPlanLinkDialogVisible && <CopyShareLinkDialog selection={selectedCellInfoRef.current} onDismiss={() => setIsCopyPlanLinkDialogVisible(false)} />,
    setIsCopyPlanLinkDialogVisible
  ];
};
