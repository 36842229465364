import { Stack } from '@fluentui/react';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { ProgressValue } from './ProgressValue';

export const ProgressBlockDates = (props: {
  startDate?: Date;
  endDate?: Date;
}) => {
  return (
    <Stack horizontal wrap tokens={{ childrenGap: 's1' }}>
      <ProgressValue label={TextService.format(strings.StartDate)} value={TextService.formatDate(props.startDate)} width='4em' />
      <ProgressValue label={TextService.format(strings.EndDate)} value={TextService.formatDate(props.endDate)} width='4em' />
    </Stack>
  );
};
