import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { PlanWizard } from 'wizard';
import { ActionButton, Stack } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { GettingStartedDialog } from 'dialogs/GettingStartedDialog';
import { IVistoPlan } from 'sp';

export function UpgradePlanPlaceholder(props: {
  plan: IVistoPlan;
  onUpgradeFinished: () => void;
}) {

  const [isWizardOpened, setIsWizardOpened] = React.useState(false);

  const onOpenWizard = () => {
    setIsWizardOpened(true);
  };

  const onWizardDsimiss = (changed: boolean) => {
    if (changed) {
      props.onUpgradeFinished();
    }
    setIsWizardOpened(false);
  };

  const [isWhatsNewOpen, setIsWhatsNewOpen] = React.useState(false);
  const onPlaceholderShowWhatsNew = () => {
    setIsWhatsNewOpen(true);
  }

  return (
    <Stack grow style={{ maxHeight: 200 }}>
      {!isWizardOpened &&
        <Placeholder
          iconName='Repair'
          iconText={TextService.format(strings.UpgradePlanPlaceholder_IconText)}
          description={TextService.format(strings.UpgradePlanPlaceholder_Description)}
          buttonLabel={TextService.format(strings.UpgradePlanPlaceholder_Button)}
          onConfigure={onOpenWizard}
        >
          <Stack grow horizontalAlign='center' tokens={{ padding: 30 }}>
            <ActionButton onClick={onPlaceholderShowWhatsNew}>{TextService.format(strings.Placeholer_ShowWhatsNewButton)}</ActionButton>
            {isWhatsNewOpen && <GettingStartedDialog topic='whatsnew' showCheckbox={false} checked={false} onDismiss={() => setIsWhatsNewOpen(false)} />}
          </Stack>
        </Placeholder>
      }

      {isWizardOpened &&
        <PlanWizard
          isPopup
          planName={props.plan.name}
          topPlanId={props.plan.topPlanId}
          onDismiss={onWizardDsimiss}
          trigger='UpgradePlaceholder'
        />
      }
    </Stack>
  );
}
