import React from 'react';
import strings from 'VistoWebPartStrings';
import { Stack, Text } from '@fluentui/react';
import { IPlanGenerationUpdates } from 'services/PlanGenerationInterfaces';
import { TextService } from 'services/TextService';
import { VistoKind } from 'sp';
import { IOpenaiFile } from 'shared/api/openai';
import { IAssistantDocument } from './IAssistantDocument';

export class AssistantService {

  private static formatSizeInKb(size: number): string {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  
  public static mapFileToDocument (file: IOpenaiFile): IAssistantDocument {
    const docType = file.filename.split('.').pop();
    const doc: IAssistantDocument = {
      key: file.id,
      name: file.filename,
      value: file.filename,
      dateModifiedValue: file.created_at,
      dateModified: new Date(file.created_at * 1000).toLocaleDateString(),
      fileSizeRaw: file.bytes,
      fileSize: this.formatSizeInKb(file.bytes),
      iconName: `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/16/${docType}.svg`,
      fileType: docType,
      status: file.status,
    }
    return doc;
  }

  public static formatChanges (changes: IPlanGenerationUpdates): JSX.Element {
    return <>
      {changes.addedCapabilities.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_AddedLOP)}</Text>
          <ul>
            {changes.addedCapabilities.map(capability => <li key={capability.guid}><strong>{capability.name}</strong></li>)}
          </ul>
        </Stack>}
      {changes.addedAmbitions.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_AddedDP)}</Text>
          <ul>
            {changes.addedAmbitions.map(ambition => <li key={ambition.guid}><strong>{ambition.name}</strong></li>)}
          </ul>
        </Stack>}
      {changes.deletedCapabilities.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_DeletedLOP)}</Text>
          <ul>
            {changes.deletedCapabilities.map(capability => <li key={capability.guid}><strong>{capability.name}</strong></li>)}
          </ul>
        </Stack>}
      {changes.deletedAmbitions.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_DeletedDP)}</Text>
          <ul>
            {changes.deletedAmbitions.map(ambition => <li key={ambition.guid}><strong>{ambition.name}</strong></li>)}
          </ul>
        </Stack>}
      {changes.changedCapabilities.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_ChangedLOP)}</Text>
          <ul>
            {changes.changedCapabilities.map(capability => <li key={capability.item.guid}>
              <strong>{capability.item.name}</strong>
              <div dangerouslySetInnerHTML={{ __html: TextService.formatChanges(VistoKind.LOP, capability.changes) }}></div>
            </li>)}
          </ul>
        </Stack>}
      {changes.changedAmbitions.length > 0 &&
        <Stack>
          <Text variant='mediumPlus'>{TextService.format(strings.AssistantTooltip_ChangedDP)}</Text>
          <ul>
            {changes.changedAmbitions.map(ambition => <li key={ambition.item.guid}>
              <strong>{ambition.item.name}</strong>
              <div dangerouslySetInnerHTML={{ __html: TextService.formatChanges(VistoKind.DP, ambition.changes) }}></div>
            </li>)}
          </ul>
        </Stack>}
    </>
  }

}
