import { Dropdown, IDropdownOption } from '@fluentui/react';
import strings from 'VistoWebPartStrings';
import React from 'react';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { IVistoPlan, VistoKind, VistoLopItem } from 'sp';
import { NULL_KEY } from 'shared/parse';

export const LopPicker = (props: {
  disabled?: boolean;
  label: string;
  plan: IVistoPlan;
  lopGuid: string;
  getAdditionalItemInfo?: (dp: VistoLopItem) => { disabled: boolean, why: string } | undefined;
  setLopGuid: (lopGuid: string) => void;
}) => {

  const allLops = PlanDataService.getItems<VistoLopItem>(props.plan.items, VistoKind.LOP);
  const lopOptions = allLops.map(lop => {
    const itemInfo = props.getAdditionalItemInfo && props.getAdditionalItemInfo(lop);
    const option: IDropdownOption = {
      key: lop.guid,
      text: lop.name,
      disabled: itemInfo?.disabled ?? false,
      title: itemInfo?.why ?? undefined,
    }
    return option;
  })

  lopOptions.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.SelectOption),
  });

  return (
    <Dropdown
      required
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.lopGuid ?? NULL_KEY}
      onChange={(_, val) => props.setLopGuid(val.key === NULL_KEY ? null : '' + val.key)}
      options={lopOptions}
    />
  );
}
