import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { IVistoListItem, IVistoPlan } from 'sp';
import { trackClient } from 'services/trackClient';
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo';
import { CopyLinkDialogBase } from './CopyLinkDialogBase';
import { UrlService } from 'shared/urlService';
import { Commands } from 'services/Commands';

export function CopyProgressLinkDialog(props: {
  plan: IVistoPlan;
  item: IVistoListItem;
  onDismiss: () => void;
}) {

  React.useEffect(() => trackClient.page('CopyLinkDialog'), []);

  const envContext = React.useContext(EnvContext);

  const link = UrlService.getSourceLinkFromItem(props.plan, props.item, envContext.channelId, envContext);

  return (
    <CopyLinkDialogBase
      content={TextService.format(strings.CopyLinkDialog_Content)}
      linkLabel={TextService.format(strings.CopyLinkDialog_ItemLink)}
      link={link}
      onDismiss={props.onDismiss}
    />
  );
}
