import { IButtonStyles, MessageBarButton } from '@fluentui/react';
import { BasicDialog } from 'dialogs/common';
import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { INotificationAction } from 'services/Notify';
import { trackClient } from 'services/trackClient';
import { EditItemDialog } from './EditItemDialog';

export const NotificationActionButton = (props: { action: INotificationAction, styles?: IButtonStyles }) => {

  const appContext = React.useContext(AppContext);
  const [disabled, setDisabled] = React.useState(false);

  const [dialogVisibe, setDialogVisible] = React.useState(false);

  const onConfirm = async (wrap: boolean) => {
    try {
      setDisabled(true);
      const plan = await appContext.dispatchCommand(props.action.command, { wrap });
      props.action.done = true;
      if (appContext.notify) {
        appContext.notify.plan(plan);
      }
    } finally {
      setDisabled(false);
    }
  };

  const onClick = async () => {
    if (props.action.action) {
      try {
        setDisabled(true);
        await props.action.action();
      } finally {
        setDisabled(false);
      }
    }
    else if (props.action.confirmation || props.action.edit) {
      trackClient.page('NotificationActionDialog');
      setDialogVisible(true);
    } else if (props.action.command) {
      onConfirm(true);
    }
  };

  const onDismiss = (changed) => {
    if (changed) {
      props.action.done = true;
    }
    setDialogVisible(false);
  };

  return (
    <React.Fragment>
      <MessageBarButton styles={props.styles} disabled={disabled} onClick={onClick}>{props.action.title}</MessageBarButton>
      {dialogVisibe &&
        <div style={{ display: 'none' }}>
          {props.action.edit &&
            <EditItemDialog
              onDismiss={onDismiss}
              plan={props.action.edit.plan}
              item={props.action.edit.item}
            />
          }
          {props.action.confirmation &&
            <BasicDialog
              onDismiss={onDismiss}
              buttonOkAction={() => onConfirm(false)}
              buttonOkText={props.action.confirmation.buttonOkText}
              buttonOkBusyText={props.action.confirmation.buttonOkBusyText}
              buttonCancelText={props.action.confirmation.buttonCancelText}
              title={props.action.confirmation.title}
            >
              <span>{props.action.confirmation.content}</span>
            </BasicDialog>
          }
        </div>
      }
    </React.Fragment>
  );
};
