import * as React from 'react';
import { Stack, Toggle } from '@fluentui/react';
import { ITreeItem, SelectChildrenMode, TreeItemActionsDisplayMode, TreeView, TreeViewSelectionMode } from '@pnp/spfx-controls-react/lib/controls/treeView';
import { IVistoPlan } from 'sp';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';

export const ChildPlanWizardSelectPage = (props: {
  plan: IVistoPlan,
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  topPlanEnabled: boolean;
  setTopPlanEnabled: (val: boolean) => void;
}) => {

  const treeitems: ITreeItem[] = PlanDataService.getLops(props.plan).map(lop => ({
    key: lop.guid,
    label: TextService.formatTitle(lop, props.plan.items),
    data: lop,
    disabled: !!lop.sourceItemUrl,
    children: PlanDataService.getLopDps(props.plan, lop.guid).map(dp => ({
      key: dp.guid,
      label: TextService.formatTitle(dp, props.plan.items),
      data: dp,
      disabled: !!dp.sourceItemUrl || !!lop.sourceItemUrl,
    }))
  }));

  const addParents = (keySet: Set<string>) => {
    for (const lop of PlanDataService.getLops(props.plan)) {
      for (const dp of PlanDataService.getLopDps(props.plan, lop.guid)) {
        for (const action of PlanDataService.getDpActions(props.plan, dp.guid)) {
          if (keySet.has(action.guid)) {
            keySet.add(dp.guid);
          }
        }
        if (keySet.has(dp.guid)) {
          keySet.add(lop.guid);
        }
      }
    }
  };

  const onTreeItemSelect = (items: ITreeItem[]) => {
    const keys = new Set(items.map(item => item.key));
    addParents(keys);
    props.setSelectedKeys(Array.from(keys));
  };

  return (
    <Stack grow tokens={{ childrenGap: 'm' }}>
      <Stack grow style={{ maxHeight: '50vh', overflowY: 'auto' }}>
      <Toggle
          label={TextService.format(strings.ChildPlanWizard_ToggleCascading)}
          checked={props.topPlanEnabled}
          onChange={(_, val) => props.setTopPlanEnabled(val)}
          inlineLabel
        />
        <TreeView
          items={treeitems}
          defaultExpanded={false}
          selectionMode={TreeViewSelectionMode.Multiple}
          selectChildrenMode={SelectChildrenMode.Select | SelectChildrenMode.Unselect}
          showCheckboxes={true}
          treeItemActionsDisplayMode={TreeItemActionsDisplayMode.ContextualMenu}
          defaultSelectedKeys={props.selectedKeys}
          expandToSelected={false}
          defaultExpandedChildren={false}
          onSelect={onTreeItemSelect}
        />
      </Stack>
    </Stack>
  );
};
