import { trackClient } from 'services/trackClient';
import { TextService } from './TextService';
import { TokenKind } from 'shared/TokenKind';
import { clearInfoBar, IBasicNotify, NotificationType, notifyInfoBar } from './Notify';
import { isConsentError } from 'shared/parse';
import strings from 'VistoWebPartStrings';
import '@pnp/graph/users';
import '@pnp/graph/groups';
import '@pnp/graph/photos';
import '@pnp/graph/teams';
import { AuthService } from './AuthService';
import { CachingType } from './CachingType';
import { User } from '@microsoft/microsoft-graph-types';
import { CacheKey } from '@pnp/queryable';
import { graphGet, GraphQueryable } from '@pnp/graph';
import { CopyFrom } from '@pnp/core';

export interface IMembershipInfo {
  groups: string[];
  teams: {
    id: string;
    displayName: string;
    channels: {
      id: string;
      displayName: string;
      membershipType?: string;
      isFavoriteByDefault?: boolean;
    }[];
  }[];
}

interface IGraphOrganizationInfo {
  id: string;
  displayName: string;
  verifiedDomains: {
    isDefault: boolean;
    name: string;
  }[];
}

export class UserInfoService {

  public static async configure(tid: string, notify?: IBasicNotify) {

    const callback = async () => {
      await AuthService.getAuthToken(TokenKind.dashboard, tid);
    };

    try {

      await callback();

    } catch (err) {

      if (isConsentError(err)) {
        this.makeConsentNotification(callback, notify);
      } else {
        trackClient.warn('Unable to configure user info service', err);
      }
    }
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify) {

    AuthService.resetAuth(TokenKind.dashboard);

    notifyInfoBar(notify, {
      message: TextService.format(strings.GraphNotification_AuthorizationRequired),
      group: 'Dashboard_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.GraphNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.GraphNotification_AuthorizeButton),
          action: async () => {
            try {
              await AuthService.getConsent(TokenKind.dashboard, '', callback);
              clearInfoBar(notify, 'Dashboard_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.GraphNotification_ConsentGrant), group: 'Dashboard_Consent' });
            } catch (error) {
              const message = TextService.format(strings.GraphNotification_AuthorizationRequiredErrorDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'Dashboard_Consent' });
            }
          }
        }
      ]
    });
  }

  static PERSONAL_ACCOUNT = '9188040d-6c67-4c5b-b112-36a304b66dad';

  public static async getUserMembershipInfo(tid: string, userObjectId: string): Promise<IMembershipInfo> {
    const graph = AuthService.getGraphClient(TokenKind.dashboard, userObjectId, 'long');

    if (tid === UserInfoService.PERSONAL_ACCOUNT) {
      return {
        groups: [],
        teams: []
      };
    }

    const groups = await graph.me.memberOf.using(CacheKey(`groups.${userObjectId}`)).select('id')();

    const result = {
      groups: groups.map(g => g.id),
      teams: []
    };

    const joinedTeams = await graph.me.joinedTeams.using(CacheKey(`joinedTeams.${userObjectId}`)).select('id', 'displayName')();
    for (let chunk = 0; chunk < joinedTeams.length; chunk += 20) {
      const [batch, execute] = graph.batched();
      for (const joinedTeam of joinedTeams.slice(chunk, chunk + 20)) {
        batch.teams.getById(joinedTeam.id).channels.using(CacheKey(`channels.${joinedTeam.id}`)).select('id', 'displayName', 'membershipType', 'isFavoriteByDefault')().then(channels => {
          result.teams.push({
            id: joinedTeam.id,
            displayName: joinedTeam.displayName,
            channels: channels.map((c: any) => ({
              id: c.id,
              displayName: c.displayName,
              membershipType: c.membershipType,
              isFavoriteByDefault: c.isFavoriteByDefault
            }))
          });
        });
      }
      await execute();
    }
    return result;
  }

  public static encodeUserObjectId(rawUserObjectId: string) {
    return rawUserObjectId.replace(/#/g, '%23');
  }

  public static async getUserInfo(rawUserObjectId: string): Promise<User> {
    if (rawUserObjectId) {
      const userObjectId = this.encodeUserObjectId(rawUserObjectId);
      const graph = AuthService.getGraphClient(TokenKind.dashboard, userObjectId, 'long');
      const userInfo = await graph.users.getById(userObjectId)();
      return userInfo;
    }
  }

  public static async getOrganizationInfo(tid: string, userObjectId: string): Promise<IGraphOrganizationInfo> {
    if (tid !== UserInfoService.PERSONAL_ACCOUNT) {
      const graph = AuthService.getGraphClient(TokenKind.dashboard, userObjectId, 'long');
      const queryable = GraphQueryable(graph.me.toUrl().replace('/me', ''), '/organization')
        .using(CopyFrom(graph.me))
        .using(CacheKey(`organization.${tid}`));
      const organizations = await graphGet(queryable) as IGraphOrganizationInfo[];
      return organizations.find(o => o.id === tid);
    }
  }
}
