import { useNavigate } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

export const navigateTo = (url: string, target?: string) => {
  if (url.startsWith('https://teams.microsoft.com/') || url.startsWith('https://teams.cloud.microsoft/')) {
    microsoftTeams.executeDeepLink(url);
  } else {
    window.open(url, target);
  }
}

export const useEnvNavigate = (isPortal: boolean) => {

  const navigate = isPortal ? useNavigate() : null;

  return (url: string, target?: string) => {

    if (url?.startsWith('http://') || url?.startsWith('https://') || !navigate) {
      navigateTo(url, target);
    } else {
      navigate(url);
    }
  }
}
