import { TokenKind } from 'shared/TokenKind';
import { AuthService } from './AuthService';
import { GraphFI, graphGet, GraphQueryable } from '@pnp/graph';
import { IDrive } from '@pnp/graph/onedrive';
import { CachingType } from './CachingType';


export class ExcelWorkbookService {

  public static async getWorkbookTables(drive: IDrive, fileId: string): Promise<{ name: string }[]> {
    const tables = await graphGet(GraphQueryable(drive, `/items/${fileId}/workbook/tables`));
    return tables;
  }

  private static async getDriveInfo(graph: GraphFI, siteUrl: string) {
    const url = new URL(siteUrl);
    const host = url.host;
    const pathname = url.pathname;
    const driveInfo = await graph.sites.getByUrl(host, pathname).drive();
    return driveInfo;
  }

  public static async getDrive(siteUrl: string, caching?: CachingType) {
    const graph = AuthService.getGraphClient(TokenKind.excel, 'default', caching);
    const driveInfo = await ExcelWorkbookService.getDriveInfo(graph, siteUrl);
    return graph.drives.getById(driveInfo.id);
  }

  public static async getRootUrl(siteUrl: string) {
    const graph = AuthService.getGraphClient(TokenKind.excel, 'default', 'long');
    const driveInfo = await ExcelWorkbookService.getDriveInfo(graph, siteUrl);
    return decodeURI(driveInfo.webUrl);
  }

  public static async getFile(drive: IDrive, excelOptions: IkeyResultExcelOptions): Promise<{ 
    id: string,
    name: string
    webUrl: string,
  }> {
    try {
      const file = await graphGet(GraphQueryable(drive, `/root:/${excelOptions.excelWorkbook}`));
      return file;
    } catch (error) {
      if (error.status === 404 && excelOptions.excelSourceDoc) {
        const file = await graphGet(GraphQueryable(drive, `/items/${excelOptions.excelSourceDoc}`));
        return file;
      } else {
        throw error;
      }
    }
  }
}
