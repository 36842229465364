import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { PlanDataService } from 'services/PlanDataService';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { getListDefinition, IFieldDefinition, IVistoPlan, VistoKind } from 'sp';

export const makeMigrateRichTextFields = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Convert rich text fields`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);
    
    const kinds = PlanDataService.getAllVistoKinds({ includePlan: true }).sort(PlanDataService.compareItemKind);

    for (const kind of kinds) {
      const listUrl = UrlService.getListRelativeUrl(foundPlan, kind);

      const listDefinition = getListDefinition(kind);
      for (const fieldKey in listDefinition.fields) {
        const field: IFieldDefinition = listDefinition.fields[fieldKey];
        if (field.richText) {
          await sp.web.getList(listUrl).fields.getByInternalNameOrTitle(field.name).update({ RichText: 'FALSE' }, 'SP.FieldMultiLineText');
        }
      }
    }
    StorageCacheService.resetCache();
    return p;
  }
});
