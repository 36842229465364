
import { IFieldValueUser, IVistoListItem, IVistoListItemWithProgress, IVistoPlan, IVistoPlanSettings } from 'sp';
import { ProgressService } from 'services/ProgressService';
import { IProgressData } from 'services/IProgressData';
import { TextService } from 'services/TextService';
import { AuthService } from 'services/AuthService';
import { clearInfoBar, IBasicNotify, INotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { ICheckListItem } from 'services/ICheckListItem';
import { IOperationOptions } from 'services/IOperationOptions';
import { PlanDataService } from 'services/PlanDataService';
import { IItemChanges } from 'services/Interfaces';
import { ChangesService } from 'services/ChangesService';
import { trackClient } from 'services/trackClient';
import { CommandName } from 'shared/CommandName';
import { getObjectValues, isConsentError } from 'shared/parse';
import { TokenKind } from 'shared/TokenKind';
import { Operation } from 'shared/Operation';
import strings from 'VistoWebPartStrings';

import { PlanSettingsService } from 'services/PlanSettingsService';
import { DevOpsService } from './DevOpsService';
import { IDevOpsWorkItem } from './IDevOpsWorkItem';
import { IDevOpsItemType } from './IDevOpsItemType';
import { DevOpsNotifications } from './DevOpsNotifications';
import { SharepointUserResolver } from 'services/SharepointUserResolver';
import { LicenseService } from 'services/LicenseService';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { Commands } from 'services/Commands';
import { IDevOpsIteration } from './IDevOpsIteration';

export interface IDevOpsIntegrationSettings {
  enabled: boolean;
  devopsUrl: string;
  itemTypes: IDevOpsItemType[];
  itemTypeNames: string[];
  syncDate?: Date;
}

export class DevOpsDataService {

  public static isLinkedItem(url: string) {
    return url && !!DevOpsService.getWorkItemIdFromSourceUrl(url);
  }

  public static formatLinkName(url: string, taskName: string) {
    const projectName = url && DevOpsService.getProjectName(url);
    return TextService.format(strings.DevOpsData_DevOpsTaskLinkName, { taskName, projectName });
  }

  public static makeBreakDevOpsLinkNotificationAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.DevOpsNotification_BreakLinkTitle),
      command: Commands.makeBreakLinkAction(plan, item, true, CommandName.BreakLinkDevOps, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonBreak),
        buttonOkBusyText: TextService.format(strings.ButtonBreaking),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.DevOpsNotification_BreakDevOpsLink),
        content: TextService.format(strings.DevOpsNotification_BreakDevOpsLinkDescription, { title: TextService.formatTitle(item, plan.items) }),
      }
    };
  }

  public static makeConsentNotification(callback: () => Promise<void>, notify: IBasicNotify) {

    notifyInfoBar(notify, {
      message: TextService.format(strings.DevOpsNotification_AuthorizationRequired),
      group: 'DevOps_Consent',
      type: NotificationType.warn,
      error: TextService.format(strings.DevOpsNotification_AuthorizationRequiredError),
      actions: [
        {
          title: TextService.format(strings.DevOpsNotification_ButtonAuthorize),
          action: async () => {
            try {
              await AuthService.getConsent(TokenKind.devops, '', callback);
              clearInfoBar(notify, 'DevOps_Consent');
              notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.DevOpsNotification_ConsentGrant), group: 'DevOps_Consent' });
            } catch (error) {
              const message = TextService.format(strings.DevOpsNotification_ConsentRequiredDescription);
              notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'DevOps_Consent' });
            }
          }
        }
      ]
    });
  }

  public static configure() {

    IntegrationService.hooks['devops'] = {

      isRecognizedLink: url => {
        return this.isLinkedItem(url);
      },

      getBrowserLink: (url) => {
        const parsed = new URL(url);
        return parsed.origin + parsed.pathname;
      },

      getSyncDate: (settings: IVistoPlanSettings): Date => {
        return settings?.integrations?.devops?.syncDate;
      },

      transformHtml: async (plan: IVistoPlan, html: string) => {
        const devopsUrl = DevOpsService.getPlanDevOpsUrl(plan);
        const imgRegex = /<img\s+([^>]*?)src\s*=\s*(['"])(.*?)\2(.*?)>/gi;
        const matches = [...html.matchAll(imgRegex)];
        for (const match of matches) {
          const beforeHref = match[1];
          const quote = match[2];
          const hrefOld = match[3];
          const afterHref = match[4];
          const newHref = hrefOld.startsWith(devopsUrl) ? await DevOpsService.getImageUrl(hrefOld) : hrefOld;
          const newImg = `<img ${beforeHref}src=${quote}${newHref}${quote} data-href=${quote}${hrefOld}${quote} ${afterHref}>`;
          html = html.replace(match[0], newImg);
        }
        return html;
      },
  

      getIconInfo: async (url: string): Promise<IIconInfo> => {
        const devopsUrl = DevOpsService.getDevOpsUrl(url);
        const icon = DevOpsService.getWorkItemIconFomSourceUrl(url);
        const color = DevOpsService.getWorkItemColorFomSourceUrl(url);
        const iconUrl = await DevOpsService.getWorkItemIconUrl(devopsUrl, icon, color);
        const tooltipText = TextService.format(strings.LinkIconTitle_DevOps);
        return {
          iconUrl,
          tooltipText
        };
      },

      getDashbordRef: async (url: string) => {
        const devopsUrl = DevOpsService.getDevOpsUrl(url);
        const projectName = DevOpsService.getProjectName(url);
        const projectId = await DevOpsService.getProjectIdFromProjectName(devopsUrl, projectName);
        return devopsUrl && projectName && `devops:${devopsUrl}##${projectId}`;
      },

      getCheckList: async (url: string, plan: IVistoPlan) => {
        const devopsUrl = DevOpsService.getDevOpsUrl(url);
        const itemId = DevOpsService.getWorkItemIdFromSourceUrl(url);
        const workItems = await DevOpsService.getWorkItems(devopsUrl, [itemId], true);
        const workItem = workItems[itemId];
        const checkboxes = [];
        if (workItem.relations) {
          const childIds = workItem.relations.filter(r => r.attributes?.name === 'Child').map(r => DevOpsService.getWorkItemIdFromApiUrl(r.url));
          const childWorkItems = await DevOpsService.getWorkItems(devopsUrl, childIds);

          for (const id in childWorkItems) {
            const childWorkItem = childWorkItems[id];
            const childTitle = childWorkItem.fields['System.Title'];
            const childState = childWorkItem.fields['System.State'];
            const childProject = childWorkItem.fields['System.TeamProject'];
            const childUrl = `${devopsUrl}/${childProject}/_workitems/edit/${id}`;
            const childChecked = childState === 'Done' || childState === 'Closed';
            const childRemoved = childState === 'Removed';
            const childItemType = childWorkItem.fields['System.WorkItemType'];
            if (!childRemoved) {
              const itemType = DevOpsService.getPlanItemType(plan, childItemType, childProject);
              const state = itemType.states?.find(s => s.name === childState);
              const checkbox: ICheckListItem = {
                id,
                title: childTitle,
                checked: childChecked,
                url: childUrl,
                iconUrl: itemType && await DevOpsService.getWorkItemIconUrl(devopsUrl, itemType.icon, itemType.color),
                stateColor: state?.color,
                stateText: childState
              };
              checkboxes.push(checkbox);
            };
          }
        }
        return checkboxes;
      },

      getLinkName: async (url) => {
        const name = await this.getWorkItemName(url);
        return this.formatLinkName(url, name);
      },

      allowRecalculation: (name: keyof IProgressData) => {
        return name === 'plannedPercentComplete';
      },

      allowEdit: (name: keyof IProgressData) => {
        return name !== 'percentComplete' && name !== 'plannedPercentComplete' && name !== 'startDate' && name !== 'endDate';
      },

      removalWarning: (items: IVistoListItem[]) => '',
      removalAction: (items: IVistoListItem[]) => Promise.resolve(),

      synchronize: async (p: IVistoPlan, items: IVistoListItemWithProgress[], notify: INotify, operation: Operation, options: IOperationOptions): Promise<IVistoPlan> => {

        clearInfoBar(notify, 'DevOps');
        
        const oldSettings = PlanSettingsService.getPlanSettings(p);
        if (!oldSettings?.integrations?.devops?.enabled || !LicenseService.license?.devopsEnabled) {
          return p;
        }

        const callback = async () => {
          const updates: IItemChanges<IVistoListItemWithProgress>[] = [];

          let linkedItems = PlanDataService.getItemsHaving<IVistoListItemWithProgress>(p.items, (item: IVistoListItemWithProgress) => this.isLinkedItem(item.sourceItemUrl));

          if (options?.enableSimpleUpdate) {
            linkedItems = linkedItems.filter(a => items.some(b => a.guid === b.guid));
          }

          if (!linkedItems.length) {
            return;
          }

          const devopsUrl = DevOpsService.getPlanDevOpsUrl(p);
          const itemTypeNames = DevOpsService.getPlanItemTypeNames(p);
          const ids = linkedItems.map(x => DevOpsService.getWorkItemIdFromSourceUrl(x.sourceItemUrl));
          const workItems = await DevOpsService.getWorkItems(devopsUrl, ids);
          const workItemsPercentComplete = await DevOpsService.queryProgress(devopsUrl, itemTypeNames, ids);

          const workItemsProgress = {};
          for (const linkedItem of linkedItems) {
            const itemId = DevOpsService.getWorkItemIdFromSourceUrl(linkedItem.sourceItemUrl);
            const percentComplete = workItemsPercentComplete[itemId];
            const workItem = workItems[itemId];
            workItemsProgress[itemId] = await this.getWorkItemProgress(devopsUrl, workItem, p.statusDate, percentComplete);
          }

          await SharepointUserResolver.resolveUserIds(p, getObjectValues(workItemsProgress));

          for (const linkedItem of linkedItems) {
            try {
              const itemId = DevOpsService.getWorkItemIdFromSourceUrl(linkedItem.sourceItemUrl);
              const progress = workItemsProgress[itemId];

              const initiated = !!items.find(a => a.guid === linkedItem.guid) && operation !== Operation.load;

              const unconditionalChanges = ChangesService.getChanges(linkedItem, progress, ['percentComplete', 'plannedPercentComplete', 'startDate', 'endDate']);
              if (unconditionalChanges.detected) {
                updates.push({ item: linkedItem, changes: unconditionalChanges });
              }

              const changes = ChangesService.getChanges(linkedItem, progress, ['name', 'description', 'assignedTo'], { roundDates: true });
              if (changes.detected) {
                if (initiated) {
                  await DevOpsService.updateWorkItem(devopsUrl, itemId, changes.oldValues);
                } else {
                  DevOpsNotifications.makeWorkItemNameConflictNotification(p, devopsUrl, linkedItem, itemId, changes, notify);
                }
              }
            } catch (error) {
              notifyInfoBar(notify, {
                type: NotificationType.warn,
                message: TextService.format(strings.MessageError_DevOps_BrokenLinkDescription, { 
                  title: TextService.formatTitle(linkedItem, p.items) 
                }),
                error,
                group: 'DevOps',
                guid: linkedItem.guid,
                actions: [
                  this.makeBreakDevOpsLinkNotificationAction(p, linkedItem, notify)
                ]
              });
            }
          }

          if (updates.length) {
            trackClient.debug(`Received devops data (${updates.length})`, updates);
            p = await StorageService.get(p.siteUrl).updateItems(p, updates, notify, { excludeExternals: true, excludeGroupByKind: true });
          }
        };

        try {

          await callback();

          const newSettings = { ...oldSettings, integrations: { ...oldSettings?.integrations, devops: { ...oldSettings?.integrations?.devops, syncDate: new Date() } } };
          p = PlanSettingsService.setPlanSettings(p, newSettings);

        } catch (error) {

          if (isConsentError(error)) {
            AuthService.resetAuth(TokenKind.devops);
            DevOpsDataService.makeConsentNotification(callback, notify);
          }
          else {
            const notificationType = error?.typeKey === 'WorkItemUnauthorizedAccessException'
              ? NotificationType.warn : NotificationType.error;
            notifyInfoBar(notify, {
              type: notificationType,
              group: 'DevOps',
              message: TextService.format(strings.MessageError_DevOps_UnableToUpdateProgress),
              error: error
            });
          }
        }

        return p;
      }
    };

  }

  private static findIterationDate (iteration: IDevOpsIteration, iterationPath: string, attributeName: 'startDate' | 'finishDate') {

    // format of path in the item and in the /_apis/wit/classificationnodes api is different
    const fixedIterationPath = iteration.path.split('\\').filter(x => x && x !== 'Iteration').join('\\');

    const iterationDate = DevOpsService.parseDate(iteration.attributes?.[attributeName]);

    if (fixedIterationPath === iterationPath) {
      return { found: true, date: iterationDate };
    }
    
    if (iteration.hasChildren) {
      for (const child of iteration.children) {
        const { found, date } = this.findIterationDate(child, iterationPath, attributeName);
        if (found) {
          if (date) {
            return { found, date };
          } else {
            return { found, date: iterationDate };
          }
        }
      }
    }

    return { found: false };
  }

  public static async getWorkItemProgress(devopsUrl: string, workItem: IDevOpsWorkItem, statusDate?: Date, percentComplete?: number): Promise<IProgressData> {

    const name = workItem.fields['System.Title'];
    const projectName = workItem.fields['System.TeamProject'];
    const iterationPath = workItem.fields['System.IterationPath'];
    const description = DevOpsService.parseDescription(workItem.fields['System.Description']);
    let startDate = DevOpsService.parseDate(workItem.fields['Microsoft.VSTS.Scheduling.StartDate']);
    let endDate = DevOpsService.parseDate(workItem.fields['Microsoft.VSTS.Scheduling.TargetDate']);
    const assignedTo = DevOpsService.parseAssignedTo(workItem.fields['System.AssignedTo']);

    if (!startDate) {
      const tree = await DevOpsService.getIterationsTree(devopsUrl, projectName);
      const { found, date } = this.findIterationDate(tree, iterationPath, 'startDate') ?? {};
      if (found) {
        startDate = date;
      }
    }

    if (!endDate) {
      const tree = await DevOpsService.getIterationsTree(devopsUrl, projectName);
      const { found, date } = this.findIterationDate(tree, iterationPath, 'finishDate') ?? {};
      if (found) {
        endDate = date;
      }
    }

    const plannedPercentComplete = statusDate && ProgressService.getPlannedPercentComplete(startDate, endDate, statusDate);

    const state = workItem.fields['System.State'];
    const done = state === 'Done' || state === 'Closed' || state === 'Removed';

    return {
      name,
      description,
      startDate,
      endDate,
      assignedTo,
      plannedPercentComplete,
      percentComplete: done ? 100 : percentComplete > 0 ? percentComplete : (startDate && endDate ? 0 : undefined)
    };
  }

  public static async getWorkItemProgressData(plan: IVistoPlan, url: string): Promise<IProgressData> {
    if (url) {
      const statusDate = plan.statusDate;
      const itemTypeNames = DevOpsService.getPlanItemTypeNames(plan);
      const devopsUrl = DevOpsService.getDevOpsUrl(url);
      const itemId = DevOpsService.getWorkItemIdFromSourceUrl(url);
      const workItems = await DevOpsService.getWorkItems(devopsUrl, [itemId]);
      const workItemsPercentComplete = await DevOpsService.queryProgress(devopsUrl, itemTypeNames, [itemId]);
      const workItem = workItems[itemId];
      const percentComplete = workItemsPercentComplete[itemId];
      const progress = workItem && await this.getWorkItemProgress(devopsUrl, workItem, statusDate, percentComplete);
      return progress;
    }
  }

  public static async getWorkItemName(url: string): Promise<string> {
    if (url) {
      const devopsUrl = DevOpsService.getDevOpsUrl(url);
      const itemId = DevOpsService.getWorkItemIdFromSourceUrl(url);
      const workItems = await DevOpsService.getWorkItems(devopsUrl, [itemId]);
      const workItem = workItems[itemId];
      return workItem.fields['System.Title'];
    }
  }

}
