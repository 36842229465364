import * as React from 'react';
import { Stack, Text, Link, FontSizes } from '@fluentui/react';
import { IVistoPlan, VistoDpItem, IAttachment, VistoActionItem, VistoKind, IVistoListItemWithProgress } from 'sp';
import { EditDpDialog, CopyProgressLinkDialog } from 'dialogs';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { AppContext } from 'services/AppContext';
import { AttachmentService } from 'services/AttachmentService';
import { ProgressBlock, IconButtonAttachments, IconButtonProgress, IconButtonMore, AttachDocument } from './common';
import { DeleteSourceLinkDialog, EditSourceLinkDialog } from 'dialogs/common';
import { SidebarActionList } from './SidebarActionList';
import { ExpandButton, RichTextTooltipHost } from 'components';
import { HistoryDialog } from './common/HistoryDialog';
import { useSidebarShareDialog } from './SidebarShareHook';
import { UrlService } from 'shared/urlService';
import { SidebarMenuItemDisabled } from './common/SidebarMenuItemDisabled';
import { EnvContext } from 'services/EnvContext';
import { CommentIcon } from './comment/CommentIcon';

export function SidebarLopDp(props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
}) {

  const [editDpDialogVisible, setEditDpDialogVisible] = React.useState(false);
  const [isAttachDocumentOpen, setIsAttachDocumentOpen] = React.useState(false);
  const [historyDialogVisible, setHistoryDialogVisible] = React.useState(false);
  const [editLinkDialogVisible, setEditLinkDialogVisible] = React.useState(false);
  const [deleteLinkDialogVisible, setDeleteLinkDialogVisible] = React.useState(false);
  const [copyLinkDialogVisible, setCopyLinkDialogVisible] = React.useState(false);

  const envContext = React.useContext(EnvContext);
  const { isPlanEditEnabled, isPlanLive, isPlanLocal, isPopupOpen, setIsPopupOpen, dispatchCommand, notify, propertyBag } = React.useContext(AppContext);
  React.useEffect(() => {
    setIsPopupOpen(editDpDialogVisible);
  }, [editDpDialogVisible]);

  const isPopupOpenDisabled = SidebarMenuItemDisabled.isPopupOpenDisabled(isPopupOpen);
  const isEditDisabled = SidebarMenuItemDisabled.isEditDisabled(isPlanEditEnabled) ?? isPopupOpenDisabled

  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const isBreakLinkDisabled = SidebarMenuItemDisabled.isBreakLinkDisabled(props.dp, props.plan) ?? isEditDisabled;
  const isEditLinkDisabled = SidebarMenuItemDisabled.isEditLinkDisabled(props.dp, props.plan) ?? isEditDisabled;

  const menuItems = [
    MenuItems.getDefaultMenuItemDp(isPopupOpenDisabled, isPlanEditEnabled, () => setEditDpDialogVisible(true)),
    MenuItems.getAttachmentsMenuItem(() => setIsAttachDocumentOpen(true), isEditDisabled),
    MenuItems.getShowHistoryDialogMenu(isHistoryDisabled, () => setHistoryDialogVisible(true)),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, UrlService.getSelectionFromItem(props.dp))),
    MenuItems.getProgressLinkMenuItem([
      MenuItems.getOpenProgressLinkMenuItem(props.dp, envContext),
      MenuItems.getEditProgressLinkMenuItem(() => setEditLinkDialogVisible(true), isEditLinkDisabled),
      MenuItems.getBreakProgressLinkMenuItem(() => setDeleteLinkDialogVisible(true), isBreakLinkDisabled),
      MenuItems.getDividerMenuItem(1),
      MenuItems.getCopyToClipboardMenuItem(() => setCopyLinkDialogVisible(true)),
    ]),
  ];

  const onAttachDocument = (attachment: IAttachment) => {
    setIsAttachDocumentOpen(false);
    if (attachment) {
      dispatchCommand(AttachmentService.makeAddAttachmentCommand(props.dp, attachment, AttachmentService.makeItemAttachmentsCommand, notify), { wrap: true });
    }
  };

  const isExpanded = propertyBag?.expanded?.[props.dp.guid];

  return (
    <>
      <Stack horizontal verticalAlign='start'>
        <ExpandButton expandKey={props.dp.guid} />
        <Stack grow>
          <Stack horizontal verticalAlign='center' horizontalAlign='space-between'>
            <RichTextTooltipHost tooltip={props.dp.description} >
              <Link disabled={isPopupOpen} onClick={() => setEditDpDialogVisible(true)}>
                <Text variant='xLarge'>{props.dp.name}</Text>
              </Link>
            </RichTextTooltipHost>
            <Stack horizontal>
              <CommentIcon plan={props.plan} item={props.dp} />
              <IconButtonAttachments item={props.dp} />
              <IconButtonProgress
                plan={props.plan}
                disabled={isPopupOpen}
                item={props.dp}
              />
              <IconButtonMore menuItems={menuItems} fontSize={FontSizes.large} />
            </Stack>
          </Stack>

          <ProgressBlock item={props.dp} plan={props.plan} />
        </Stack>
      </Stack>
      {isExpanded && <SidebarActionList plan={props.plan} dp={props.dp} />}
      {editDpDialogVisible && <EditDpDialog onDismiss={() => setEditDpDialogVisible(false)} plan={props.plan} dp={props.dp} />}
      {editLinkDialogVisible && <EditSourceLinkDialog plan={props.plan} item={props.dp} onDismiss={() => setEditLinkDialogVisible(false)} />}
      {deleteLinkDialogVisible && <DeleteSourceLinkDialog plan={props.plan} item={props.dp} onDismiss={() => setDeleteLinkDialogVisible(false)} />}
      {copyLinkDialogVisible && <CopyProgressLinkDialog plan={props.plan} onDismiss={() => setCopyLinkDialogVisible(false)} item={props.dp} />}
      {isAttachDocumentOpen && <AttachDocument onChange={onAttachDocument} isOpen={isAttachDocumentOpen} />}
      {historyDialogVisible && <HistoryDialog item={props.dp} plan={props.plan} onDismiss={() => setHistoryDialogVisible(false)} />}
      {ShareLinkDialog}
      </>
  );
}
