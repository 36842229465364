import React from 'react';
import strings from 'VistoWebPartStrings';
import { BasicDialog } from './BasicDialog';
import { ITag, Stack, TextField } from '@fluentui/react';
import { TeamPicker } from '@pnp/spfx-controls-react/lib/controls/TeamPicker';
import { TeamChannelPicker } from '@pnp/spfx-controls-react/lib/controls/TeamChannelPicker';
import { SharepointService } from 'services/SharepointService';
import { EnvContext } from 'services/EnvContext';
import { TextService } from 'services/TextService';
import { UrlService } from 'shared/urlService';
import { InfoBar, useErrorInfo } from 'components';
import { isConsentError } from 'shared/parse';
import { clearInfoBar, IBasicNotify, NotificationType, notifyInfoBar } from 'services/Notify';
import { AuthService } from 'services/AuthService';
import { TokenKind } from 'shared/TokenKind';

const makeConsentNotification = async (callback: () => Promise<void>, notify: IBasicNotify) => {

  AuthService.resetAuth(TokenKind.dashboard);

  notifyInfoBar(notify, {
    message: TextService.format(strings.SitePickerConsent_Message),
    group: 'SitePicker_Consent',
    type: NotificationType.warn,
    error: TextService.format(strings.SitePickerConsent_Details),
    actions: [
      {
        title: TextService.format(strings.SitePickerConsent_Button),
        action: async () => {
          try {
            await AuthService.getConsent(TokenKind.sites, '', callback);
            clearInfoBar(notify, 'SitePicker_Consent');
            notifyInfoBar(notify, { type: NotificationType.success, message: TextService.format(strings.SitePickerConsent_Succeeded), group: 'SitePicker_Consent' });
          } catch (error) {
            const message = TextService.format(strings.SitePickerConsent_Failed);
            notifyInfoBar(notify, { type: NotificationType.error, message, error, group: 'SitePicker_Consent' });
          }
        }
      }
    ]
  });
}

export const SitePickerDialog = (props: {
  siteUrl: string;
  onCommit: (siteUrl: string) => Promise<any>;
  onDismiss: () => void;
}) => {

  const { userObjectId } = React.useContext(EnvContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [siteUrl, setSiteUrl] = React.useState(props.siteUrl);

  const [selectedTeams, setSelectedTeam] = React.useState<ITag[]>([]);
  const [selectedChannels, setSelectedChannels] = React.useState<ITag[]>([]);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const onSelectedTeams = (tagList: ITag[]) => {
    setSelectedTeam(tagList);
    setSelectedChannels([]);
  }

  const onSelectedChannels = async (tagList: ITag[]) => {
    setSelectedChannels(tagList);
    if (selectedTeams.length > 0) {
      const selectedTeam = selectedTeams[0].key as string;
      if (tagList.length > 0) {
        const selectedChannel = tagList[0].key as string;

        const action = async () => {
          setIsLoading(true);
          const siteUrl = await SharepointService.getTeamSiteUrl(userObjectId, selectedTeam, selectedChannel);
          setSiteUrl(siteUrl);
          setIsLoading(false);
        }

        try {
          await action();
        } catch (error) {
          if (isConsentError(error)) {
            makeConsentNotification(action, {
              addNotification: (n) => setErrorInfo(n),
              clearNotifications: () => setErrorInfo(null)
            })
          } else {
            setErrorInfo({
              type: NotificationType.error,
              message: TextService.format(strings.SitePickerService_Error),
              error
            });
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
  }

  const onCommit = async () => {
    props.onCommit(siteUrl ? siteUrl : UrlService.LOCAL_URL);
  }

  return (
    <BasicDialog onDismiss={props.onDismiss}
      buttonOkAction={onCommit}
      minWidth={450}
      maxWidth={500}
      isButtonOkDisabled={siteUrl === props.siteUrl}
      buttonOkText={TextService.format(strings.ButtonOK)}
      buttonOkBusyText={TextService.format(strings.ButtonSaving)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
      title={TextService.format(strings.SitePickerDialog_Title)}
      subText={TextService.format(strings.SitePickerDialog_SubTitle)}
    >
      <Stack tokens={{ childrenGap: 'm' }}>
        <InfoBar {...errorInfo} />
        <Stack horizontal tokens={{ childrenGap: 'm' }}>
          <TeamPicker
            label={TextService.format(strings.PickupLabel_SelectTeam)}
            selectedTeams={selectedTeams}
            itemLimit={1}
            onSelectedTeams={onSelectedTeams}
          />
          <TeamChannelPicker
            label={TextService.format(strings.PickupLabel_SelectChannel)}
            teamId={selectedTeams[0]?.key}
            itemLimit={1}
            selectedChannels={selectedChannels}
            onSelectedChannels={onSelectedChannels}
          />
        </Stack>

        <TextField
          label={TextService.format(strings.SitePickerDialog_SiteLabel)}
          value={isLoading ? strings.SitePickerDialog_LoadingSiteLabel : siteUrl}
          disabled={isLoading}
          onChange={(e, newValue) => setSiteUrl(newValue)}
        />
      </Stack>
    </BasicDialog>
  )
}
