import { DashboardDataService } from 'services/DashboardDataService';
import { StorageService } from 'services/StorageService';
import { IVistoPlan } from 'sp';
import { IUpgradeCard } from './IUpgradeCard';
import { EnvContext } from 'services/EnvContext';
import React from 'react';

export const useUpgradeTreeService = () => {

  const envContext = React.useContext(EnvContext);

  const getSiteCardSet = async (siteGroups: any, siteUrl: string) => {

    const siteCards = siteGroups[siteUrl];
    await DashboardDataService.loadSitePlanProps(siteUrl, siteCards, ['name', 'planId', 'planVersion'], null);

    const siteCardSet = {}
    for (const key in siteCards) {
      const siteCard = siteCards[key];
      siteCardSet[siteCard.key] = siteCard;
    }
    return siteCardSet;
  }

  const getPlanFromKey = async (key: string): Promise<IVistoPlan> => {
    const re = new RegExp(`^visplan:(.+)##(.*)`);
    const match = re.exec(key);
    if (match) {
      const siteUrl = match[1];
      const planId = match[2];
      const plan = await StorageService.get(siteUrl).loadPlanItem({ siteUrl, planId }, ['name', 'planId', 'planVersion']);
      return plan;
    }
  }

  const getCards = async (): Promise<IUpgradeCard[]> => {
    return await DashboardDataService.getCardsFromPlanItems(envContext);
  }

  return {
    getCards,
    getSiteCardSet,
    getPlanFromKey
  }
}
