import React from 'react';
import { SvgShapes } from 'graphics/SvgShapes';
import { ColorService } from 'services/colorService';
import { TextService } from 'services/TextService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';

export const OkrUserPicture = (props: {
  size: number;
  userName: string;
  displayName: string;
}) => {

  const color = ColorService.getUserColor(props.userName);
  const imageUrl = UserInfoPhotoService.getCachedUserPhotoUrl(props.userName);

  const svg = imageUrl
    ? SvgShapes.makeCircleImageSvg({
      size: props.size,
      strokeColor: '#ccc',
      stroke: 2,
      url: imageUrl
    })
    : SvgShapes.makeCircleTextShape({
      size: props.size,
      fillColor: color,
      strokeColor: '#ccc',
      stroke: 2,
      text: props.userName ? TextService.getInitials(props.displayName) : props.displayName
    });

  return <div style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: svg }} />;
}
