import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Progress } from '@pnp/spfx-controls-react/lib/controls/progress';
import { Stack, Text, Spinner, Icon, IIconStyleProps, FontSizes } from '@fluentui/react';
import { IExecutableAction } from 'services/IExecutableAction';
import { TextService } from 'services/TextService';
import { ProgressStatusText } from 'dialogs/common/ProgressStatusText';

export function UpgradeProgressPage(props: {
  statusText: string;
  actions: IExecutableAction[];
  currentProgressActionIndex?: number;
}) {

  const isRunning = TextService.isValidNumber(props.currentProgressActionIndex) && props.currentProgressActionIndex < props.actions.length;
  const isFinished = TextService.isValidNumber(props.currentProgressActionIndex) && props.currentProgressActionIndex === props.actions.length;

  const success = !props.actions.some(a => a.hasError);
  const finishIconName = success ? 'CheckMark' : 'Error';
  const getStyles = (styleProps: IIconStyleProps) => {
    return {
      root: {
        fontSize: FontSizes.large,
        color: success
          ? styleProps.theme.semanticColors.successIcon
          : styleProps.theme.semanticColors.errorIcon
      }
    };
  };

  return (
    <Stack grow>
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 'm' }}>
        {isRunning && <Spinner />}
        {isFinished && <Icon iconName={finishIconName} styles={getStyles} />}
        <ProgressStatusText statusText={props.statusText} />
      </Stack>
      <Progress
        height='250px'
        showOverallProgress={true}
        showIndeterminateOverallProgress={false}
        hideNotStartedActions={false}
        actions={props.actions}
        currentActionIndex={props.currentProgressActionIndex}
        longRunningText={TextService.format(strings.PlanWizardProgressPage_LongerThanExpected)}
        longRunningTextDisplayDelay={40000}
      />
    </Stack>
  );
}
