import { CellKind } from './CellKind';
import { env } from './clientEnv';
import { VistoKind } from './VistoKind';
import { ISelectedCellInfo } from './ISelectedCellInfo';
import { IEnvContext } from 'services/EnvContext';

export class UrlService {

  public static LOCAL_URL = 'local:local';

  public static combine(left: string, right: string) {
    if (!right) return left;
    if (!left) return right;
    return left.replace(/\/+$/, '') + '/' + right.replace(/^\/+/, '');
  }

  public static getOrigin(url: string) {
    return url && !UrlService.isLocalUrl(url) && new URL(url).origin;
  }

  public static haveSameOrigin(a: string, b: string) {
    return UrlService.getOrigin(a) === UrlService.getOrigin(b);
  }

  public static makeInstanceId(siteUrl: string, planId: string): string {
    return UrlService.combine(siteUrl, planId);
  };

  public static getDomain(url: string) {
    return url && new URL(url).host;
  }

  public static getPathName(url: string) {
    return url && new URL(url).pathname;
  }

  public static isLocalUrl(siteUrl: string) {
    return siteUrl && siteUrl.startsWith(UrlService.LOCAL_URL);
  };

  public static getListName(kind: VistoKind, planId: string) {
    return kind === VistoKind.Plan ? `Visto_Plans` : `Visto_${VistoKind[kind]}_${planId}`;
  }

  public static getListRelativeUrl(plan: { siteUrl: string, planId: string }, kind: VistoKind = VistoKind.Plan) {
    const listName = UrlService.getListName(kind, plan.planId);
    const listRelativeUrl = UrlService.combine('Lists', listName);
    const siteRelativeUrl = UrlService.getPathName(plan.siteUrl);
    return UrlService.combine(siteRelativeUrl, listRelativeUrl);
  }

  public static makeSitePagesListUrl(siteUrl: string) {
    return UrlService.combine(siteUrl, 'SitePages');
  }

  public static makePageUrl(siteUrl: string, fileName: string): string {
    return UrlService.combine(UrlService.makeSitePagesListUrl(siteUrl), `${fileName}.aspx`);
  }

  public static getLinkAppId(envContext: IEnvContext): string {
    const appIds = env.getTemplateParamValue('INTERNAL_APPLICATION_ID');
    if (!appIds.includes(';') && !appIds.includes('=')) {
      return appIds;
    }
    const items = appIds.split(';');
    for (const item of items) {
      if (item.includes('=')) {
        const [tid, id] = item.split('=');
        if (tid === envContext.tid) {
          return id;
        }
      } else {
        return item;
      }
    }
  }

  public static makeConfigurableTabDeepLink(siteUrl: string, planId: string, channelId: string, subEntityId: string, envContext: IEnvContext): string {
    const appId = UrlService.getLinkAppId(envContext);
    const encodedContext = encodeURI(`{\x22subEntityId\x22:\x22${siteUrl}||${planId}||${subEntityId}\x22,\x22channelId\x22:\x22${channelId}\x22}`);
    const result = `https://teams.microsoft.com/l/entity/${appId}/${planId}?context=${encodedContext}`;
    return result;
  }

  public static makePersonalTabDeepLink(siteUrl: string, planId: string, subEntityId: string, envContext: IEnvContext): string {
    const appId = UrlService.getLinkAppId(envContext);
    const entityId = env.getTemplateParamValue('PERSONAL_TAB_ID');
    const encodedContext = encodeURI(`{\x22subEntityId\x22:\x22${siteUrl}||${planId}||${subEntityId}\x22}`);
    const result = `https://teams.microsoft.com/l/entity/${appId}/${entityId}?context=${encodedContext}`;
    return result;
  }

  public static makeChannelDeepLink(channelId: string): string {
    const result = `https://teams.microsoft.com/l/channel/${channelId}`;
    return result;
  }

  public static makeAppDeepLink(envContext: IEnvContext): string {
    const appId = UrlService.getLinkAppId(envContext);
    const result = `https://teams.microsoft.com/l/app/${appId}`;
    return result;
  }

  public static getPlanFromSubEntityId(subEntityId: string): { siteUrl: string, planId: string } {
    if (subEntityId) {
      const items = subEntityId.split('||');
      if (items.length > 2) {
        const siteUrl = decodeURIComponent(items[0]);
        const planId = decodeURIComponent(items[1]);
        return { siteUrl, planId };
      }
    }
  }

  public static parseSubEntityId(subEntityId: string): ISelectedCellInfo {
    if (subEntityId) {
      const subEntityIdParts = subEntityId.split('||');
      const lasItem = subEntityIdParts[subEntityIdParts.length - 1];
      const items = lasItem.split('|');
      const cellKind = CellKind[items[0]];
      const cellId = items[1];
      const guid = items[items.length - 1];
      return { cellKind, cellId, guid };
    }
  }

  public static makeSubEntityId(selection: ISelectedCellInfo) {
    return selection ? `${CellKind[selection.cellKind]}|${selection.cellId}|${selection.guid}` : null;
  }

  public static get isDev() {
    const hostname = document?.location?.hostname;
    return hostname?.startsWith('localhost') || hostname?.startsWith('dev');
  }

  public static getSelectionFromItem(item: { kind: VistoKind, guid?: string, dpGuid?: string }): ISelectedCellInfo {
    switch (item.kind) {
      case VistoKind.Plan:
        return { cellKind: CellKind.TITLE, cellId: item.guid, guid: item.guid };
      case VistoKind.Action:
        return { cellKind: CellKind.DP, cellId: item.dpGuid, guid: item.guid };
      case VistoKind.DP:
        return { cellKind: CellKind.DP, cellId: item.guid, guid: item.guid };
      case VistoKind.LOP:
        return { cellKind: CellKind.LOP, cellId: item.guid, guid: item.guid };
      case VistoKind.Focus:
        return { cellKind: CellKind.FOCUS, cellId: item.guid, guid: item.guid };
      case VistoKind.SO:
        return { cellKind: CellKind.POSITION, cellId: item.guid, guid: item.guid };
      case VistoKind.KeyResult:
        return { cellKind: CellKind.POSITION, cellId: item.guid, guid: item.guid };
    }
  }

  public static getSourceLinkFromItem(
    plan: { siteUrl: string, planId: string },
    item: { kind: VistoKind, itemId?: number, guid?: string, dpGuid?: string },
    channelId: string,
    envContext: IEnvContext
  ): string {

    const listUrl = UrlService.getListRelativeUrl(plan, item.kind);

    let link = `${UrlService.getOrigin(plan.siteUrl)}${listUrl}/DispForm.aspx?ID=${item.itemId}`;

    const selection: ISelectedCellInfo = UrlService.getSelectionFromItem(item);
    const subEntityId = UrlService.makeSubEntityId(selection);

    const deepLink = channelId
      ? UrlService.makeConfigurableTabDeepLink(plan.siteUrl, plan.planId, channelId, subEntityId, envContext)
      : UrlService.makePersonalTabDeepLink(plan.siteUrl, plan.planId, subEntityId, envContext);

    link += `&deepLink=${encodeURIComponent(deepLink)}`;
    return link;
  }

  public static transformProgressUrl(src: string, envContext: IEnvContext) {
    const parsed = new URL(src);
    const planId = /\/Lists\/Visto_\w+_(\w+)/.exec(parsed.pathname)?.[1];
    const siteUrl = parsed.origin + parsed.pathname.replace(/\/Lists\/.*/, '');
    const deepLink = parsed.searchParams.get('deepLink');
    if (deepLink) {
      const deepLinkUrl = new URL(deepLink);
      const context = deepLinkUrl.searchParams.get('context');
      if (context) {
        const parsedContext = JSON.parse(context);
        const subEntityId = UrlService.makeSubEntityId(UrlService.parseSubEntityId(parsedContext.subEntityId));
        const channelId = parsedContext.channelId;
        if (envContext?.isTeams) {
          if (channelId) {
            return UrlService.makeConfigurableTabDeepLink(siteUrl, planId, channelId, subEntityId, envContext);
          } else {
            return UrlService.makePersonalTabDeepLink(siteUrl, planId, subEntityId, envContext);
          }
        } else {
          return `/teams?planId=${planId}&siteUrl=${encodeURIComponent(siteUrl)}`;
        }
      }
      return deepLink;
    } else {
      if (envContext?.isTeams) {
        return UrlService.makePersonalTabDeepLink(siteUrl, planId, undefined, envContext);
      } else {
        return `/teams?planId=${planId}&siteUrl=${encodeURIComponent(siteUrl)}`;
      }
    }
  }

  public static makeHomeUrl(envContext: IEnvContext): string {
    if (envContext?.isPortal) {
      return '/';
    } else {
      const appId = UrlService.getLinkAppId(envContext);
      const entityId = env.getTemplateParamValue('PERSONAL_TAB_ID');
      const result = `https://teams.microsoft.com/l/entity/${appId}/${entityId}`;
      return result;
    }
  }

  public static makePlanUrl(siteUrl: string, planId: string , channelId: string, envContext: IEnvContext): string {
    if (envContext?.isPortal) {
      return UrlService.isLocalUrl(siteUrl)
        ? `/local?planId=${planId}`
        : `/teams?planId=${planId}&siteUrl=${encodeURIComponent(siteUrl)}`;
    } else {
      return (channelId && envContext?.isTeams)
        ? UrlService.makeConfigurableTabDeepLink(siteUrl, planId, channelId, null, envContext)
        : UrlService.makePersonalTabDeepLink(siteUrl, planId, null, envContext);
    }
  };

  public static getSharePointSiteUrl(fileAbsoluteUrl: string) {
    const origin = UrlService.getOrigin(fileAbsoluteUrl);
    const pathname = UrlService.getPathName(fileAbsoluteUrl);
    return pathname.startsWith('/sites/')
      ? UrlService.combine(origin, pathname.split('/').slice(0, 3).join('/'))
      : '';
  }
}
