import React from 'react';
import { PrimaryButton, Stack, TextField } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { SitePickerDialog } from './SitePickerDialog';
import strings from 'VistoWebPartStrings';
import { UrlService } from 'shared/urlService';

export const SitePicker = (props: {
  label: string;
  siteUrl: string;
  setSiteUrl: (url: string) => void;
  disabled?: boolean;
  title?: string;
}) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const onCommit = async (siteUrl: string) => {
    props.setSiteUrl(siteUrl);
  }

  const siteUrlText = UrlService.isLocalUrl(props.siteUrl)
    ? TextService.format(strings.Message_LocationLocalBrowser)
    : props.siteUrl;

  return (
    <Stack tokens={{ childrenGap: 's2' }}>
      <TextField
        label={props.label}
        value={siteUrlText}
        disabled={props.disabled}
        title={props.title}
        readOnly
      />
      <Stack.Item align='end'>
        <PrimaryButton
          disabled={props.disabled}
          title={props.title}
          text={TextService.format(strings.SitePicker_ButtonBrowse)}
          onClick={() => setIsOpen(true)}
        />
      </Stack.Item>
      {isOpen && <SitePickerDialog
        siteUrl={props.siteUrl}
        onDismiss={() => setIsOpen(false)}
        onCommit={onCommit}
      />}
    </Stack>
  );
}
