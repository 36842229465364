
export class FileService {

  public static export(fileName, type, text) {

    var element = document.createElement('a');
    element.setAttribute('href', `data:${type},${encodeURIComponent(text)}`);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public static prompt(accept?: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      if (accept) {
        input.accept = accept;
      }

      input.onchange = () => {
        // getting a hold of the file reference
        const file = input.files[0];
        resolve(file);
      };

      input.click();
    });
  }

  public static import(accept: string): Promise<{ fileName: string, content: string }> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = accept;

      input.onchange = () => {

        // getting a hold of the file reference
        const file = input.files[0];

        // setting up the reader
        const reader = new FileReader();
        reader.readAsText(file);

        // here we tell the reader what to do when it's done reading...
        reader.onload = () => {
          const xml = reader.result; // this is the content!

          try {
            resolve({ fileName: file.name, content: xml as string });
          }
          catch (e) {
            reject(e);
          }
        };
      };

      input.click();
    });
  }
}
