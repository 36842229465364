import { Stack, IconButton, Slider } from '@fluentui/react'
import React from 'react'
import { EnvContext } from 'services/EnvContext';

export const StatusBarMatrixZoom = (props: {
  fontSize: number;
  setFontSize: (number) => void;
}) => {

  const formatSliderValue = (value: number) => {
    return `${Math.round(value * 100 / 14)}%`;
  };

  const { isMobile } = React.useContext(EnvContext);

  return (
    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '0 s2' }}>
      <IconButton onClick={() => props.setFontSize(props.fontSize - 1)} iconProps={{ iconName: 'ZoomOut' }} />
      {!isMobile && <Slider styles={{ line: { minWidth: '4em' } }}
        min={3}
        max={30}
        step={1}
        showValue
        snapToStep
        value={props.fontSize}
        onChange={(val) => props.setFontSize(val)}
        valueFormat={formatSliderValue}
      />}
      <IconButton onClick={() => props.setFontSize(props.fontSize + 1)} iconProps={{ iconName: 'ZoomIn' }} />
    </Stack>
  )
}
