import * as React from 'react';
import * as strings from 'VistoWebPartStrings';
import { DefaultButton, Dialog, DialogType, Stack, Text, Link, Spinner, PrimaryButton, TextField, useTheme, Toggle } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { IVistoPlan } from 'sp';
import { trackClient } from 'services/trackClient';
import { EditorUi } from 'frames/TopFrame/drawing/common';
import { InfoBar, useErrorInfo } from 'components';
import { ExportLinkPage } from './ExportLinkPage';
import { defaultModalProps } from 'dialogs/common';
import { ExportPlanFileNamePage } from './ExportPlanFileNamePage';
import { ImageCacheService } from 'services/ImageCacheService';
import { AppContext } from 'services/AppContext';
import { PlanSettingsService } from 'services/PlanSettingsService';

export function ExportPlanPngDialog(props: {
  plan: IVistoPlan;
  editorUi: EditorUi;
  onDismiss?: () => void;
}) {

  React.useEffect(() => trackClient.page('ExportPlanPngDialog'), []);

  const { siteUrl, defaultFolderRelativeUrl } = React.useContext(EnvContext);
  const { propertyBag } = React.useContext(AppContext);

  const [selectedFolder, setSelectedFolder] = React.useState(defaultFolderRelativeUrl);
  const [planUrl, setPlanUrl] = React.useState('');

  const defaultFileName = TextService.makeExportFileName(props.plan.name, '.png');
  const [fileName, setFileName] = React.useState(defaultFileName);
  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [uploading, setUploading] = React.useState(false);

  const [page, setPage] = React.useState<'name' | 'done'>('name');

  const planSettings = PlanSettingsService.getPlanSettings(props.plan);
  const [includeOverlays, setIncludeOverlays] = React.useState(planSettings.showProgressIcons || planSettings.showIndicators  || planSettings.showKpiIndicators);

  const theme = useTheme();

  const upload = () => {
    setUploading(true);
    const graph = props.editorUi.editor.graph;
    ImageCacheService.getPreviewPng({
      embedImages: true,
      drawingXml: props.plan.drawingXml,
      styleJson: props.plan.styleJson,
      theme: theme,
      width: graph.pageFormat.width,
      height: graph.pageFormat.height,
      plan: props.plan,
      propertyBag: propertyBag,
      includeOverlays: includeOverlays,
    }).then(png => {
      var file = new File([png], fileName, { type: 'image/png' });
      StorageService.get(siteUrl).putFile(siteUrl, selectedFolder, fileName, file).then(addedFile => {
        setPlanUrl(addedFile.fileAbsoluteUrl);
        setPage('done');
        setUploading(false);
      }, error => {
        setUploading(false);
        setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.ExportPlan_UnableToUploadTheImage), error });
      });
    }, error => {
      setUploading(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.ExportPlan_UnableToUploadTheImage), error });
    });
  };

  return (
    <Dialog
      minWidth={640}
      hidden={false}
      dialogContentProps={{ title: TextService.format(strings.ExportPlanDialog_Title), type: DialogType.normal }}
      modalProps={defaultModalProps}
      onDismiss={() => props.onDismiss()} >

      <Stack style={{ minHeight: 300 }} tokens={{ childrenGap: 'm' }}>
        <InfoBar {...errorInfo} />

        {page === 'name' && 
          <>
            <ExportPlanFileNamePage suffix='.png' fileName={fileName} setFileName={setFileName} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
            <Toggle inlineLabel label={TextService.format(strings.ExportImage_IncludeIndicators)} checked={includeOverlays} onChange={(ev, checked) => setIncludeOverlays(!!checked)} />
          </>}
        {page === 'done' && <ExportLinkPage link={planUrl} fileName={fileName} />}
      </Stack>

      <Stack horizontal horizontalAlign='end' tokens={{ padding: 'm 0 0 0', childrenGap: 'm' }} grow>
        {page === 'name' && <PrimaryButton disabled={uploading} onClick={() => upload()}>{uploading ? <Spinner style={{ marginRight: 8 }} /> : null}{uploading ? TextService.format(strings.Button_Exporting) : TextService.format(strings.Button_Export)}</PrimaryButton>}
        <DefaultButton onClick={() => props.onDismiss()}>{page === 'done' ? TextService.format(strings.ButtonClose) : TextService.format(strings.ButtonCancel)}</DefaultButton>
      </Stack>
    </Dialog>
  );
}
