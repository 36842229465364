import { VistoKind, IVistoListItemWithProgress, addListDefinition, IFieldDefinitions, ListItemUpdater } from './common';
import { FieldGenerator } from './common/FieldGenerator';

export interface VistoSoItem extends IVistoListItemWithProgress {
  sortOrder?: number;
  originalPlanId: string;
  local?: boolean;
}

export const VistoSoListFields: IFieldDefinitions<VistoSoItem> = {
  itemId: FieldGenerator.makeIdField('VISTO_SO_ItemId'),
  guid: FieldGenerator.makeGuidField('VISTO_SO_Guid'),
  name: FieldGenerator.makeTitleField('VISTO_SO_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_SO_Description'),
  links: FieldGenerator.makeLinksField('VISTO_SO_Links'),
  sortOrder: FieldGenerator.makeNumberField('VISTO_SO_SortOrder', 'SortOrder', true),
  assignedTo: FieldGenerator.makeUserField('VISTO_SO_AssignedTo', 'AssignedTo', true),

  local: FieldGenerator.makeBooleanField('VISTO_SO_Local', 'Local', true),

  createdDate: FieldGenerator.makeCreatedField('VISTO_SO_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_SO_Modified'),
  originalPlanId: FieldGenerator.makeTextField('VISTO_SO_OriginPlanId', 'Origin', true),
};

addListDefinition({

  kind: VistoKind.SO,
  fields: VistoSoListFields,
  enableVersioning: true,
  majorVersionLimit: 500,
  // hasProgress: true,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoSoListFields.description.name}' />
      <FieldRef Name='${VistoSoListFields.assignedTo.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<VistoSoItem>) => {
    updater.setItemSpField(VistoSoListFields, spItem, update, 'sortOrder');
    updater.setItemSpField(VistoSoListFields, spItem, update, 'assignedTo');
    updater.setItemSpField(VistoSoListFields, spItem, update, 'originalPlanId');
    updater.setItemSpField(VistoSoListFields, spItem, update, 'local');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<VistoSoItem>, spItem) => {
    updater.getItemSpField(VistoSoListFields, item, spItem, 'sortOrder');
    updater.getItemSpField(VistoSoListFields, item, spItem, 'assignedTo');
    updater.getItemSpField(VistoSoListFields, item, spItem, 'originalPlanId');
    updater.getItemSpField(VistoSoListFields, item, spItem, 'local');
  }
});
