import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { Stack, Slider, IContextualMenuProps, ContextualMenuItemType, IconButton, Text, CommandButton } from '@fluentui/react';

import { EditorUi, mxglobals } from 'frames/TopFrame/drawing/common';
import { FixedViewType, mx, ViewAlignment } from 'frames/TopFrame/drawing/common';
import { CustomZoomDialog } from 'dialogs/CustomZoomDialog';
import { TextService } from 'services/TextService';
import { EnvContext } from 'services/EnvContext';
import { useZoomInfo } from 'services/useZoomInfo';

export function StatusBarZoom(props: {
  editorUi: EditorUi;
  zoomInfoKey: string;
  viewAlignmentX: ViewAlignment;
  viewAlignmentY: ViewAlignment;
}) {

  const graph = props.editorUi?.editor?.graph;

  const scales = [0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4];

  const [showCustomZoomDialog, setShowCustomZoomDialog] = React.useState(false);

  const [, updateZoomInfo] = useZoomInfo(props.zoomInfoKey);

  const zoomTo = (scale: number) => {
    graph.zoomTo(scale, true);
    updateZoomInfo({ scale });
    props.editorUi.chromelessResize(false, null, 0, 0);
  };

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'fitPage',
        text: TextService.format(strings.StatusBarZoom_FitPage),
        onClick: () => {
          mx.setFixedView(graph, null, FixedViewType.FitPage, props.viewAlignmentX, props.viewAlignmentY);
          updateZoomInfo({ scale: graph.view.scale});
        }
      },
      {
        key: 'fillPage',
        text: TextService.format(strings.StatusBarZoom_FillPage),
        onClick: () => {
          mx.setFixedView(graph, null, FixedViewType.FillPage, props.viewAlignmentX, props.viewAlignmentY);
          updateZoomInfo({ scale: graph.view.scale });
        }
      },
      {
        key: 'customZoom',
        text: TextService.format(strings.StatusBarZoom_CustomZoom),
        onClick: () => setShowCustomZoomDialog(true)
      },
      {
        key: 'zoom',
        itemType: ContextualMenuItemType.Divider
      },
      ...scales.map(scale => ({
        key: `zoom${(scale * 100).toFixed(0)}`,
        text: `${(scale * 100).toFixed(0)}%`,
        onClick: () => zoomTo(scale)
      }))
    ],
  };

  const view = graph?.view;

  const positionText = () => {
    if (view)
      return `${Math.round(view.translate.x)}, ${Math.round(view.translate.y)}`;
  };

  const [index, setIndex] = React.useState(4);
  const [position, setPosition] = React.useState(positionText());

  React.useEffect(() => {

    if (graph) {

      const updateZoom = (sender, evt) => {
        const scale = evt.getProperty('scale');
        updateZoomInfo({ scale });
        setIndex(getIndex(scale))
      };
      
      const updatePosition = (sender, evt) => {
        setPosition(positionText());
      }

      const updateZoomAndPosition = (sender, evt) => {
        updateZoom(sender, evt);
        updatePosition(sender, evt);
      };

      view.addListener(mxglobals.mxEvent.SCALE, updateZoom);
      view.addListener(mxglobals.mxEvent.TRANSLATE, updatePosition);
      view.addListener(mxglobals.mxEvent.SCALE_AND_TRANSLATE, updateZoomAndPosition);
      return () => {
        view.removeListener(updateZoom);
        view.removeListener(updatePosition);
        view.removeListener(updateZoomAndPosition);
      };
    }
  }, [graph]);

  const getIndex = (v) => {
    return 0 + (v - scales[0]) * (scales.length - 1) / (scales[scales.length-1] - scales[0]);
  };

  const getScale = (v) => {
    return scales[0] + v * (scales[scales.length-1] - scales[0]) / (scales.length - 1);
  };

  const onSliderChange = (v: number) => {
    if (graph) {
      zoomTo(getScale(v));
    }
  };

  const formatSliderValue = () => {
    if (graph)
      return `${Math.round(graph?.view.scale * 100)}%`;
  };

  const zoomIn = () => {
    if (graph) {
      graph['lazyZoom'](true);
    }
  };

  const zoomOut = () => {
    if (graph) {
      graph['lazyZoom'](false);
    }
  };

  const onZoom = (save: boolean, scale: number) => {
    if (save) {
      zoomTo(scale / 100);
    }
    setShowCustomZoomDialog(false);
  };

  const { isMobile } = React.useContext(EnvContext);
  
  return (
    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '0 s2' }}>
      <IconButton onClick={zoomOut} iconProps={{ iconName: 'ZoomOut' }} />
      {!isMobile && <Slider styles={{ line: { minWidth: '4em' } }}
        min={0}
        max={scales.length - 1}
        step={1}
        showValue
        snapToStep
        value={index}
        onChange={onSliderChange}
        valueFormat={formatSliderValue} />}
      <IconButton onClick={zoomIn} iconProps={{ iconName: 'ZoomIn' }} />
      <CommandButton text={isMobile ? '' : TextService.format(strings.StatusBarZoom_Zoom)} iconProps={{ iconName: 'Zoom' }} menuProps={menuProps} />
      {showCustomZoomDialog && <CustomZoomDialog onDismiss={onZoom} percents={Math.round(graph.view.scale * 100)} />}
    </Stack>
  );
}
