import { mxgraph } from 'ts-mxgraph-typings';
import { mxglobals } from './mxglobals';

type Align = { horizontal: string, vertical: string };

export class OverlayService {

  public static alignBottomRight: Align = {
    horizontal: mxglobals.mxConstants.ALIGN_RIGHT,
    vertical: mxglobals.mxConstants.ALIGN_BOTTOM,
  };

  public static alignTopRight: Align = {
    horizontal: mxglobals.mxConstants.ALIGN_RIGHT,
    vertical: mxglobals.mxConstants.ALIGN_TOP,
  };

  public static alignTopLeft: Align = {
    horizontal: mxglobals.mxConstants.ALIGN_LEFT,
    vertical: mxglobals.mxConstants.ALIGN_TOP,
  };

  public static alignBottomLeft: Align = {
    horizontal: mxglobals.mxConstants.ALIGN_LEFT,
    vertical: mxglobals.mxConstants.ALIGN_BOTTOM,
  };

  static makeCellOverlay(
    data: { url: string, tooltip: string },
    align: Align,
    offset: { x: number, y: number },
    size: { w: number, h: number },
    onClick?: () => void) {

    const img = new mxglobals.mxImage(data.url, size.w, size.h);

    const overlay: mxgraph.mxCellOverlay = new mxglobals.mxCellOverlay(img, data.tooltip);
    if (align) {
      overlay.verticalAlign = align.vertical;
      overlay.align = align.horizontal;
    }
    if (offset) {
      overlay.offset = new mxglobals.mxPoint(offset.x, offset.y);
    }
    overlay.cursor = 'default';
    if (onClick) {
      overlay.cursor = 'pointer';
      overlay.addListener(mxglobals.mxEvent.CLICK, () => onClick());
    }
    return overlay;
  };

}
