import { parseResponseError, parseResponseJSON } from 'shared/parse';
import { StorageCacheService } from '../StorageCacheService';
import { ILicenseInfo } from 'shared/ILicenseInfo';

export class ApiService {

  public static getIdToken: () => Promise<string>;
  public static configure(getIdToken: () => Promise<string>) {
    this.getIdToken = getIdToken;
  }

  public static async fetch<T>(path: string, method: 'get' | 'post' | 'delete', data?: any, cacheKey?: string): Promise<T> {

    if (cacheKey) {
      const val: T = StorageCacheService.getValue(cacheKey);
      if (val) {
        return val;
      }
    }

    const token = await this.getIdToken();
    const authorization = token ? `Bearer ${token}` : '';

    const request: any = (data instanceof FormData) 
    ? {
      headers: {
        'Accept': 'application/json',
        'Authorization': authorization
      },
      method,
      body: data,
      mode: 'cors',
      cache: 'default'
    } : {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authorization
      },
      body: data && JSON.stringify(data),
      mode: 'cors',
      cache: 'default'
    };

    const response = await fetch(path, request);
    if (response.ok) {
      const result = await parseResponseJSON(response);
      if (cacheKey) {
        StorageCacheService.setValue(cacheKey, result);
      }
      return result;
    } else {
      throw await parseResponseError(response);
    }
  }

  public static async fetchImage(url: string, token: string): Promise<Blob> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        mode: 'cors',
        cache: 'default'
      }
    };
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
    try {
      const response = await fetch(url, options);
      const blob = await response.blob();
      return blob;
    } catch (err) {
      const proxyUrl = `/image?url=${encodeURIComponent(url)}`;
      const response = await fetch(proxyUrl, options);
      const blob = await response.blob();
      return blob;
    }
  }

  public static async getLicense() {
    const result = await ApiService.fetch<ILicenseInfo>(`/plan/license`, 'get');
    return result;
  }
}
