import { IOpenaiMessage } from 'shared/api/openai';
import { ApiService } from './ApiService';
import { TextPlanJson } from 'shared/PlanGenerationModel';

export class ApiOpenaiChatService {

  public static async getAssistantPlan(messages: IOpenaiMessage[]): Promise<TextPlanJson> {
    const json = await ApiService.fetch('/openai/generate', 'post', { messages }) as TextPlanJson;
    return json;
  }

}
