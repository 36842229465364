import { AuthService } from 'services/AuthService';
import { IExecutableAction } from 'services/IExecutableAction';
import { UrlService } from 'shared/urlService';
import { StorageCacheService } from 'services/StorageCacheService';
import { IVistoPlan, VistoKind } from 'sp';

export const MigrateSourceOriginalPlanId = (foundPlan: IVistoPlan): IExecutableAction => ({
  title: `Fill Original Plan ID`,
  execute: async (p) => {

    const sp = AuthService.getSpClient(foundPlan.siteUrl);

    const planListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.Plan);
    const planItems = await sp.web.getList(planListUrl).select('VISTO_ParentPlan_Id').items.filter(`VISTO_Plan_Id eq '${foundPlan.planId}'`).top(1)();
    
    const topPlanId = planItems.length > 0 ? planItems[0]['VISTO_ParentPlan_Id'] : null;
    if (topPlanId == null) {
      const soListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.SO);
      const soSpItems = await sp.web.getList(soListUrl).items.top(2000)();
      for (const soSpItem of soSpItems) {

        const soId = soSpItem['ID'];
        const originalPlanId = soSpItem['Origin'];
        if (!originalPlanId) {
          sp.web.getList(soListUrl).items.getById(soId).update({ Origin: foundPlan.planId });
        }
      }

      const krListUrl = UrlService.getListRelativeUrl(foundPlan, VistoKind.KeyResult);
      const krSpItems = await sp.web.getList(krListUrl).items.top(2000)();
      for (const krSpItem of krSpItems) {

        const krId = krSpItem['ID'];
        const originalPlanId = krSpItem['Origin'];
        if (!originalPlanId) {
          sp.web.getList(krListUrl).items.getById(krId).update({ Origin: foundPlan.planId });
        }
      }
    }

    StorageCacheService.resetCache();
    return p;
  }
});
