import * as React from 'react';
import { IButtonStyles, MessageBar, MessageBarButton, Stack } from '@fluentui/react';
import { Commands } from 'services/Commands';
import { INotification, NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { AppContext } from 'services/AppContext';
import { trackClient } from 'services/trackClient';
import { getErrorMessage } from 'shared/parse';
import { NotificationActionButton } from './NotificationActionButton';
import strings from 'VistoWebPartStrings';
import { translateInfoBarType } from './translateInfoBarType';

export const useErrorInfo = (): [INotification, (s: INotification) => void] => {

  const [errorInfo, _setErrorInfo] = React.useState<INotification>(null);

  const setErrorInfo = (info: INotification) => {
    if (info) {
      switch (info.type) {
        case NotificationType.error:
          trackClient.error(info.message, info.error);
          break;
        case NotificationType.warn:
          trackClient.warn(info.message, info.error);
          break;
        case NotificationType.success:
        case NotificationType.log:
          trackClient.log(info.message);
          break;
      }

    }
    _setErrorInfo(info);
  };
  return [errorInfo, setErrorInfo];
};

export function InfoBar(props: INotification & { onDismiss?: () => void }) {

  if (!props.message)
    return null;

  const messageBarType = translateInfoBarType(props.type);

  const [details, setDetails] = React.useState<string>('');
  React.useEffect(() => {
    if (props.error) {
      getErrorMessage(props.error).then(setDetails);
    } else {
      setDetails('');
    }
  }, [props.error]);

  const appContext = React.useContext(AppContext);

  const notificationButtonStyles: IButtonStyles = { root: { padding: 's2 s1', whiteSpace: 'nowrap', marginBottom: 4, marginLeft: '8px!important', minHeight: 22 } };

  return props.actions?.some(a => a.done) ? null : (
    <MessageBar
      messageBarType={messageBarType}
      isMultiline={true}
      truncated={false}
      onDismiss={props.onDismiss}
      styles={{ root: { minHeight: 'auto' }, dismissal: { margin: 'm m 0 0' } }}
      actions={(props.actions?.length > 0 || props.guid) &&
        <Stack horizontal wrap style={{ display: 'inline-block', marginTop: 8 }} tokens={{ childrenGap: 's1' }} >
          {props.guid && <MessageBarButton styles={notificationButtonStyles} onClick={() => Commands.setSelection(appContext, props.guid)}>{TextService.format(strings.InfoBar_SelectItemButton)}</MessageBarButton>}
          {props.actions?.length > 0 && props.actions.map((action, index) => <NotificationActionButton styles={notificationButtonStyles} key={index} action={action} />)}
        </Stack>
      }
    >
      <Stack tokens={{ childrenGap: 's2' }} style={{ maxHeight: 100, overflow: 'auto', marginRight: 8 }} >
        <strong dangerouslySetInnerHTML={{ __html: props.message }}></strong>
        <span dangerouslySetInnerHTML={{ __html: details }}></span>
      </Stack>
    </MessageBar>
  );
}
