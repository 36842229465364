import * as React from 'react';

import { IVistoPlan, VistoDpItem, VistoLopItem } from 'sp';
import { ItemEditDialog } from 'dialogs/common';
import { TitleBlock } from 'components';
import { FontSizes, Stack } from '@fluentui/react';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { ProgressService } from 'services/ProgressService';
import { trackClient } from 'services/trackClient';
import { AssigneePicker } from './common/AssigneePicker';
import { StorageService } from 'services/StorageService';

export function EditLopDialog(props: {
  plan: IVistoPlan;
  lop: VistoLopItem;
  drop?: VistoLopItem;
  onDismiss: (changed: boolean, lop: VistoLopItem) => void;
}) {

  React.useEffect(() => trackClient.page('EditLopDialog'), []);

  const { isPlanEditEnabled, dispatchCommand, notify } = React.useContext(AppContext);

  const oldLop = props.lop ?? props.drop;
  const [newLop, setNewLop] = React.useState<VistoDpItem>({ ...oldLop });

  return (
    <ItemEditDialog
      onDismiss={(changed) => props.onDismiss(changed, newLop)}
      onLoaded={loaded => setNewLop({ ...newLop, ...loaded })}
      plan={props.plan}
      oldItem={oldLop}
      newItem={newLop}
      onCommit={() => props.drop ? Promise.resolve(props.plan) : dispatchCommand(Commands.makeSaveCommand(oldLop, newLop, notify), { wrap: false })}
      validationIssues={!newLop.name}
    >
      <Stack tokens={{ childrenGap: 's1' }}>
        <TitleBlock
          fontSize={FontSizes.xLarge}
          edit={isPlanEditEnabled}
          nameDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newLop, 'name')}
          descriptionDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newLop, 'description')}
          name={newLop.name}
          description={newLop.description}
          onNameChanged={val => setNewLop({ ...newLop, name: val })}
          onDescriptionChanged={val => newLop.description = val}
          assignedToDisabled={!isPlanEditEnabled || !ProgressService.allowEdit(newLop, 'assignedTo') || !StorageService.get(props.plan.siteUrl).assigneeSupported}
          onAssignedToChanged={assignedTo => setNewLop({ ...newLop, assignedTo: assignedTo })}
          assignedTo={newLop.assignedTo}
        />
      </Stack>
    </ItemEditDialog>);

}
