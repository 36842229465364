import React from 'react';
import { ActionButton, FontSizes, IButtonStyles, Link, Stack, Text, useTheme } from '@fluentui/react';
import { StorageService } from 'services/StorageService';
import { RichTextTooltipHost } from 'components';
import { IVistoPlan } from 'sp';

export const PlanNameBlock = (props: {
  plan: IVistoPlan;
  item: { name?: string, description?: string, originalPlanId?: string };
  large?: boolean;
  bold?: boolean;
  iconName?: string;
  disabled?: boolean;
  onClick?: () => void;
}) => {

  const { plan, item, iconName, disabled, onClick } = props;

  const [planName, setPlanName] = React.useState('');
  React.useEffect(() => {
    StorageService.get(plan.siteUrl).getPlanItems(plan.siteUrl, ['planId', 'name', 'topPlanId'], 'short').then(planItems => {
      const originalPlan = planItems.find(x => x.planId === item.originalPlanId);
      if (originalPlan && originalPlan.planId !== plan.planId) {
        setPlanName(originalPlan.name);
      }
    })
  }, [plan.siteUrl, plan.planId]);

  const onLocationClick = () => {

  };

  const { palette } = useTheme();

  const titleStyles: IButtonStyles = {
    root: {
      textAlign: 'left',
      fontWeight: props.bold ? 'bold' : 'normal',
      height: 'auto',
      fontSize: props.large ? FontSizes.xLarge : FontSizes.medium
    },
    flexContainer: {
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };

  const locationStyles: IButtonStyles = {
    root: {
      height: 'auto',
      color: palette.neutralTertiary,
      fontSize: FontSizes.small
    }
  };

  return (
    <Stack horizontal verticalAlign='center' horizontalAlign='start'>
      <RichTextTooltipHost tooltip={item.description} >
        <ActionButton styles={titleStyles} disabled={disabled} onClick={onClick} iconProps={{ iconName }}>{item.name}</ActionButton>
      </RichTextTooltipHost>
      <RichTextTooltipHost tooltip={planName} >
        <ActionButton styles={locationStyles} disabled={disabled} onClick={onLocationClick}>{planName}</ActionButton>
      </RichTextTooltipHost>
    </Stack >
  )
}
