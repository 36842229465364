import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useTheme } from '@fluentui/react';
import { CSSProperties } from 'react';

export const useAddCommentStyles = () => { // eslint-disable-line @typescript-eslint/explicit-function-return-type
  const { palette } = useTheme();

  const componentClasses = mergeStyleSets({
    container: {
      borderWidth: 1,
      borderStyle: 'inset',
      display: 'block',
      borderColor: 'silver',
      overflow: 'hidden',
      ':focus': {
        borderColor: palette.themePrimary,
      },
      ':hover': {
        borderColor: palette.themePrimary,
      },
    } as CSSProperties,
  });

  const mentionsClasses = mergeStyleSets({
    mention: {
      position: 'relative',
      zIndex: 9999,
      color: palette.themePrimary,
      pointerEvents: 'none',
    } as CSSProperties,
  });

  const reactMentionStyles = {
    control: {
      backgroundColor: '#fff',
      fontSize: 12,
      border: 'none',
      fontWeight: 'normal',
      flex: 1,
      outlineColor: palette.themePrimary,
      borderRadius: 0,
    } as CSSProperties,
    '&multiLine': {
      control: {
        border: 'none',
        fontFamily:
          `'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue'`,
        minHeight: 35,
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 0,
      } as CSSProperties,
      highlighter: {
        padding: 9,
        border: 'none',
        borderWidth: 0,
        borderRadius: 0,
      } as CSSProperties,
      input: {
        padding: 9,
        border: 'none',
        outline: 'none',
      } as CSSProperties,
    },

    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid',
        borderBottomColor: palette.themeLight,
        '&focused': {
          backgroundColor: palette.neutralLighterAlt,
        },
      },
    },
  };

  return {
    reactMentionStyles,
    mentionsClasses,
    componentClasses,
  };
};
