import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { OkrTree } from './OkrTree';
import { CommandButton, ContextualMenu, IContextualMenuItem, IContextualMenuProps, SearchBox, Stack, Toggle, TooltipHost } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';
import { StatusBarZoom } from 'frames/TopFrame/statusbar/StatusBarZoom';
import { ViewAlignment } from 'frames/TopFrame/drawing/common/mx';
import { IBasicNotify } from 'services/Notify';
import styles from './OkrFrame.module.scss';
import { UserInfoService } from 'services/UserInfoService';
import { IOkrCardInfo } from './IOkrCardInfo';
import { TreeService } from 'services/TreeService';
import { AppContext } from 'services/AppContext';
import { PlanDataService } from 'services/PlanDataService';
import { IFieldValueUser, IVistoListItem, IVistoListItemWithAssignee, KeyResultType, VistoActionItem, VistoAssocItem, VistoKeyResultItem, VistoKind, VistoSoItem } from 'sp';
import { EditItemDialog } from 'components/EditItemDialog';
import { renderToString } from 'react-dom/server';
import { IRenderCardData } from './IRenderCardData';
import { OkrCardSO } from './OkrCardSO';
import { OkrCardKR } from './OkrCardKR';
import { OkrCardAction } from './OkrCardAction';
import { ProgressService } from 'services/ProgressService';
import { OkrLegend } from './OkrLegend';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { KeyResultService } from 'services/KeyResultService';
import { EditKeyResultDialog } from 'dialogs/EditKeyResultDialog';
import { makeGuidString } from 'shared/guid';
import { CommandsKeyResult, IKeyResultChanges } from 'services/CommandsKeyResult';
import { ConfirmDeleteDialog } from 'dialogs/common';
import { Commands } from 'services/Commands';
import { EditActionDialog } from 'dialogs';
import { useExpand } from 'services/useExpand';
import { TopFilter } from 'components/TopFilter';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { SidebarMenuItemDisabled } from 'frames/TopFrame/sidebars/common/SidebarMenuItemDisabled';

export const OkrFrame = (props: {
  readOnly: boolean;
  notifications: IBasicNotify
}) => {

  const [newKeyResult, setNewKeyResult] = React.useState<VistoKeyResultItem>(null);
  const [newAction, setNewAction] = React.useState<VistoActionItem>(null);
  const [newAssoc, setNewAssoc] = React.useState<VistoAssocItem>(null);
  const [deleteItem, setDeleteItem] = React.useState<IVistoListItem>(null);

  const { planRef, propertyBag, isPlanEditEnabled, isPopupOpen, setIsPopupOpen, dispatchCommand, notify } = React.useContext(AppContext);
  React.useEffect(() => {
    setIsPopupOpen(!!newKeyResult || !!deleteItem || !!newAction);
  }, [newKeyResult, deleteItem, newAction]);

  const isPopupOpenDisabled = SidebarMenuItemDisabled.isPopupOpenDisabled(isPopupOpen);
  const isEditDisabled = SidebarMenuItemDisabled.isEditDisabled(isPlanEditEnabled) ?? isPopupOpenDisabled

  const [allCardInfos, setAllCardInfos] = React.useState<IOkrCardInfo[]>([]);


  const { userObjectId, tid, isMobile } = React.useContext(EnvContext);

  const onConnect = React.useCallback(() => { }, []);
  const onDisconnect = React.useCallback(() => { }, []);

  const addAssigneeNames = (userNameSet: any, item: IVistoListItemWithAssignee) => {
    if (item.assignedTo) {
      for (const user of item.assignedTo) {
        userNameSet[user.userName] = user;
      }
    }
  }

  const [expandedCards, setExpandedCards] = useExpand<IOkrCardInfo>(`OkrView_ExpandedCards_${planRef.current.planId}`);

  const [menuProps, setMenuProps] = React.useState<IContextualMenuProps>();
  const [selectedUser, setSelectedUser] = React.useState<IFieldValueUser>(null);

  const load = async () => {

    const plan = planRef.current;

    const sos = PlanDataService.getItems<VistoSoItem>(plan.items, VistoKind.SO);

    const assocList = PlanDataService.getItems<VistoAssocItem>(plan.items, VistoKind.Assoc);

    const newCardInfos: IOkrCardInfo[] = [];

    const userNameSet = {};

    for (const so of sos) {

      const krs = PlanDataService
        .getItemsHaving<VistoKeyResultItem>(plan.items, x => x.kind === VistoKind.KeyResult && x.soGuid === so.guid && !x.parentKrGuid);

      const soActions = assocList
        .filter(x => x.soGuid === so.guid && x.actionGuid && !x.krGuid)
        .map(assoc => PlanDataService.getItemByGuid<VistoActionItem>(plan.items, assoc.actionGuid))
        .filter(x => x);

      newCardInfos.push({
        key: so.guid,
        item: so,
        children: krs.map(x => ({ childRef: `${so.guid}#${x.guid}` })).concat(soActions.map(x => ({ childRef: `${so.guid}#${x.guid}` })))
      });

      addAssigneeNames(userNameSet, so);

      for (const action of soActions) {

        newCardInfos.push({
          key: `${so.guid}#${action.guid}`,
          item: action,
          children: []
        });

        addAssigneeNames(userNameSet, action);
      }

      for (const kr of krs) {

        const kpis = PlanDataService.getItemsHaving<VistoKeyResultItem>(plan.items, x => x.kind == VistoKind.KeyResult && x.parentKrGuid === kr.guid && x.soGuid === so.guid);

        const krActions = assocList
          .filter(x => x.soGuid === so.guid && x.actionGuid && x.krGuid === kr.guid)
          .map(assoc => PlanDataService.getItemByGuid<VistoActionItem>(plan.items, assoc.actionGuid))
          .filter(x => x);

        newCardInfos.push({
          key: `${so.guid}#${kr.guid}`,
          item: kr,
          children: kpis.map(x => ({ childRef: `${so.guid}#${kr.guid}#${x.guid}` })).concat(krActions.map(x => ({ childRef: `${so.guid}#${kr.guid}#${x.guid}` })))
        });

        for (const action of krActions) {

          newCardInfos.push({
            key: `${so.guid}#${kr.guid}#${action.guid}`,
            item: action,
            children: []
          });

          addAssigneeNames(userNameSet, action);
        }

        addAssigneeNames(userNameSet, kr);

        for (const kpi of kpis) {

          const kpiActions = assocList
            .filter(x => x.soGuid === so.guid && x.actionGuid && x.krGuid === kpi.guid)
            .map(assoc => PlanDataService.getItemByGuid<VistoActionItem>(plan.items, assoc.actionGuid))
            .filter(x => x);

          newCardInfos.push({
            key: `${so.guid}#${kr.guid}#${kpi.guid}`,
            item: kpi,
            children: kpiActions.map(x => ({ childRef: `${so.guid}#${kr.guid}#${kpi.guid}#${x.guid}` }))
          });

          addAssigneeNames(userNameSet, kpi);

          for (const action of kpiActions) {

            newCardInfos.push({
              key: `${so.guid}#${kr.guid}#${kpi.guid}#${action.guid}`,
              item: action,
              children: []
            });

            addAssigneeNames(userNameSet, action);
          }
        }
      }
    }

    const users = Object.values<IFieldValueUser>(userNameSet);
    await UserInfoPhotoService.cacheUserPictures(users);

    setMenuProps({
      items: [
        {
          key: 'Clear',
          text: TextService.format(strings.OkrFilter_ClearOwner),
          iconProps: { iconName: 'Clear' },
          onClick: () => {
            setSelectedUser(null);
          }
        },
        ...users.map(x => ({
          key: x.userName,
          text: x.title,
          iconProps: { iconName: 'Contact' },
          onClick: () => {
            setSelectedUser(x);
          }
        }))
      ]
    });
    setAllCardInfos(newCardInfos);
  };

  React.useEffect(() => {
    UserInfoService.configure(tid, props.notifications);
    load();
  }, [userObjectId, planRef.current]);

  const [textFilter, setTextFilter] = React.useState(localStorage.getItem('DashboardView_OkrFilter') || '');
  const [currentTextFilter, setCurrentTextFilter] = React.useState(textFilter);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      localStorage.setItem('DashboardView_OkrFilter', currentTextFilter);
      setTextFilter(currentTextFilter)
    }, 400);
    return () => clearTimeout(timeout);
  }, [currentTextFilter]);

  const [showCompleted, _setShowCompleted] = React.useState(localStorage.getItem('DashboardView_Completed') == 'true');
  const setShowCompleted = (val: boolean) => {
    _setShowCompleted(val);
    localStorage.setItem('DashboardView_Completed', val ? 'true' : 'false');
  };
  const filterCompletedFn = (ci: IOkrCardInfo) => {
    return showCompleted || (ci.item.kind != VistoKind.Action) || !ProgressService.isCompleted(ci.item as VistoActionItem);
  }

  const filterByTextFn = (ci: IOkrCardInfo) => {
    return !textFilter || ci.item.name && ci.item.name.toLowerCase().includes(textFilter.toLowerCase());
  };

  const filterByUserFn = (ci: IOkrCardInfo) => {
    return !selectedUser || !!(ci.item.assignedTo && ci.item.assignedTo.find(x => x.userName === selectedUser.userName));
  }

  const getKindSortOrder = React.useCallback((x: VistoKind) => {
    switch (x) {
      case VistoKind.SO:
        return 1;
      case VistoKind.KeyResult:
        return 2;
      case VistoKind.Action:
        return 3;
      default:
        return 4;
    }
  }, []);

  const sortFn = React.useCallback((a: IOkrCardInfo, b: IOkrCardInfo) => {
    const aKind = getKindSortOrder(a.item.kind);
    const bKind = getKindSortOrder(b.item.kind);
    if (aKind != bKind) {
      return aKind - bKind;
    }
    return TextService.compareNames(a.item.name, b.item.name);
  }, []);

  const [editorUi, setEditorUi] = React.useState(null);

  const cardInfos = React.useMemo(() => {
    let result = allCardInfos.filter(filterCompletedFn);
    result = TreeService.filterCards(result, filterByUserFn);
    result = TreeService.filterCards(result, filterByTextFn);
    result = result.sort(sortFn);
    return result;
  }, [allCardInfos, showCompleted, selectedUser, textFilter]);

  const [itemToEdit, setItemToEdit] = React.useState<IVistoListItem>(null);

  const decodeOperation = (target: HTMLElement) => {
    if (target.classList.contains('expand')) {
      return 'expand';
    } else if (target.classList.contains('menu')) {
      return 'menu';
    } else {
      return 'edit';
    }
  }

  const getItemExpandCollapse = React.useCallback((ci: IOkrCardInfo) => {
    return {
      key: 'Expand',
      disabled: !ci.children.length,
      text: TextService.format(expandedCards[ci.key] ? strings.MenuItem_Collapse : strings.MenuItem_Expand),
      iconProps: { iconName: 'Plus' },
      onClick: () => toggleCard(ci)
    };
  }, [expandedCards]);

  const getMenuItemNewKeyResult = React.useCallback((ci: IOkrCardInfo, soGuid: string, krGuid: string) => {
    return MenuItems.getNewKeyResultMenuItem(() => {
      const kr: VistoKeyResultItem = {
        kind: VistoKind.KeyResult,
        guid: makeGuidString(),
        soGuid,
        parentKrGuid: krGuid,
        units: '%',
        keyResultType: KeyResultType.Numeric,
        originalPlanId: planRef.current.planId
      };
      setNewKeyResult(kr);
      setExpandedCards({ ...expandedCards, [ci.key]: ci });
    }, isEditDisabled)
  }, []);

  const getMenuItemNewAction = React.useCallback((ci: IOkrCardInfo, soGuid: string, krGuid: string) => {

    const activeFocus = PlanDataService.getActiveFocus(planRef.current);

    return MenuItems.getNewActionMenuItem(() => {
      const action: VistoActionItem = {
        kind: VistoKind.Action,
        guid: makeGuidString(),
        dpGuid: null,
        lopGuid: null,
        effectGuid: null,
        assignedTo: [],
        sortOrder: 0,
        focusGuid: activeFocus?.guid ?? null,
        useFocusDates: !!activeFocus,
        startDate: activeFocus?.startDate ?? null,
        endDate: activeFocus?.endDate ?? null,
      };
      setNewAction(action);
      const assoc: VistoAssocItem = {
        kind: VistoKind.Assoc,
        guid: makeGuidString(),
        krGuid: krGuid,
        soGuid: soGuid,
        actionGuid: action.guid,
        confidence: 9
      }
      setNewAssoc(assoc);
      setExpandedCards({ ...expandedCards, [ci.key]: ci });
    }, [], isEditDisabled)
  }, []);

  const getItemMenuSo = React.useCallback((ci: IOkrCardInfo) => {
    const item: VistoSoItem = ci.item as VistoSoItem;
    return [
      getItemExpandCollapse(ci),
      getMenuItemNewKeyResult(ci, item.guid, null),
      getMenuItemNewAction(ci, item.guid, null),
      MenuItems.getDeleteSoMenuItem(() => setDeleteItem(ci.item), isEditDisabled),
    ];
  }, [expandedCards]);

  const getItemMenuKr = React.useCallback((ci: IOkrCardInfo) => {
    const item: VistoKeyResultItem = ci.item as VistoKeyResultItem;
    return [
      getItemExpandCollapse(ci),
      ...(item.parentKrGuid ? [] : [getMenuItemNewKeyResult(ci, item.soGuid, item.guid)]),
      getMenuItemNewAction(ci, item.soGuid, item.guid),
      MenuItems.getDeleteKeyResultMenuItem(() => setDeleteItem(ci.item), isEditDisabled),
    ]
  }, [expandedCards]);

  const getItemMenuAction = React.useCallback((ci: IOkrCardInfo) => {
    return [
      MenuItems.getDeleteActionMenuItem(() => setDeleteItem(ci.item), isEditDisabled),
    ]
  }, [expandedCards]);

  const getItemMenu = React.useCallback((ci: IOkrCardInfo) => {
    switch (ci.item.kind) {
      case VistoKind.SO:
        return getItemMenuSo(ci);
      case VistoKind.KeyResult:
        return getItemMenuKr(ci);
      case VistoKind.Action:
        return getItemMenuAction(ci);
      default:
        return [];
    }
  }, [expandedCards]);

  const toggleCard = React.useCallback((ci: IOkrCardInfo) => {
    const newExpandedCards = { ...expandedCards }
    if (newExpandedCards[ci.key]) {
      delete newExpandedCards[ci.key];
    } else {
      newExpandedCards[ci.key] = ci;
    }
    setExpandedCards(newExpandedCards);
  }, [expandedCards]);

  const onClick = React.useCallback((ci: IOkrCardInfo, target: HTMLElement) => {
    switch (decodeOperation(target)) {
      case 'expand':
        toggleCard(ci);
        break;
      case 'menu':
        setMenuInfo({ target: target.getBoundingClientRect(), menuItems: getItemMenu(ci) });
        break;
      case 'edit':
        setItemToEdit(ci.item);
        break;
    }
  }, [expandedCards]);

  const onDismiss = (changed) => {
    setItemToEdit(null);
  };

  const getCardSize = (kind: VistoKind) => {
    switch (kind) {
      case VistoKind.SO:
        return [250, 150];
      case VistoKind.KeyResult:
        return [250, 170];
      case VistoKind.Action:
        return [300, 170];
    }
  }

  const getCardContent = (card: IOkrCardInfo) => {

    const showExpand = card.children.length > 0;
    const expanded = !!expandedCards[card.key];

    switch (card.item.kind) {
      case VistoKind.SO:
        return <OkrCardSO plan={planRef.current} item={card.item as VistoSoItem} showExpand={showExpand} expanded={expanded} />;
      case VistoKind.KeyResult:
        return <OkrCardKR plan={planRef.current} item={card.item as VistoKeyResultItem} showExpand={showExpand} expanded={expanded}
          viewStartDate={propertyBag.viewStartDate} viewEndDate={propertyBag.viewEndDate} />;
      case VistoKind.Action:
        return <OkrCardAction plan={planRef.current} item={card.item as VistoActionItem} />;
    }
  }

  const renderCard = React.useCallback((card: IOkrCardInfo): IRenderCardData => {

    const [width, height] = getCardSize(card.item.kind);

    const markup = getCardContent(card);

    const html = renderToString(markup).replace(/\r|\n/g, '');

    return { width, height, html }

  }, [planRef.current, propertyBag.viewStartDate, propertyBag.viewEndDate, expandedCards]);

  const [menuInfo, setMenuInfo] = React.useState<{
    menuItems: IContextualMenuItem[],
    target: { top: number, left: number }
  }>(null);

  const onSaveKeyResult = (changes: IKeyResultChanges) => {
    const targetsChanges = CommandsKeyResult.getKeyResultListChanges(changes.oldTargets, changes.newTargets);
    return dispatchCommand(CommandsKeyResult.makeKeyResultUpdateCommand(changes.oldKr, changes.newKr, targetsChanges, undefined, notify), { wrap: false });
  };

  const deleteConfirmed = (items: IVistoListItem[]) => {
    return dispatchCommand(Commands.makeDeleteCommand(items, notify), { wrap: false });
  };

  const zoomInfoKey = `OkrView_Zoom_${planRef.current.planId}`;

  return (
    <Stack grow tokens={{ padding: 's1', childrenGap: 's2' }}>
      <Stack verticalAlign='center' horizontal>
        <Stack verticalAlign='center' horizontal grow tokens={{ childrenGap: 's2' }}>
          <TopFilter placeholder={TextService.format(strings.DashboardFilter_SearchBoxPlaceholder)} value={currentTextFilter} setValue={setCurrentTextFilter} />
          <TooltipHost content={TextService.format(strings.OkrFilter_CompletedTooltip)}>
            <Toggle inlineLabel label={isMobile ? '' : TextService.format(strings.OkrFilter_Completed)} checked={showCompleted} onChange={(_, val) => setShowCompleted(!showCompleted)} />
          </TooltipHost>
        </Stack>
        <CommandButton text={isMobile ? '' : (selectedUser?.title ?? TextService.format(strings.OkrFilter_Owner))} iconProps={{ iconName: 'Contact' }} menuProps={menuProps} />
        <StatusBarZoom zoomInfoKey={zoomInfoKey} editorUi={editorUi} viewAlignmentX={ViewAlignment.Middle} viewAlignmentY={ViewAlignment.Begin} />
      </Stack>
      {<Stack horizontal grow>
        {!isMobile && <OkrLegend />}
        <Stack grow>
          <OkrTree
            zoomInfoKey={zoomInfoKey}
            items={cardInfos}
            readOnly={props.readOnly}
            sort={sortFn}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
            setEditorUi={setEditorUi}
            onClick={onClick}
            renderCard={renderCard}
            expandedCards={expandedCards}
          />
        </Stack>
      </Stack>}
      {itemToEdit && <EditItemDialog
        onDismiss={onDismiss}
        plan={planRef.current}
        item={itemToEdit}
      />}
      {menuInfo && <ContextualMenu
        items={menuInfo.menuItems}
        target={menuInfo.target}
        onDismiss={() => setMenuInfo(null)}
      />}
      {newKeyResult && <EditKeyResultDialog
        plan={planRef.current}
        kr={newKeyResult}
        oldTargets={[]}
        newTargets={KeyResultService.makeDeafultResultTargets(planRef.current, newKeyResult)}
        onDismiss={() => setNewKeyResult(null)}
        onCommit={onSaveKeyResult}
        isNew={true}
      />}
      {newAction && <EditActionDialog
        plan={planRef.current}
        action={newAction}
        onDismiss={() => setNewAction(null)}
      />}
      {deleteItem && <ConfirmDeleteDialog
        planItems={planRef.current.items}
        items={[deleteItem, ...PlanDataService.getDependencis(planRef.current.items, deleteItem)]}
        onDelete={deleteConfirmed}
        onDismiss={() => setDeleteItem(null)}
      />}
    </Stack>
  );
};
