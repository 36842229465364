import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import { ICommentCard } from '../IComment';
import { CommentText } from './CommentText';
import { isEmpty } from 'lodash';
import { FontWeights, IconButton, IContextualMenuItem, IStyle, Persona, PersonaSize, useTheme } from '@fluentui/react';
import { TextService } from 'services/TextService';
import { UserInfoPhotoService } from 'services/UserInfoPhotoService';
import { UrlService } from 'shared/urlService';
import { AppContext } from 'services/AppContext';
import { CellKind } from 'shared/CellKind';
import { CommentTargetLabel } from './CommentTargetLabel';

export const CommentItem = (props: {
  card: ICommentCard;
  highlightedCommentId?: string;
  menuItems?: IContextualMenuItem[];
}) => {

  const { planRef, setSelection } = React.useContext(AppContext);
  const { comment } = props.card;
  const { author, createdDate, text, mentions } = comment;

  const [imageUrl, setImageUrl] = React.useState<string>(UserInfoPhotoService.getCachedUserPhotoUrl(author.oid || author.preferred_username));
  React.useEffect(() => {
    if (!imageUrl) {
      UserInfoPhotoService.getUserPhotoUrl(author.oid || author.preferred_username).then(url => {
        if (url) {
          setImageUrl(url)
        }
      });
    }
  }, [author.preferred_username]);

  const selection = UrlService.parseSubEntityId(comment.subEntityId) || { cellKind: CellKind.TITLE, cellId: CellKind[CellKind.TITLE], guid: null };

  const onLinkClick = () => {
    setSelection(selection);
  };

  const theme = useTheme();

  const defaultStyle: IStyle = {
    marginTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow: theme.effects.elevation4,
    borderRadius: theme.effects.roundedCorner4,
  };

  const style = (comment.guid === props.highlightedCommentId)
    ? {
      ...defaultStyle,
      backgroundColor: theme.palette.themeLighter
    } : props.card.disabled
      ? {
        ...defaultStyle,
        backgroundColor: theme.palette.neutralLighterAlt,
        opacity: 0.5,
      }
      : {
        ...defaultStyle,
        backgroundColor: theme.palette.neutralLighter,
      }

  return (
    <Stack styles={{ root: style }}>
      <Stack horizontal verticalAlign='center' grow>
        <Persona hidePersonaDetails imageShouldFadeIn={false} imageUrl={imageUrl} size={PersonaSize.size32} />
        <Stack style={{ paddingLeft: 12 }} grow>
          <Stack horizontal verticalAlign='center'>
            <Stack.Item grow>
              <Text styles={{ root: { color: theme.palette.neutralSecondary } }}>{author.name}</Text>
            </Stack.Item>
            <Text variant='small' styles={{ root: { color: theme.palette.neutralTertiary, fontWeight: FontWeights.bold } }}>{TextService.formatDateTime(createdDate)}</Text>
            {props.menuItems && <IconButton style={{ fontSize: theme.fonts.medium.fontSize }} menuProps={{ items: props.menuItems }} menuIconProps={{ iconName: 'MoreVertical' }} />}
          </Stack>
          <CommentTargetLabel onClick={onLinkClick} plan={planRef.current} selection={selection} />
        </Stack>
      </Stack>
      <CommentText text={text} mentions={mentions} />
    </Stack>
  );
};
