import * as strings from 'VistoWebPartStrings';
import { VistoKeyResultItem, VistoKeyResultValueItem, VistoFocusItem, IVistoPlan, VistoKind, VistoAssocItem, IVistoListItem } from 'sp';
import { ICommand } from 'services/ICommand';
import { StorageService } from './StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { ChangesService, IChanges } from 'services/ChangesService';
import { INotify } from 'services/Notify';
import { TextService } from 'services/TextService';
import { CommandName } from 'shared/CommandName';
import { PlanDataService } from './PlanDataService';
import { IItemChanges } from './Interfaces';

export interface IKeyResultChanges {
  oldKr: VistoKeyResultItem;
  newKr: VistoKeyResultItem;
  oldTargets: VistoKeyResultValueItem[];
  newTargets: VistoKeyResultValueItem[];
}

interface IKeyResultChangesList {
  deleted: VistoKeyResultValueItem[];
  added: VistoKeyResultValueItem[];
  updated: { item: VistoKeyResultValueItem, changes: IChanges<VistoKeyResultValueItem> }[];
}

const spUpdateOptions: IOperationOptions = {
  excludeExternals: true,
  excludeGroupByKind: true,
  excludePercentComplete: true
};

export class CommandsKeyResult {

  private static keyResultEquals = (a: VistoKeyResultValueItem, b: VistoKeyResultValueItem) => {
    const oldValue = a.value ?? '';
    const newValue = b.value ?? '';
    
    const oldValueDate = a.valueDate && TextService.getDate(a.valueDate).toISOString().split('T')[0];
    const newValueDate = b.valueDate && TextService.getDate(b.valueDate).toISOString().split('T')[0];

    const oldDescription = a.description ?? '';
    const newDescription = b.description ?? '';

    return a.guid === b.guid || oldValue == newValue && oldValueDate == newValueDate && oldDescription == newDescription;
  }

  public static getKeyResultListChanges(
    oldKrvs: VistoKeyResultValueItem[], 
    newKrvs: VistoKeyResultValueItem[],
  ): IKeyResultChangesList {

    return {
      deleted: oldKrvs.filter(oldKrv => newKrvs.every(newKrv => !this.keyResultEquals(newKrv, oldKrv))),
      added: newKrvs.filter(newKrv => oldKrvs.every(oldKrv => !this.keyResultEquals(newKrv, oldKrv))),
      updated: newKrvs.map(newKrv => {
      const oldKrv = oldKrvs.find(krv => this.keyResultEquals(krv, newKrv));
      if (oldKrv) {
        const krvChanges = ChangesService.getChanges(oldKrv, newKrv, ['value', 'valueDate', 'description'], { roundDates: true });
        if (krvChanges.detected) {
          return { item: oldKrv, changes: krvChanges };
        }
      }
      }).filter(krv => !!krv)
    };
  }

  private static doKeyResultValueLists = async (p: IVistoPlan, changes: IKeyResultChangesList, notify: INotify) => {
    if (changes?.added?.length > 0) {
      p = await StorageService.get(p.siteUrl).createItems(p, changes.added, notify, spUpdateOptions);
    }
    if (changes?.deleted?.length > 0) {
      p = await StorageService.get(p.siteUrl).deleteItems(p, changes.deleted, notify, spUpdateOptions);
    }
    if (changes?.updated?.length > 0) {
      p = await StorageService.get(p.siteUrl).updateItems(p, changes.updated, notify, { ...spUpdateOptions, reverse: false });
    }
    return p;
  }

  private static undoKeyResultValueList = async (p: IVistoPlan, changes: IKeyResultChangesList, notify: INotify) => {
    if (changes?.added?.length > 0) {
      p = await StorageService.get(p.siteUrl).deleteItems(p, changes.added, notify, spUpdateOptions);
    }
    if (changes?.deleted?.length > 0) {
      p = await StorageService.get(p.siteUrl).createItems(p, changes.deleted, notify, spUpdateOptions);
    }
    if (changes?.updated?.length > 0) {
      p = await StorageService.get(p.siteUrl).updateItems(p, changes.updated, notify, { ...spUpdateOptions, reverse: true });
    }
    return p;
  }

  public static addKeyResultDependentChanges = (p: IVistoPlan, kr: VistoKeyResultItem, changes: IItemChanges<IVistoListItem>[]) => {
    const krGuids = new Set<string>(kr.guid);
    const childKrList = PlanDataService.getItemsHaving<VistoKeyResultItem>(p.items, x => x.kind == VistoKind.KeyResult && x.parentKrGuid == kr.guid);
    for (const childKr of childKrList) {
      const childKrChanges = ChangesService.getChanges(childKr, { soGuid: kr.soGuid });
      changes.push({ item: childKr, changes: childKrChanges });
      krGuids.add(childKr.guid);
    }
    const assocList = PlanDataService.getItemsHaving<VistoAssocItem>(p.items, x => x.kind == VistoKind.Assoc && krGuids.has(x.krGuid));
    for (const assoc of assocList) {
      const assocChanges = ChangesService.getChanges(assoc, { soGuid: kr.soGuid })
      changes.push({ item: assoc, changes: assocChanges });
    }
  }

  public static makeKeyResultUpdateCommand(
    oldKr: VistoKeyResultItem, 
    newKr: VistoKeyResultItem, 
    targetsChanges: IKeyResultChangesList,
    actualsChanges: IKeyResultChangesList,
    notify: INotify): ICommand {

    return {
      prepare: async () => {
        const krChanges = ChangesService.getChanges(oldKr, newKr);
        return {
          do: async (p) => {
            if (krChanges.detected) {

              const changes: IItemChanges<IVistoListItem>[] = [{ item: newKr, changes: krChanges }];
              if (krChanges.properties.indexOf('soGuid') > -1) {
                this.addKeyResultDependentChanges(p, newKr, changes);
              }

              p = (newKr.itemId)
                ? await StorageService.get(p.siteUrl).updateItems(p, changes, notify, spUpdateOptions)
                : await StorageService.get(p.siteUrl).createItems(p, [newKr], notify, spUpdateOptions);
            }
            p = await this.doKeyResultValueLists(p, targetsChanges, notify);
            p = await this.doKeyResultValueLists(p, actualsChanges, notify);
            return p;
          },
          undo: async (p) => {
            p = await this.undoKeyResultValueList(p, actualsChanges, notify);
            p = await this.undoKeyResultValueList(p, targetsChanges, notify);
            if (krChanges.detected) {

              const changes: IItemChanges<IVistoListItem>[] = [{ item: newKr, changes: krChanges }];
              if (krChanges.properties.indexOf('soGuid') > -1) {
                this.addKeyResultDependentChanges(p, newKr, changes);
              }

              p = (newKr.itemId)
                ? await StorageService.get(p.siteUrl).updateItems(p, changes, notify, { ...spUpdateOptions, reverse: true })
                : await StorageService.get(p.siteUrl).deleteItems(p, [newKr], notify, spUpdateOptions);
            }
            return p;
          }
        };
      },
      message: TextService.format(strings.CommandsKeyResult_UpdateCommandMessage),
      name: CommandName.EditKeyResult
    };
  }
}
