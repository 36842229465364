import { IFieldValueUser } from 'sp';
import { ImageCacheService } from './ImageCacheService';
import { UserInfoService } from './UserInfoService';
import { SingleRequest } from './SingleRequest';
import { trackClient } from './trackClient';
import { TokenKind } from 'shared/TokenKind';
import { AuthService } from './AuthService';

export class UserInfoPhotoService {

  public static async cacheUserPictures(assignedTo: IFieldValueUser[]) {
    await Promise.all(assignedTo.map(async (user) => await this.getUserPhotoUrl(user.guid || user.userName)));
  }

  public static getCachedUserPhotoUrl(rawUserObjectId: string) {
    if (!rawUserObjectId)
      return null;

    const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);
    const imageUrl = `visplan://photo/${userObjectId}`;
    return ImageCacheService.getCachedImageUrl(imageUrl);
  }

  public static async getUserPhotoUrl(rawUserObjectId: string) {
    if (rawUserObjectId) {
      const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);

      const imageUrl = `visplan://photo/${userObjectId}`;
      const requestPromise = () => ImageCacheService.getImageAsync(imageUrl, async () => {
        const graph = AuthService.getGraphClient(TokenKind.dashboard, userObjectId);
        try {
          const b = await graph.users.getById(userObjectId).photo.getBlob();
          return b
        } catch (error) {
          trackClient.warn(`Failed to get user photo for ${userObjectId}`, error);
          return null;
        }
      });

      return await SingleRequest.query(imageUrl, requestPromise);
    }
  }
}
